import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export const LogoImage = withStyles({
	root: { width: '10vw', filter: ({ hideShadow }) => (hideShadow ? 'none' : 'drop-shadow(0 0 10px black)') }
})(({ classes, className, alt = 'wisdrop', hideShadow, ...props }) => (
	<img loading="lazy" {...props} alt={alt} className={clsx('w-logo', classes.root)} />
));
LogoImage.displayName = 'LogoImage';
