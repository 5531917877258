"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CommandAddTag = _interopRequireDefault(require("../model/CommandAddTag"));
var _CommandRenameTag = _interopRequireDefault(require("../model/CommandRenameTag"));
var _Tag = _interopRequireDefault(require("../model/Tag"));
var _TagQueryResult = _interopRequireDefault(require("../model/TagQueryResult"));
var _TagsQuery = _interopRequireDefault(require("../model/TagsQuery"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Tags service.
* @module api/TagsApi
* @version 1.1.3-alpha.1
*/
class TagsApi {
  /**
  * Constructs a new TagsApi. 
  * @alias module:api/TagsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new tag
   * Creates a new tag and returns the created object. If the tag already exists, it returns the existing tag
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandAddTag} opts.CommandAddTag 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Tag} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandAddTag'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Tag.default;
    return this.apiClient.callApi('/api/v1/Tags', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new tag
   * Creates a new tag and returns the created object. If the tag already exists, it returns the existing tag
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandAddTag} opts.CommandAddTag 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Tag}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get tag
   * Returns the requested tag
   * @param {Number} id Id of the tag to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Tag} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Tag.default;
    return this.apiClient.callApi('/api/v1/Tags/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get tag
   * Returns the requested tag
   * @param {Number} id Id of the tag to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Tag}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple tags
   * Returns an array of the requested tags
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the tags to get. Not specifiying ids will return all tags
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tag>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Tag.default];
    return this.apiClient.callApi('/api/v1/Tags/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple tags
   * Returns an array of the requested tags
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the tags to get. Not specifiying ids will return all tags
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tag>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple tags referencing the requested id by a certain field
   * Returns a list of tags that are referencing the requested id by a certain field
   * @param {String} field The field of the tags that is referencing the requested id
   * @param {String} id Id of the tags that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tag>} and HTTP response
   */
  getReferencesWithHttpInfo(field, id) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error("Missing the required parameter 'field' when calling getReferences");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getReferences");
    }
    let pathParams = {
      'field': field,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Tag.default];
    return this.apiClient.callApi('/api/v1/Tags/references/{field}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple tags referencing the requested id by a certain field
   * Returns a list of tags that are referencing the requested id by a certain field
   * @param {String} field The field of the tags that is referencing the requested id
   * @param {String} id Id of the tags that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tag>}
   */
  getReferences(field, id) {
    return this.getReferencesWithHttpInfo(field, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query tag
   * Queries tags and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/TagsQuery} opts.TagsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['TagsQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _TagQueryResult.default;
    return this.apiClient.callApi('/api/v1/Tags/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query tag
   * Queries tags and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/TagsQuery} opts.TagsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete tag
   * Deletes a tag and returns the deleted object
   * @param {Number} id Id of the tag to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Tag} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Tag.default;
    return this.apiClient.callApi('/api/v1/Tags/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete tag
   * Deletes a tag and returns the deleted object
   * @param {Number} id Id of the tag to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Tag}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple tags
   * Deletes multiple tags and returns a list of the deleted records
   * @param {Array.<Number>} request_body Ids of the tags to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tag>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Tag.default];
    return this.apiClient.callApi('/api/v1/Tags/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple tags
   * Deletes multiple tags and returns a list of the deleted records
   * @param {Array.<Number>} request_body Ids of the tags to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tag>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update tag
   * Updates a tag and returns the updated object
   * @param {Number} id Id of the tag to update
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandRenameTag} opts.CommandRenameTag 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Tag} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CommandRenameTag'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Tag.default;
    return this.apiClient.callApi('/api/v1/Tags/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update tag
   * Updates a tag and returns the updated object
   * @param {Number} id Id of the tag to update
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandRenameTag} opts.CommandRenameTag 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Tag}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = TagsApi;