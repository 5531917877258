"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MailgunEventPayload = _interopRequireDefault(require("../model/MailgunEventPayload"));
var _MailgunWebhookTestResult = _interopRequireDefault(require("../model/MailgunWebhookTestResult"));
var _ProblemDetails = _interopRequireDefault(require("../model/ProblemDetails"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Mailgun service.
* @module api/MailgunApi
* @version 1.1.3-alpha.1
*/
class MailgunApi {
  /**
  * Constructs a new MailgunApi. 
  * @alias module:api/MailgunApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Webhook for the 'clicked' event
   * Collects the clicked events produced by mailgun
   * @param {Object} opts Optional parameters
   * @param {module:model/MailgunEventPayload} opts.MailgunEventPayload 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  eventClickedWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['MailgunEventPayload'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = null;
    return this.apiClient.callApi('/webhooks/Mailgun/events/clicked', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Webhook for the 'clicked' event
   * Collects the clicked events produced by mailgun
   * @param {Object} opts Optional parameters
   * @param {module:model/MailgunEventPayload} opts.MailgunEventPayload 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  eventClicked(opts) {
    return this.eventClickedWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Webhook for the 'delivered' event
   * Collects the clicked events produced by mailgun
   * @param {Object} opts Optional parameters
   * @param {module:model/MailgunEventPayload} opts.MailgunEventPayload 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  eventDeliveredWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['MailgunEventPayload'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = null;
    return this.apiClient.callApi('/webhooks/Mailgun/events/delivered', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Webhook for the 'delivered' event
   * Collects the clicked events produced by mailgun
   * @param {Object} opts Optional parameters
   * @param {module:model/MailgunEventPayload} opts.MailgunEventPayload 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  eventDelivered(opts) {
    return this.eventDeliveredWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Webhook for the 'opened' event
   * Collects the clicked events produced by mailgun
   * @param {Object} opts Optional parameters
   * @param {module:model/MailgunEventPayload} opts.MailgunEventPayload 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  eventOpenedWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['MailgunEventPayload'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = null;
    return this.apiClient.callApi('/webhooks/Mailgun/events/opened', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Webhook for the 'opened' event
   * Collects the clicked events produced by mailgun
   * @param {Object} opts Optional parameters
   * @param {module:model/MailgunEventPayload} opts.MailgunEventPayload 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  eventOpened(opts) {
    return this.eventOpenedWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Test mailgun webhooks
   * Tests if mailgun webhooks controller is working
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MailgunWebhookTestResult} and HTTP response
   */
  testWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _MailgunWebhookTestResult.default;
    return this.apiClient.callApi('/webhooks/Mailgun', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Test mailgun webhooks
   * Tests if mailgun webhooks controller is working
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MailgunWebhookTestResult}
   */
  test() {
    return this.testWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = MailgunApi;