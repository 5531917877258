import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { memo } from 'react';

export const WisdropTabs = memo(({ children, className, ...props }) => {
	const classes = useTabsStyles();
	return (
		<div {...props} className={clsx(classes.root, className, 'wisdrop-tabs')}>
			{children}
		</div>
	);
});

export const Tab = memo(({ children, active, className, ...props }) => {
	const classes = useTabStyles();
	return (
		<div
			{...props}
			className={clsx('wisdrop-tab', classes.root, className, {
				[classes.active]: !!active,
				'wisdrop-tab--active': !!active
			})}>
			{children}
		</div>
	);
});

const useTabsStyles = makeStyles(theme => ({
	root: {
		fontSize: '0.6em',
		backgroundColor: 'rgb(108, 108, 108)',
		borderRadius: '2.3vw',
		height: '2.3vw',
		display: 'flex',
		flexWrap: 'nowrap',
		[theme.breakpoints.up('lg')]: { borderRadius: '1.9vw', height: '1.9vw' }
	}
}));

const useTabStyles = makeStyles(theme => ({
	root: {
		'flexGrow': 1,
		'flexShrink': 1,
		'flexBasis': 'auto',
		'position': 'relative',
		'textAlign': 'center',
		'whiteSpace': 'nowrap',
		'cursor': 'pointer',
		'borderRadius': '2.3vw',
		'height': '2.3vw',
		'paddingLeft': '1.5vw',
		'paddingRight': '1.5vw',
		[theme.breakpoints.up('lg')]: { borderRadius: '1.9vw', height: '1.9vw' },
		'transition': theme.transitions.create(['background-color', 'border'], {
			duration: '0.2s',
			easing: theme.transitions.easing.easeInOut
		}),
		'& button': {
			backgroundColor: 'transparent',
			border: 'none',
			outline: 'none',
			width: '100%'
		},
		'& a, & button, & .fa': {
			fontSize: 'inherit',
			fontFamily: 'HelveticaNeue-Thin',
			color: '#FFFFFF',
			display: 'block',
			textAlign: 'center',
			transition: theme.transitions.create(['color'], {
				duration: '0.2s',
				easing: theme.transitions.easing.easeIn
			})
		},
		'& a, & button': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '100%'
		},
		'&:hover': {
			'& a, & button, & .fa': {
				color: '#000000',
				textDecoration: 'none',
				fontWeight: 'bold'
			}
		}
	},
	active: {
		'boxShadow': '0 0 6px rgba(0, 0, 0, 0.4)',
		'backgroundColor': '#FFFFFF',
		'border': '1px solid rgb(169, 169, 169)',
		'height': '2.5vw',
		'borderRadius': '2.5vw',
		'marginTop': '-0.05vw',
		[theme.breakpoints.up('lg')]: { borderRadius: '2.1vw', height: '2.1vw', marginTop: '-0.1vw' },
		'& a, & button, & .fa': {
			fontFamily: 'Helvetica',
			color: '#000000',
			fontWeight: 600
		}
	}
}));
