"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GetActiveUserStatsFilteredResultQueryResult = _interopRequireDefault(require("../model/GetActiveUserStatsFilteredResultQueryResult"));
var _ManageUserContactCommand = _interopRequireDefault(require("../model/ManageUserContactCommand"));
var _UserStatsMediaRequest = _interopRequireDefault(require("../model/UserStatsMediaRequest"));
var _UserStatsQuery = _interopRequireDefault(require("../model/UserStatsQuery"));
var _ViewUserStats = _interopRequireDefault(require("../model/ViewUserStats"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* UserStats service.
* @module api/UserStatsApi
* @version 1.1.3-alpha.1
*/
class UserStatsApi {
  /**
  * Constructs a new UserStatsApi. 
  * @alias module:api/UserStatsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Manages a customer's contact
   * Manages admin and suspend state of a customer's contact
   * @param {String} id Id of the user to manage
   * @param {Object} opts Optional parameters
   * @param {module:model/ManageUserContactCommand} opts.ManageUserContactCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ViewUserStats} and HTTP response
   */
  manageWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['ManageUserContactCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling manage");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _ViewUserStats.default;
    return this.apiClient.callApi('/api/v1/UserStats/{id}/manage', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Manages a customer's contact
   * Manages admin and suspend state of a customer's contact
   * @param {String} id Id of the user to manage
   * @param {Object} opts Optional parameters
   * @param {module:model/ManageUserContactCommand} opts.ManageUserContactCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ViewUserStats}
   */
  manage(id, opts) {
    return this.manageWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Download user statistics of a media
   * Returns user statistics for media in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/UserStatsMediaRequest} opts.UserStatsMediaRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  mediaDownloadWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['UserStatsMediaRequest'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/UserStats/media/download', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Download user statistics of a media
   * Returns user statistics for media in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/UserStatsMediaRequest} opts.UserStatsMediaRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  mediaDownload(opts) {
    return this.mediaDownloadWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query User stats
   * Queries user stats and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/UserStatsQuery} opts.UserStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetActiveUserStatsFilteredResultQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['UserStatsQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _GetActiveUserStatsFilteredResultQueryResult.default;
    return this.apiClient.callApi('/api/v1/UserStats/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query User stats
   * Queries user stats and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/UserStatsQuery} opts.UserStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetActiveUserStatsFilteredResultQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query User stats in excel format
   * Queries user stats and returns a filtered, sorted and paged result in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/UserStatsQuery} opts.UserStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  queryDownloadWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['UserStatsQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/UserStats/query/download', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query User stats in excel format
   * Queries user stats and returns a filtered, sorted and paged result in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/UserStatsQuery} opts.UserStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  queryDownload(opts) {
    return this.queryDownloadWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete a customer's contact
   * Deletes a customer's contact and returns the deleted object
   * @param {String} id Id of the user to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ViewUserStats} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ViewUserStats.default;
    return this.apiClient.callApi('/api/v1/UserStats/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete a customer's contact
   * Deletes a customer's contact and returns the deleted object
   * @param {String} id Id of the user to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ViewUserStats}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = UserStatsApi;