"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FAVORITES = exports.DISLIKES = void 0;
Object.defineProperty(exports, "INDUSTRIES", {
  enumerable: true,
  get: function () {
    return _industries.default;
  }
});
exports.LIKES = void 0;
Object.defineProperty(exports, "MEDIA", {
  enumerable: true,
  get: function () {
    return _media.default;
  }
});
exports.default = exports.MY_MY_LIST = exports.MY_LIST = void 0;
var _industries = _interopRequireDefault(require("./industries"));
var _media = _interopRequireDefault(require("./media"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FAVORITES = 'favorites';
exports.FAVORITES = FAVORITES;
const MY_LIST = 'myList';
exports.MY_LIST = MY_LIST;
const MY_MY_LIST = 'my_myList';
exports.MY_MY_LIST = MY_MY_LIST;
const LIKES = 'likes';
exports.LIKES = LIKES;
const DISLIKES = 'dislikes';
exports.DISLIKES = DISLIKES;
var _default = {
  MEDIA: _media.default,
  INDUSTRIES: _industries.default,
  FAVORITES,
  MY_LIST,
  MY_MY_LIST,
  LIKES,
  DISLIKES
};
exports.default = _default;