"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunUserVariablesInfo model module.
 * @module model/MailgunUserVariablesInfo
 * @version 1.1.3-alpha.1
 */
class MailgunUserVariablesInfo {
  /**
   * Constructs a new <code>MailgunUserVariablesInfo</code>.
   * @alias module:model/MailgunUserVariablesInfo
   * @extends Object
   */
  constructor() {
    MailgunUserVariablesInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunUserVariablesInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunUserVariablesInfo} obj Optional instance to populate.
   * @return {module:model/MailgunUserVariablesInfo} The populated <code>MailgunUserVariablesInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunUserVariablesInfo();
      _ApiClient.default.constructFromObject(data, obj, 'Object');
      if (data.hasOwnProperty('environment')) {
        obj['environment'] = _ApiClient.default.convertToType(data['environment'], 'String');
      }
      if (data.hasOwnProperty('notificationId')) {
        obj['notificationId'] = _ApiClient.default.convertToType(data['notificationId'], 'Number');
      }
      if (data.hasOwnProperty('notificationType')) {
        obj['notificationType'] = _ApiClient.default.convertToType(data['notificationType'], 'String');
      }
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = _ApiClient.default.convertToType(data['userId'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunUserVariablesInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunUserVariablesInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['environment'] && !(typeof data['environment'] === 'string' || data['environment'] instanceof String)) {
      throw new Error("Expected the field `environment` to be a primitive type in the JSON string but got " + data['environment']);
    }
    // ensure the json data is a string
    if (data['notificationType'] && !(typeof data['notificationType'] === 'string' || data['notificationType'] instanceof String)) {
      throw new Error("Expected the field `notificationType` to be a primitive type in the JSON string but got " + data['notificationType']);
    }
    // ensure the json data is a string
    if (data['userId'] && !(typeof data['userId'] === 'string' || data['userId'] instanceof String)) {
      throw new Error("Expected the field `userId` to be a primitive type in the JSON string but got " + data['userId']);
    }
    return true;
  }
}

/**
 * @member {String} environment
 */
MailgunUserVariablesInfo.prototype['environment'] = undefined;

/**
 * @member {Number} notificationId
 */
MailgunUserVariablesInfo.prototype['notificationId'] = undefined;

/**
 * @member {String} notificationType
 */
MailgunUserVariablesInfo.prototype['notificationType'] = undefined;

/**
 * @member {String} userId
 */
MailgunUserVariablesInfo.prototype['userId'] = undefined;
var _default = exports.default = MailgunUserVariablesInfo;