const cache = {
	urls: {},
	onFly: {}
};

export const getPrefetched = url => (cache.urls[url] ? url : null);

export const prefetchUrl = url => {
	var result = cache.urls[url];
	if (result) return Promise.resolve(url);
	var prom = cache.onFly[url];
	if (!prom) {
		cache.onFly[url] = prom = fetch(url, { mode: 'no-cors' })
			.then(r => {
				cache.urls[url] = true;
				return Promise.resolve(url);
			})
			.catch(e => {
				console.error(e);
				return Promise.resolve(url);
			})
			.finally(() => {
				delete cache.onFly[url];
			});
	}
	return prom;
};
