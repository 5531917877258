import { actionTypes } from 'redux-resource';

export const SET_LIST_INCLUSION_PENDING = 'SET_LIST_INCLUSION_PENDING';

export const createInclusionListAction = (ids, resourceType, list) => ({
	setPending: pending => ({
		type: SET_LIST_INCLUSION_PENDING,
		resourceType: resourceType,
		ids: ids,
		list: list,
		pending: pending
	})
});

const setListMeta = ({ meta, newMeta, merge }) => {
	if (typeof newMeta === 'object') {
		if (merge) return { ...meta, ...newMeta };
		return { ...newMeta };
	}
	return newMeta(meta);
};

const setListsMeta = ({ meta, list, newMeta, merge }) => {
	var { [list]: listMeta, ...restLists } = meta;
	return {
		...restLists,
		[list]: setListMeta({ meta: listMeta || {}, newMeta, merge })
	};
};

const setResourceListMeta = ({ meta, list, newMeta, merge }) => {
	var { lists, ...restMeta } = meta;
	return {
		...restMeta,
		lists: setListsMeta({ meta: lists || {}, list, newMeta, merge })
	};
};

const setResourcesListMeta = ({ resources, meta, list, newMeta, mergeMeta }) => {
	if (!resources || resources.length === 0) return meta;

	var merge = typeof mergeMeta === 'undefined' ? true : !!mergeMeta;

	var nextMeta = { ...meta };
	resources.forEach(
		id =>
			(nextMeta[id] = setResourceListMeta({
				meta: nextMeta[id] || {},
				list: list,
				newMeta: newMeta,
				merge: merge
			}))
	);
	return nextMeta;
};

export function listInclusion(resType) {
	return (state, action) => {
		const { type, resourceType, list, lists, resources, mergeListIds } = action;
		var idsInList;

		const isReadType = type === actionTypes.READ_RESOURCES_SUCCEEDED;
		const isCreateType = type === actionTypes.CREATE_RESOURCES_SUCCEEDED;
		const isUpdateType = type === actionTypes.UPDATE_RESOURCES_SUCCEEDED;
		const isImmediateUpdate = type === actionTypes.UPDATE_RESOURCES;
		var meta = state.meta;

		if (isReadType || isCreateType || isUpdateType) {
			if (resourceType !== resType || !list || !resources) return state;

			var rss = Array.isArray(resources) ? resources : [resources];
			idsInList = rss.map(r => (typeof r === 'object' ? r.id : r));

			meta = setResourcesListMeta({
				resources: idsInList,
				meta: meta,
				list: list,
				newMeta: { included: true },
				mergeMeta: true
			});

			if (!mergeListIds) {
				// we need to remove the inclusion for the resources that are not in the list
				var inListIdIndex = {};
				idsInList.forEach(id => (inListIdIndex[id] = true));

				meta = setResourcesListMeta({
					resources: Object.keys(state.meta).filter(id => !inListIdIndex[id]),
					meta: meta,
					list: list,
					newMeta: { included: false },
					mergeMeta: true
				});
			}

			return {
				...state,
				meta: meta
			};
		}

		if (isImmediateUpdate) {
			var resourceLists = lists[resType];
			if (!resourceLists) return state;

			var listNames = Object.keys(resourceLists);
			for (var l = 0; l < listNames.length; ++l) {
				var listName = listNames[l];
				meta = calculateNewMeta(resourceLists[listName], listName, meta, state.meta);
			}

			return {
				...state,
				meta: meta
			};
		}

		return state;
	};
}

export function listInclusionPending(resType) {
	return (state, action) => {
		var { type, resourceType, ids, list, pending } = action;

		if (type !== SET_LIST_INCLUSION_PENDING || resourceType !== resType || !ids || !list) return state;

		if (!Array.isArray(ids)) ids = [ids];
		else if (ids.length === 0) return state;

		return {
			...state,
			meta: setResourcesListMeta({
				resources: ids,
				meta: state.meta,
				list: list,
				newMeta: { pending: !!pending },
				mergeMeta: true
			})
		};
	};
}

function calculateNewMeta(ids, list, meta, stateMeta) {
	var result = meta;
	result = setResourcesListMeta({
		resources: ids,
		list: list,
		newMeta: { included: true },
		meta: result,
		mergeMeta: true
	});
	var index = {};
	ids.forEach(id => (index[id] = true));
	result = setResourcesListMeta({
		resources: Object.keys(stateMeta).filter(id => !index[id]),
		list: list,
		newMeta: { included: false },
		meta: result,
		mergeMeta: true
	});
	return result;
}
