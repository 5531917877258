import { push } from 'redux-first-history';
import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { authLoggedIn } from '../state/auth';
import { wrapInLoading } from '../state/loading';
import { getModalParameters, modalActionCreators } from '../state/modals';
import { getFormSubmissionActions } from '../state/resources';
import {
	SIGN_IN_FORGOT_PASSWORD,
	SIGN_IN_PICK_USER_SUBMIT,
	SIGN_IN_SUBMIT,
	signInModalClose,
	signInPickUserSubmitResolved,
	signInSubmitResolved,
	signInToggleOpen
} from '../state/sign-in';
import { notifyError } from './flow-utils';

const signInPickUserSubmitWorker = wrapInLoading(function* signInPickUserSubmitWorker({
	payload: { username } = {},
	successRedirect,
	failureRedirect
} = {}) {
	let api = yield getContext('api');

	try {
		let users = yield call(api.submitFrontEndPickLoginUser, { username });
		yield put(signInPickUserSubmitResolved());
		yield put(signInToggleOpen({ parameters: users }));
	} catch (e) {
		if (!(e.status === 401 || e.status === 402)) yield notifyError(e);
		yield call(persistToken, null);
		yield call(openSessionExpired, e, signInPickUserSubmitResolved);
		if (failureRedirect) yield put(push(failureRedirect.call ? failureRedirect() : failureRedirect));
	}
});

const signInSubmitWorker = wrapInLoading(function* signInSubmitWorker({
	payload = {},
	successRedirect,
	failureRedirect
} = {}) {
	let api = yield getContext('api');

	const { userId, password } = payload;

	try {
		let { code, authToken, user, ...rest } = yield call(api.submitFrontEndSignIn, { userId, password });
		yield put(signInSubmitResolved());

		// εδώ πρέπει να ελεγχθεί αν code === 'TWO_STEP_REQUIRED'.
		if (code === 'TWO_STEP_REQUIRED') {
			var { open } = modalActionCreators('pinRequestModal');
			yield put(open({ successRedirect, failureRedirect }));
		} else yield call(processLoginSuccess, authToken, api, user, rest, successRedirect);
	} catch (e) {
		if (!(e.status === 401 || e.status === 402)) yield notifyError(e);
		yield call(persistToken, null);
		yield call(openSessionExpired, e, signInSubmitResolved);
		if (failureRedirect) yield put(push(failureRedirect.call ? failureRedirect() : failureRedirect));
	}
});

const requestPINWorker = wrapInLoading(function* requestPINWorker({ payload: { pin } = {} } = {}) {
	let api = yield getContext('api');
	var { successRedirect, failureRedirect } = yield select(state => getModalParameters(state, 'pinRequestModal'));
	var { close } = modalActionCreators('pinRequestModal');
	var { completed } = getFormSubmissionActions('FORM_REQUEST_PIN');

	try {
		let { authToken, user, ...rest } = yield call(api.sessionLogin, pin);
		// εδώ έχει γίνει επιτυχημένο authentication

		yield put(close());
		yield put(completed());
		yield call(processLoginSuccess, authToken, api, user, rest, successRedirect);
	} catch (e) {
		if (!(e.status === 401 || e.status === 402)) yield notifyError(e);
		yield call(persistToken, null);
		yield call(openSessionExpired, e, completed);
		if (failureRedirect) yield put(push(failureRedirect.call ? failureRedirect() : failureRedirect));
	}
});

export function* openSessionExpired(e, resolve) {
	if (e?.status === 402) {
		var { open } = modalActionCreators('sessionExpiredModal');
		yield put(open());
		yield put(resolve());
	} else yield put(resolve(e));
}

function* processLoginSuccess(authToken, api, user, rest, redirect) {
	yield call(persistToken, authToken);
	try {
		yield call(api.logSignIn);
	} catch (ee) {
		console.error(ee);
	}
	yield put(authLoggedIn({ apiKey: authToken, user: user, ...rest }, redirect));
	if (redirect) yield put(push(redirect.call ? redirect() : redirect));
}

function* persistToken(token) {
	let { save: saveToken } = yield getContext('tokenPersisor');
	yield call(saveToken, token);
}

const signForgotPasswordWorker = wrapInLoading(function* signForgotPasswordWorker({
	payload: { email } = {},
	successRedirect,
	failureRedirect
} = {}) {
	let api = yield getContext('api');
	var { open } = modalActionCreators('actionResultModal');
	var { close: closeForgotPassword } = modalActionCreators('forgotPasswordModal');
	try {
		let res = yield call(api.requestResetPassword, email);

		if (res && res.success)
			yield put(
				open({
					success: true,
					title: 'Reset password',
					message:
						'A password reset link has been sent to your email. The link will remain valid for about 30 minutes'
				})
			);
		else
			yield put(
				open({
					success: false,
					title: 'Failure',
					message:
						res === 'NOT_FOUND'
							? 'This email does not correspond to a wisdrop account'
							: 'An unexpected error occured. Please try again later'
				})
			);
	} catch (e) {
		yield notifyError(e);
		yield put(open({ success: false, message: 'An unexpected error occured. Please try again later' }));
	} finally {
		try {
			yield put(signInModalClose());
			yield put(closeForgotPassword());
		} catch {}
	}
});

const resetPasswordWorker = wrapInLoading(function* resetPasswordWorker({
	payload: { passwordResetToken, newPassword } = {},
	successRedirect,
	failureRedirect
} = {}) {
	let api = yield getContext('api');
	var { close } = modalActionCreators('resetPasswordModal');
	var { open } = modalActionCreators('actionResultModal');
	var { completed } = getFormSubmissionActions('FORM_RESET_PASSWORD');
	try {
		let { success, expired } = yield call(api.resetPassword, passwordResetToken, newPassword);
		if (success)
			yield put(
				open({
					success: true,
					title: 'Reset password',
					message: 'Your password has been successfully reset. You can now sign in with your new password'
				})
			);
		else if (expired)
			yield put(
				open({
					success: false,
					title: 'Failure',
					message: 'The link has expired. Please follow the reset password procedure again'
				})
			);
		else
			yield put(
				open({
					success: false,
					title: 'Failure',
					message: 'The link has expired. Please follow the reset password procedure again'
				})
			);
	} catch (e) {
		yield notifyError(e);
		yield put(open({ success: false, message: 'An unexpected error occured. Please try again later' }));
	} finally {
		try {
			yield put(close());
		} catch {}
		try {
			yield put(completed());
		} catch {}
	}
});

// a watcher function that watches for a certain action and fires a saga for it
export const signInSubmitWatcher = function* signInSubmitWatcher() {
	yield takeEvery(SIGN_IN_SUBMIT, signInSubmitWorker);
};
export const signInPickUserSubmitWatcher = function* signInPickUserSubmitWatcher() {
	yield takeEvery(SIGN_IN_PICK_USER_SUBMIT, signInPickUserSubmitWorker);
};
export const signForgotPasswordWatcher = function* signForgotPasswordWatcher() {
	yield takeEvery(SIGN_IN_FORGOT_PASSWORD, signForgotPasswordWorker);
};
export const resetPasswordWatcher = function* signForgotPasswordWatcher() {
	var { submitAction } = getFormSubmissionActions('FORM_RESET_PASSWORD');
	yield takeEvery(submitAction, resetPasswordWorker);
};

export const requestPinSubmitted = function* requestPinSubmitted() {
	var { submitAction } = getFormSubmissionActions('FORM_REQUEST_PIN');
	yield takeEvery(submitAction, requestPINWorker);
};
