"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BusinessCustomerAddressQueryResult = _interopRequireDefault(require("../model/BusinessCustomerAddressQueryResult"));
var _CommandCustomerChangeAdScoresAccessibility = _interopRequireDefault(require("../model/CommandCustomerChangeAdScoresAccessibility"));
var _CommandCustomerChangeMediaAccessibility = _interopRequireDefault(require("../model/CommandCustomerChangeMediaAccessibility"));
var _CreateCustomerCommand = _interopRequireDefault(require("../model/CreateCustomerCommand"));
var _Customer = _interopRequireDefault(require("../model/Customer"));
var _CustomerAddressQuery = _interopRequireDefault(require("../model/CustomerAddressQuery"));
var _CustomerContactQuery = _interopRequireDefault(require("../model/CustomerContactQuery"));
var _CustomerContactQueryResult = _interopRequireDefault(require("../model/CustomerContactQueryResult"));
var _CustomerGetAdScoreAccessibilityParams = _interopRequireDefault(require("../model/CustomerGetAdScoreAccessibilityParams"));
var _CustomerGetMediaAccessibilityParams = _interopRequireDefault(require("../model/CustomerGetMediaAccessibilityParams"));
var _CustomerQueryResult = _interopRequireDefault(require("../model/CustomerQueryResult"));
var _CustomersQuery = _interopRequireDefault(require("../model/CustomersQuery"));
var _EntityOneToMenyReferences = _interopRequireDefault(require("../model/EntityOneToMenyReferences"));
var _GetAdScoreAccessibilityViewResult = _interopRequireDefault(require("../model/GetAdScoreAccessibilityViewResult"));
var _GetMediaAccessibilityViewResult = _interopRequireDefault(require("../model/GetMediaAccessibilityViewResult"));
var _SubscriptionQuery = _interopRequireDefault(require("../model/SubscriptionQuery"));
var _SubscriptionQueryResult = _interopRequireDefault(require("../model/SubscriptionQueryResult"));
var _UpdateCustomerCommand = _interopRequireDefault(require("../model/UpdateCustomerCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Customers service.
* @module api/CustomersApi
* @version 1.1.3-alpha.1
*/
class CustomersApi {
  /**
  * Constructs a new CustomersApi. 
  * @alias module:api/CustomersApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Change accessibility of adscores for current customer
   * Changes the accessibility for the requested adscores of the current customer
   * @param {String} id Id of the customer whose resource accessibility to change
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandCustomerChangeAdScoresAccessibility} opts.CommandCustomerChangeAdScoresAccessibility 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Boolean}>} and HTTP response
   */
  changeAdScoresAccessibilityWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CommandCustomerChangeAdScoresAccessibility'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling changeAdScoresAccessibility");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = {
      'String': 'Boolean'
    };
    return this.apiClient.callApi('/api/v1/Customers/{id}/accessibility/adscores', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Change accessibility of adscores for current customer
   * Changes the accessibility for the requested adscores of the current customer
   * @param {String} id Id of the customer whose resource accessibility to change
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandCustomerChangeAdScoresAccessibility} opts.CommandCustomerChangeAdScoresAccessibility 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Boolean}>}
   */
  changeAdScoresAccessibility(id, opts) {
    return this.changeAdScoresAccessibilityWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Change accessibility of media for current customer
   * Changes the accessibility for the requested media of the current customer
   * @param {String} id Id of the customer whose resource accessibility to change
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandCustomerChangeMediaAccessibility} opts.CommandCustomerChangeMediaAccessibility 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Boolean}>} and HTTP response
   */
  changeMediaAccessibilityWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CommandCustomerChangeMediaAccessibility'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling changeMediaAccessibility");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = {
      'String': 'Boolean'
    };
    return this.apiClient.callApi('/api/v1/Customers/{id}/accessibility/media', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Change accessibility of media for current customer
   * Changes the accessibility for the requested media of the current customer
   * @param {String} id Id of the customer whose resource accessibility to change
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandCustomerChangeMediaAccessibility} opts.CommandCustomerChangeMediaAccessibility 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Boolean}>}
   */
  changeMediaAccessibility(id, opts) {
    return this.changeMediaAccessibilityWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create a new customers
   * Creates a new customer and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerCommand} opts.CreateCustomerCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateCustomerCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Customer.default;
    return this.apiClient.callApi('/api/v1/Customers', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new customers
   * Creates a new customer and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerCommand} opts.CreateCustomerCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Exports adscores in excel format
   * Exports adscores and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomersQuery} opts.CustomersQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  exportFileWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CustomersQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/Customers/export', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Exports adscores in excel format
   * Exports adscores and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomersQuery} opts.CustomersQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  exportFile(opts) {
    return this.exportFileWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get customers
   * Returns the requested customer
   * @param {String} id Id of the customer to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Customer.default;
    return this.apiClient.callApi('/api/v1/Customers/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get customers
   * Returns the requested customer
   * @param {String} id Id of the customer to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get adscore accessibility of current customer
   * Returns the adscore accessibility data for current customer
   * @param {String} id Id of the customer whose media accessibility info to get
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerGetAdScoreAccessibilityParams} opts.CustomerGetAdScoreAccessibilityParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/GetAdScoreAccessibilityViewResult>} and HTTP response
   */
  getAdScoresAccessibilityWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CustomerGetAdScoreAccessibilityParams'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAdScoresAccessibility");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_GetAdScoreAccessibilityViewResult.default];
    return this.apiClient.callApi('/api/v1/Customers/{id}/accessibility/adscores', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get adscore accessibility of current customer
   * Returns the adscore accessibility data for current customer
   * @param {String} id Id of the customer whose media accessibility info to get
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerGetAdScoreAccessibilityParams} opts.CustomerGetAdScoreAccessibilityParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/GetAdScoreAccessibilityViewResult>}
   */
  getAdScoresAccessibility(id, opts) {
    return this.getAdScoresAccessibilityWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a specific customer list
   * Returns an array of the customers included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Customer>} and HTTP response
   */
  getListWithHttpInfo(listName) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling getList");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Customer.default];
    return this.apiClient.callApi('/api/v1/Customers/list/{listName}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a specific customer list
   * Returns an array of the customers included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Customer>}
   */
  getList(listName) {
    return this.getListWithHttpInfo(listName).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple customer lists
   * Returns a dictionary of listnames to customer lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: [Customer]}>} and HTTP response
   */
  getListsWithHttpInfo(names) {
    let postBody = null;
    // verify the required parameter 'names' is set
    if (names === undefined || names === null) {
      throw new Error("Missing the required parameter 'names' when calling getLists");
    }
    let pathParams = {};
    let queryParams = {
      'names': this.apiClient.buildCollectionParam(names, 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': [_Customer.default]
    };
    return this.apiClient.callApi('/api/v1/Customers/lists', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple customer lists
   * Returns a dictionary of listnames to customer lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: [Customer]}>}
   */
  getLists(names) {
    return this.getListsWithHttpInfo(names).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple customers
   * Returns an array of requested customers
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the customers to get. Not specifiying ids will return all customers
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Customer>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Customer.default];
    return this.apiClient.callApi('/api/v1/Customers/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple customers
   * Returns an array of requested customers
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the customers to get. Not specifiying ids will return all customers
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Customer>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media accessibility of current customer
   * Returns the media accessibility data for current customer
   * @param {String} id Id of the customer whose media accessibility info to get
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerGetMediaAccessibilityParams} opts.CustomerGetMediaAccessibilityParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/GetMediaAccessibilityViewResult>} and HTTP response
   */
  getMediaAccessibilityWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CustomerGetMediaAccessibilityParams'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getMediaAccessibility");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_GetMediaAccessibilityViewResult.default];
    return this.apiClient.callApi('/api/v1/Customers/{id}/accessibility/media', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media accessibility of current customer
   * Returns the media accessibility data for current customer
   * @param {String} id Id of the customer whose media accessibility info to get
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerGetMediaAccessibilityParams} opts.CustomerGetMediaAccessibilityParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/GetMediaAccessibilityViewResult>}
   */
  getMediaAccessibility(id, opts) {
    return this.getMediaAccessibilityWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get refrenced entities (one-to-many) of the requested customer
   * Returns Get refrenced entities (one-to-many) of the requested customer
   * @param {String} id The id of the customer whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EntityOneToMenyReferences} and HTTP response
   */
  getOneToManyReferencesWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getOneToManyReferences");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _EntityOneToMenyReferences.default;
    return this.apiClient.callApi('/api/v1/Customers/{id}/onetomanyreferences', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get refrenced entities (one-to-many) of the requested customer
   * Returns Get refrenced entities (one-to-many) of the requested customer
   * @param {String} id The id of the customer whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EntityOneToMenyReferences}
   */
  getOneToManyReferences(id) {
    return this.getOneToManyReferencesWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query customers
   * Queries customers and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomersQuery} opts.CustomersQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CustomersQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _CustomerQueryResult.default;
    return this.apiClient.callApi('/api/v1/Customers/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query customers
   * Queries customers and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomersQuery} opts.CustomersQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query customers's addresses
   * Queries the specified customer's addresses and returns a filtered, sorted and paged result. This is equivalent to querying addresses directly and adding an extra filter for customer id
   * @param {String} id Id of the customer whose addresses to query
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerAddressQuery} opts.CustomerAddressQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BusinessCustomerAddressQueryResult} and HTTP response
   */
  queryAddressesWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CustomerAddressQuery'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling queryAddresses");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BusinessCustomerAddressQueryResult.default;
    return this.apiClient.callApi('/api/v1/Customers/{id}/addresses/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query customers's addresses
   * Queries the specified customer's addresses and returns a filtered, sorted and paged result. This is equivalent to querying addresses directly and adding an extra filter for customer id
   * @param {String} id Id of the customer whose addresses to query
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerAddressQuery} opts.CustomerAddressQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BusinessCustomerAddressQueryResult}
   */
  queryAddresses(id, opts) {
    return this.queryAddressesWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query customers's contacts
   * Queries the specified customer's contacts and returns a filtered, sorted and paged result. This is equivalent to querying contacts directly and adding an extra filter for customer id
   * @param {String} id Id of the customer whose contacts to query
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerContactQuery} opts.CustomerContactQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerContactQueryResult} and HTTP response
   */
  queryContactsWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CustomerContactQuery'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling queryContacts");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _CustomerContactQueryResult.default;
    return this.apiClient.callApi('/api/v1/Customers/{id}/contacts/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query customers's contacts
   * Queries the specified customer's contacts and returns a filtered, sorted and paged result. This is equivalent to querying contacts directly and adding an extra filter for customer id
   * @param {String} id Id of the customer whose contacts to query
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerContactQuery} opts.CustomerContactQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerContactQueryResult}
   */
  queryContacts(id, opts) {
    return this.queryContactsWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query customers's subscriptions
   * Queries the specified customer's subscriptions and returns a filtered, sorted and paged result. This is equivalent to querying subscriptions directly and adding an extra filter for customer id
   * @param {String} id Id of the customer whose subscriptions to query
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionQuery} opts.SubscriptionQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionQueryResult} and HTTP response
   */
  querySubscriptionsWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['SubscriptionQuery'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling querySubscriptions");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SubscriptionQueryResult.default;
    return this.apiClient.callApi('/api/v1/Customers/{id}/subscriptions/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query customers's subscriptions
   * Queries the specified customer's subscriptions and returns a filtered, sorted and paged result. This is equivalent to querying subscriptions directly and adding an extra filter for customer id
   * @param {String} id Id of the customer whose subscriptions to query
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionQuery} opts.SubscriptionQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionQueryResult}
   */
  querySubscriptions(id, opts) {
    return this.querySubscriptionsWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete customer
   * Deletes a customer and returns the deleted object
   * @param {String} id Id of the customer to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Customer.default;
    return this.apiClient.callApi('/api/v1/Customers/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete customer
   * Deletes a customer and returns the deleted object
   * @param {String} id Id of the customer to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple customers
   * Deletes multiple customers and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the customers to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Customer>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Customer.default];
    return this.apiClient.callApi('/api/v1/Customers/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple customers
   * Deletes multiple customers and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the customers to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Customer>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Suspend multiple customers
   * Suspends multiple customers and returns a list of the suspended customer ids
   * @param {Array.<String>} request_body Ids of the customers to suspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  suspendManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling suspendMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/Customers/suspend', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Suspend multiple customers
   * Suspends multiple customers and returns a list of the suspended customer ids
   * @param {Array.<String>} request_body Ids of the customers to suspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  suspendMany(request_body) {
    return this.suspendManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Unsuspend multiple customers
   * Unsuspends multiple customers and returns a list of the unsuspended customer ids
   * @param {Array.<String>} request_body Ids of the customers to unsuspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  unsuspendManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling unsuspendMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/Customers/unsuspend', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Unsuspend multiple customers
   * Unsuspends multiple customers and returns a list of the unsuspended customer ids
   * @param {Array.<String>} request_body Ids of the customers to unsuspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  unsuspendMany(request_body) {
    return this.unsuspendManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update customers
   * Updates a customer and returns the updated object
   * @param {String} id Id of the customers to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerCommand} opts.UpdateCustomerCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateCustomerCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Customer.default;
    return this.apiClient.callApi('/api/v1/Customers/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update customers
   * Updates a customer and returns the updated object
   * @param {String} id Id of the customers to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerCommand} opts.UpdateCustomerCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = CustomersApi;