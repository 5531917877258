import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { learnMoreSubmitFailure, learnMoreSubmitSuccess, LEARN_MORE_SUBMIT } from '../state/learn-more';
import { wrapInLoading } from '../state/loading';
import { RESOURCE } from '@extend/paywall-api/lib';
import { notifyError } from './flow-utils';

// sagas (workflows). They represent long running (asynchronous) flows where the application communicates with the outside world. Can be thought of as background jobs.
// While a saga (workflow) is being executed it can dispatch actions to the store with `yield put`, and so the state can change during the saga execution.
// sagas are always asynchronous and are created as generator functions (function*(){} syntax)
const learnMoreSubmitWorker = wrapInLoading(function* learnMoreSubmitWorker({
	payload: { name, company, email, country } = {}
} = {}) {
	try {
		let api = yield getContext('api');
		let ga = yield getContext('ga');
		ga.event({
			category: 'Landing',
			action: 'LearnMore',
			label: company,
			value: `${name} - ${company} - ${email} - ${country}`
		});
		let data = yield call(api.resourceCreate, RESOURCE.LEARN_MORE, { name, company, email, country });

		yield put(learnMoreSubmitSuccess(data)); // `put` only dispatches the action to the store. It does not wait on it.
		// If one saga must fire another saga you have to`call` on the one to the other in order to wait.
		// If another saga starts from dispatching an action from this saga, but you have to wait for the inner saga to complete, you cannot do it within the same saga with `yield put`.
		// The second saga has to complete and fire an action that will trigger a third saga which will be the continuation of the first saga.
	} catch (e) {
		yield notifyError(e);
		yield put(learnMoreSubmitFailure(e));
	}
});

// a watcher function that watches for a certain action and fires a saga for it
export const learnMoreSubmitWatcher = function* learnMoreSubmitWatcher() {
	yield takeEvery(LEARN_MORE_SUBMIT, learnMoreSubmitWorker);
};
