"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBrowserId = void 0;
var _uuid = _interopRequireDefault(require("uuid4"));
var _jsStorage = _interopRequireDefault(require("js-storage"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const BROWSER_ID_KEY = 'wisdrop--browser-id';
const keyCache = {
  key: null
};
const getBrowserId = () => {
  let {
    key
  } = keyCache;
  if (!key) {
    // read key from local storage
    key = _jsStorage.default.localStorage.get(BROWSER_ID_KEY);
    if (!key) {
      // generate new key
      const ts = new Date().getTime();
      const id = (0, _uuid.default)();
      key = `${id}-${ts}`;
      _jsStorage.default.localStorage.set(BROWSER_ID_KEY, key);
    }
    keyCache.key = key;
  }
  return key;
};
exports.getBrowserId = getBrowserId;