/* eslint-disable no-useless-escape */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import 'moment/locale/el';
import { initReactI18next } from 'react-i18next';

moment.updateLocale('el', {
	months: {
		format:
			'Ιανουάριο_Φεβρουάριο_Μάρτιο_Απρίλιο_Μάιο_Ιούνιο_Ιούλιο_Αύγουστο_Σεπτέμβριο_Οκτώβριο_Νοέμβριο_Δεκέμβριο'.split(
				'_'
			),
		standalone:
			'Ιανουάριος_Φεβρουάριος_Μάρτιος_Απρίλιος_Μάιος_Ιούνιος_Ιούλιος_Αύγουστος_Σεπτέμβριος_Οκτώβριος_Νοέμβριος_Δεκέμβριος'.split(
				'_'
			),
		isFormat: /(([oO][fF])||([iI][nN]))(\[[^\[\]]*\]|\s+)+MMMM/
	}
});

i18n.on('languageChanged', locale => moment.locale(locale)); // sync moment with i18n
i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		namespaces: ['translation', 'error', 'common'],
		defaultNS: 'translation',
		fallbackNS: 'common',
		fallbackLng: ['en', 'el'],
		// debug: true,
		returnEmptyString: false,
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
			queryStringParams: { v: '0.2' }
		},
		react: {
			//bindI18n: 'languageChanged',
			//bindI18nStore: '',
			//transEmptyNodeValue: '',
			transSupportBasicHtmlNodes: true,
			//transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
			useSuspense: false
		}
	});

export default i18n;
