"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunDeliveryStatusInfo model module.
 * @module model/MailgunDeliveryStatusInfo
 * @version 1.1.3-alpha.1
 */
class MailgunDeliveryStatusInfo {
  /**
   * Constructs a new <code>MailgunDeliveryStatusInfo</code>.
   * @alias module:model/MailgunDeliveryStatusInfo
   */
  constructor() {
    MailgunDeliveryStatusInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunDeliveryStatusInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunDeliveryStatusInfo} obj Optional instance to populate.
   * @return {module:model/MailgunDeliveryStatusInfo} The populated <code>MailgunDeliveryStatusInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunDeliveryStatusInfo();
      if (data.hasOwnProperty('tls')) {
        obj['tls'] = _ApiClient.default.convertToType(data['tls'], 'Boolean');
      }
      if (data.hasOwnProperty('mx-host')) {
        obj['mx-host'] = _ApiClient.default.convertToType(data['mx-host'], 'String');
      }
      if (data.hasOwnProperty('code')) {
        obj['code'] = _ApiClient.default.convertToType(data['code'], 'Number');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('session-seconds')) {
        obj['session-seconds'] = _ApiClient.default.convertToType(data['session-seconds'], 'Number');
      }
      if (data.hasOwnProperty('utf8')) {
        obj['utf8'] = _ApiClient.default.convertToType(data['utf8'], 'Boolean');
      }
      if (data.hasOwnProperty('retry-seconds')) {
        obj['retry-seconds'] = _ApiClient.default.convertToType(data['retry-seconds'], 'Number');
      }
      if (data.hasOwnProperty('enhanced-code')) {
        obj['enhanced-code'] = _ApiClient.default.convertToType(data['enhanced-code'], 'String');
      }
      if (data.hasOwnProperty('attempt-no')) {
        obj['attempt-no'] = _ApiClient.default.convertToType(data['attempt-no'], 'Number');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = _ApiClient.default.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('certificate-verified')) {
        obj['certificate-verified'] = _ApiClient.default.convertToType(data['certificate-verified'], 'Boolean');
      }
      if (data.hasOwnProperty('bounce-code')) {
        obj['bounce-code'] = _ApiClient.default.convertToType(data['bounce-code'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunDeliveryStatusInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunDeliveryStatusInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['mx-host'] && !(typeof data['mx-host'] === 'string' || data['mx-host'] instanceof String)) {
      throw new Error("Expected the field `mx-host` to be a primitive type in the JSON string but got " + data['mx-host']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['enhanced-code'] && !(typeof data['enhanced-code'] === 'string' || data['enhanced-code'] instanceof String)) {
      throw new Error("Expected the field `enhanced-code` to be a primitive type in the JSON string but got " + data['enhanced-code']);
    }
    // ensure the json data is a string
    if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
      throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
    }
    // ensure the json data is a string
    if (data['bounce-code'] && !(typeof data['bounce-code'] === 'string' || data['bounce-code'] instanceof String)) {
      throw new Error("Expected the field `bounce-code` to be a primitive type in the JSON string but got " + data['bounce-code']);
    }
    return true;
  }
}

/**
 * @member {Boolean} tls
 */
MailgunDeliveryStatusInfo.prototype['tls'] = undefined;

/**
 * @member {String} mx-host
 */
MailgunDeliveryStatusInfo.prototype['mx-host'] = undefined;

/**
 * @member {Number} code
 */
MailgunDeliveryStatusInfo.prototype['code'] = undefined;

/**
 * @member {String} description
 */
MailgunDeliveryStatusInfo.prototype['description'] = undefined;

/**
 * @member {Number} session-seconds
 */
MailgunDeliveryStatusInfo.prototype['session-seconds'] = undefined;

/**
 * @member {Boolean} utf8
 */
MailgunDeliveryStatusInfo.prototype['utf8'] = undefined;

/**
 * @member {Number} retry-seconds
 */
MailgunDeliveryStatusInfo.prototype['retry-seconds'] = undefined;

/**
 * @member {String} enhanced-code
 */
MailgunDeliveryStatusInfo.prototype['enhanced-code'] = undefined;

/**
 * @member {Number} attempt-no
 */
MailgunDeliveryStatusInfo.prototype['attempt-no'] = undefined;

/**
 * @member {String} message
 */
MailgunDeliveryStatusInfo.prototype['message'] = undefined;

/**
 * @member {Boolean} certificate-verified
 */
MailgunDeliveryStatusInfo.prototype['certificate-verified'] = undefined;

/**
 * @member {String} bounce-code
 */
MailgunDeliveryStatusInfo.prototype['bounce-code'] = undefined;
var _default = exports.default = MailgunDeliveryStatusInfo;