"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MediaKeyTakeAwayModel = _interopRequireDefault(require("./MediaKeyTakeAwayModel"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MediaKeyTakeAwaysModel model module.
 * @module model/MediaKeyTakeAwaysModel
 * @version 1.1.3-alpha.1
 */
class MediaKeyTakeAwaysModel {
  /**
   * Constructs a new <code>MediaKeyTakeAwaysModel</code>.
   * @alias module:model/MediaKeyTakeAwaysModel
   * @param id {String} 
   */
  constructor(id) {
    MediaKeyTakeAwaysModel.initialize(this, id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id) {
    obj['id'] = id;
  }

  /**
   * Constructs a <code>MediaKeyTakeAwaysModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MediaKeyTakeAwaysModel} obj Optional instance to populate.
   * @return {module:model/MediaKeyTakeAwaysModel} The populated <code>MediaKeyTakeAwaysModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MediaKeyTakeAwaysModel();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('key_take_away_title')) {
        obj['key_take_away_title'] = _ApiClient.default.convertToType(data['key_take_away_title'], 'String');
      }
      if (data.hasOwnProperty('key_take_away_timeout')) {
        obj['key_take_away_timeout'] = _ApiClient.default.convertToType(data['key_take_away_timeout'], 'Number');
      }
      if (data.hasOwnProperty('key_take_aways')) {
        obj['key_take_aways'] = _ApiClient.default.convertToType(data['key_take_aways'], [_MediaKeyTakeAwayModel.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MediaKeyTakeAwaysModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MediaKeyTakeAwaysModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of MediaKeyTakeAwaysModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['key_take_away_title'] && !(typeof data['key_take_away_title'] === 'string' || data['key_take_away_title'] instanceof String)) {
      throw new Error("Expected the field `key_take_away_title` to be a primitive type in the JSON string but got " + data['key_take_away_title']);
    }
    if (data['key_take_aways']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['key_take_aways'])) {
        throw new Error("Expected the field `key_take_aways` to be an array in the JSON data but got " + data['key_take_aways']);
      }
      // validate the optional field `key_take_aways` (array)
      for (const item of data['key_take_aways']) {
        _MediaKeyTakeAwayModel.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
MediaKeyTakeAwaysModel.RequiredProperties = ["id"];

/**
 * @member {String} id
 */
MediaKeyTakeAwaysModel.prototype['id'] = undefined;

/**
 * @member {String} key_take_away_title
 */
MediaKeyTakeAwaysModel.prototype['key_take_away_title'] = undefined;

/**
 * @member {Number} key_take_away_timeout
 */
MediaKeyTakeAwaysModel.prototype['key_take_away_timeout'] = undefined;

/**
 * @member {Array.<module:model/MediaKeyTakeAwayModel>} key_take_aways
 */
MediaKeyTakeAwaysModel.prototype['key_take_aways'] = undefined;
var _default = exports.default = MediaKeyTakeAwaysModel;