"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ResultCode = _interopRequireDefault(require("./ResultCode"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The BaseResult model module.
 * @module model/BaseResult
 * @version 1.1.3-alpha.1
 */
class BaseResult {
  /**
   * Constructs a new <code>BaseResult</code>.
   * @alias module:model/BaseResult
   */
  constructor() {
    BaseResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>BaseResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BaseResult} obj Optional instance to populate.
   * @return {module:model/BaseResult} The populated <code>BaseResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BaseResult();
      if (data.hasOwnProperty('code')) {
        obj['code'] = _ResultCode.default.constructFromObject(data['code']);
      }
      if (data.hasOwnProperty('success')) {
        obj['success'] = _ApiClient.default.convertToType(data['success'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>BaseResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BaseResult</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * @member {module:model/ResultCode} code
 */
BaseResult.prototype['code'] = undefined;

/**
 * @member {Boolean} success
 */
BaseResult.prototype['success'] = undefined;
var _default = exports.default = BaseResult;