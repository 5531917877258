import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import { extractClasses } from '../utilities/classname-utils';
import { PageLoader } from './loaders/site-loaders';

export const PendingImage = memo(({ simple, ...props }) => (
	<div {...extractClasses(props, ['pending-placeholder', { simple: !!simple }])}></div>
));

export const DelayPending = memo(({ delay }) => {
	const delayRef = useRef(delay);
	const [visible, setVisible] = useState(!(delayRef.current > 0));
	useLayoutEffect(() => {
		if (delayRef.current > 0) {
			const timer = setTimeout(() => setVisible(true), delayRef.current);
			return () => clearTimeout(timer);
		}
	}, []);
	return visible ? <PageLoader /> : null;
});
