import { normalized, RESOURCE } from '@extend/paywall-api/lib';
import deepMerge from 'deepmerge';
import get from 'lodash/get';
import { push } from 'redux-first-history';
import createResourceActions from 'redux-resource-action-creators';
import { call, cancel, delay, fork, getContext, put, select, takeEvery } from 'redux-saga/effects';
import {
	authLoadUser,
	authLoadUserFailed,
	authLoggedIn,
	authLogOut,
	authPermissionsLoaded,
	authPreferencesLoaded,
	authSessionExpired,
	AUTH_LOAD_PERMISSIONS,
	AUTH_LOAD_PREFERENCES,
	AUTH_LOAD_USER,
	AUTH_LOGGED_IN,
	AUTH_LOGOUT,
	AUTH_SAVE_PREFERENCES
} from '../state/auth';
import { wrapInLoading } from '../state/loading';
import { CRUD_ACTION } from '../state/resource-constants';
import { signInSubmitResolved } from '../state/sign-in';
import { normalizrToReduxResource } from '../utils';
import { notifyError } from './flow-utils';
import { openSessionExpired } from './sign-in';

const loadPreferencesWorker = wrapInLoading(function* loadPreferencesWorker() {
	try {
		let userId = yield select(state => get(state, 'auth.user_id'));
		if (userId) {
			let api = yield getContext('api');
			var preferences = yield call(api.getUserPreferences, userId);
			yield put(authPreferencesLoaded(preferences));
		}
	} catch (e) {
		yield notifyError(e);
	}
});

export const loadPreferencesWatcher = function* loadPreferencesWatcher() {
	yield takeEvery(AUTH_LOAD_PREFERENCES, loadPreferencesWorker);
};

const savePreferencesWorker = wrapInLoading(function* savePreferencesWorker({ payload: prefs }) {
	try {
		let userId = yield select(state => get(state, 'auth.user_id'));
		if (userId) {
			let api = yield getContext('api');
			var preferences = yield call(api.setUserPreferences, userId, prefs);
			yield put(authPreferencesLoaded(preferences));
		}
	} catch (e) {
		yield notifyError(e);
	}
});
export const savePreferencesWatcher = function* savePreferencesWatcher() {
	yield takeEvery(AUTH_SAVE_PREFERENCES, savePreferencesWorker);
};

const authPermissionsLoaderWorker = wrapInLoading(function* authPermissionsLoaderWorker({
	payload: { permissions, merge }
}) {
	try {
		let api = yield getContext('api');
		var permDict = yield call(api.getPermissions, permissions);
		yield put(authPermissionsLoaded({ permissions: permDict, merge: merge }));
	} catch (e) {
		yield notifyError(e);
		yield put(authPermissionsLoaded({ permissions: {} }));
	}
});

const authLoaderWorker = wrapInLoading(function* authLoaderWorker() {
	let { save: saveToken } = yield getContext('tokenPersisor');
	try {
		let api = yield getContext('api');
		var { authToken, user, ...rest } = yield call(api.getCurrentAuth);
		if (user && authToken) {
			yield put(authLoggedIn({ apiKey: authToken, user: user, ...rest }));
		} else {
			yield call(saveToken, authToken);
			yield put(authSessionExpired());
		}
	} catch (e) {
		var status = Number.parseInt(e.status || '500');
		if (status === 401 || status === 403) {
			yield call(saveToken, authToken);
			yield put(authSessionExpired());
		} else yield put(authLoadUserFailed(e));
	}
});

const reportWorker = {
	instance: null
};

const logoutWorker = function* logoutWorker() {
	try {
		let { save: saveToken } = yield getContext('tokenPersisor');
		let api = yield getContext('api');
		yield call(api.setAuthToken, null);
		yield call(saveToken, null);

		if (reportWorker.instance) yield cancel(reportWorker.instance);

		yield put(push('/'));
	} catch (e) {
		yield notifyError(e);
	}
};

const reportOnlineWorker = function* reportOnlineWorker() {
	while (true) {
		let api = yield getContext('api');
		yield call(api.statsLogIAmOnline);
		yield delay(60000); // 1 minute before next online report;
	}
};

const loggedInWorker = function* loggedInWorker({ payload: { apiKey, resources } }) {
	try {
		let { save: saveToken } = yield getContext('tokenPersisor');
		yield call(saveToken, apiKey);
		let { pathname, state: routerState } = yield select(state => state.router.location);
		const redirect = routerState?.redirect;
		if (reportWorker.instance) yield cancel(reportWorker.instance);
		reportWorker.instance = yield fork(reportOnlineWorker);

		var { user, role, customer, contact, subscription: resSubsc } = resources;

		var userRes = normalized(user, RESOURCE.USERS) || {};
		var roleRes = normalized(role, RESOURCE.ROLES) || {};
		var customerRes = customer ? normalized(customer, RESOURCE.CUSTOMERS) : {};
		var contactRes = contact ? normalized(contact, RESOURCE.CONTACTS) : {};
		var subscription = resSubsc ? normalized(resSubsc, RESOURCE.SUBSCRIPTIONS) : {};

		var { entities } = deepMerge.all([subscription, contactRes, customerRes, roleRes, userRes]);

		var { succeeded } = createResourceActions(CRUD_ACTION.READ, { resourceType: RESOURCE.USERS });
		var successEntitiesLoadResult = normalizrToReduxResource(RESOURCE.USERS, {
			entities: entities,
			result: [user.id]
		});
		yield put(succeeded(successEntitiesLoadResult));

		if (redirect) {
			if (typeof redirect === 'function') {
				var loc = yield call(redirect);
				yield put(push(loc));
			}
			yield put(push(redirect));
		} else if (pathname === '/') yield put(push('/wisdrop'));
	} catch (e) {
		yield notifyError(e);
	}
};

export const loggedInWatcher = function* loggedInWatcher() {
	yield takeEvery(AUTH_LOGGED_IN, loggedInWorker);
};
export const logoutWatcher = function* logoutWatcher() {
	yield takeEvery(AUTH_LOGOUT, logoutWorker);
};
export const loadAuthWatcher = function* loadAuthWatcher() {
	yield takeEvery(AUTH_LOAD_USER, authLoaderWorker);
};
export const loadPermissionsWatcher = function* loadPermissionsWatcher() {
	yield takeEvery(AUTH_LOAD_PERMISSIONS, authPermissionsLoaderWorker);
};

// saga responsible for the initial loading of user data
export const initializeAuth = function* initializeAuth() {
	try {
		const search = new URLSearchParams(yield select(state => state.router?.location?.search ?? '?'));
		let pwdreset = search.get('pwdreset');
		let activation = search.get('activation');
		let cancelsubscription = search.get('cancelsubscription');
		if (pwdreset || activation || cancelsubscription) {
			yield put(authLogOut());
		} else {
			let apiKey = yield select(state => state.auth.apiKey);
			if (apiKey) yield put(authLoadUser());
		}
	} catch (e) {
		yield notifyError(e);
		yield put(authLoadUserFailed(e));
		yield call(openSessionExpired, e, signInSubmitResolved);
	}
};
