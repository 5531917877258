"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SlideComment model module.
 * @module model/SlideComment
 * @version 1.1.3-alpha.1
 */
class SlideComment {
  /**
   * Constructs a new <code>SlideComment</code>.
   * @alias module:model/SlideComment
   * @param slide_id {String} 
   * @param user_id {String} 
   * @param text {String} 
   * @param id {String} 
   * @param created_at {String} 
   * @param updated_at {String} 
   */
  constructor(slide_id, user_id, text, id, created_at, updated_at) {
    SlideComment.initialize(this, slide_id, user_id, text, id, created_at, updated_at);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, slide_id, user_id, text, id, created_at, updated_at) {
    obj['slide_id'] = slide_id;
    obj['user_id'] = user_id;
    obj['text'] = text;
    obj['id'] = id;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
  }

  /**
   * Constructs a <code>SlideComment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SlideComment} obj Optional instance to populate.
   * @return {module:model/SlideComment} The populated <code>SlideComment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SlideComment();
      if (data.hasOwnProperty('slide_id')) {
        obj['slide_id'] = _ApiClient.default.convertToType(data['slide_id'], 'String');
      }
      if (data.hasOwnProperty('parent_comment_id')) {
        obj['parent_comment_id'] = _ApiClient.default.convertToType(data['parent_comment_id'], 'String');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('contact_id')) {
        obj['contact_id'] = _ApiClient.default.convertToType(data['contact_id'], 'Number');
      }
      if (data.hasOwnProperty('text')) {
        obj['text'] = _ApiClient.default.convertToType(data['text'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
      if (data.hasOwnProperty('created_by')) {
        obj['created_by'] = _ApiClient.default.convertToType(data['created_by'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('time_created')) {
        obj['time_created'] = _ApiClient.default.convertToType(data['time_created'], 'String');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'String');
      }
      if (data.hasOwnProperty('updated_by')) {
        obj['updated_by'] = _ApiClient.default.convertToType(data['updated_by'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
      if (data.hasOwnProperty('time_updated')) {
        obj['time_updated'] = _ApiClient.default.convertToType(data['time_updated'], 'String');
      }
      if (data.hasOwnProperty('deleted_at')) {
        obj['deleted_at'] = _ApiClient.default.convertToType(data['deleted_at'], 'String');
      }
      if (data.hasOwnProperty('deleted_by')) {
        obj['deleted_by'] = _ApiClient.default.convertToType(data['deleted_by'], 'String');
      }
      if (data.hasOwnProperty('date_deleted')) {
        obj['date_deleted'] = _ApiClient.default.convertToType(data['date_deleted'], 'String');
      }
      if (data.hasOwnProperty('time_deleted')) {
        obj['time_deleted'] = _ApiClient.default.convertToType(data['time_deleted'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SlideComment</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SlideComment</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of SlideComment.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['slide_id'] && !(typeof data['slide_id'] === 'string' || data['slide_id'] instanceof String)) {
      throw new Error("Expected the field `slide_id` to be a primitive type in the JSON string but got " + data['slide_id']);
    }
    // ensure the json data is a string
    if (data['parent_comment_id'] && !(typeof data['parent_comment_id'] === 'string' || data['parent_comment_id'] instanceof String)) {
      throw new Error("Expected the field `parent_comment_id` to be a primitive type in the JSON string but got " + data['parent_comment_id']);
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['text'] && !(typeof data['text'] === 'string' || data['text'] instanceof String)) {
      throw new Error("Expected the field `text` to be a primitive type in the JSON string but got " + data['text']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
      throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
    }
    // ensure the json data is a string
    if (data['time_created'] && !(typeof data['time_created'] === 'string' || data['time_created'] instanceof String)) {
      throw new Error("Expected the field `time_created` to be a primitive type in the JSON string but got " + data['time_created']);
    }
    // ensure the json data is a string
    if (data['updated_by'] && !(typeof data['updated_by'] === 'string' || data['updated_by'] instanceof String)) {
      throw new Error("Expected the field `updated_by` to be a primitive type in the JSON string but got " + data['updated_by']);
    }
    // ensure the json data is a string
    if (data['time_updated'] && !(typeof data['time_updated'] === 'string' || data['time_updated'] instanceof String)) {
      throw new Error("Expected the field `time_updated` to be a primitive type in the JSON string but got " + data['time_updated']);
    }
    // ensure the json data is a string
    if (data['deleted_by'] && !(typeof data['deleted_by'] === 'string' || data['deleted_by'] instanceof String)) {
      throw new Error("Expected the field `deleted_by` to be a primitive type in the JSON string but got " + data['deleted_by']);
    }
    // ensure the json data is a string
    if (data['time_deleted'] && !(typeof data['time_deleted'] === 'string' || data['time_deleted'] instanceof String)) {
      throw new Error("Expected the field `time_deleted` to be a primitive type in the JSON string but got " + data['time_deleted']);
    }
    return true;
  }
}
SlideComment.RequiredProperties = ["slide_id", "user_id", "text", "id", "created_at", "updated_at"];

/**
 * @member {String} slide_id
 */
SlideComment.prototype['slide_id'] = undefined;

/**
 * @member {String} parent_comment_id
 */
SlideComment.prototype['parent_comment_id'] = undefined;

/**
 * @member {String} user_id
 */
SlideComment.prototype['user_id'] = undefined;

/**
 * @member {String} customer_id
 */
SlideComment.prototype['customer_id'] = undefined;

/**
 * @member {Number} contact_id
 */
SlideComment.prototype['contact_id'] = undefined;

/**
 * @member {String} text
 */
SlideComment.prototype['text'] = undefined;

/**
 * @member {String} id
 */
SlideComment.prototype['id'] = undefined;

/**
 * @member {String} created_at
 */
SlideComment.prototype['created_at'] = undefined;

/**
 * @member {String} created_by
 */
SlideComment.prototype['created_by'] = undefined;

/**
 * @member {String} date_created
 */
SlideComment.prototype['date_created'] = undefined;

/**
 * @member {String} time_created
 */
SlideComment.prototype['time_created'] = undefined;

/**
 * @member {String} updated_at
 */
SlideComment.prototype['updated_at'] = undefined;

/**
 * @member {String} updated_by
 */
SlideComment.prototype['updated_by'] = undefined;

/**
 * @member {String} date_updated
 */
SlideComment.prototype['date_updated'] = undefined;

/**
 * @member {String} time_updated
 */
SlideComment.prototype['time_updated'] = undefined;

/**
 * @member {String} deleted_at
 */
SlideComment.prototype['deleted_at'] = undefined;

/**
 * @member {String} deleted_by
 */
SlideComment.prototype['deleted_by'] = undefined;

/**
 * @member {String} date_deleted
 */
SlideComment.prototype['date_deleted'] = undefined;

/**
 * @member {String} time_deleted
 */
SlideComment.prototype['time_deleted'] = undefined;
var _default = exports.default = SlideComment;