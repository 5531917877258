"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AdScoreFilter = _interopRequireDefault(require("./AdScoreFilter"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CommandCustomerChangeAdScoresAccessibility model module.
 * @module model/CommandCustomerChangeAdScoresAccessibility
 * @version 1.1.3-alpha.1
 */
class CommandCustomerChangeAdScoresAccessibility {
  /**
   * Constructs a new <code>CommandCustomerChangeAdScoresAccessibility</code>.
   * @alias module:model/CommandCustomerChangeAdScoresAccessibility
   * @param property {String} The accessibility property to change
   * @param ids {Array.<String>} Ids of the resource for which to change accessibility
   */
  constructor(property, ids) {
    CommandCustomerChangeAdScoresAccessibility.initialize(this, property, ids);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, property, ids) {
    obj['property'] = property;
    obj['ids'] = ids;
  }

  /**
   * Constructs a <code>CommandCustomerChangeAdScoresAccessibility</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CommandCustomerChangeAdScoresAccessibility} obj Optional instance to populate.
   * @return {module:model/CommandCustomerChangeAdScoresAccessibility} The populated <code>CommandCustomerChangeAdScoresAccessibility</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CommandCustomerChangeAdScoresAccessibility();
      if (data.hasOwnProperty('property')) {
        obj['property'] = _ApiClient.default.convertToType(data['property'], 'String');
      }
      if (data.hasOwnProperty('value')) {
        obj['value'] = _ApiClient.default.convertToType(data['value'], 'Boolean');
      }
      if (data.hasOwnProperty('ids')) {
        obj['ids'] = _ApiClient.default.convertToType(data['ids'], ['String']);
      }
      if (data.hasOwnProperty('filter')) {
        obj['filter'] = _AdScoreFilter.default.constructFromObject(data['filter']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CommandCustomerChangeAdScoresAccessibility</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommandCustomerChangeAdScoresAccessibility</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CommandCustomerChangeAdScoresAccessibility.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['property'] && !(typeof data['property'] === 'string' || data['property'] instanceof String)) {
      throw new Error("Expected the field `property` to be a primitive type in the JSON string but got " + data['property']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['ids'])) {
      throw new Error("Expected the field `ids` to be an array in the JSON data but got " + data['ids']);
    }
    // validate the optional field `filter`
    if (data['filter']) {
      // data not null
      _AdScoreFilter.default.validateJSON(data['filter']);
    }
    return true;
  }
}
CommandCustomerChangeAdScoresAccessibility.RequiredProperties = ["property", "ids"];

/**
 * The accessibility property to change
 * @member {String} property
 */
CommandCustomerChangeAdScoresAccessibility.prototype['property'] = undefined;

/**
 * The new value of the accessibility property
 * @member {Boolean} value
 */
CommandCustomerChangeAdScoresAccessibility.prototype['value'] = undefined;

/**
 * Ids of the resource for which to change accessibility
 * @member {Array.<String>} ids
 */
CommandCustomerChangeAdScoresAccessibility.prototype['ids'] = undefined;

/**
 * @member {module:model/AdScoreFilter} filter
 */
CommandCustomerChangeAdScoresAccessibility.prototype['filter'] = undefined;
var _default = exports.default = CommandCustomerChangeAdScoresAccessibility;