"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CreateCustomerAddressInline = _interopRequireDefault(require("./CreateCustomerAddressInline"));
var _DefinedCustomerType = _interopRequireDefault(require("./DefinedCustomerType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CreateCustomerCommand model module.
 * @module model/CreateCustomerCommand
 * @version 1.1.3-alpha.1
 */
class CreateCustomerCommand {
  /**
   * Constructs a new <code>CreateCustomerCommand</code>.
   * @alias module:model/CreateCustomerCommand
   * @param email {String} 
   * @param name {String} 
   * @param last_name {String} 
   * @param customer_type {module:model/DefinedCustomerType} 
   * @param is_counted {Boolean} 
   * @param auto_renewal {Boolean} 
   * @param report_pdf_download {Boolean} 
   */
  constructor(email, name, last_name, customer_type, is_counted, auto_renewal, report_pdf_download) {
    CreateCustomerCommand.initialize(this, email, name, last_name, customer_type, is_counted, auto_renewal, report_pdf_download);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, email, name, last_name, customer_type, is_counted, auto_renewal, report_pdf_download) {
    obj['email'] = email;
    obj['name'] = name;
    obj['last_name'] = last_name;
    obj['customer_type'] = customer_type;
    obj['is_counted'] = is_counted;
    obj['auto_renewal'] = auto_renewal;
    obj['report_pdf_download'] = report_pdf_download;
  }

  /**
   * Constructs a <code>CreateCustomerCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateCustomerCommand} obj Optional instance to populate.
   * @return {module:model/CreateCustomerCommand} The populated <code>CreateCustomerCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateCustomerCommand();
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = _ApiClient.default.convertToType(data['last_name'], 'String');
      }
      if (data.hasOwnProperty('logo_id')) {
        obj['logo_id'] = _ApiClient.default.convertToType(data['logo_id'], 'String');
      }
      if (data.hasOwnProperty('customer_type')) {
        obj['customer_type'] = _DefinedCustomerType.default.constructFromObject(data['customer_type']);
      }
      if (data.hasOwnProperty('available_in_country_ids')) {
        obj['available_in_country_ids'] = _ApiClient.default.convertToType(data['available_in_country_ids'], ['String']);
      }
      if (data.hasOwnProperty('mobile_phone')) {
        obj['mobile_phone'] = _ApiClient.default.convertToType(data['mobile_phone'], 'String');
      }
      if (data.hasOwnProperty('afm')) {
        obj['afm'] = _ApiClient.default.convertToType(data['afm'], 'String');
      }
      if (data.hasOwnProperty('doy')) {
        obj['doy'] = _ApiClient.default.convertToType(data['doy'], 'String');
      }
      if (data.hasOwnProperty('job_description')) {
        obj['job_description'] = _ApiClient.default.convertToType(data['job_description'], 'String');
      }
      if (data.hasOwnProperty('is_counted')) {
        obj['is_counted'] = _ApiClient.default.convertToType(data['is_counted'], 'Boolean');
      }
      if (data.hasOwnProperty('auto_renewal')) {
        obj['auto_renewal'] = _ApiClient.default.convertToType(data['auto_renewal'], 'Boolean');
      }
      if (data.hasOwnProperty('industry_id')) {
        obj['industry_id'] = _ApiClient.default.convertToType(data['industry_id'], 'String');
      }
      if (data.hasOwnProperty('email_domains')) {
        obj['email_domains'] = _ApiClient.default.convertToType(data['email_domains'], 'String');
      }
      if (data.hasOwnProperty('email_whitelist')) {
        obj['email_whitelist'] = _ApiClient.default.convertToType(data['email_whitelist'], 'String');
      }
      if (data.hasOwnProperty('report_pdf_download')) {
        obj['report_pdf_download'] = _ApiClient.default.convertToType(data['report_pdf_download'], 'Boolean');
      }
      if (data.hasOwnProperty('addresses')) {
        obj['addresses'] = _ApiClient.default.convertToType(data['addresses'], [_CreateCustomerAddressInline.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CreateCustomerCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateCustomerCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CreateCustomerCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
      throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
    }
    // ensure the json data is a string
    if (data['logo_id'] && !(typeof data['logo_id'] === 'string' || data['logo_id'] instanceof String)) {
      throw new Error("Expected the field `logo_id` to be a primitive type in the JSON string but got " + data['logo_id']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['available_in_country_ids'])) {
      throw new Error("Expected the field `available_in_country_ids` to be an array in the JSON data but got " + data['available_in_country_ids']);
    }
    // ensure the json data is a string
    if (data['mobile_phone'] && !(typeof data['mobile_phone'] === 'string' || data['mobile_phone'] instanceof String)) {
      throw new Error("Expected the field `mobile_phone` to be a primitive type in the JSON string but got " + data['mobile_phone']);
    }
    // ensure the json data is a string
    if (data['afm'] && !(typeof data['afm'] === 'string' || data['afm'] instanceof String)) {
      throw new Error("Expected the field `afm` to be a primitive type in the JSON string but got " + data['afm']);
    }
    // ensure the json data is a string
    if (data['doy'] && !(typeof data['doy'] === 'string' || data['doy'] instanceof String)) {
      throw new Error("Expected the field `doy` to be a primitive type in the JSON string but got " + data['doy']);
    }
    // ensure the json data is a string
    if (data['job_description'] && !(typeof data['job_description'] === 'string' || data['job_description'] instanceof String)) {
      throw new Error("Expected the field `job_description` to be a primitive type in the JSON string but got " + data['job_description']);
    }
    // ensure the json data is a string
    if (data['industry_id'] && !(typeof data['industry_id'] === 'string' || data['industry_id'] instanceof String)) {
      throw new Error("Expected the field `industry_id` to be a primitive type in the JSON string but got " + data['industry_id']);
    }
    // ensure the json data is a string
    if (data['email_domains'] && !(typeof data['email_domains'] === 'string' || data['email_domains'] instanceof String)) {
      throw new Error("Expected the field `email_domains` to be a primitive type in the JSON string but got " + data['email_domains']);
    }
    // ensure the json data is a string
    if (data['email_whitelist'] && !(typeof data['email_whitelist'] === 'string' || data['email_whitelist'] instanceof String)) {
      throw new Error("Expected the field `email_whitelist` to be a primitive type in the JSON string but got " + data['email_whitelist']);
    }
    if (data['addresses']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['addresses'])) {
        throw new Error("Expected the field `addresses` to be an array in the JSON data but got " + data['addresses']);
      }
      // validate the optional field `addresses` (array)
      for (const item of data['addresses']) {
        _CreateCustomerAddressInline.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
CreateCustomerCommand.RequiredProperties = ["email", "name", "last_name", "customer_type", "is_counted", "auto_renewal", "report_pdf_download"];

/**
 * @member {String} email
 */
CreateCustomerCommand.prototype['email'] = undefined;

/**
 * @member {String} name
 */
CreateCustomerCommand.prototype['name'] = undefined;

/**
 * @member {String} last_name
 */
CreateCustomerCommand.prototype['last_name'] = undefined;

/**
 * @member {String} logo_id
 */
CreateCustomerCommand.prototype['logo_id'] = undefined;

/**
 * @member {module:model/DefinedCustomerType} customer_type
 */
CreateCustomerCommand.prototype['customer_type'] = undefined;

/**
 * @member {Array.<String>} available_in_country_ids
 */
CreateCustomerCommand.prototype['available_in_country_ids'] = undefined;

/**
 * @member {String} mobile_phone
 */
CreateCustomerCommand.prototype['mobile_phone'] = undefined;

/**
 * @member {String} afm
 */
CreateCustomerCommand.prototype['afm'] = undefined;

/**
 * @member {String} doy
 */
CreateCustomerCommand.prototype['doy'] = undefined;

/**
 * @member {String} job_description
 */
CreateCustomerCommand.prototype['job_description'] = undefined;

/**
 * @member {Boolean} is_counted
 */
CreateCustomerCommand.prototype['is_counted'] = undefined;

/**
 * @member {Boolean} auto_renewal
 */
CreateCustomerCommand.prototype['auto_renewal'] = undefined;

/**
 * @member {String} industry_id
 */
CreateCustomerCommand.prototype['industry_id'] = undefined;

/**
 * @member {String} email_domains
 */
CreateCustomerCommand.prototype['email_domains'] = undefined;

/**
 * @member {String} email_whitelist
 */
CreateCustomerCommand.prototype['email_whitelist'] = undefined;

/**
 * @member {Boolean} report_pdf_download
 */
CreateCustomerCommand.prototype['report_pdf_download'] = undefined;

/**
 * @member {Array.<module:model/CreateCustomerAddressInline>} addresses
 */
CreateCustomerCommand.prototype['addresses'] = undefined;
var _default = exports.default = CreateCustomerCommand;