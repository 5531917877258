import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useMatch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { TopMenu } from './components/top-menu';
import { HeaderWithClose } from './components/wisdrop-buttons';
import { useAuthentication } from './hooks/auth';
import { useGoBack } from './hooks/browser';
import { setClasses } from './utilities/classname-utils';

export const TopMenuHeader = ({ headerContainerProps, ...props }) => {
	const { success } = useAuthentication();
	const goBack = useGoBack();

	var wisdropMatch = useMatch('/wisdrop');
	var adscoresMatch = useMatch('/adscores');
	var adminMatch = useMatch({ path: '/admin', end: false });
	var adminfullreportsMatch = useMatch('/admin/fullreports');
	var myReportsMatch = useMatch({ path: '/myreports', end: false });
	var myListMatch = useMatch({ path: '/mylist', end: false });
	var searchMatch = useMatch('/search');
	var settingsMatch = useMatch('/settings');

	const hMenu = adminfullreportsMatch ? <HeaderWithClose onClose={goBack} /> : <TopMenu {...props} key="menu" />;

	return success &&
		(wisdropMatch || adscoresMatch || adminMatch || myReportsMatch || myListMatch || searchMatch || settingsMatch) ? (
		<SiteHeader
			{...headerContainerProps}
			inHomePage={!!wisdropMatch}
			inAdmin={!!adminMatch}
			inSearch={!!searchMatch}
			inSettings={!!settingsMatch}
			key="menuHeader">
			<Container
				className={setClasses({
					'ad-scores-container': !!adscoresMatch
				})}
				fluid
				key="headerContainer">
				{hMenu}
			</Container>
		</SiteHeader>
	) : null;
};
TopMenuHeader.displayName = 'TopMenuHeader';

const SiteHeader = ({ children, className, ...props }) => {
	const { header } = useStyles(props);
	return <header className={clsx(className, header)}>{children}</header>;
};
SiteHeader.displayName = 'SiteHeader';

const useStyles = makeStyles(theme => {
	return {
		header: {
			position: ({ fixed }) => (fixed ? 'fixed' : null),
			top: ({ fixed }) => (fixed ? 0 : null),
			left: ({ fixed }) => (fixed ? 0 : null),
			right: ({ fixed }) => (fixed ? 0 : null),
			height: '7.5vw',
			zIndex: ({ fixed }) => (fixed ? 1000 : null),
			padding: '1vw 0',
			backgroundImage: ({ inHomePage, inAdmin, inSearch, inSettings }) => {
				return null;
			}
		}
	};
});
