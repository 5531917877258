import get from 'lodash/get';
import { createReducer } from './utils';

var actionCreatorsCache = {};
const initialState = {
	sorting: [],
	paging: {
		offset: 0,
		total: 0,
		pageSize: 0
	},
	filter: {},
	data: [],
	status: 'IDLE',
	error: null
};

export const INDEX_DATA_FETCH_SUCCEEDED = 'INDEX_DATA_FETCH_SUCCEEDED';
export const INDEX_DATA_FETCH_FAILED = 'INDEX_DATA_FETCH_FAILED';
export const INDEX_DATA_RESET_STATUS = 'INDEX_DATA_RESET_STATUS';
export const INDEX_DATA_RESET = 'INDEX_DATA_RESET';
export const INDEX_DATA_SET_SORTING = 'INDEX_DATA_SET_SORTING';
export const INDEX_DATA_SET_FILTER = 'INDEX_DATA_SET_FILTER';
export const INDEX_DATA_REQUEST_FILTER = 'INDEX_DATA_REQUEST_FILTER';
export const INDEX_DATA_CLEAR_FILTER = 'INDEX_DATA_CLEAR_FILTER';
export const INDEX_DATA_REQUEST_SORTING = 'INDEX_DATA_REQUEST_SORTING';
export const INDEX_DATA_SET_PENDING = 'INDEX_DATA_SET_PENDING';

export const getIndexDataActionCreators = namespace => {
	var cached = actionCreatorsCache[namespace];
	if (!cached)
		actionCreatorsCache[namespace] = cached = {
			fetchSucceeded: ({ data, offset, pageSize, total, sorting, filter, replace }) => ({
				type: INDEX_DATA_FETCH_SUCCEEDED,
				payload: { namespace, data, offset, pageSize, total, sorting, filter, replace }
			}),
			fetchFailed: ({ error }) => ({
				type: INDEX_DATA_FETCH_FAILED,
				payload: { namespace, error }
			}),
			resetStatus: () => ({ type: INDEX_DATA_RESET_STATUS, payload: { namespace } }),
			fetchPending: () => ({ type: INDEX_DATA_SET_PENDING, payload: { namespace } }),
			reset: () => ({ type: INDEX_DATA_RESET, payload: { namespace } }),
			setSorting: ({ sorting }) => ({
				type: INDEX_DATA_SET_SORTING,
				payload: { namespace, sorting }
			}),
			requestSorting: ({ field, append, desc } = {}) => ({
				type: INDEX_DATA_REQUEST_SORTING,
				payload: { namespace, field, append, desc }
			}),
			setFilter: filter => ({
				type: INDEX_DATA_SET_FILTER,
				payload: { namespace, filter: filter }
			}),
			requestFilter: ({ name, value } = {}) => ({
				type: INDEX_DATA_REQUEST_FILTER,
				payload: { namespace, name, value }
			}),
			clearFilter: names => ({ type: INDEX_DATA_CLEAR_FILTER, payload: { namespace, names } })
		};

	return cached;
};

const createSliceReducer = sliceReducer => {
	return (state, { namespace, ...rest }) => {
		if (!namespace) return state;
		return {
			...state,
			[namespace]: sliceReducer(state[namespace] || initialState, rest) || initialState
		};
	};
};

const setFilterReducer = createSliceReducer((state, { filter }) => ({
	...state,
	filter: { ...state.filter, ...filter }
}));
const setPendingReducer = createSliceReducer(state => ({
	...state,
	status: 'PENDING',
	error: null
}));
const resetStatusReducer = createSliceReducer(state => ({ ...state, status: 'IDLE', error: null }));
const resetReducer = createSliceReducer(() => initialState);
const setSortingReducer = createSliceReducer((state, { sorting = [] } = {}) => ({
	...state,
	sorting: [...(sorting || [])]
}));
const fetchSucceededReducer = createSliceReducer(
	(state, { data, offset, pageSize, total, sorting, filter, replace }) => {
		var slice = {
			...state,
			paging: replace
				? { offset: offset, total: total, pageSize: pageSize }
				: { ...state.paging, total: total, pageSize: pageSize },
			data: replace ? [...data] : [...state.data, ...data],
			status: 'SUCCEEDED',
			error: null
		};
		if (sorting) slice.sorting = [...sorting];
		if (filter) slice.filter = { ...filter };
		return slice;
	}
);

const requestSortingReducer = createSliceReducer((state, { field, append, desc }) => {
	var { sorting } = state;

	var sortArray;

	if (!append) {
		// new sorting requested
		// look in the array to see if field already exists. If yes, toggle ordering else start ascending (desc=false)
		var descriptor = sorting.find(d => d.field === field);
		sortArray = [
			{
				field,
				desc: desc === true ? true : desc === false ? false : descriptor ? !descriptor.desc : false
			}
		];
	} else {
		// if not appending it means we append the new sorting to the existing ones.
		// if the sorting already exists in the chain, put it in the end and toggle it's ordering
		// else put it in the end ascending

		var idx = sorting.findIndex(d => d.field === field);
		// the element is not in the array. Add it last as ascending.
		if (idx === -1) sortArray = [...sorting, { field: field, desc: desc === true }];
		else {
			// The element is in the array. Put it last and change its ordering
			descriptor = sorting[idx];
			sortArray = [
				...sorting.slice(0, idx), // the items before the element
				...sorting.slice(idx + 1, sorting.length), // the items after the element
				{ field: field, desc: desc === true ? true : desc === false ? false : !descriptor.desc } // the element is put last
			];
		}
	}

	return {
		...state,
		sorting: sortArray
	};
});
const requestFilterReducer = createSliceReducer((state, { name, value }) => ({
	...state,
	filter: {
		...state.filter,
		[name]: value
	}
}));
const clearFilterReducer = createSliceReducer((state, { names }) => {
	if (!names || names.length === 0)
		return {
			...state,
			filter: {}
		};

	var { filter } = state;
	var newFilter = { ...filter };
	names.forEach(n => {
		if (newFilter[n] !== undefined) delete newFilter[n];
	});

	return {
		...state,
		filter: newFilter
	};
});

const fetchFailedReducer = createSliceReducer((state, { error }) => ({
	...state,
	status: 'FAILED',
	error: error
}));

export const indexDataReducer = createReducer(
	{
		[INDEX_DATA_FETCH_SUCCEEDED]: fetchSucceededReducer,
		[INDEX_DATA_RESET_STATUS]: resetStatusReducer,
		[INDEX_DATA_RESET]: resetReducer,
		[INDEX_DATA_FETCH_FAILED]: fetchFailedReducer,
		[INDEX_DATA_SET_SORTING]: setSortingReducer,
		[INDEX_DATA_REQUEST_SORTING]: requestSortingReducer,
		[INDEX_DATA_SET_PENDING]: setPendingReducer,
		[INDEX_DATA_SET_FILTER]: setFilterReducer,
		[INDEX_DATA_REQUEST_FILTER]: requestFilterReducer,
		[INDEX_DATA_CLEAR_FILTER]: clearFilterReducer
	},
	{ state: {} }
);

export const getIndexDataState = (stateSlice, namespace, section) => {
	var state = get(stateSlice, namespace, initialState);
	if (!section) return state;
	return get(state, section);
};

const getMapFilterObjectMapper = mapFilter => {
	if (!mapFilter) return null;
	if (typeof mapFilter === 'function') return mapFilter;
	var mapFilterProps = Object.keys(mapFilter);
	return filter => {
		var mappedFilter = {};
		if (filter) {
			mapFilterProps.forEach(prop => {
				var value = get(filter, mapFilter[prop], null);
				if (value) mappedFilter[prop] = value;
			});
		}
		return mappedFilter;
	};
};

export const getIndexDataFilterMapped = (stateSlice, namespace, mapper) => {
	var state = get(stateSlice, namespace, initialState);
	var filter = get(state, 'filter');
	mapper = getMapFilterObjectMapper(mapper);
	if (!mapper) return null;
	return mapper(filter);
};

export const getIndexDataFilterField = (stateSlice, namespace, name) => {
	var state = get(stateSlice, namespace, initialState);
	return get(state, `filter.${name}`);
};

export const getIndexDataThereIsMore = (stateSlice, namespace) => {
	var state = get(stateSlice, namespace, initialState);
	var { total = 0, offset = 0 } = get(state, `paging`, {});
	var data = get(state, `data`, []);
	var nextOffset = offset + data.length;
	return nextOffset < total;
};

export const getIndexDataThereIsLess = (stateSlice, namespace) => {
	var state = get(stateSlice, namespace, initialState);
	var { total = 0, offset = 0 } = get(state, `paging`, {});
	return total > 0 && offset > 0;
};
