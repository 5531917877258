"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedMediaType = _interopRequireDefault(require("./DefinedMediaType"));
var _FontTypes = _interopRequireDefault(require("./FontTypes"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UpdateMediaCommand model module.
 * @module model/UpdateMediaCommand
 * @version 1.1.3-alpha.1
 */
class UpdateMediaCommand {
  /**
   * Constructs a new <code>UpdateMediaCommand</code>.
   * @alias module:model/UpdateMediaCommand
   * @param id {String} 
   */
  constructor(id) {
    UpdateMediaCommand.initialize(this, id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id) {
    obj['id'] = id;
  }

  /**
   * Constructs a <code>UpdateMediaCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UpdateMediaCommand} obj Optional instance to populate.
   * @return {module:model/UpdateMediaCommand} The populated <code>UpdateMediaCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UpdateMediaCommand();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('media_type')) {
        obj['media_type'] = _DefinedMediaType.default.constructFromObject(data['media_type']);
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = _ApiClient.default.convertToType(data['price'], 'Number');
      }
      if (data.hasOwnProperty('allow_comments')) {
        obj['allow_comments'] = _ApiClient.default.convertToType(data['allow_comments'], 'Boolean');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('available_in_country_ids')) {
        obj['available_in_country_ids'] = _ApiClient.default.convertToType(data['available_in_country_ids'], ['String']);
      }
      if (data.hasOwnProperty('category_ids')) {
        obj['category_ids'] = _ApiClient.default.convertToType(data['category_ids'], ['String']);
      }
      if (data.hasOwnProperty('industry_ids')) {
        obj['industry_ids'] = _ApiClient.default.convertToType(data['industry_ids'], ['String']);
      }
      if (data.hasOwnProperty('subscription_type_ids')) {
        obj['subscription_type_ids'] = _ApiClient.default.convertToType(data['subscription_type_ids'], ['String']);
      }
      if (data.hasOwnProperty('tag_ids')) {
        obj['tag_ids'] = _ApiClient.default.convertToType(data['tag_ids'], ['Number']);
      }
      if (data.hasOwnProperty('media_image_id')) {
        obj['media_image_id'] = _ApiClient.default.convertToType(data['media_image_id'], 'String');
      }
      if (data.hasOwnProperty('media_banner_id')) {
        obj['media_banner_id'] = _ApiClient.default.convertToType(data['media_banner_id'], 'String');
      }
      if (data.hasOwnProperty('logo_image_id')) {
        obj['logo_image_id'] = _ApiClient.default.convertToType(data['logo_image_id'], 'String');
      }
      if (data.hasOwnProperty('background_image_id')) {
        obj['background_image_id'] = _ApiClient.default.convertToType(data['background_image_id'], 'String');
      }
      if (data.hasOwnProperty('media_file_id')) {
        obj['media_file_id'] = _ApiClient.default.convertToType(data['media_file_id'], 'String');
      }
      if (data.hasOwnProperty('email_image_id')) {
        obj['email_image_id'] = _ApiClient.default.convertToType(data['email_image_id'], 'String');
      }
      if (data.hasOwnProperty('distribution_plan_id')) {
        obj['distribution_plan_id'] = _ApiClient.default.convertToType(data['distribution_plan_id'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('available_from')) {
        obj['available_from'] = _ApiClient.default.convertToType(data['available_from'], 'String');
      }
      if (data.hasOwnProperty('font_type')) {
        obj['font_type'] = _FontTypes.default.constructFromObject(data['font_type']);
      }
      if (data.hasOwnProperty('source')) {
        obj['source'] = _ApiClient.default.convertToType(data['source'], 'String');
      }
      if (data.hasOwnProperty('webinar_link')) {
        obj['webinar_link'] = _ApiClient.default.convertToType(data['webinar_link'], 'String');
      }
      if (data.hasOwnProperty('webinar_link_en')) {
        obj['webinar_link_en'] = _ApiClient.default.convertToType(data['webinar_link_en'], 'String');
      }
      if (data.hasOwnProperty('short_description')) {
        obj['short_description'] = _ApiClient.default.convertToType(data['short_description'], 'String');
      }
      if (data.hasOwnProperty('season_parent_id')) {
        obj['season_parent_id'] = _ApiClient.default.convertToType(data['season_parent_id'], 'String');
      }
      if (data.hasOwnProperty('season_number')) {
        obj['season_number'] = _ApiClient.default.convertToType(data['season_number'], 'Number');
      }
      if (data.hasOwnProperty('season_caption')) {
        obj['season_caption'] = _ApiClient.default.convertToType(data['season_caption'], 'String');
      }
      if (data.hasOwnProperty('exclude_in_new_releases')) {
        obj['exclude_in_new_releases'] = _ApiClient.default.convertToType(data['exclude_in_new_releases'], 'Boolean');
      }
      if (data.hasOwnProperty('send_newsletter')) {
        obj['send_newsletter'] = _ApiClient.default.convertToType(data['send_newsletter'], 'Boolean');
      }
      if (data.hasOwnProperty('meeting_url')) {
        obj['meeting_url'] = _ApiClient.default.convertToType(data['meeting_url'], 'String');
      }
      if (data.hasOwnProperty('meeting_date')) {
        obj['meeting_date'] = _ApiClient.default.convertToType(data['meeting_date'], 'String');
      }
      if (data.hasOwnProperty('meeting_time')) {
        obj['meeting_time'] = _ApiClient.default.convertToType(data['meeting_time'], 'String');
      }
      if (data.hasOwnProperty('attachments')) {
        obj['attachments'] = _ApiClient.default.convertToType(data['attachments'], {
          'String': 'String'
        });
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UpdateMediaCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateMediaCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of UpdateMediaCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['available_in_country_ids'])) {
      throw new Error("Expected the field `available_in_country_ids` to be an array in the JSON data but got " + data['available_in_country_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['category_ids'])) {
      throw new Error("Expected the field `category_ids` to be an array in the JSON data but got " + data['category_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['industry_ids'])) {
      throw new Error("Expected the field `industry_ids` to be an array in the JSON data but got " + data['industry_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['subscription_type_ids'])) {
      throw new Error("Expected the field `subscription_type_ids` to be an array in the JSON data but got " + data['subscription_type_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['tag_ids'])) {
      throw new Error("Expected the field `tag_ids` to be an array in the JSON data but got " + data['tag_ids']);
    }
    // ensure the json data is a string
    if (data['media_image_id'] && !(typeof data['media_image_id'] === 'string' || data['media_image_id'] instanceof String)) {
      throw new Error("Expected the field `media_image_id` to be a primitive type in the JSON string but got " + data['media_image_id']);
    }
    // ensure the json data is a string
    if (data['media_banner_id'] && !(typeof data['media_banner_id'] === 'string' || data['media_banner_id'] instanceof String)) {
      throw new Error("Expected the field `media_banner_id` to be a primitive type in the JSON string but got " + data['media_banner_id']);
    }
    // ensure the json data is a string
    if (data['logo_image_id'] && !(typeof data['logo_image_id'] === 'string' || data['logo_image_id'] instanceof String)) {
      throw new Error("Expected the field `logo_image_id` to be a primitive type in the JSON string but got " + data['logo_image_id']);
    }
    // ensure the json data is a string
    if (data['background_image_id'] && !(typeof data['background_image_id'] === 'string' || data['background_image_id'] instanceof String)) {
      throw new Error("Expected the field `background_image_id` to be a primitive type in the JSON string but got " + data['background_image_id']);
    }
    // ensure the json data is a string
    if (data['media_file_id'] && !(typeof data['media_file_id'] === 'string' || data['media_file_id'] instanceof String)) {
      throw new Error("Expected the field `media_file_id` to be a primitive type in the JSON string but got " + data['media_file_id']);
    }
    // ensure the json data is a string
    if (data['email_image_id'] && !(typeof data['email_image_id'] === 'string' || data['email_image_id'] instanceof String)) {
      throw new Error("Expected the field `email_image_id` to be a primitive type in the JSON string but got " + data['email_image_id']);
    }
    // ensure the json data is a string
    if (data['distribution_plan_id'] && !(typeof data['distribution_plan_id'] === 'string' || data['distribution_plan_id'] instanceof String)) {
      throw new Error("Expected the field `distribution_plan_id` to be a primitive type in the JSON string but got " + data['distribution_plan_id']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['source'] && !(typeof data['source'] === 'string' || data['source'] instanceof String)) {
      throw new Error("Expected the field `source` to be a primitive type in the JSON string but got " + data['source']);
    }
    // ensure the json data is a string
    if (data['webinar_link'] && !(typeof data['webinar_link'] === 'string' || data['webinar_link'] instanceof String)) {
      throw new Error("Expected the field `webinar_link` to be a primitive type in the JSON string but got " + data['webinar_link']);
    }
    // ensure the json data is a string
    if (data['webinar_link_en'] && !(typeof data['webinar_link_en'] === 'string' || data['webinar_link_en'] instanceof String)) {
      throw new Error("Expected the field `webinar_link_en` to be a primitive type in the JSON string but got " + data['webinar_link_en']);
    }
    // ensure the json data is a string
    if (data['short_description'] && !(typeof data['short_description'] === 'string' || data['short_description'] instanceof String)) {
      throw new Error("Expected the field `short_description` to be a primitive type in the JSON string but got " + data['short_description']);
    }
    // ensure the json data is a string
    if (data['season_parent_id'] && !(typeof data['season_parent_id'] === 'string' || data['season_parent_id'] instanceof String)) {
      throw new Error("Expected the field `season_parent_id` to be a primitive type in the JSON string but got " + data['season_parent_id']);
    }
    // ensure the json data is a string
    if (data['season_caption'] && !(typeof data['season_caption'] === 'string' || data['season_caption'] instanceof String)) {
      throw new Error("Expected the field `season_caption` to be a primitive type in the JSON string but got " + data['season_caption']);
    }
    // ensure the json data is a string
    if (data['meeting_url'] && !(typeof data['meeting_url'] === 'string' || data['meeting_url'] instanceof String)) {
      throw new Error("Expected the field `meeting_url` to be a primitive type in the JSON string but got " + data['meeting_url']);
    }
    // ensure the json data is a string
    if (data['meeting_time'] && !(typeof data['meeting_time'] === 'string' || data['meeting_time'] instanceof String)) {
      throw new Error("Expected the field `meeting_time` to be a primitive type in the JSON string but got " + data['meeting_time']);
    }
    return true;
  }
}
UpdateMediaCommand.RequiredProperties = ["id"];

/**
 * @member {String} id
 */
UpdateMediaCommand.prototype['id'] = undefined;

/**
 * @member {module:model/DefinedMediaType} media_type
 */
UpdateMediaCommand.prototype['media_type'] = undefined;

/**
 * @member {String} title
 */
UpdateMediaCommand.prototype['title'] = undefined;

/**
 * @member {Number} price
 */
UpdateMediaCommand.prototype['price'] = undefined;

/**
 * @member {Boolean} allow_comments
 */
UpdateMediaCommand.prototype['allow_comments'] = undefined;

/**
 * @member {String} description
 */
UpdateMediaCommand.prototype['description'] = undefined;

/**
 * @member {Array.<String>} available_in_country_ids
 */
UpdateMediaCommand.prototype['available_in_country_ids'] = undefined;

/**
 * @member {Array.<String>} category_ids
 */
UpdateMediaCommand.prototype['category_ids'] = undefined;

/**
 * @member {Array.<String>} industry_ids
 */
UpdateMediaCommand.prototype['industry_ids'] = undefined;

/**
 * @member {Array.<String>} subscription_type_ids
 */
UpdateMediaCommand.prototype['subscription_type_ids'] = undefined;

/**
 * @member {Array.<Number>} tag_ids
 */
UpdateMediaCommand.prototype['tag_ids'] = undefined;

/**
 * @member {String} media_image_id
 */
UpdateMediaCommand.prototype['media_image_id'] = undefined;

/**
 * @member {String} media_banner_id
 */
UpdateMediaCommand.prototype['media_banner_id'] = undefined;

/**
 * @member {String} logo_image_id
 */
UpdateMediaCommand.prototype['logo_image_id'] = undefined;

/**
 * @member {String} background_image_id
 */
UpdateMediaCommand.prototype['background_image_id'] = undefined;

/**
 * @member {String} media_file_id
 */
UpdateMediaCommand.prototype['media_file_id'] = undefined;

/**
 * @member {String} email_image_id
 */
UpdateMediaCommand.prototype['email_image_id'] = undefined;

/**
 * @member {String} distribution_plan_id
 */
UpdateMediaCommand.prototype['distribution_plan_id'] = undefined;

/**
 * @member {String} country_id
 */
UpdateMediaCommand.prototype['country_id'] = undefined;

/**
 * @member {String} available_from
 */
UpdateMediaCommand.prototype['available_from'] = undefined;

/**
 * @member {module:model/FontTypes} font_type
 */
UpdateMediaCommand.prototype['font_type'] = undefined;

/**
 * @member {String} source
 */
UpdateMediaCommand.prototype['source'] = undefined;

/**
 * @member {String} webinar_link
 */
UpdateMediaCommand.prototype['webinar_link'] = undefined;

/**
 * @member {String} webinar_link_en
 */
UpdateMediaCommand.prototype['webinar_link_en'] = undefined;

/**
 * @member {String} short_description
 */
UpdateMediaCommand.prototype['short_description'] = undefined;

/**
 * @member {String} season_parent_id
 */
UpdateMediaCommand.prototype['season_parent_id'] = undefined;

/**
 * @member {Number} season_number
 */
UpdateMediaCommand.prototype['season_number'] = undefined;

/**
 * @member {String} season_caption
 */
UpdateMediaCommand.prototype['season_caption'] = undefined;

/**
 * @member {Boolean} exclude_in_new_releases
 */
UpdateMediaCommand.prototype['exclude_in_new_releases'] = undefined;

/**
 * @member {Boolean} send_newsletter
 */
UpdateMediaCommand.prototype['send_newsletter'] = undefined;

/**
 * @member {String} meeting_url
 */
UpdateMediaCommand.prototype['meeting_url'] = undefined;

/**
 * @member {String} meeting_date
 */
UpdateMediaCommand.prototype['meeting_date'] = undefined;

/**
 * @member {String} meeting_time
 */
UpdateMediaCommand.prototype['meeting_time'] = undefined;

/**
 * @member {Object.<String, String>} attachments
 */
UpdateMediaCommand.prototype['attachments'] = undefined;
var _default = exports.default = UpdateMediaCommand;