export const createReducer = (actionMapper, { state, initialize } = {}) => {
	let _initialState = initialize ? initialize() : state;

	let handlerGetter = typeof actionMapper === 'object' ? _type => actionMapper[_type] : _type => actionMapper(_type);

	return (_state, action) => {
		if (typeof _state === 'undefined') {
			if (typeof _initialState === 'undefined') return null;
			return _initialState;
		}

		if (!action) {
			console.error('Executing reducer without action!');
			return state;
		}
		let { type, payload, ...rest } = action;
		let handler = handlerGetter(type);
		if (!handler) return _state;
		return handler(_state, payload, rest);
	};
};
