export const AD_SCORES = 'adscores';
export const AD_SCORE_SLIDES = 'adscoreslides';
export const AD_SCORE_MEASUREMENTS = 'adscoremeasurements';
export const AD_SCORE_ATTRIBUTES = 'adscoreattributes';
export const ADDRESSES = 'addresses';
export const CATEGORIES = 'categories';
export const BRANDS = 'brands';
export const COMPANIES = 'companies';
export const COUNTRIES = 'countries';
export const CUSTOMERS = 'customers';
export const CONTACTS = 'contacts';
export const DISTRIBUTION_PLANS = 'distributionplans';
export const ENTITY_ATTACHMENTS = 'entityattachments';
export const ATTACHMENTS = 'attachments';
export const FILE = 'file';
export const INDUSTRIES = 'industries';
export const CUSTOMER_CATEGORIES = 'customercategories';
export const LEARN_MORE = 'learnmore';
export const ACCOUNT = 'account';
export const MEDIA = 'media';
export const MEDIA_READ_STATS = 'mediareadstats';
export const MEDIA_FILES = 'mediafiles';
export const MEDIA_SLIDES = 'mediaslides';
export const MEDIA_SLIDE_FILES = 'mediaslidefiles';
export const SLIDE_COMMENTS = 'slidecomments';
export const MEDIA_TYPES = 'mediatypes';
export const ROLES = 'roles';
export const SUBSCRIPTIONS = 'subscriptions';
export const SUBSCRIPTION_TYPES = 'subscriptiontypes';
export const SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY = 'subscriptiontypemediaavailability';
export const SUBSCRIPTION_DISTRIBUTION_SETTINGS = 'subscriptiondistributionsettings';
export const TAGS = 'tags';
export const USERS = 'users';
export const USER_STATS = 'userstats';
export const REPORT_STATS = 'reportstats';
export const MEDIA_STATS = 'mediastats';
export const PREFERENCE_DEFINITIONS = 'preferencedefinitions';
export const CUSTOMER_MEDIA_ACCESSIBILITIES = 'customermediaaccessibilities';
export const CUSTOMER_MEDIA_STATS = 'customermediastats';
export const CUSTOMER_ADSCORE_ACCESSIBILITIES = 'customeradscoreaccessibilities';
export const KEY_TAKE_AWAYS = 'keytakeaways';
export const MEDIA_KEY_TAKE_AWAYS = 'mediakeytakeaways'; // this resource combines 5 key take aways and their title into one virtual resource. The id of the resource is the id of the media
export const YEARS = 'years';
export const LOOKUP_VALUES = 'lookupvalues';

export default {
	ACCOUNT,
	AD_SCORES,
	AD_SCORE_SLIDES,
	AD_SCORE_MEASUREMENTS,
	AD_SCORE_ATTRIBUTES,
	ADDRESSES,
	CATEGORIES,
	BRANDS,
	COMPANIES,
	COUNTRIES,
	CUSTOMERS,
	CONTACTS,
	DISTRIBUTION_PLANS,
	ENTITY_ATTACHMENTS,
	FILE,
	INDUSTRIES,
	CUSTOMER_CATEGORIES,
	LEARN_MORE,
	MEDIA,
	MEDIA_READ_STATS,
	MEDIA_FILES,
	MEDIA_SLIDES,
	MEDIA_SLIDE_FILES,
	SLIDE_COMMENTS,
	MEDIA_TYPES,
	ROLES,
	SUBSCRIPTIONS,
	SUBSCRIPTION_TYPES,
	SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY,
	SUBSCRIPTION_DISTRIBUTION_SETTINGS,
	TAGS,
	USERS,
	USER_STATS,
	REPORT_STATS,
	MEDIA_STATS,
	PREFERENCE_DEFINITIONS,
	CUSTOMER_MEDIA_ACCESSIBILITIES,
	CUSTOMER_MEDIA_STATS,
	CUSTOMER_ADSCORE_ACCESSIBILITIES,
	KEY_TAKE_AWAYS,
	MEDIA_KEY_TAKE_AWAYS,
	YEARS,
	LOOKUP_VALUES,
	ATTACHMENTS
};
