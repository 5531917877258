import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import baseTheme from './base-theme';
import {
	ActionResultModal,
	ConfirmActionModal,
	ExcessiveDownloadModal,
	RatingModal,
	SessionExpiredModal
} from './components/modals';
import { Notifications } from './components/notifications';
import { OnlyAuthenticated } from './components/only-authenticated';
import { DelayPending } from './components/pending-image';
import { useGAPageView } from './ga';
import FrontEndAdminPage from './pages/admin/authenticated/lazy';
import FullReportPage from './pages/admin/full-report/lazy';
import AdminPage from './pages/admin/lazy';
import AdScoresPage from './pages/adscores/lazy';
import LandingPage from './pages/landing/lazy';
import MyListPage from './pages/mylist/lazy';
import MyReportsPage from './pages/myreports/lazy';
import MyReportsLists from './pages/myreports/lists/lazy';
import UploadMyStudyPage from './pages/myreports/upload/lazy';
import ResearchSlidesPage from './pages/reportslides/lazy';
import ResearchPage from './pages/research/lazy';
import SearchPage from './pages/search/lazy';
import SettingsPage from './pages/settings/lazy';
import WisdropPage from './pages/wisdrop/lazy';
import './styles/app.scss';
import { TopMenuHeader } from './top-menu-header';
import './translation-provider';

const App = ({ fixedHeader }) => {
	useGAPageView();
	return (
		<ThemeProvider theme={baseTheme}>
			<TopMenuHeader headerContainerProps={{ fixed: fixedHeader }} />
			<MainContent fixed={fixedHeader} />
			<RatingModal id="ratingModal" />
			<SessionExpiredModal id="sessionExpiredModal" />
			<ActionResultModal />
			<ConfirmActionModal />
			<ExcessiveDownloadModal />
			<Notifications />
		</ThemeProvider>
	);
};

export default App;

const useMainContentStyles = makeStyles(theme => {
	return {
		mainContent: {
			// height: '100%',
			// width: 'calc(100% + 17px)',
			overflowX: 'hidden',
			// overflowY: 'scroll',
			marginTop: ({ fixed, isLanding, isResearch }) => (fixed && !isLanding && !isResearch ? '7.5vw' : 0)
		}
	};
});

const MainContent = props => {
	const isLanding = useMatch('/');
	const isResearch = useMatch({ path: '/research/:id', end: false });
	const { mainContent } = useMainContentStyles({ ...props, isResearch, isLanding });
	return (
		<main className={mainContent}>
			<Suspense fallback={<DelayPending delay={300} />}>
				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route element={<OnlyAuthenticated />}>
						<Route path="/wisdrop" element={<WisdropPage />} />
						<Route path="/adscores" element={<AdScoresPage />} />
						<Route path="/search" element={<SearchPage />} />
						<Route path="/settings" element={<SettingsPage />} />
						<Route path="/mylist" element={<MyListPage />} />
						<Route path="/admin" element={<AdminPage />}>
							<Route index element={<FrontEndAdminPage />} />
							<Route path="fullreports" element={<FullReportPage />} />
							<Route path="*" element={<Navigate to="." replace />} />
						</Route>
						<Route path="/myreports" element={<MyReportsPage />}>
							<Route index element={<MyReportsLists />} />
							<Route path="upload" element={<UploadMyStudyPage />} />
							<Route path=":id/edit" element={<UploadMyStudyPage />} />
							<Route path="*" element={<Navigate to="." replace />} />
						</Route>
						<Route path="/research/:id">
							<Route index element={<ResearchPage />} />
							<Route path=":slideCategory" element={<ResearchSlidesPage />} />
							<Route path=":slideCategory/:slideNumber" element={<ResearchSlidesPage />} />
							<Route path="*" element={<Navigate to="." replace />} />
						</Route>
					</Route>
					<Route path="*" element={<Navigate to="/wisdrop" replace />} />
				</Routes>
			</Suspense>
		</main>
	);
};
