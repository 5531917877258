import { RESOURCES } from '@extend/paywall-api/lib';
import get from 'lodash/get';
import { getResources, getStatus, resourceReducer } from 'redux-resource';
import { includedResources, reset } from 'redux-resource-plugins';
import { customerMeta, getCustomerMetaRequestKey } from './plugins/customer-meta';
import { listInclusion, listInclusionPending } from './plugins/list-inclusion';
import { requestUpdate } from './plugins/request-update';
import { getUserMetaRequestKey, userMeta } from './plugins/user-meta';
import {
	FORM_SUBMISSION,
	REQUEST_TYPE,
	RESOURCE_GET,
	RESOURCE_GET_CUSTOMER_META,
	RESOURCE_GET_LIST,
	RESOURCE_GET_QUERY,
	RESOURCE_GET_REF_MANY,
	RESOURCE_GET_USER_META,
	RESOURCE_MUTATE
} from './resource-constants';
import { getResourceSlice } from './store';

// documentation of redux-resource: https://redux-resource.js.org/api-reference

export const resourceGet = (resource, ids) => ({
	type: RESOURCE_GET,
	payload: { resource: resource, ids: ids }
});
export const resourceGetCustomerMeta = (resource, id, meta) => ({
	type: RESOURCE_GET_CUSTOMER_META,
	payload: { resource: resource, id: id, meta: meta }
});
export const resourceGetUserMeta = (resource, id, meta) => ({
	type: RESOURCE_GET_USER_META,
	payload: { resource: resource, id: id, meta: meta }
});
export const resourceGetQuery = (
	resource,
	queryName,
	queryParams = {},
	mergeListIds = false,
	omitNotifyOnError = false
) => ({
	type: RESOURCE_GET_QUERY,
	payload: { resource, queryName, queryParams, mergeListIds, omitNotifyOnError }
});
export const resourceGetList = (resource, list, merge = false, immediately = false) => ({
	type: RESOURCE_GET_LIST,
	payload: { resource: resource, list: list, mergeListIds: merge, immediately: immediately }
});
export const resourceGetRefMany = (resource, field, id) => ({
	type: RESOURCE_GET_REF_MANY,
	payload: { resource: resource, field: field, id: id }
});
export const actionResourceMutate = (mutation, payload, success, fail, always) => ({
	type: RESOURCE_MUTATE,
	mutation,
	payload,
	success,
	fail,
	always
});

export const getListRequestKey = list => (list ? `${REQUEST_TYPE.GET_LIST}/${list}` : null);
export const getRefManyRequestKey = (field, id) =>
	field && id ? `${REQUEST_TYPE.GET_REFERENCE_MANY}/${field}/${id}` : null;
export const getQueryRequestKey = queryName => (queryName ? `${REQUEST_TYPE.QUERY}/${queryName}` : null);
export const getQueryListRequestKey = queryName => (queryName ? `${REQUEST_TYPE.QUERY}/list/${queryName}` : null);

export const createResourceReducers = () => {
	var result = {};
	RESOURCES.forEach(
		resourceName =>
			(result[resourceName] = resourceReducer(resourceName, {
				plugins: [
					listInclusionPending,
					listInclusion,
					userMeta,
					customerMeta,
					includedResources,
					requestUpdate,
					reset
				]
			}))
	);
	return result;
};

// state derivations

export const getResourceRecords = (state, resource, resourceIds) => {
	resourceIds = resourceIds || [];
	if (resource) {
		var slice = getResourceSlice(state, resource);
		if (slice) {
			var ress = slice.resources;
			if (ress) return resourceIds.map(id => ress[id] || null);
		}
	}
	return resourceIds.map(id => null);
};

// resources
export const getResourceRecord = (state, resource, resourceId) => {
	if (resource && (resourceId || resourceId === 0)) {
		var slice = getResourceSlice(state, resource);
		if (slice && slice.resources) return slice.resources[resourceId] || null;
	}
	return null;
};

export const getResourceMeta = (state, resource, resourceId) => {
	if (resource && (resourceId || resourceId === 0)) {
		var slice = getResourceSlice(state, resource);
		if (slice && slice.meta) return slice.meta[resourceId] || {};
	}
	return {};
};
export const getResourcesMeta = (state, resource, resourceIds) => {
	resourceIds = resourceIds || [];
	if (resource) {
		var slice = getResourceSlice(state, resource);
		if (slice) {
			var mt = slice.meta;
			if (mt) return resourceIds.map(id => mt[id] || {});
		}
	}
	return resourceIds.map(id => ({}));
};

export const getResourceCustomerMeta = (state, resource, resourceId) => {
	var meta = getResourceMeta(state, resource, resourceId);
	return meta.customerMeta || {};
};

export const getResourceCustomerMetaValue = (state, resource, resourceId, meta) => {
	var custMeta = getResourceCustomerMeta(state, resource, resourceId);
	return custMeta[meta];
};

export const getResourceCustomerMetaRequest = (state, resource, resourceId, meta) => {
	var key = getCustomerMetaRequestKey(resourceId, meta);
	return getResourceRequest(state, resource, key);
};

export const getResourceUserMeta = (state, resource, resourceId) => {
	var meta = getResourceMeta(state, resource, resourceId);
	return meta.userMeta || {};
};

export const getResourceUserMetaValue = (state, resource, resourceId, meta) => {
	var usrMeta = getResourceUserMeta(state, resource, resourceId);
	return usrMeta[meta];
};

export const getResourceUserMetaRequest = (state, resource, resourceId, meta) =>
	getResourceRequest(state, resource, getUserMetaRequestKey(resourceId, meta));

export const getResourceRequest = (state, resource, requestKey) => {
	if (resource && (requestKey || requestKey === 0)) {
		var slice = getResourceSlice(state, resource);
		if (slice && slice.requests) {
			var request = slice.requests[requestKey];
			return request || {};
		}
	}
	return {};
};

// resource queries
export const getResourceQueryRequest = (state, resource, requestKey) =>
	get(state, ['resources', resource, 'requests', requestKey], {});
export const getResourceQueryStatus = (state, resource, requestKey) =>
	getStatus(get(state, ['resources', resource, 'requests', requestKey], {}), 'status');
export const getResourceQueryList = (state, resource, requestKey) =>
	get(state, ['resources', resource, 'lists', requestKey], []);

// resource lists
export const getResourceList = (state, resource, list) => {
	if (resource && list) {
		var slice = getResourceSlice(state, resource);
		if (slice && slice.lists) return slice.lists[list] || null;
	}
	return null;
};

export const getResourceListRecords = (state, resource, list, byId = false) => {
	if (resource && list) return getResources(getResourceSlice(state, resource), list, { byId: byId }) || null;
	return null;
};

export const getResourceListGetRequest = (state, resource, resourceList) =>
	getResourceRequest(state, resource, getListRequestKey(resourceList));
export const getResourceRefManyRequest = (state, resource, field, id) =>
	getResourceRequest(state, resource, getRefManyRequestKey(field, id));

export const getGenericStatus = root => getStatus(root, 'status');
export const getReadStatus = root => getStatus(root, 'readStatus');
export const getListMeta = (meta, list) => get(meta, `lists.${list}`, { included: false, pending: false });
export const getInList = (meta, list) => get(meta, `lists.${list}.included`, false);
export const getInListPending = (meta, list) => get(meta, `lists.${list}.pending`, false);

export const getFormSubmissionActions = (formId, formInstanceId) => {
	var actionKey = `${FORM_SUBMISSION}/${formId}`;
	if (formInstanceId) actionKey = `${actionKey}/@/${formInstanceId}`;
	var submitedKey = `${actionKey}/SUBMITED`;
	var completedKey = `${actionKey}/COMPLETED`;

	var submit = payload => ({ type: submitedKey, payload: payload });
	var completed = error => {
		if (error)
			return {
				type: completedKey,
				payload: error
			};
		return { type: completedKey };
	};

	return { submitAction: submitedKey, completedAction: completedKey, submit, completed };
};
