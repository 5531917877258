"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DecimalAnalyticsItem model module.
 * @module model/DecimalAnalyticsItem
 * @version 1.1.3-alpha.1
 */
class DecimalAnalyticsItem {
  /**
   * Constructs a new <code>DecimalAnalyticsItem</code>.
   * @alias module:model/DecimalAnalyticsItem
   */
  constructor() {
    DecimalAnalyticsItem.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>DecimalAnalyticsItem</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DecimalAnalyticsItem} obj Optional instance to populate.
   * @return {module:model/DecimalAnalyticsItem} The populated <code>DecimalAnalyticsItem</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DecimalAnalyticsItem();
      if (data.hasOwnProperty('value')) {
        obj['value'] = _ApiClient.default.convertToType(data['value'], 'Number');
      }
      if (data.hasOwnProperty('previousVal')) {
        obj['previousVal'] = _ApiClient.default.convertToType(data['previousVal'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DecimalAnalyticsItem</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DecimalAnalyticsItem</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * @member {Number} value
 */
DecimalAnalyticsItem.prototype['value'] = undefined;

/**
 * @member {Number} previousVal
 */
DecimalAnalyticsItem.prototype['previousVal'] = undefined;
var _default = exports.default = DecimalAnalyticsItem;