"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedMediaType = _interopRequireDefault(require("../model/DefinedMediaType"));
var _MediaType = _interopRequireDefault(require("../model/MediaType"));
var _MediaTypeQuery = _interopRequireDefault(require("../model/MediaTypeQuery"));
var _MediaTypeQueryResult = _interopRequireDefault(require("../model/MediaTypeQueryResult"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* MediaTypes service.
* @module api/MediaTypesApi
* @version 1.1.3-alpha.1
*/
class MediaTypesApi {
  /**
  * Constructs a new MediaTypesApi. 
  * @alias module:api/MediaTypesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Get media type
   * Returns the requested media type
   * @param {module:model/DefinedMediaType} id Id of the media type to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaType} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _MediaType.default;
    return this.apiClient.callApi('/api/v1/MediaTypes/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media type
   * Returns the requested media type
   * @param {module:model/DefinedMediaType} id Id of the media type to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaType}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media types
   * Returns an array of requested media types
   * @param {Object} opts Optional parameters
   * @param {Array.<module:model/DefinedMediaType>} opts.DefinedMediaType Ids of the media types to get. Not specifiying ids will return all media types
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediaType>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['DefinedMediaType'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_MediaType.default];
    return this.apiClient.callApi('/api/v1/MediaTypes/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media types
   * Returns an array of requested media types
   * @param {Object} opts Optional parameters
   * @param {Array.<module:model/DefinedMediaType>} opts.DefinedMediaType Ids of the media types to get. Not specifiying ids will return all media types
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediaType>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query media types
   * Queries media types and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaTypeQuery} opts.MediaTypeQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaTypeQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['MediaTypeQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _MediaTypeQueryResult.default;
    return this.apiClient.callApi('/api/v1/MediaTypes/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query media types
   * Queries media types and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaTypeQuery} opts.MediaTypeQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaTypeQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = MediaTypesApi;