"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AdScoreAttribute model module.
 * @module model/AdScoreAttribute
 * @version 1.1.3-alpha.1
 */
class AdScoreAttribute {
  /**
   * Constructs a new <code>AdScoreAttribute</code>.
   * @alias module:model/AdScoreAttribute
   * @param id {Number} 
   * @param name {String} 
   * @param positioning {Number} 
   * @param is_active {Boolean} 
   */
  constructor(id, name, positioning, is_active) {
    AdScoreAttribute.initialize(this, id, name, positioning, is_active);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, name, positioning, is_active) {
    obj['id'] = id;
    obj['name'] = name;
    obj['positioning'] = positioning;
    obj['is_active'] = is_active;
  }

  /**
   * Constructs a <code>AdScoreAttribute</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AdScoreAttribute} obj Optional instance to populate.
   * @return {module:model/AdScoreAttribute} The populated <code>AdScoreAttribute</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AdScoreAttribute();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('positioning')) {
        obj['positioning'] = _ApiClient.default.convertToType(data['positioning'], 'Number');
      }
      if (data.hasOwnProperty('is_active')) {
        obj['is_active'] = _ApiClient.default.convertToType(data['is_active'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AdScoreAttribute</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AdScoreAttribute</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AdScoreAttribute.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    return true;
  }
}
AdScoreAttribute.RequiredProperties = ["id", "name", "positioning", "is_active"];

/**
 * @member {Number} id
 */
AdScoreAttribute.prototype['id'] = undefined;

/**
 * @member {String} name
 */
AdScoreAttribute.prototype['name'] = undefined;

/**
 * @member {Number} positioning
 */
AdScoreAttribute.prototype['positioning'] = undefined;

/**
 * @member {Boolean} is_active
 */
AdScoreAttribute.prototype['is_active'] = undefined;
var _default = exports.default = AdScoreAttribute;