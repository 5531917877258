"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserRoleName = exports.getUserRoleKey = exports.default = exports.UserRoleValues = exports.UserRoleName = exports.UserRoleKeyName = exports.UserRoleItems = exports.ROLE_SUPERADMIN = exports.ROLE_CUSTOMER = exports.ROLE_CONTACT = exports.ROLE_ADMIN = void 0;
const ROLE_SUPERADMIN = 'SuperAdmin';
exports.ROLE_SUPERADMIN = ROLE_SUPERADMIN;
const ROLE_ADMIN = 'Admin';
exports.ROLE_ADMIN = ROLE_ADMIN;
const ROLE_CUSTOMER = 'Customer';
exports.ROLE_CUSTOMER = ROLE_CUSTOMER;
const ROLE_CONTACT = 'Contact';
exports.ROLE_CONTACT = ROLE_CONTACT;
const UserRoleName = {
  [ROLE_SUPERADMIN]: 'System Administrator',
  [ROLE_ADMIN]: 'Administrator',
  [ROLE_CUSTOMER]: 'Customer',
  [ROLE_CONTACT]: 'Customer Contact'
};
exports.UserRoleName = UserRoleName;
const UserRoleKeyName = {
  [ROLE_SUPERADMIN]: 'super_admin',
  [ROLE_ADMIN]: 'admin',
  [ROLE_CUSTOMER]: 'customer',
  [ROLE_CONTACT]: 'contact'
};
exports.UserRoleKeyName = UserRoleKeyName;
const getUserRoleName = id => UserRoleName[id] || '-';
exports.getUserRoleName = getUserRoleName;
const getUserRoleKey = id => UserRoleKeyName[id] || '-';
exports.getUserRoleKey = getUserRoleKey;
const UserRoleValues = [ROLE_SUPERADMIN, ROLE_ADMIN, ROLE_CUSTOMER, ROLE_CONTACT];
exports.UserRoleValues = UserRoleValues;
const UserRoleItems = UserRoleValues.map(id => ({
  id: id,
  name: UserRoleName[id],
  keyText: UserRoleKeyName[id]
}));
exports.UserRoleItems = UserRoleItems;
var _default = {
  SUPERADMIN: ROLE_SUPERADMIN,
  ADMIN: ROLE_ADMIN,
  CUSTOMER: ROLE_CUSTOMER,
  CONTACT: ROLE_CONTACT,
  Values: UserRoleValues,
  Items: UserRoleItems,
  Name: UserRoleName,
  KeyName: UserRoleKeyName,
  getName: getUserRoleName,
  getKey: getUserRoleKey
};
exports.default = _default;