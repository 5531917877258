"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AdScoreFilter model module.
 * @module model/AdScoreFilter
 * @version 1.1.3-alpha.1
 */
class AdScoreFilter {
  /**
   * Constructs a new <code>AdScoreFilter</code>.
   * @alias module:model/AdScoreFilter
   */
  constructor() {
    AdScoreFilter.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>AdScoreFilter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AdScoreFilter} obj Optional instance to populate.
   * @return {module:model/AdScoreFilter} The populated <code>AdScoreFilter</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AdScoreFilter();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('company_id')) {
        obj['company_id'] = _ApiClient.default.convertToType(data['company_id'], 'String');
      }
      if (data.hasOwnProperty('available_to_customer_id')) {
        obj['available_to_customer_id'] = _ApiClient.default.convertToType(data['available_to_customer_id'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('agency')) {
        obj['agency'] = _ApiClient.default.convertToType(data['agency'], 'String');
      }
      if (data.hasOwnProperty('brand_id')) {
        obj['brand_id'] = _ApiClient.default.convertToType(data['brand_id'], 'String');
      }
      if (data.hasOwnProperty('industry_id')) {
        obj['industry_id'] = _ApiClient.default.convertToType(data['industry_id'], 'String');
      }
      if (data.hasOwnProperty('company_ids')) {
        obj['company_ids'] = _ApiClient.default.convertToType(data['company_ids'], ['String']);
      }
      if (data.hasOwnProperty('brand_ids')) {
        obj['brand_ids'] = _ApiClient.default.convertToType(data['brand_ids'], ['String']);
      }
      if (data.hasOwnProperty('industry_ids')) {
        obj['industry_ids'] = _ApiClient.default.convertToType(data['industry_ids'], ['String']);
      }
      if (data.hasOwnProperty('positionings')) {
        obj['positionings'] = _ApiClient.default.convertToType(data['positionings'], ['Number']);
      }
      if (data.hasOwnProperty('gender_groups')) {
        obj['gender_groups'] = _ApiClient.default.convertToType(data['gender_groups'], ['Number']);
      }
      if (data.hasOwnProperty('age_ranges')) {
        obj['age_ranges'] = _ApiClient.default.convertToType(data['age_ranges'], ['Number']);
      }
      if (data.hasOwnProperty('years')) {
        obj['years'] = _ApiClient.default.convertToType(data['years'], ['Number']);
      }
      if (data.hasOwnProperty('from')) {
        obj['from'] = _ApiClient.default.convertToType(data['from'], 'String');
      }
      if (data.hasOwnProperty('to')) {
        obj['to'] = _ApiClient.default.convertToType(data['to'], 'String');
      }
      if (data.hasOwnProperty('favorite')) {
        obj['favorite'] = _ApiClient.default.convertToType(data['favorite'], 'Boolean');
      }
      if (data.hasOwnProperty('visible_to_customer')) {
        obj['visible_to_customer'] = _ApiClient.default.convertToType(data['visible_to_customer'], 'Boolean');
      }
      if (data.hasOwnProperty('unlocked_to_customer')) {
        obj['unlocked_to_customer'] = _ApiClient.default.convertToType(data['unlocked_to_customer'], 'Boolean');
      }
      if (data.hasOwnProperty('noStats')) {
        obj['noStats'] = _ApiClient.default.convertToType(data['noStats'], 'Boolean');
      }
      if (data.hasOwnProperty('q')) {
        obj['q'] = _ApiClient.default.convertToType(data['q'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AdScoreFilter</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AdScoreFilter</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['company_id'] && !(typeof data['company_id'] === 'string' || data['company_id'] instanceof String)) {
      throw new Error("Expected the field `company_id` to be a primitive type in the JSON string but got " + data['company_id']);
    }
    // ensure the json data is a string
    if (data['available_to_customer_id'] && !(typeof data['available_to_customer_id'] === 'string' || data['available_to_customer_id'] instanceof String)) {
      throw new Error("Expected the field `available_to_customer_id` to be a primitive type in the JSON string but got " + data['available_to_customer_id']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['agency'] && !(typeof data['agency'] === 'string' || data['agency'] instanceof String)) {
      throw new Error("Expected the field `agency` to be a primitive type in the JSON string but got " + data['agency']);
    }
    // ensure the json data is a string
    if (data['brand_id'] && !(typeof data['brand_id'] === 'string' || data['brand_id'] instanceof String)) {
      throw new Error("Expected the field `brand_id` to be a primitive type in the JSON string but got " + data['brand_id']);
    }
    // ensure the json data is a string
    if (data['industry_id'] && !(typeof data['industry_id'] === 'string' || data['industry_id'] instanceof String)) {
      throw new Error("Expected the field `industry_id` to be a primitive type in the JSON string but got " + data['industry_id']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['company_ids'])) {
      throw new Error("Expected the field `company_ids` to be an array in the JSON data but got " + data['company_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['brand_ids'])) {
      throw new Error("Expected the field `brand_ids` to be an array in the JSON data but got " + data['brand_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['industry_ids'])) {
      throw new Error("Expected the field `industry_ids` to be an array in the JSON data but got " + data['industry_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['positionings'])) {
      throw new Error("Expected the field `positionings` to be an array in the JSON data but got " + data['positionings']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['gender_groups'])) {
      throw new Error("Expected the field `gender_groups` to be an array in the JSON data but got " + data['gender_groups']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['age_ranges'])) {
      throw new Error("Expected the field `age_ranges` to be an array in the JSON data but got " + data['age_ranges']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['years'])) {
      throw new Error("Expected the field `years` to be an array in the JSON data but got " + data['years']);
    }
    // ensure the json data is a string
    if (data['q'] && !(typeof data['q'] === 'string' || data['q'] instanceof String)) {
      throw new Error("Expected the field `q` to be a primitive type in the JSON string but got " + data['q']);
    }
    return true;
  }
}

/**
 * @member {String} id
 */
AdScoreFilter.prototype['id'] = undefined;

/**
 * @member {String} company_id
 */
AdScoreFilter.prototype['company_id'] = undefined;

/**
 * @member {String} available_to_customer_id
 */
AdScoreFilter.prototype['available_to_customer_id'] = undefined;

/**
 * @member {String} country_id
 */
AdScoreFilter.prototype['country_id'] = undefined;

/**
 * @member {String} agency
 */
AdScoreFilter.prototype['agency'] = undefined;

/**
 * @member {String} brand_id
 */
AdScoreFilter.prototype['brand_id'] = undefined;

/**
 * @member {String} industry_id
 */
AdScoreFilter.prototype['industry_id'] = undefined;

/**
 * @member {Array.<String>} company_ids
 */
AdScoreFilter.prototype['company_ids'] = undefined;

/**
 * @member {Array.<String>} brand_ids
 */
AdScoreFilter.prototype['brand_ids'] = undefined;

/**
 * @member {Array.<String>} industry_ids
 */
AdScoreFilter.prototype['industry_ids'] = undefined;

/**
 * @member {Array.<Number>} positionings
 */
AdScoreFilter.prototype['positionings'] = undefined;

/**
 * @member {Array.<Number>} gender_groups
 */
AdScoreFilter.prototype['gender_groups'] = undefined;

/**
 * @member {Array.<Number>} age_ranges
 */
AdScoreFilter.prototype['age_ranges'] = undefined;

/**
 * @member {Array.<Number>} years
 */
AdScoreFilter.prototype['years'] = undefined;

/**
 * @member {String} from
 */
AdScoreFilter.prototype['from'] = undefined;

/**
 * @member {String} to
 */
AdScoreFilter.prototype['to'] = undefined;

/**
 * @member {Boolean} favorite
 */
AdScoreFilter.prototype['favorite'] = undefined;

/**
 * @member {Boolean} visible_to_customer
 */
AdScoreFilter.prototype['visible_to_customer'] = undefined;

/**
 * @member {Boolean} unlocked_to_customer
 */
AdScoreFilter.prototype['unlocked_to_customer'] = undefined;

/**
 * @member {Boolean} noStats
 */
AdScoreFilter.prototype['noStats'] = undefined;

/**
 * @member {String} q
 */
AdScoreFilter.prototype['q'] = undefined;
var _default = exports.default = AdScoreFilter;