"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BatchSlideInfo = _interopRequireDefault(require("./BatchSlideInfo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The BatchCreateAdScoreSlideCommand model module.
 * @module model/BatchCreateAdScoreSlideCommand
 * @version 1.1.3-alpha.1
 */
class BatchCreateAdScoreSlideCommand {
  /**
   * Constructs a new <code>BatchCreateAdScoreSlideCommand</code>.
   * @alias module:model/BatchCreateAdScoreSlideCommand
   * @param adscore_id {String} 
   * @param batch_id {String} 
   */
  constructor(adscore_id, batch_id) {
    BatchCreateAdScoreSlideCommand.initialize(this, adscore_id, batch_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, adscore_id, batch_id) {
    obj['adscore_id'] = adscore_id;
    obj['batch_id'] = batch_id;
  }

  /**
   * Constructs a <code>BatchCreateAdScoreSlideCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BatchCreateAdScoreSlideCommand} obj Optional instance to populate.
   * @return {module:model/BatchCreateAdScoreSlideCommand} The populated <code>BatchCreateAdScoreSlideCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BatchCreateAdScoreSlideCommand();
      if (data.hasOwnProperty('adscore_id')) {
        obj['adscore_id'] = _ApiClient.default.convertToType(data['adscore_id'], 'String');
      }
      if (data.hasOwnProperty('batch_id')) {
        obj['batch_id'] = _ApiClient.default.convertToType(data['batch_id'], 'String');
      }
      if (data.hasOwnProperty('slides')) {
        obj['slides'] = _ApiClient.default.convertToType(data['slides'], [_BatchSlideInfo.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>BatchCreateAdScoreSlideCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BatchCreateAdScoreSlideCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of BatchCreateAdScoreSlideCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['adscore_id'] && !(typeof data['adscore_id'] === 'string' || data['adscore_id'] instanceof String)) {
      throw new Error("Expected the field `adscore_id` to be a primitive type in the JSON string but got " + data['adscore_id']);
    }
    // ensure the json data is a string
    if (data['batch_id'] && !(typeof data['batch_id'] === 'string' || data['batch_id'] instanceof String)) {
      throw new Error("Expected the field `batch_id` to be a primitive type in the JSON string but got " + data['batch_id']);
    }
    if (data['slides']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['slides'])) {
        throw new Error("Expected the field `slides` to be an array in the JSON data but got " + data['slides']);
      }
      // validate the optional field `slides` (array)
      for (const item of data['slides']) {
        _BatchSlideInfo.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
BatchCreateAdScoreSlideCommand.RequiredProperties = ["adscore_id", "batch_id"];

/**
 * @member {String} adscore_id
 */
BatchCreateAdScoreSlideCommand.prototype['adscore_id'] = undefined;

/**
 * @member {String} batch_id
 */
BatchCreateAdScoreSlideCommand.prototype['batch_id'] = undefined;

/**
 * @member {Array.<module:model/BatchSlideInfo>} slides
 */
BatchCreateAdScoreSlideCommand.prototype['slides'] = undefined;
var _default = exports.default = BatchCreateAdScoreSlideCommand;