"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ViewAdScoreMeasurementsTable model module.
 * @module model/ViewAdScoreMeasurementsTable
 * @version 1.1.3-alpha.1
 */
class ViewAdScoreMeasurementsTable {
  /**
   * Constructs a new <code>ViewAdScoreMeasurementsTable</code>.
   * @alias module:model/ViewAdScoreMeasurementsTable
   * @param adscore_id {String} 
   * @param attr_id {Number} 
   * @param attr_positioning {Number} 
   * @param attribute_name {String} 
   */
  constructor(adscore_id, attr_id, attr_positioning, attribute_name) {
    ViewAdScoreMeasurementsTable.initialize(this, adscore_id, attr_id, attr_positioning, attribute_name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, adscore_id, attr_id, attr_positioning, attribute_name) {
    obj['adscore_id'] = adscore_id;
    obj['attr_id'] = attr_id;
    obj['attr_positioning'] = attr_positioning;
    obj['attribute_name'] = attribute_name;
  }

  /**
   * Constructs a <code>ViewAdScoreMeasurementsTable</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ViewAdScoreMeasurementsTable} obj Optional instance to populate.
   * @return {module:model/ViewAdScoreMeasurementsTable} The populated <code>ViewAdScoreMeasurementsTable</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ViewAdScoreMeasurementsTable();
      if (data.hasOwnProperty('adscore_id')) {
        obj['adscore_id'] = _ApiClient.default.convertToType(data['adscore_id'], 'String');
      }
      if (data.hasOwnProperty('attr_id')) {
        obj['attr_id'] = _ApiClient.default.convertToType(data['attr_id'], 'Number');
      }
      if (data.hasOwnProperty('attr_positioning')) {
        obj['attr_positioning'] = _ApiClient.default.convertToType(data['attr_positioning'], 'Number');
      }
      if (data.hasOwnProperty('attribute_name')) {
        obj['attribute_name'] = _ApiClient.default.convertToType(data['attribute_name'], 'String');
      }
      if (data.hasOwnProperty('total')) {
        obj['total'] = _ApiClient.default.convertToType(data['total'], 'Number');
      }
      if (data.hasOwnProperty('only_men')) {
        obj['only_men'] = _ApiClient.default.convertToType(data['only_men'], 'Number');
      }
      if (data.hasOwnProperty('only_women')) {
        obj['only_women'] = _ApiClient.default.convertToType(data['only_women'], 'Number');
      }
      if (data.hasOwnProperty('age_16_24')) {
        obj['age_16_24'] = _ApiClient.default.convertToType(data['age_16_24'], 'Number');
      }
      if (data.hasOwnProperty('age_25_34')) {
        obj['age_25_34'] = _ApiClient.default.convertToType(data['age_25_34'], 'Number');
      }
      if (data.hasOwnProperty('age_35_44')) {
        obj['age_35_44'] = _ApiClient.default.convertToType(data['age_35_44'], 'Number');
      }
      if (data.hasOwnProperty('age_45_54')) {
        obj['age_45_54'] = _ApiClient.default.convertToType(data['age_45_54'], 'Number');
      }
      if (data.hasOwnProperty('age_55_plus')) {
        obj['age_55_plus'] = _ApiClient.default.convertToType(data['age_55_plus'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ViewAdScoreMeasurementsTable</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ViewAdScoreMeasurementsTable</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ViewAdScoreMeasurementsTable.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['adscore_id'] && !(typeof data['adscore_id'] === 'string' || data['adscore_id'] instanceof String)) {
      throw new Error("Expected the field `adscore_id` to be a primitive type in the JSON string but got " + data['adscore_id']);
    }
    // ensure the json data is a string
    if (data['attribute_name'] && !(typeof data['attribute_name'] === 'string' || data['attribute_name'] instanceof String)) {
      throw new Error("Expected the field `attribute_name` to be a primitive type in the JSON string but got " + data['attribute_name']);
    }
    return true;
  }
}
ViewAdScoreMeasurementsTable.RequiredProperties = ["adscore_id", "attr_id", "attr_positioning", "attribute_name"];

/**
 * @member {String} adscore_id
 */
ViewAdScoreMeasurementsTable.prototype['adscore_id'] = undefined;

/**
 * @member {Number} attr_id
 */
ViewAdScoreMeasurementsTable.prototype['attr_id'] = undefined;

/**
 * @member {Number} attr_positioning
 */
ViewAdScoreMeasurementsTable.prototype['attr_positioning'] = undefined;

/**
 * @member {String} attribute_name
 */
ViewAdScoreMeasurementsTable.prototype['attribute_name'] = undefined;

/**
 * @member {Number} total
 */
ViewAdScoreMeasurementsTable.prototype['total'] = undefined;

/**
 * @member {Number} only_men
 */
ViewAdScoreMeasurementsTable.prototype['only_men'] = undefined;

/**
 * @member {Number} only_women
 */
ViewAdScoreMeasurementsTable.prototype['only_women'] = undefined;

/**
 * @member {Number} age_16_24
 */
ViewAdScoreMeasurementsTable.prototype['age_16_24'] = undefined;

/**
 * @member {Number} age_25_34
 */
ViewAdScoreMeasurementsTable.prototype['age_25_34'] = undefined;

/**
 * @member {Number} age_35_44
 */
ViewAdScoreMeasurementsTable.prototype['age_35_44'] = undefined;

/**
 * @member {Number} age_45_54
 */
ViewAdScoreMeasurementsTable.prototype['age_45_54'] = undefined;

/**
 * @member {Number} age_55_plus
 */
ViewAdScoreMeasurementsTable.prototype['age_55_plus'] = undefined;
var _default = exports.default = ViewAdScoreMeasurementsTable;