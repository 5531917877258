import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const primaryColor = '#e52028';
const secondaryColor = '#6c6c6c';
const secondaryTextColor = '#ACACAC';
const backgroundColor = '#000000';

// A custom theme for this app
const baseTheme = createTheme({
	wisdrop: {
		studyPreview: {
			fontSizes: {
				factors: {
					title: 1,
					subtitle: 0.583,
					description: 0.5,
					captions: 0.5
				}
			}
		},
		adScores: {
			fontColor: '#202020',
			metricPalette: { poor: '#E50813', moderate: '#FF7E79', good: '#ED9505', strong: '#00E000', amazing: '#4B9D00' }
		}
	},
	typography: {
		fontSize: 14,
		fontFamily: [
			'Helvetica',
			'Helvetica Neue',
			'-apple-system',
			'BlinkMacSystemFont',
			'Segoe UI',
			'Roboto',
			'Arial',
			'Noto Sans',
			'sans-serif',
			'Apple Color Emoji',
			'Segoe UI Emoji',
			'Segoe UI Symbol',
			'Noto Color Emoji'
		],
		button: {
			textTransform: 'none'
		}
	},
	palette: {
		type: 'dark',
		primary: {
			main: primaryColor
		},
		secondary: {
			main: secondaryColor
		},
		error: {
			main: red.A400
		},
		background: {
			default: backgroundColor
		}
	},
	overrides: {
		MuiTypography: {
			caption: {
				fontSize: '0.8rem'
			}
		},
		MuiButton: {
			root: {
				transitionProperty: 'background-color, box-shadow, border, color',
				padding: '2px 10px'
			},
			label: {
				fontFamily: 'Helvetica-Bold',
				flexWrap: 'nowrap',
				whiteSpace: 'nowrap'
			},
			contained: {
				'borderStyle': 'solid',
				'borderWidth': '1px',
				'&:hover': {
					backgroundColor: 'transparent'
				}
			},
			containedPrimary: {
				'borderColor': primaryColor,
				'&:hover': {
					backgroundColor: 'transparent',
					color: primaryColor
				},
				'&.Mui-disabled': { color: primaryColor, opacity: 0.5 }
			},
			containedSecondary: {
				'borderColor': secondaryColor,
				'&:hover': {
					backgroundColor: 'transparent',
					color: 'white'
				},
				'&.Mui-disabled': { color: 'white', opacity: 0.5 }
			},
			outlinedPrimary: {
				'borderColor': primaryColor,
				'color': primaryColor,
				'&:hover': {
					backgroundColor: 'transparent',
					color: primaryColor
				},
				'&.Mui-disabled': { borderColor: primaryColor, color: primaryColor, opacity: 0.5 }
			},
			textSecondary: {
				'color': secondaryTextColor,
				'&:hover': {
					color: 'white'
				}
			},
			startIcon: {
				marginRight: '0.5vw'
			},
			endIcon: {
				marginLeft: '0.5vw'
			},
			sizeSmall: {
				fontSize: '12px'
			}
		},
		MuiFilledInput: {
			root: {
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px',
				borderBottomLeftRadius: '4px',
				borderBottomRightRadius: '4px',
				backgroundColor: '#333333'
			},
			underline: {
				'&.Mui-error:after': {
					borderBottom: '1px solid transparent'
				},
				'&:after': {
					borderBottom: '1px solid transparent'
				},
				'&:before': {
					borderBottom: '1px solid transparent'
				},
				'&:hover:before': {
					borderBottom: '1px solid transparent'
				}
			}
		},
		MuiFormHelperText: {
			root: {
				fontSize: '0.77em'
			}
		},
		MuiFormLabel: {
			root: {
				'&.Mui-focused': {
					color: 'inherit'
				}
			}
		},
		MuiFab: {
			sizeSmall: { minWidth: 40, minHeight: 40 },
			sizeMedium: { minWidth: 48, minHeight: 48 }
		}
	},
	props: {
		MuiButton: {
			variant: 'contained',
			color: 'primary'
		},
		MuiTextField: {
			margin: 'dense',
			variant: 'filled'
		}
	}
});

export default baseTheme;
