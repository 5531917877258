"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedMediaType = _interopRequireDefault(require("./DefinedMediaType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MediaType model module.
 * @module model/MediaType
 * @version 1.1.3-alpha.1
 */
class MediaType {
  /**
   * Constructs a new <code>MediaType</code>.
   * @alias module:model/MediaType
   * @param id {module:model/DefinedMediaType} 
   * @param description {String} 
   */
  constructor(id, description) {
    MediaType.initialize(this, id, description);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, description) {
    obj['id'] = id;
    obj['description'] = description;
  }

  /**
   * Constructs a <code>MediaType</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MediaType} obj Optional instance to populate.
   * @return {module:model/MediaType} The populated <code>MediaType</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MediaType();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _DefinedMediaType.default.constructFromObject(data['id']);
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MediaType</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MediaType</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of MediaType.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    return true;
  }
}
MediaType.RequiredProperties = ["id", "description"];

/**
 * @member {module:model/DefinedMediaType} id
 */
MediaType.prototype['id'] = undefined;

/**
 * @member {String} description
 */
MediaType.prototype['description'] = undefined;
var _default = exports.default = MediaType;