"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AdScoreSlide = _interopRequireDefault(require("../model/AdScoreSlide"));
var _AdScoreSlideQuery = _interopRequireDefault(require("../model/AdScoreSlideQuery"));
var _AdScoreSlideQueryResult = _interopRequireDefault(require("../model/AdScoreSlideQueryResult"));
var _CommandAddAdScoreSlide = _interopRequireDefault(require("../model/CommandAddAdScoreSlide"));
var _CommandUpdateAdScoreSlide = _interopRequireDefault(require("../model/CommandUpdateAdScoreSlide"));
var _FileIndex = _interopRequireDefault(require("../model/FileIndex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* AdScoreSlides service.
* @module api/AdScoreSlidesApi
* @version 1.1.3-alpha.1
*/
class AdScoreSlidesApi {
  /**
  * Constructs a new AdScoreSlidesApi. 
  * @alias module:api/AdScoreSlidesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new slide
   * Creates a new slide and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandAddAdScoreSlide} opts.CommandAddAdScoreSlide 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreSlide} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandAddAdScoreSlide'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AdScoreSlide.default;
    return this.apiClient.callApi('/api/v1/AdScoreSlides', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new slide
   * Creates a new slide and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandAddAdScoreSlide} opts.CommandAddAdScoreSlide 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreSlide}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get ad-score slide
   * Returns the requested ad-score slide
   * @param {String} id Id of the ad-score slide to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreSlide} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _AdScoreSlide.default;
    return this.apiClient.callApi('/api/v1/AdScoreSlides/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get ad-score slide
   * Returns the requested ad-score slide
   * @param {String} id Id of the ad-score slide to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreSlide}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a specific ad-score slide list
   * Returns an array of the ad-score slide included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScoreSlide>} and HTTP response
   */
  getListWithHttpInfo(listName) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling getList");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_AdScoreSlide.default];
    return this.apiClient.callApi('/api/v1/AdScoreSlides/list/{listName}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a specific ad-score slide list
   * Returns an array of the ad-score slide included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScoreSlide>}
   */
  getList(listName) {
    return this.getListWithHttpInfo(listName).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple adScore slide lists
   * Returns a dictionary of listnames to adscore slide lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: [AdScoreSlide]}>} and HTTP response
   */
  getListsWithHttpInfo(names) {
    let postBody = null;
    // verify the required parameter 'names' is set
    if (names === undefined || names === null) {
      throw new Error("Missing the required parameter 'names' when calling getLists");
    }
    let pathParams = {};
    let queryParams = {
      'names': this.apiClient.buildCollectionParam(names, 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': [_AdScoreSlide.default]
    };
    return this.apiClient.callApi('/api/v1/AdScoreSlides/lists', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple adScore slide lists
   * Returns a dictionary of listnames to adscore slide lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: [AdScoreSlide]}>}
   */
  getLists(names) {
    return this.getListsWithHttpInfo(names).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple ad-score slides
   * Returns an array of requested ad-score slides
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the ad-score slides to get. Not specifiying ids will return all ad-score slides
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScoreSlide>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_AdScoreSlide.default];
    return this.apiClient.callApi('/api/v1/AdScoreSlides/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple ad-score slides
   * Returns an array of requested ad-score slides
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the ad-score slides to get. Not specifiying ids will return all ad-score slides
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScoreSlide>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple ad-score slides referencing the requested id by a certain field
   * Returns a list of ad-score slides that are referencing the requested id by a certain field
   * @param {String} field The field of the ad-score slide that is referencing the requested id
   * @param {String} id Id of the ad-score slide that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScoreSlide>} and HTTP response
   */
  getReferencesWithHttpInfo(field, id) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error("Missing the required parameter 'field' when calling getReferences");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getReferences");
    }
    let pathParams = {
      'field': field,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_AdScoreSlide.default];
    return this.apiClient.callApi('/api/v1/AdScoreSlides/references/{field}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple ad-score slides referencing the requested id by a certain field
   * Returns a list of ad-score slides that are referencing the requested id by a certain field
   * @param {String} field The field of the ad-score slide that is referencing the requested id
   * @param {String} id Id of the ad-score slide that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScoreSlide>}
   */
  getReferences(field, id) {
    return this.getReferencesWithHttpInfo(field, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query ad-score slides
   * Queries ad-score slides and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreSlideQuery} opts.AdScoreSlideQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreSlideQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AdScoreSlideQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AdScoreSlideQueryResult.default;
    return this.apiClient.callApi('/api/v1/AdScoreSlides/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query ad-score slides
   * Queries ad-score slides and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreSlideQuery} opts.AdScoreSlideQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreSlideQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete ad-score slide
   * Deletes a ad-score slide and returns the deleted object
   * @param {String} id Id of the ad-score slide to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreSlide} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _AdScoreSlide.default;
    return this.apiClient.callApi('/api/v1/AdScoreSlides/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete ad-score slide
   * Deletes a ad-score slide and returns the deleted object
   * @param {String} id Id of the ad-score slide to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreSlide}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple ad-score slides
   * Deletes multiple ad-score slides and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the ad-score slides to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScoreSlide>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_AdScoreSlide.default];
    return this.apiClient.callApi('/api/v1/AdScoreSlides/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple ad-score slides
   * Deletes multiple ad-score slides and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the ad-score slides to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScoreSlide>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update ad-score slides
   * Updates a ad-score slide and returns the updated object
   * @param {String} id Id of the ad-score slide to update
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandUpdateAdScoreSlide} opts.CommandUpdateAdScoreSlide 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreSlide} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CommandUpdateAdScoreSlide'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AdScoreSlide.default;
    return this.apiClient.callApi('/api/v1/AdScoreSlides/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update ad-score slides
   * Updates a ad-score slide and returns the updated object
   * @param {String} id Id of the ad-score slide to update
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandUpdateAdScoreSlide} opts.CommandUpdateAdScoreSlide 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreSlide}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Upload slide files
   * Uploads files to the specified slide
   * @param {String} id Id of the ad-score slide to upload files to
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FileIndex>} and HTTP response
   */
  uploadFilesWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling uploadFiles");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'files': this.apiClient.buildCollectionParam(opts['files'], 'passthrough')
    };
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['application/json'];
    let returnType = [_FileIndex.default];
    return this.apiClient.callApi('/api/v1/AdScoreSlides/{id}/files', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Upload slide files
   * Uploads files to the specified slide
   * @param {String} id Id of the ad-score slide to upload files to
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FileIndex>}
   */
  uploadFiles(id, opts) {
    return this.uploadFilesWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AdScoreSlidesApi;