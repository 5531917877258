import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import logoImage from '../assets/imgs/wisdrop_logo_HRZ-04.png';
import { setClasses } from '../utilities/classname-utils';
import { CloseFab } from './close-fab';
import { LogoImage } from './logo-image';

export const WisdropButton = memo(({ classes, className, color, filled, ...props }) => (
	<Button
		className={setClasses(classes, className)}
		color={filled === false ? 'wisdrop' : 'wisdrop-filled'}
		{...props}
	/>
));
WisdropButton.displayName = 'WisdropButton';

export const HeaderWithClose = memo(({ onClose }) => {
	return (
		<Row className="justify-content-between align-items-center">
			<Col xs={3}>
				<Link to="/wisdrop">
					<LogoImage src={logoImage} hideShadow />
				</Link>
			</Col>
			<Col xs="auto" className="account-col">
				<CloseFab onClick={onClose} />
			</Col>
		</Row>
	);
});
HeaderWithClose.displayName = 'HeaderWithClose';

export const SlideNavigationButton = memo(({ direction, onClick, className, classes, ...props }) => {
	return (
		<button
			className={setClasses('btn-navigate', className, classes, {
				'btn-prev': direction === 'prev',
				'btn-next': direction === 'next'
			})}></button>
	);
});
SlideNavigationButton.displayName = 'SlideNavigationButton';
