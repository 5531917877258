import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { makeStyles, Switch } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { Children, cloneElement, isValidElement, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useAuthentication } from '../hooks/auth';
import { useNavigatePush } from '../hooks/browser';
import { getSignInPickUserModalIsOpen, signInPickUserToggleOpen } from '../state/sign-in';
import { useUserSettingAutoAdvance } from '../state/user-settings';
import { FA, IconAngleRight } from './icons';
import { WisdropButton } from './wisdrop-buttons';

export const withAuth = Component =>
	memo(props => {
		const { success, pending, error, expired, logOut, name, state } = useAuthentication();
		return (
			<Component
				authSuccess={success}
				authPending={pending}
				authExpired={expired}
				authError={error}
				authDisplayName={name}
				authState={state}
				onLogOut={logOut}
				{...props}
			/>
		);
	});

export const AuthView = memo(({ children, onAuthorized, onUnauthorized, onPending, onExpired, onError, ...props }) => {
	const { success, pending, error, expired, logOut, name, state } = useAuthentication();

	const authProps = {
		authSuccess: success,
		authPending: pending,
		authExpired: expired,
		authError: error,
		authState: state,
		authDisplayName: name,
		onLogOut: logOut
	};

	if (typeof children === 'function') return children({ ...authProps, ...props });

	if (Children.count(children) > 0)
		return Children.map(children, child => cloneElement(child, { ...authProps, ...props, ...child.props }));

	let forRender = pending
		? onPending
		: expired
		? onExpired
		: error
		? onError
		: success
		? onAuthorized
		: onUnauthorized;

	if (!forRender) return null;

	if (isValidElement(forRender)) return cloneElement(forRender, { ...authProps, ...props, ...forRender.props });

	if (typeof forRender === 'function') return forRender({ ...authProps, ...props });

	if (forRender.render && forRender.render.call) {
		let Component = forRender;
		return (
			<Component {...authProps} {...props}>
				{children}
			</Component>
		);
	}
});

export const LogoutView = memo(({ render, children, ...props }) => {
	const { logOut: onLogOut } = useAuthentication();

	if (typeof children === 'function') return children({ onLogOut, ...props });

	if (Children.count(children) > 0)
		return Children.map(children, child => cloneElement(child, { onLogOut, ...props }));

	if (!render) return null;

	if (isValidElement(render)) return cloneElement(render, { onLogOut, ...props });

	if (typeof render === 'function') return render({ onLogOut, ...props });

	if (render.render && render.render.call) {
		let Component = render;
		return (
			<Component onLogOut={onLogOut} {...props}>
				{children}
			</Component>
		);
	}

	return null;
});

const ButtonLogout = memo(
	({ onClick, authDisplayName, authState, authError, onLogOut, authPending, authSuccess, authExpired, ...props }) => {
		const { pending, expired, error, logOut } = useAuthentication();
		return (
			<WisdropButton onClick={logOut} filled={false} disabled={pending || expired || error} {...props}>
				{'Sign out'}
			</WisdropButton>
		);
	}
);

const ButtonLogin = memo(
	({ onClick, authDisplayName, authState, authError, onLogOut, authPending, authSuccess, authExpired, ...props }) => {
		const dispatch = useDispatch();
		const { pending } = useAuthentication();
		const isOpen = useSelector(getSignInPickUserModalIsOpen);
		const toggleOpen = useCallback(() => dispatch(signInPickUserToggleOpen()), [dispatch]);
		return (
			<WisdropButton onClick={toggleOpen} filled={false} disabled={isOpen || pending} {...props}>
				{pending ? <FA icon={faSpinner} spin={true} /> : 'Sign in'}
			</WisdropButton>
		);
	}
);

export const LoggedInStateButton = memo(props => (
	<AuthView
		{...props}
		onAuthorized={<ButtonLogout />}
		onUnauthorized={<ButtonLogin />}
		onExpired={<ButtonLogin />}
		onError={<ButtonLogin />}
		onPending={<ButtonLogin />}
	/>
));

export const AccountDropDown = memo(
	({ authSuccess, authPending, authExpired, authError, authState, authDisplayName, onLogOut, ...props }) => {
		const { menu: menuClass, button: buttonClasses } = useDropDownStyles();

		const { name, logOut } = useAuthentication();
		const [autoPlay, toggleAutoPlay] = useUserSettingAutoAdvance();
		const [isOpen, setOpen] = useState(false);
		const toggle = useCallback(() => setOpen(open => !open), [setOpen]);
		const push = useNavigatePush();
		const goToSettings = useCallback(() => push('/settings'), [push]);
		const goToAdmin = useCallback(() => push('/admin'), [push]);

		return (
			<Dropdown isOpen={isOpen} toggle={toggle}>
				<DropdownToggle id={'usernameBtn'} color="profile" className={buttonClasses}>
					<AccountCircleIcon fontSize="inherit" title={name} />
				</DropdownToggle>
				<DropdownMenu className={menuClass}>
					<DropdownItem header>{name}</DropdownItem>
					<DropdownItem divider />
					<DropdownItem toggle={false}>
						{'Auto-Play '}
						<Switch checked={autoPlay} onChange={toggleAutoPlay} color="primary" size="small" />
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={goToAdmin}>
						{'Manage Users'} <IconAngleRight />
					</DropdownItem>
					<DropdownItem onClick={goToSettings}>
						{'My Account'} <IconAngleRight />
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={logOut}>{'Sign out'}</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
);

const useDropDownStyles = makeStyles(theme => ({
	button: { fontSize: 'inherit' },
	menu: {
		'backgroundColor': 'black',
		'borderRadius': '0.5vw',
		'color': 'white',
		'& .dropdown-divider': {
			marginRight: 'auto',
			marginLeft: 'auto',
			width: '90%',
			borderColor: theme.palette.secondary.main
		},
		'& .dropdown-item': {
			'color': 'white',
			'&:hover': { color: theme.palette.primary.main, backgroundColor: 'black' }
		},
		'& .dropdown-item:not(:last-child)': { borderBottom: '1px solid sliver' }
	}
}));
