import { ACCOUNT } from '@extend/paywall-api/src/resource-types';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Tooltip,
	Typography,
	makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useState } from 'react';
import { FormProvider, useController, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useMutation } from 'react-query';
import { usePaywallApi } from '../hooks/paywall-api';
import { useNotifications } from './notifications';

export const InviteColleagueButton = props => {
	const [isModalOpen, setModalOpen] = useState(false);
	const onClose = () => setModalOpen(false);
	return (
		<>
			<Tooltip title="Invite your colleagues">
				<PersonAddRoundedIcon fontSize="inherit" {...props} onClick={() => setModalOpen(true)} />
			</Tooltip>
			<InviteDialog open={isModalOpen} onClose={onClose} />
		</>
	);
};

const emailValidation = {
	required: 'Email is required',
	pattern: {
		value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
		message: 'Invalid email address'
	}
};
const required = {
	required: 'Field is required'
};

function InviteDialog({ open, onClose }) {
	const form = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues: { colleagues: [{ name: '', email: '' }] }
	});
	const classes = inviteColleaguesStyles();
	const { handleSubmit, setError } = form;
	const api = usePaywallApi();
	const { error, success } = useNotifications();
	const handleClose = () => {
		form.reset();
		onClose();
	};

	const { mutateAsync, isLoading } = useMutation({
		mutationFn: async values => {
			try {
				const accountApi = await api.resolveApi(ACCOUNT);
				return await accountApi.invite(values);
			} catch (e) {
				console.log('Error while sending invitations', e);
				throw e;
			}
		},
		onSuccess: () => {
			handleClose();
			success({
				title: 'Success',
				message: 'Email invitations have been sent successfully'
			});
		},
		onError: e => {
			if (e.status === 400) {
				// validation errors
				const { errors } = e.body || {};
				if (errors) {
					Object.keys(errors).forEach(field => {
						var msg = errors[field][0];
						if (field === 'serverError') {
							if (msg === 'invalid domains')
								msg =
									'You can only invite people that work in your organisation via their business email account.';
							setError('root.serverError', {
								type: '400',
								message: msg
							});
						} else {
							setError(field, {
								type: '400',
								message: msg
							});
						}
					});
				}
				return;
			}

			error({
				title: 'Error',
				message: 'There was en error trying to send email invitations'
			});
		}
	});

	const {
		formState: { isLoading: isFormLoading, isSubmitting, isValidating, isValid }
	} = form;

	const submitDisabled = isFormLoading || isSubmitting || isLoading || isValidating || !isValid;

	return (
		<FormProvider {...form}>
			<Dialog open={open} onClose={handleClose} classes={classes} maxWidth="xl" keepMounted={false}>
				<DialogTitle disableTypography className="invite-title">
					<Typography variant="h2">Invite your colleagues</Typography>
					<IconButton aria-label="close" size="medium" className="close-button" onClick={handleClose}>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				</DialogTitle>
				<DialogTitle className="invite-subtitle">(it's included in your plan!)</DialogTitle>
				<form onSubmit={submitDisabled ? null : handleSubmit(mutateAsync)}>
					<DialogContent className="invite-body">
						<div class="invite-icon">
							<PersonAddRoundedIcon fontSize="inherit" />
						</div>
						<div>
							<Typography variant="body1">Send invites by email</Typography>
						</div>
						<InviteEmailFields />
					</DialogContent>
					<DialogContent className="invite-toolbar">
						<Button
							className="invite-send-button"
							size="large"
							variant="contained"
							color="primary"
							disableElevation
							type="submit"
							disabled={submitDisabled}>
							Send Invites
						</Button>
					</DialogContent>
				</form>
			</Dialog>
		</FormProvider>
	);
}

const InviteEmailFields = () => {
	const { isValid, isSubmitting, isLoading, isValidating, errors } = useFormState();
	const { fields, append, remove } = useFieldArray({ name: 'colleagues', rules: { minLength: 1 } });

	const { root: { serverError = null } = {} } = errors || {};

	const addDisabled = isSubmitting || isLoading || isValidating || !isValid;

	const showRemove = fields?.length > 1;
	return (
		<>
			{(fields || []).map((field, index) => (
				<ColleagueInputRow
					key={field.id}
					index={index}
					name={`colleagues.${index}`}
					remove={showRemove ? () => remove(index) : null}
					showRemove={showRemove}
				/>
			))}
			{serverError ? (
				<div className="server-error-row">
					<Typography variant="subtitle1" color="error">
						{serverError.message}
					</Typography>
				</div>
			) : null}
			<div className="email-add-row">
				<Button
					className="email-add-button"
					disableElevation
					startIcon={<AddIcon htmlColor="black" />}
					size="large"
					onClick={addDisabled ? null : () => append({ name: '', email: '' }, { shouldFocus: true })}
					disabled={addDisabled}>
					Add More
				</Button>
				{showRemove ? (
					<div>
						<IconButton size="small" onClick={null}>
							<AddIcon htmlColor="transparent" />
						</IconButton>
					</div>
				) : null}
			</div>
		</>
	);
};

const ColleagueInputRow = ({ remove, name, showRemove }) => {
	return (
		<div className="email-input-row">
			<FormTextField
				name={`${name}.last_name`}
				className="last-name-field"
				placeholder="Last Name"
				rules={required}
			/>
			<FormTextField name={`${name}.name`} className="name-field" placeholder="Name" rules={required} />
			<FormTextField
				name={`${name}.email`}
				className="email-field"
				placeholder="Business email"
				inputProps={{ type: 'email' }}
				rules={emailValidation}
			/>
			{showRemove ? (
				<div className="remove-icon-column">
					<IconButton size="small" onClick={remove} tabIndex={-1}>
						<RemoveCircleOutlineIcon htmlColor="red" />
					</IconButton>
				</div>
			) : null}
		</div>
	);
};

const FormTextField = ({ name: inName, disabled: inDisabled, rules, ...props }) => {
	const {
		field: { name, onBlur, onChange, ref, value, disabled },
		fieldState: { invalid, error }
	} = useController({
		name: inName,
		rules: rules,
		shouldUnregister: true
	});
	return (
		<TextField
			variant="outlined"
			inputRef={ref}
			hiddenLabel
			{...props}
			error={invalid}
			helperText={error?.message}
			value={value}
			onBlur={onBlur}
			name={name}
			onChange={onChange}
			disabled={disabled || inDisabled}
		/>
	);
};

const inviteColleaguesStyles = makeStyles(theme => ({
	root: {
		'& .invite-title': {
			'paddingBottom': 0,
			'& .MuiTypography-root': {
				fontFamily: 'Helvetica-Bold',
				fontSize: '1.8vw',
				paddingRight: '60px',
				whiteSpace: 'nowrap'
			},
			'& .close-button': {
				'fontSize': '1.3vw',
				'position': 'absolute',
				'right': theme.spacing(1),
				'top': theme.spacing(1),
				'color': 'black',
				'& .MuiIconButton-label': {
					border: '1px sold black',
					borderColor: 'black',
					borderRadius: '50%',
					borderStyle: 'solid',
					borderWidth: '1px',
					padding: '0.2vw'
				}
			}
		},
		'& .invite-subtitle': {
			'paddingTop': 0,
			'paddingBottom': 0,
			'marginTop': -5,
			'& .MuiTypography-root': { fontSize: '1vw' }
		},
		'& .invite-body': {
			'display': 'flex',
			'flexDirection': 'column',
			// justifyContent: 'center',
			'alignItems': 'stretch',
			'paddingTop': 0,
			'& .invite-icon': {
				'textAlign': 'center',
				'fontSize': '5vw',
				'& .MuiSvgIcon-fontSizeInherit': { fontSize: '5vw' }
			},
			'& .email-input-row': {
				'display': 'flex',
				'alignItems': 'top',
				'justifyContent': 'flex-start',
				'& .name-field': {
					flex: '2 1 0'
				},
				'& .last-name-field': {
					flex: '2 1 0'
				},
				'& .email-field': {
					flex: '4 1 0'
				},
				'& .remove-icon-column': {
					paddingTop: '15px'
				}
			},
			'& .email-add-row': {
				'display': 'flex',
				'alignItems': 'center',
				'justifyContent': 'stretch',
				'paddingTop': '0.2vw',
				'paddingRight': '0.2vw',
				'& .email-add-button': {
					backgroundColor: 'white',
					width: '100%',
					color: 'black',
					borderRadius: '0.5vw'
				}
			},
			'& .MuiOutlinedInput-root': {
				'backgroundColor': 'white',
				'color': 'black',
				'border': '1px solid black',
				'borderRadius': '0.5vw',
				'fontSize': '1.2em',
				'marginRight': 5,
				'&.Mui-error': {
					border: '2px solid red'
				}
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none'
			}
		},
		'& .invite-toolbar': {
			'display': 'flex',
			'justifyContent': 'end',
			'alignItems': 'center',
			'paddingRight': 60,
			'& .invite-send-button': {
				borderRadius: '0.5vw'
			}
		}
	},
	paper: { color: 'black', backgroundColor: 'white', borderRadius: '10px', width: '50vw', minWidth: '300px' }
}));
