import React, { memo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthentication } from '../hooks/auth';
import { useMinimumScreenWidth } from '../hooks/browser';
import { TooSmallWidthLoader } from './loaders/site-loaders';
import { DelayPending } from './pending-image';

export const OnlyAuthenticated = props => {
	const isSmall = useMinimumScreenWidth(1024);
	if (isSmall) return <TooSmallWidthLoader width={1024} />;
	return <InternalOnlyAuthenticated {...props} />;
};

const InternalOnlyAuthenticated = memo(props => {
	const location = useLocation();
	const { success, pending } = useAuthentication();
	if (pending) return <DelayPending delay={300} />;
	return success ? <Outlet /> : <Navigate to="/" replace state={{ openLogin: true, redirect: location }} />;
});
