const RESOURCE_REQUEST_UPDATE = '@PLUGIN/RESOURCE_REQUEST_UPDATE';

export const updateResourceRequest = ({ resourceType, requestKey, ids, mergeIds = true, newStatus } = {}) => {
	return {
		type: RESOURCE_REQUEST_UPDATE,
		resourceType,
		requestKey,
		ids,
		mergeIds,
		newStatus
	};
};

export function requestUpdate(resType) {
	return (state, action) => {
		const { type, resourceType, requestKey, ids, mergeIds, newStatus } = action;
		if (type !== RESOURCE_REQUEST_UPDATE || resourceType !== resType || !requestKey) return state;

		var { requests = {} } = state;
		var { [requestKey]: request = {} } = requests;
		var { ids: rIds = [], status } = request;

		if (ids) {
			if (mergeIds) {
				rIds = [...rIds, ...ids];
			} else rIds = [...ids];
		}

		if (newStatus) status = newStatus;

		return {
			...state,
			requests: {
				...requests,
				[requestKey]: {
					requestKey,
					resourceType,
					ids: rIds,
					status
				}
			}
		};
	};
}
