"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _EntityMetadataType = _interopRequireDefault(require("./EntityMetadataType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The EntityCustomerMeta model module.
 * @module model/EntityCustomerMeta
 * @version 1.1.3-alpha.1
 */
class EntityCustomerMeta {
  /**
   * Constructs a new <code>EntityCustomerMeta</code>.
   * @alias module:model/EntityCustomerMeta
   * @param customer_id {String} 
   * @param entity_id {String} 
   * @param meta {String} 
   * @param meta_type {module:model/EntityMetadataType} 
   */
  constructor(customer_id, entity_id, meta, meta_type) {
    EntityCustomerMeta.initialize(this, customer_id, entity_id, meta, meta_type);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, customer_id, entity_id, meta, meta_type) {
    obj['customer_id'] = customer_id;
    obj['entity_id'] = entity_id;
    obj['meta'] = meta;
    obj['meta_type'] = meta_type;
  }

  /**
   * Constructs a <code>EntityCustomerMeta</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EntityCustomerMeta} obj Optional instance to populate.
   * @return {module:model/EntityCustomerMeta} The populated <code>EntityCustomerMeta</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EntityCustomerMeta();
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('entity_id')) {
        obj['entity_id'] = _ApiClient.default.convertToType(data['entity_id'], 'String');
      }
      if (data.hasOwnProperty('meta')) {
        obj['meta'] = _ApiClient.default.convertToType(data['meta'], 'String');
      }
      if (data.hasOwnProperty('meta_type')) {
        obj['meta_type'] = _EntityMetadataType.default.constructFromObject(data['meta_type']);
      }
      if (data.hasOwnProperty('value_bool')) {
        obj['value_bool'] = _ApiClient.default.convertToType(data['value_bool'], 'Boolean');
      }
      if (data.hasOwnProperty('value_int')) {
        obj['value_int'] = _ApiClient.default.convertToType(data['value_int'], 'Number');
      }
      if (data.hasOwnProperty('value_date')) {
        obj['value_date'] = _ApiClient.default.convertToType(data['value_date'], 'String');
      }
      if (data.hasOwnProperty('value_datetime')) {
        obj['value_datetime'] = _ApiClient.default.convertToType(data['value_datetime'], 'String');
      }
      if (data.hasOwnProperty('value_time')) {
        obj['value_time'] = _ApiClient.default.convertToType(data['value_time'], 'String');
      }
      if (data.hasOwnProperty('value_real')) {
        obj['value_real'] = _ApiClient.default.convertToType(data['value_real'], 'Number');
      }
      if (data.hasOwnProperty('value_uuid')) {
        obj['value_uuid'] = _ApiClient.default.convertToType(data['value_uuid'], 'String');
      }
      if (data.hasOwnProperty('value_small_text')) {
        obj['value_small_text'] = _ApiClient.default.convertToType(data['value_small_text'], 'String');
      }
      if (data.hasOwnProperty('value_text')) {
        obj['value_text'] = _ApiClient.default.convertToType(data['value_text'], 'String');
      }
      if (data.hasOwnProperty('value_big_text')) {
        obj['value_big_text'] = _ApiClient.default.convertToType(data['value_big_text'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>EntityCustomerMeta</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>EntityCustomerMeta</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of EntityCustomerMeta.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['entity_id'] && !(typeof data['entity_id'] === 'string' || data['entity_id'] instanceof String)) {
      throw new Error("Expected the field `entity_id` to be a primitive type in the JSON string but got " + data['entity_id']);
    }
    // ensure the json data is a string
    if (data['meta'] && !(typeof data['meta'] === 'string' || data['meta'] instanceof String)) {
      throw new Error("Expected the field `meta` to be a primitive type in the JSON string but got " + data['meta']);
    }
    // ensure the json data is a string
    if (data['value_time'] && !(typeof data['value_time'] === 'string' || data['value_time'] instanceof String)) {
      throw new Error("Expected the field `value_time` to be a primitive type in the JSON string but got " + data['value_time']);
    }
    // ensure the json data is a string
    if (data['value_uuid'] && !(typeof data['value_uuid'] === 'string' || data['value_uuid'] instanceof String)) {
      throw new Error("Expected the field `value_uuid` to be a primitive type in the JSON string but got " + data['value_uuid']);
    }
    // ensure the json data is a string
    if (data['value_small_text'] && !(typeof data['value_small_text'] === 'string' || data['value_small_text'] instanceof String)) {
      throw new Error("Expected the field `value_small_text` to be a primitive type in the JSON string but got " + data['value_small_text']);
    }
    // ensure the json data is a string
    if (data['value_text'] && !(typeof data['value_text'] === 'string' || data['value_text'] instanceof String)) {
      throw new Error("Expected the field `value_text` to be a primitive type in the JSON string but got " + data['value_text']);
    }
    // ensure the json data is a string
    if (data['value_big_text'] && !(typeof data['value_big_text'] === 'string' || data['value_big_text'] instanceof String)) {
      throw new Error("Expected the field `value_big_text` to be a primitive type in the JSON string but got " + data['value_big_text']);
    }
    return true;
  }
}
EntityCustomerMeta.RequiredProperties = ["customer_id", "entity_id", "meta", "meta_type"];

/**
 * @member {String} customer_id
 */
EntityCustomerMeta.prototype['customer_id'] = undefined;

/**
 * @member {String} entity_id
 */
EntityCustomerMeta.prototype['entity_id'] = undefined;

/**
 * @member {String} meta
 */
EntityCustomerMeta.prototype['meta'] = undefined;

/**
 * @member {module:model/EntityMetadataType} meta_type
 */
EntityCustomerMeta.prototype['meta_type'] = undefined;

/**
 * @member {Boolean} value_bool
 */
EntityCustomerMeta.prototype['value_bool'] = undefined;

/**
 * @member {Number} value_int
 */
EntityCustomerMeta.prototype['value_int'] = undefined;

/**
 * @member {String} value_date
 */
EntityCustomerMeta.prototype['value_date'] = undefined;

/**
 * @member {String} value_datetime
 */
EntityCustomerMeta.prototype['value_datetime'] = undefined;

/**
 * @member {String} value_time
 */
EntityCustomerMeta.prototype['value_time'] = undefined;

/**
 * @member {Number} value_real
 */
EntityCustomerMeta.prototype['value_real'] = undefined;

/**
 * @member {String} value_uuid
 */
EntityCustomerMeta.prototype['value_uuid'] = undefined;

/**
 * @member {String} value_small_text
 */
EntityCustomerMeta.prototype['value_small_text'] = undefined;

/**
 * @member {String} value_text
 */
EntityCustomerMeta.prototype['value_text'] = undefined;

/**
 * @member {String} value_big_text
 */
EntityCustomerMeta.prototype['value_big_text'] = undefined;
var _default = exports.default = EntityCustomerMeta;