"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CreateBatchUploadModel = _interopRequireDefault(require("../model/CreateBatchUploadModel"));
var _CreateBatchUploadResult = _interopRequireDefault(require("../model/CreateBatchUploadResult"));
var _FileIndex = _interopRequireDefault(require("../model/FileIndex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* BatchUpload service.
* @module api/BatchUploadApi
* @version 1.1.3-alpha.1
*/
class BatchUploadApi {
  /**
  * Constructs a new BatchUploadApi. 
  * @alias module:api/BatchUploadApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Request creating a batch for multiple files uploading
   * 
   * @param {module:model/CreateBatchUploadModel} CreateBatchUploadModel The files to create batch for
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateBatchUploadResult} and HTTP response
   */
  createWithHttpInfo(CreateBatchUploadModel) {
    let postBody = CreateBatchUploadModel;
    // verify the required parameter 'CreateBatchUploadModel' is set
    if (CreateBatchUploadModel === undefined || CreateBatchUploadModel === null) {
      throw new Error("Missing the required parameter 'CreateBatchUploadModel' when calling create");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = _CreateBatchUploadResult.default;
    return this.apiClient.callApi('/api/v1/BatchUpload/create', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Request creating a batch for multiple files uploading
   * 
   * @param {module:model/CreateBatchUploadModel} CreateBatchUploadModel The files to create batch for
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateBatchUploadResult}
   */
  create(CreateBatchUploadModel) {
    return this.createWithHttpInfo(CreateBatchUploadModel).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Upload files to batch
   * Uploads files to a batch and returns an array with the file descriptors
   * @param {String} batch_id Batch id
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FileIndex>} and HTTP response
   */
  uploadToBatchWithHttpInfo(batch_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'batch_id' is set
    if (batch_id === undefined || batch_id === null) {
      throw new Error("Missing the required parameter 'batch_id' when calling uploadToBatch");
    }
    let pathParams = {
      'batch_id': batch_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'files': this.apiClient.buildCollectionParam(opts['files'], 'passthrough')
    };
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = [_FileIndex.default];
    return this.apiClient.callApi('/api/v1/BatchUpload/{batch_id}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Upload files to batch
   * Uploads files to a batch and returns an array with the file descriptors
   * @param {String} batch_id Batch id
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FileIndex>}
   */
  uploadToBatch(batch_id, opts) {
    return this.uploadToBatchWithHttpInfo(batch_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = BatchUploadApi;