import { makeStyles } from '@material-ui/core';
import React from 'react';
import { CloseFab } from './close-fab';

export const CloseModalButton = ({ classes, size, disableRipple, ...props }) => {
	const finalClasses = useStyles({ classes });
	return <CloseFab {...props} classes={finalClasses} size="small" disableRipple />;
};

const useStyles = makeStyles({
	root: {
		'backgroundColor': 'transparent',
		'color': 'white',
		'borderColor': 'white',
		'borderWidth': '1px',
		'borderStyle': 'solid',
		'&:hover': { backgroundColor: 'transparent', color: 'white' }
	}
});
