export const READ_CUSTOMER_META_SUCCEEDED = '@CUSTOMER_META/READ_SUCCEEDED';
export const READ_CUSTOMER_META_PENDING = '@CUSTOMER_META/READ_PENDING';
export const READ_CUSTOMER_META_FAILED = '@CUSTOMER_META/READ_FAILED';
export const READ_CUSTOMER_META_IDLE = '@CUSTOMER_META/READ_IDLE';

export const getCustomerMetaRequestKey = (id, meta) => `getCustMeta/${id}/${meta}`;

export const createReadCustomerMetaActions = ({
	resourceType,
	id,
	metaIds: requestedIds = [],
	meta: resultMeta,
	mergeMeta: requestedMergeMeta = true
} = {}) => ({
	succeeded: ({ meta, metaIds, mergeMeta = true } = {}) => ({
		type: READ_CUSTOMER_META_SUCCEEDED,
		resourceType: resourceType,
		id: id,
		meta: meta === undefined ? resultMeta : meta,
		metaIds: metaIds || requestedIds || [],
		mergeMeta: !!(mergeMeta === undefined ? requestedMergeMeta : mergeMeta)
	}),

	pending: ({ metaIds } = {}) => ({
		type: READ_CUSTOMER_META_PENDING,
		resourceType: resourceType,
		id: id,
		metaIds: (metaIds === undefined ? requestedIds : metaIds) || []
	}),

	idle: ({ metaIds } = {}) => ({
		type: READ_CUSTOMER_META_IDLE,
		resourceType: resourceType,
		id: id,
		metaIds: (metaIds === undefined ? requestedIds : metaIds) || []
	}),

	failed: ({ metaIds } = {}) => ({
		type: READ_CUSTOMER_META_FAILED,
		resourceType: resourceType,
		id: id,
		metaIds: (metaIds === undefined ? requestedIds : metaIds) || []
	})
});

export const customerMeta = resType => (state, action) => {
	var { type, resourceType, id, meta, metaIds, mergeMeta } = action;
	if (resourceType === resType) {
		switch (type) {
			case READ_CUSTOMER_META_SUCCEEDED:
				return _setCustomerMetaSuccess(state, id, metaIds, meta, resourceType, mergeMeta);
			case READ_CUSTOMER_META_PENDING:
				return _setCustomerMetaRequestState(state, metaIds, id, resourceType, 'PENDING');
			case READ_CUSTOMER_META_FAILED:
				return _setCustomerMetaRequestState(state, metaIds, id, resourceType, 'FAILED');
			case READ_CUSTOMER_META_IDLE:
				return _setCustomerMetaRequestState(state, metaIds, id, resourceType, 'IDLE');
			default:
				break;
		}
	}
	return state;
};

function _setCustomerMetaRequestState(state, metaIds, id, resourceType, status) {
	return {
		...state,
		requests: getNewRequests(state.requests, metaIds, id, resourceType, status)
	};
}

function getNewRequests(requests = {}, mids = [], id, resourceType, status) {
	var newRequests = { ...requests };
	(mids || []).forEach(mid => {
		var rkey = getCustomerMetaRequestKey(id, mid);
		var request = newRequests[rkey] || {};
		newRequests[rkey] = {
			...request,
			requestKey: rkey,
			resourceType: resourceType,
			status: status
		};
	});
	return newRequests;
}

function _setCustomerMetaSuccess(state, id, metaIds, meta, resourceType, mergeMeta) {
	var { [id]: resourceMeta = {} } = state.meta || {};
	var { customerMeta = {} } = resourceMeta;
	return {
		...state,
		meta: {
			...state.meta,
			[id]: {
				...resourceMeta,
				customerMeta: mergeMeta ? { ...customerMeta, ...meta } : { ...meta }
			}
		},
		requests: getNewRequests(
			getNewRequests(
				state.requests,
				(metaIds || []).filter(mid => !meta.hasOwnProperty(mid)),
				id,
				resourceType,
				'FAILED'
			),
			Object.keys(meta),
			id,
			resourceType,
			'SUCCEEDED'
		)
	};
}
