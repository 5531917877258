import { normalized, RESOURCE } from '@extend/paywall-api/lib';
import { AD_SCORES, MEDIA, MEDIA_SLIDES, MEDIA_FILES } from '@extend/paywall-api/lib/resource-types';
import get from 'lodash/get';
import { actionTypes } from 'redux-resource';
import createResourceActions from 'redux-resource-action-creators';
import { call, cancel, delay, getContext, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { wrapInLoading } from '../state/loading';
import { createReadCustomerMetaActions } from '../state/plugins/customer-meta';
import { createInclusionListAction } from '../state/plugins/list-inclusion';
import { createReadUserMetaActions } from '../state/plugins/user-meta';
import {
	CRUD_ACTION,
	MUTATION,
	RESOURCE_GET,
	RESOURCE_GET_CUSTOMER_META,
	RESOURCE_GET_LIST,
	RESOURCE_GET_QUERY,
	RESOURCE_GET_REF_MANY,
	RESOURCE_GET_USER_META,
	RESOURCE_MUTATE
} from '../state/resource-constants';
import {
	getListRequestKey,
	getQueryListRequestKey,
	getQueryRequestKey,
	getRefManyRequestKey,
	getResourceList
} from '../state/resources';
import { normalizrToReduxResource, propertyValuesOf } from '../utils';
import { flowExecute, notifyError } from './flow-utils';
import { updateResourceRequest } from '../state/plugins/request-update';

// Query workers
const resourceQueryGetWorker = wrapInLoading(function* resourceQueryGetWorker({
	payload: {
		resource = null,
		queryName = null,
		queryParams: { skip, take, sorting, filter } = {},
		mergeListIds = false,
		omitNotifyOnError = false
	} = {}
}) {
	if (resource && queryName) {
		var requestKey = getQueryRequestKey(queryName);
		var list = getQueryListRequestKey(queryName);
		var { failed, succeeded, pending } = createResourceActions(CRUD_ACTION.READ, {
			requestKey: requestKey,
			requestName: queryName,
			resourceType: resource,
			mergeListIds: mergeListIds,
			list: list,
			requestProperties: {
				skip,
				take,
				sorting,
				filter
			}
		});
		try {
			yield put(pending());
			let api = yield getContext('api');
			let { items, total, offset } = yield call(api.resourceQuery, resource, {
				skip: skip || 0,
				take: take || 0,
				sorting: sorting || [],
				filter: filter || {}
			});

			if (resource === AD_SCORES) {
				// remove filtered_ranking
				items = items.map(item => {
					var { filtered_ranking, ...rest } = item;
					return rest;
				});
			}

			// update resources in query request (and list)
			yield put(
				succeeded({
					...normalizrToReduxResource(resource, normalized(items, resource)),
					requestProperties: {
						skip,
						take,
						sorting,
						filter,
						offset: offset,
						total: total,
						error: null
					}
				})
			);
		} catch (e) {
			if (!omitNotifyOnError) yield notifyError(e);
			yield put(
				failed({
					requestProperties: {
						skip,
						take,
						sorting,
						filter,
						error: e
					}
				})
			);
		}
	}
});

export const resourceQueryGetWatcher = function* resourceQueryGetWatcher() {
	yield takeEvery(RESOURCE_GET_QUERY, resourceQueryGetWorker);
};

// ==============================================================================================================================

// worker that makes the actual request
const finalResourceGetWorker = wrapInLoading(function* finalResourceGetOneWorker({ resource = null, ids = null } = {}) {
	var isGetMany = Array.isArray(ids);
	if (resource && ids && (!isGetMany || ids.length > 0)) {
		ids = isGetMany ? ids : [ids];
		var { failed } = createResourceActions(CRUD_ACTION.READ, {
			resourceType: resource,
			resources: ids
		});
		try {
			let api = yield getContext('api');
			var apiCall = isGetMany ? api.resourceGetMany : api.resourceGetOne;
			let apiResult = yield call(apiCall, resource, ids);
			let data = normalized(apiResult, resource);
			let { entities } = data;

			var mainResources = entities[resource] || {};
			var succesfullIds = [];
			var unSuccesfullIds = [];

			if (Array.isArray(ids)) {
				ids.forEach(key => {
					if (mainResources[key]) succesfullIds.push(key);
					else unSuccesfullIds.push(key);
				});
				// result.forEach(key => {
				// 	if (mainResources[key]) succesfullIds.push(key);
				// 	else unSuccesfullIds.push(key);
				// });
				// } else if (ids) {
				// 	if (mainResources[result]) succesfullIds.push(result);
				// 	else unSuccesfullIds.push(result);
			} else succesfullIds = ids;

			if (succesfullIds.length > 0) {
				// success for the succesfull resources
				var { succeeded: partialSucceeded } = createResourceActions(CRUD_ACTION.READ, {
					resourceType: resource,
					resources: succesfullIds
				});
				yield put(partialSucceeded(normalizrToReduxResource(resource, data)));
			}

			if (unSuccesfullIds.length > 0) {
				// failure for the unsuccesfull resources
				var { failed: partialFailed } = createResourceActions(CRUD_ACTION.READ, {
					resourceType: resource,
					resources: unSuccesfullIds
				});
				yield put(partialFailed());
			}
		} catch (e) {
			yield notifyError(e);
			yield put(failed());
		}
	}
});

// worker that iterates over the buffered resources to retrieve and fires the actual worker for each resource type buffered
function* iterateRead(bufferedReads) {
	var resourceNames = Object.keys(bufferedReads);
	for (var i = 0; i < resourceNames.length; ++i) {
		var resource = resourceNames[i];
		try {
			yield spawn(finalResourceGetWorker, { resource: resource, ids: bufferedReads[resource] });
		} catch {}
	}
}

// worker that implements the delay to wait for all reads to arrive before firing a single read per resource type.
// Any time a new read comes, this worker is cancelled and re-run again.
function* fireBufferRead() {
	try {
		yield delay(150); // we wait here for any potential new resource gets to batch them in one request.

		// if 50ms have passed since the last get that was buffered, then we fire the actual fetching of resources that are buffered already.
		let readBuffer = yield getContext('readBuffer');

		var bufferedReads = readBuffer.getBufferedReads() || {}; // this line retrieves the resources to fetch in an object, and empties the buffer

		readBuffer.pendingRead = null;
		yield spawn(iterateRead, bufferedReads);
	} catch (e) {}
}

// worker that buffers a single resource read, and fires the delay worker. If a previous delay worker was active, it is cancelled and re-run again.
function* resourceGetWorker({ payload: { resource = null, ids = null } = {} } = {}) {
	var isGetMany = Array.isArray(ids);
	if (resource && ids && (!isGetMany || ids.length > 0)) {
		var { pending, failed } = createResourceActions(CRUD_ACTION.READ, {
			resourceType: resource,
			resources: isGetMany ? ids : [ids]
		});
		try {
			let readBuffer = yield getContext('readBuffer');
			yield put(pending());
			readBuffer.registerRead(resource, ids);

			let pendingReader = readBuffer.pendingReader;
			if (pendingReader) yield cancel(pendingReader);
			readBuffer.pendingReader = yield spawn(fireBufferRead);
		} catch (e) {
			yield notifyError(e);
			yield put(failed());
		}
	}
}

export const resourceGetWatcher = function* resourceGetOneWatcher() {
	yield takeEvery(RESOURCE_GET, resourceGetWorker);
};

// ==============================================================================================================================

var nextCustomerMetas = {};

const registerFetchCustomerMeta = (resource, id, meta) => {
	var resMeta = nextCustomerMetas[resource] || {};
	var idMeta = resMeta[id] || {};
	idMeta[meta] = true;
	resMeta[id] = idMeta;
	nextCustomerMetas[resource] = resMeta;
};

const getNextCustomerMetas = resource => {
	var meta = nextCustomerMetas[resource] || {};
	delete nextCustomerMetas[resource];
	var result = {};
	Object.keys(meta).forEach(id => (result[id] = Object.keys(meta[id])));
	return result;
};

var custMetaReader = null;

const finalResourceCustomerMetaGetMultipleWorker = wrapInLoading(function* finalResourceCustomerMetaGetMultipleWorker({
	resource = null,
	data = null
} = {}) {
	if (resource && data) {
		var ids = Object.keys(data);
		if (ids && ids.length > 0) {
			try {
				let api = yield getContext('api');
				let result = yield call(api.getCustomerMetaMultiple, resource, data);
				for (var i = 0; i < ids.length; ++i) {
					var id = ids[i];
					var metas = data[id];
					var { failed, succeeded } = createReadCustomerMetaActions({
						resourceType: resource,
						id: id,
						metaIds: metas
					});
					if (!!result[id]) yield put(succeeded({ meta: result[id], mergeMeta: true }));
					else yield put(failed());
				}
			} catch (e) {
				yield notifyError(e);
				for (i = 0; i < ids.length; ++i) {
					id = ids[i];
					metas = data[id];
					var { failed: allFailed } = createReadCustomerMetaActions({
						resourceType: resource,
						id: id,
						metaIds: metas
					});
					yield put(allFailed());
				}
			}
		}
	}
});

function* iterateReadCustomerMeta() {
	var resources = Object.keys(nextCustomerMetas);
	for (var i = 0; i < resources.length; ++i) {
		var resource = resources[i];
		var data = getNextCustomerMetas(resource);
		yield spawn(finalResourceCustomerMetaGetMultipleWorker, { resource: resource, data: data });
	}
}

function* fireBufferReadCustomerMeta() {
	try {
		yield delay(100);
		yield spawn(iterateReadCustomerMeta);
	} catch (e) {}
}

// worker that buffers a single resource read, and fires the delay worker. If a previous delay worker was active, it is cancelled and re-run again.
function* resourceGetCustomerMetaWorker({ payload: { resource = null, id = null, meta = null } = {} } = {}) {
	if (resource && id && meta) {
		var { pending, failed } = createReadCustomerMetaActions({
			resourceType: resource,
			id: id,
			metaIds: [meta]
		});
		var role_id = yield select(state => get(state, 'auth.role_id', 'Admin'));
		if (role_id === 'Customer' || role_id === 'Contact') {
			try {
				//let readBuffer = yield getContext('readBuffer');
				yield put(pending());
				registerFetchCustomerMeta(resource, id, meta);
				//readBuffer.registerReadCustMeta(resource, id, meta);
				let pendingReader = custMetaReader;
				if (pendingReader) yield cancel(pendingReader);
				custMetaReader = yield spawn(fireBufferReadCustomerMeta);
			} catch (e) {
				yield notifyError(e);
				yield put(failed());
			}
		} else yield put(failed());
	}
}

export const resourceGetCustomerMetaWatcher = function* resourceGetCustomerMetaWatcher() {
	yield takeEvery(RESOURCE_GET_CUSTOMER_META, resourceGetCustomerMetaWorker);
};

// ==============================================================================================================================

var nextUserMetas = {};

const registerFetchUserMeta = (resource, id, meta) => {
	var resMeta = nextUserMetas[resource] || {};
	var idMeta = resMeta[id] || {};
	idMeta[meta] = true;
	resMeta[id] = idMeta;
	nextUserMetas[resource] = resMeta;
};

const getNextUserMetas = resource => {
	var meta = nextUserMetas[resource] || {};
	delete nextUserMetas[resource];
	var result = {};
	Object.keys(meta).forEach(id => (result[id] = Object.keys(meta[id])));
	return result;
};

var usrMetaReader = null;

const finalResourceUserMetaGetMultipleWorker = wrapInLoading(function* finalResourceUserMetaGetMultipleWorker({
	resource = null,
	data = null
} = {}) {
	if (resource && data) {
		var ids = Object.keys(data);
		if (ids && ids.length > 0) {
			try {
				let api = yield getContext('api');
				let result = yield call(api.getUserMetaMultiple, resource, data);
				for (var i = 0; i < ids.length; ++i) {
					var id = ids[i];
					var metas = data[id];
					var { failed, succeeded } = createReadUserMetaActions({
						resourceType: resource,
						id: id,
						metaIds: metas
					});
					if (!!result[id]) yield put(succeeded({ meta: result[id], mergeMeta: true }));
					else yield put(failed());
				}
			} catch (e) {
				yield notifyError(e);
				for (i = 0; i < ids.length; ++i) {
					id = ids[i];
					metas = data[id];
					var { failed: allFailed } = createReadUserMetaActions({
						resourceType: resource,
						id: id,
						metaIds: metas
					});
					yield put(allFailed());
				}
			}
		}
	}
});

function* iterateReadUserMeta() {
	var resources = Object.keys(nextUserMetas);
	for (var i = 0; i < resources.length; ++i) {
		var resource = resources[i];
		var data = getNextUserMetas(resource);
		yield spawn(finalResourceUserMetaGetMultipleWorker, { resource: resource, data: data });
	}
}

function* fireBufferReadUserMeta() {
	try {
		yield delay(100);
		yield spawn(iterateReadUserMeta);
	} catch (e) {}
}

// worker that buffers a single resource read, and fires the delay worker. If a previous delay worker was active, it is cancelled and re-run again.
function* resourceGetUserMetaWorker({ payload: { resource = null, id = null, meta = null } = {} } = {}) {
	if (resource && id && meta) {
		var { pending, failed } = createReadUserMetaActions({
			resourceType: resource,
			id: id,
			metaIds: [meta]
		});
		try {
			yield put(pending());
			registerFetchUserMeta(resource, id, meta);
			let pendingReader = usrMetaReader;
			if (pendingReader) yield cancel(pendingReader);
			usrMetaReader = yield spawn(fireBufferReadUserMeta);
		} catch (e) {
			yield notifyError(e);
			yield put(failed());
		}
	}
}

export const resourceGetUserMetaWatcher = function* resourceGetUserMetaWatcher() {
	yield takeEvery(RESOURCE_GET_USER_META, resourceGetUserMetaWorker);
};

// ==============================================================================================================================

var nextLists = {};

const registerFetchList = (resource, list, mergeListIds) => {
	var res = nextLists[resource] || {};
	res[list] = !!mergeListIds;
	nextLists[resource] = res;
};

const getNextLists = () => {
	var res = nextLists;
	nextLists = {};
	return res;
};

var resListReader = null;

const finalResourceGetListWorker = wrapInLoading(function* finalResourceGetListWorker({ resource = null, lists = {} }) {
	if (resource) {
		var listNames = Object.keys(lists);
		try {
			if (listNames.length > 0) {
				let api = yield getContext('api');
				let resultLists = yield call(api.resourceGetLists, resource, listNames);
				// find lists that failed
				var failedLists = listNames.filter(l => !resultLists[l]);
				for (var i = 0; i < failedLists.length; ++i) {
					var { failed } = createResourceActions(CRUD_ACTION.READ, {
						requestKey: getListRequestKey(failedLists[i]),
						resourceType: resource,
						list: failedLists[i]
					});
					yield put(failed());
				}

				// succeeded lists
				for (const [list, items] of propertyValuesOf(resultLists)) {
					var { succeeded } = createResourceActions(CRUD_ACTION.READ, {
						requestKey: getListRequestKey(list),
						resourceType: resource,
						list: list
					});
					yield put(
						succeeded({
							list: list,
							mergeListIds: lists[list],
							...normalizrToReduxResource(resource, normalized(items, resource))
						})
					);
				}
			}
		} catch (e) {
			yield notifyError(e);
			for (i = 0; i < listNames.length; ++i) {
				var { failed: failedRead } = createResourceActions(CRUD_ACTION.READ, {
					requestKey: getListRequestKey(listNames[i]),
					resourceType: resource,
					list: listNames[i]
				});
				yield put(failedRead());
			}
		}
	}
});

function* iterateListRead(allResLists) {
	for (const [resource, lists] of propertyValuesOf(allResLists))
		yield spawn(finalResourceGetListWorker, { resource, lists });
}

function* fireBufferListRead() {
	try {
		yield delay(150);
		var allResLists = getNextLists();
		yield spawn(iterateListRead, allResLists);
	} catch (e) {}
}

function* resourceGetListWorker({
	payload: { resource = null, list = null, mergeListIds = false, immediately = false } = {}
} = {}) {
	if (resource && list) {
		var opts_ = {
			requestKey: getListRequestKey(list),
			resourceType: resource,
			list: list
		};
		var { pending, failed } = createResourceActions(CRUD_ACTION.READ, opts_);

		try {
			yield put(pending());
			if (immediately) yield spawn(finalResourceGetListWorker, { resource, lists: { [list]: mergeListIds } });
			else {
				registerFetchList(resource, list, mergeListIds);
				let pendingReader = resListReader;
				if (pendingReader) {
					yield cancel(pendingReader);
				}
				resListReader = yield spawn(fireBufferListRead);
			}
		} catch (e) {
			yield notifyError(e);
			yield put(failed());
		}
	}
}

export function* resourceGetListWatcher() {
	yield takeEvery(RESOURCE_GET_LIST, resourceGetListWorker);
}

// ========================== REFERENCE MANY ================================================================================

const finalResourceGetRefManyWorker = wrapInLoading(function* finalResourceGetRefManyWorker({
	resource = null,
	field = null,
	id = false
}) {
	var requestAndListKey = getRefManyRequestKey(field, id);
	if (resource && requestAndListKey) {
		var { failed, succeeded } = createResourceActions(CRUD_ACTION.READ, {
			requestKey: requestAndListKey,
			resourceType: resource,
			mergeListIds: false
		});

		try {
			let api = yield getContext('api');
			let apiResult = yield call(api.getReferences, resource, field, id);
			yield put(succeeded({ ...normalizrToReduxResource(resource, normalized(apiResult, resource)) }));
		} catch (e) {
			yield notifyError(e);
			yield put(failed());
		}
	}
});

function* iterateRefManyRead(buffer) {
	for (const [resource, fields] of propertyValuesOf(buffer)) {
		for (const [field, ids] of propertyValuesOf(fields)) {
			for (const [id] of propertyValuesOf(ids)) {
				yield spawn(finalResourceGetRefManyWorker, {
					resource: resource,
					field: field,
					id: id
				});
			}
		}
	}
}

function* fireBufferRefManyRead() {
	try {
		yield delay(100);
		let readBuffer = yield getContext('readBuffer');
		var buffer = readBuffer.getBufferedRefManyReads() || {};
		readBuffer.pendingRefManyReader = null;
		yield spawn(iterateRefManyRead, buffer);
	} catch (e) {}
}

function* resourceGetRefManyWorker({ payload: { resource = null, field = null, id = null } = {} } = {}) {
	var requestAndListKey = getRefManyRequestKey(field, id);

	if (resource && requestAndListKey) {
		var { pending, failed } = createResourceActions(CRUD_ACTION.READ, {
			requestKey: requestAndListKey,
			resourceType: resource
		});
		try {
			let readBuffer = yield getContext('readBuffer');
			yield put(pending());
			readBuffer.registerRefManyRead(resource, field, id);

			let pendingReader = readBuffer.pendingRefManyReader;
			if (pendingReader) yield cancel(pendingReader);
			readBuffer.pendingRefManyReader = yield spawn(fireBufferRefManyRead);
		} catch (e) {
			yield notifyError(e);
			yield put(failed());
		}
	}
}

export function* resourceGetRefManyWatcher() {
	yield takeEvery(RESOURCE_GET_REF_MANY, resourceGetRefManyWorker);
}

// ========================== MUTATIONS ================================================================================
const resourceAddToListWorker = wrapInLoading(function* resourceAddToListWorker(
	{ resource, ids, list },
	success,
	fail,
	always
) {
	var isGetMany = Array.isArray(ids);
	if (resource && list && ids && (!isGetMany || ids.length > 0)) {
		const { setPending } = createInclusionListAction(ids, resource, list);
		try {
			yield put(setPending(true));
			let api = yield getContext('api');
			let affectedIds = (yield call(api.addToList, ids, list)) || [];

			if (affectedIds.length > 0) {
				var existingIds = (yield select(state => getResourceList(state, resource, list))) || [];

				var existing = {};
				existingIds.forEach(id => (existing[id] = true));
				var newIds = affectedIds.filter(id => !existing[id]);

				yield put({
					type: actionTypes.UPDATE_RESOURCES,
					lists: {
						[resource]: {
							[list]: [...existingIds, ...newIds]
						}
					}
				});
				yield flowExecute(success, affectedIds);
			}
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield put(setPending(false));
			yield flowExecute(always);
		}
	}
});

const resourceRemoveFromListWorker = wrapInLoading(function* resourceRemoveFromListWorker(
	{ resource, ids, list },
	success,
	fail,
	always
) {
	var isGetMany = Array.isArray(ids);
	if (resource && list && ids && (!isGetMany || ids.length > 0)) {
		const { setPending } = createInclusionListAction(ids, resource, list);
		try {
			yield put(setPending(true));
			let api = yield getContext('api');
			let affectedIds = (yield call(api.removeFromList, ids, list)) || [];

			if (affectedIds.length > 0) {
				var existingIds = (yield select(state => getResourceList(state, resource, list))) || [];

				var deleted = {};
				affectedIds.forEach(id => (deleted[id] = true));

				yield put({
					type: actionTypes.UPDATE_RESOURCES,
					lists: {
						[resource]: {
							[list]: existingIds.filter(id => !deleted[id])
						}
					}
				});
				yield flowExecute(success, affectedIds);
			}
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield put(setPending(false));
			yield flowExecute(always);
		}
	}
});

const requestUpgradeInfoWorker = wrapInLoading(function* requestUpgradeInfoWorker(
	{ media_id, triggerAction },
	success,
	fail,
	always
) {
	if (media_id) {
		try {
			let api = yield getContext('api');
			let result = yield call(api.requestUpgradeInfo, media_id, triggerAction);
			if (result) yield flowExecute(success);
			else yield flowExecute(fail, 'Unsuccesfull upgrade info request');
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const requestUnlockWorker = wrapInLoading(function* requestUnlockWorker({ entity_id, section }, success, fail, always) {
	if (entity_id) {
		try {
			let api = yield getContext('api');
			let result = yield call(api.requestUnlock, entity_id, section);
			if (result) yield flowExecute(success);
			else yield flowExecute(fail, 'Unsuccesfull unlock request');
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const performUnlockWorker = wrapInLoading(function* performUnlockWorker({ entity_id, section }, success, fail, always) {
	if (entity_id && (section === 'fullReport' || section === 'webinar')) {
		try {
			let api = yield getContext('api');
			let result = yield call(section === 'fullReport' ? api.unlockFullReport : api.unlockWebinar, entity_id);
			if (result) {
				var { succeeded } = createReadCustomerMetaActions({
					resourceType: RESOURCE.MEDIA,
					id: entity_id
				});
				var meta = section === 'fullReport' ? 'report_unlocked' : 'webinar_unlocked';
				yield put(succeeded({ meta: { [meta]: true }, mergeMeta: true }));
				yield flowExecute(success);
			} else yield flowExecute(fail, 'Unsuccesfull unlock request');
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const resourceCreateWorker = wrapInLoading(function* resourceCreateWorker({ resource, data }, success, fail, always) {
	if (resource && data) {
		var { succeeded } = createResourceActions(CRUD_ACTION.CREATE, { resourceType: resource });
		try {
			let api = yield getContext('api');
			let apiResult = yield call(api.resourceCreate, resource, data);
			yield put(succeeded({ ...normalizrToReduxResource(resource, normalized(apiResult, resource)) }));
			yield flowExecute(success, apiResult);
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const myMediaCreateMyWorker = wrapInLoading(function* myMediaCreateMyWorker(values, success, fail, always) {
	if (values) {
		var { succeeded } = createResourceActions(CRUD_ACTION.CREATE, { resourceType: MEDIA });
		try {
			let api = yield getContext('api');
			let apiResult = yield call(api.createMyMedia, values);
			yield put(succeeded({ ...normalizrToReduxResource(MEDIA, normalized(apiResult, MEDIA)) }));
			yield flowExecute(success, apiResult);
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const myMediaUpdateMyWorker = wrapInLoading(function* myMediaUpdateMyWorker(values, success, fail, always) {
	if (values) {
		var { succeeded } = createResourceActions(CRUD_ACTION.UPDATE, { resourceType: MEDIA });
		try {
			let api = yield getContext('api');
			const { id, ...restValues } = values;
			let apiResult = yield call(api.updateMyMedia, id, restValues);
			yield put(succeeded({ ...normalizrToReduxResource(MEDIA, normalized(apiResult, MEDIA)) }));
			yield put(
				updateResourceRequest({
					resourceType: MEDIA_SLIDES,
					requestKey: getRefManyRequestKey('slide_of', id),
					newStatus: 'IDLE'
				})
			);
			yield put(
				updateResourceRequest({
					resourceType: MEDIA_FILES,
					requestKey: getRefManyRequestKey('of_media', id),
					newStatus: 'IDLE'
				})
			);
			yield flowExecute(success, apiResult);
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const giveFeedbackWorker = wrapInLoading(function* giveFeedbackWorker(
	{ feedbackComment, feedbackIdeas, researchId },
	success,
	fail,
	always
) {
	if (researchId && (feedbackComment || feedbackIdeas)) {
		try {
			let api = yield getContext('api');
			let apiResult = yield call(api.giveFeedback, researchId, feedbackComment, feedbackIdeas);
			if (apiResult) yield flowExecute(success);
			else yield flowExecute(fail, 'Unknown error');
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const haveSlideQuestionWorker = wrapInLoading(function* haveSlideQuestionWorker(
	{ question, slideId },
	success,
	fail,
	always
) {
	if (slideId && question) {
		try {
			let api = yield getContext('api');
			let apiResult = yield call(api.askSlideQuestion, slideId, question);
			if (apiResult) yield flowExecute(success);
			else yield flowExecute(fail, 'Unknown error');
		} catch (e) {
			yield notifyError(e);
			yield flowExecute(fail, e);
		} finally {
			yield flowExecute(always);
		}
	}
});

const mutationWorkerMap = {
	[MUTATION.ADD_TO_LIST]: resourceAddToListWorker,
	[MUTATION.REMOVE_FROM_LIST]: resourceRemoveFromListWorker,
	[MUTATION.REQUEST_UPGRADE_INFO]: requestUpgradeInfoWorker,
	[MUTATION.REQUEST_UNLOCK]: requestUnlockWorker,
	[MUTATION.UNLOCK]: performUnlockWorker,
	[MUTATION.RESOURCE_CREATE]: resourceCreateWorker,
	[MUTATION.MY_MEDIA_CREATE]: myMediaCreateMyWorker,
	[MUTATION.MY_MEDIA_UPDATE]: myMediaUpdateMyWorker,
	[MUTATION.GIVE_FEEDBACK]: giveFeedbackWorker,
	[MUTATION.HAVE_QUESTION]: haveSlideQuestionWorker
};

function* resourceMutationResolver({ mutation, payload, success, fail, always }) {
	try {
		var worker = mutationWorkerMap[mutation];
		if (worker) yield spawn(worker, payload, success, fail, always);
	} catch (e) {
		yield flowExecute(fail, e);
	}
}

export function* resourceMutationWatcher() {
	yield takeEvery(RESOURCE_MUTATE, resourceMutationResolver);
}
