export class BufferedResourceReads {
	_buffered = {};
	_pendingReader = null;

	_bufferedLists = {};
	_pendingListReader = null;

	_bufferedRefManys = {};
	_pendingRefManyReader = null;

	_bufferedCustMeta = {};
	_pendingCustMetaReader = null;

	_bufferedUsrMeta = {};
	_pendingUsrMetaReader = null;

	registerRead = (resource, ids) => {
		var resourceMap = this._buffered[resource];
		if (!resourceMap) {
			resourceMap = {};
			this._buffered[resource] = resourceMap;
		}
		if (Array.isArray(ids)) ids.forEach(id => (resourceMap[id] = true));
		else resourceMap[ids] = true;
	};

	getBufferedReads = () => {
		let buffer = { ...(this._buffered || {}) };
		this._buffered = {};
		let result = {};
		Object.keys(buffer).forEach(resource => (result[resource] = Object.keys(buffer[resource])));
		return result;
	};

	get pendingReader() {
		return this._pendingReader;
	}
	set pendingReader(value) {
		this._pendingReader = value ? value : null;
	}

	registerListRead = (resource, list, merge) => {
		var resourceMap = this._bufferedLists[resource];
		if (!resourceMap) this._bufferedLists[resource] = resourceMap = {};
		resourceMap[list] = !!resourceMap[list] || merge;
	};

	getBufferedListReads = () => {
		let buffer = { ...(this._bufferedLists || {}) };
		this._bufferedLists = {};
		var result = [];
		Object.keys(buffer).forEach(resource => {
			var lists = buffer[resource] || {};
			Object.keys(lists).forEach(list => result.push({ resource, list, mergeListIds: lists[list] }));
		});
		return result;
	};

	get pendingListReader() {
		return this._pendingListReader;
	}
	set pendingListReader(value) {
		this._pendingListReader = value ? value : null;
	}

	registerRefManyRead = (resource, field, id) => {
		var resourceMap = this._bufferedRefManys[resource];
		if (!resourceMap) this._bufferedRefManys[resource] = resourceMap = {};
		var fieldMap = resourceMap[field];
		if (!fieldMap) resourceMap[field] = fieldMap = {};
		fieldMap[id] = true;
	};

	getBufferedRefManyReads = () => {
		let result = { ...(this._bufferedRefManys || {}) };
		this._bufferedRefManys = {};
		return result;
	};

	get pendingRefManyReader() {
		return this._pendingRefManyReader;
	}
	set pendingRefManyReader(value) {
		this._pendingRefManyReader = value ? value : null;
	}

	registerReadCustMeta = (resource, id, meta) => {
		var { [resource]: res = {} } = this._bufferedCustMeta;
		var { [id]: metas = {} } = res;

		this._bufferedCustMeta = {
			...this._bufferedCustMeta,
			[resource]: {
				...res,
				[id]: {
					...metas,
					[meta]: true
				}
			}
		};
	};

	getBufferedCustMetaReads = () => {
		var res = { ...this._bufferedCustMeta };
		this._bufferedCustMeta = {};
		return res;
	};

	get pendingCustMetaReader() {
		return this._pendingCustMetaReader;
	}
	set pendingCustMetaReader(value) {
		this._pendingCustMetaReader = value ? value : null;
	}

	registerReadUsrMeta = (resource, id, meta) => {
		var { [resource]: res = {} } = this._bufferedUsrMeta;
		var { [id]: metas = {} } = res;

		this._bufferedUsrMeta = {
			...this._bufferedUsrMeta,
			[resource]: {
				...res,
				[id]: {
					...metas,
					[meta]: true
				}
			}
		};
	};

	getBufferedUsrMetaReads = () => {
		var res = { ...this._bufferedUsrMeta };
		this._bufferedUsrMeta = {};
		return res;
	};

	get pendingUsrMetaReader() {
		return this._pendingUsrMetaReader;
	}
	set pendingUsrMetaReader(value) {
		this._pendingUsrMetaReader = value ? value : null;
	}
}
