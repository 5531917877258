"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _QueryYearEntity = _interopRequireDefault(require("../model/QueryYearEntity"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Years service.
* @module api/YearsApi
* @version 1.1.3-alpha.1
*/
class YearsApi {
  /**
  * Constructs a new YearsApi. 
  * @alias module:api/YearsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Get a list of years
   * Gets a list of years based on some criteria (years that have adsocres, years that have media etc)
   * @param {Object} opts Optional parameters
   * @param {Number} opts.From Years starting from
   * @param {Number} opts.To Years up to
   * @param {module:model/QueryYearEntity} opts.relatedEntity This entity (media or adScores) must exist for those years
   * @param {Boolean} opts.publishedOnly Take into account only released entities
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<Number>} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'From': opts['From'],
      'To': opts['To'],
      'relatedEntity': opts['relatedEntity'],
      'publishedOnly': opts['publishedOnly']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['Number'];
    return this.apiClient.callApi('/api/v1/Years', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a list of years
   * Gets a list of years based on some criteria (years that have adsocres, years that have media etc)
   * @param {Object} opts Optional parameters
   * @param {Number} opts.From Years starting from
   * @param {Number} opts.To Years up to
   * @param {module:model/QueryYearEntity} opts.relatedEntity This entity (media or adScores) must exist for those years
   * @param {Boolean} opts.publishedOnly Take into account only released entities
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<Number>}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = YearsApi;