"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */
/**
* Enum class MediaReleaseStatus.
* @enum {}
* @readonly
*/
class MediaReleaseStatus {
  constructor() {
    /**
     * value: "Ignore"
     * @const
     */
    _defineProperty(this, "Ignore", "Ignore");
    /**
     * value: "Released"
     * @const
     */
    _defineProperty(this, "Released", "Released");
    /**
     * value: "NotReleased"
     * @const
     */
    _defineProperty(this, "NotReleased", "NotReleased");
  }
  /**
  * Returns a <code>MediaReleaseStatus</code> enum value from a Javascript object name.
  * @param {Object} data The plain JavaScript object containing the name of the enum value.
  * @return {module:model/MediaReleaseStatus} The enum <code>MediaReleaseStatus</code> value.
  */
  static constructFromObject(object) {
    return object;
  }
}
exports.default = MediaReleaseStatus;