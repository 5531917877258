"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedMediaType = _interopRequireDefault(require("./DefinedMediaType"));
var _MediaReleaseStatus = _interopRequireDefault(require("./MediaReleaseStatus"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MediaFilter model module.
 * @module model/MediaFilter
 * @version 1.1.3-alpha.1
 */
class MediaFilter {
  /**
   * Constructs a new <code>MediaFilter</code>.
   * @alias module:model/MediaFilter
   */
  constructor() {
    MediaFilter.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MediaFilter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MediaFilter} obj Optional instance to populate.
   * @return {module:model/MediaFilter} The populated <code>MediaFilter</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MediaFilter();
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('elastic_ids')) {
        obj['elastic_ids'] = _ApiClient.default.convertToType(data['elastic_ids'], ['String']);
      }
      if (data.hasOwnProperty('distribution_plan_id')) {
        obj['distribution_plan_id'] = _ApiClient.default.convertToType(data['distribution_plan_id'], 'String');
      }
      if (data.hasOwnProperty('media_type')) {
        obj['media_type'] = _DefinedMediaType.default.constructFromObject(data['media_type']);
      }
      if (data.hasOwnProperty('relevant_to')) {
        obj['relevant_to'] = _ApiClient.default.convertToType(data['relevant_to'], 'String');
      }
      if (data.hasOwnProperty('in_industry')) {
        obj['in_industry'] = _ApiClient.default.convertToType(data['in_industry'], 'String');
      }
      if (data.hasOwnProperty('in_category')) {
        obj['in_category'] = _ApiClient.default.convertToType(data['in_category'], 'String');
      }
      if (data.hasOwnProperty('in_customer_category')) {
        obj['in_customer_category'] = _ApiClient.default.convertToType(data['in_customer_category'], 'String');
      }
      if (data.hasOwnProperty('available_to_customer_id')) {
        obj['available_to_customer_id'] = _ApiClient.default.convertToType(data['available_to_customer_id'], 'String');
      }
      if (data.hasOwnProperty('is_released')) {
        obj['is_released'] = _ApiClient.default.convertToType(data['is_released'], 'Boolean');
      }
      if (data.hasOwnProperty('is_not_released')) {
        obj['is_not_released'] = _ApiClient.default.convertToType(data['is_not_released'], 'Boolean');
      }
      if (data.hasOwnProperty('use_elastic')) {
        obj['use_elastic'] = _ApiClient.default.convertToType(data['use_elastic'], 'Boolean');
      }
      if (data.hasOwnProperty('owner_filter')) {
        obj['owner_filter'] = _ApiClient.default.convertToType(data['owner_filter'], 'Number');
      }
      if (data.hasOwnProperty('search_term')) {
        obj['search_term'] = _ApiClient.default.convertToType(data['search_term'], 'String');
      }
      if (data.hasOwnProperty('source')) {
        obj['source'] = _ApiClient.default.convertToType(data['source'], 'String');
      }
      if (data.hasOwnProperty('released_from')) {
        obj['released_from'] = _ApiClient.default.convertToType(data['released_from'], 'String');
      }
      if (data.hasOwnProperty('released_to')) {
        obj['released_to'] = _ApiClient.default.convertToType(data['released_to'], 'String');
      }
      if (data.hasOwnProperty('release_status')) {
        obj['release_status'] = _MediaReleaseStatus.default.constructFromObject(data['release_status']);
      }
      if (data.hasOwnProperty('visible_to_customer')) {
        obj['visible_to_customer'] = _ApiClient.default.convertToType(data['visible_to_customer'], 'Boolean');
      }
      if (data.hasOwnProperty('unlocked_to_customer')) {
        obj['unlocked_to_customer'] = _ApiClient.default.convertToType(data['unlocked_to_customer'], 'Boolean');
      }
      if (data.hasOwnProperty('is_study_head')) {
        obj['is_study_head'] = _ApiClient.default.convertToType(data['is_study_head'], 'Boolean');
      }
      if (data.hasOwnProperty('reduce_to_latest_season')) {
        obj['reduce_to_latest_season'] = _ApiClient.default.convertToType(data['reduce_to_latest_season'], 'Boolean');
      }
      if (data.hasOwnProperty('q')) {
        obj['q'] = _ApiClient.default.convertToType(data['q'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MediaFilter</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MediaFilter</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['elastic_ids'])) {
      throw new Error("Expected the field `elastic_ids` to be an array in the JSON data but got " + data['elastic_ids']);
    }
    // ensure the json data is a string
    if (data['distribution_plan_id'] && !(typeof data['distribution_plan_id'] === 'string' || data['distribution_plan_id'] instanceof String)) {
      throw new Error("Expected the field `distribution_plan_id` to be a primitive type in the JSON string but got " + data['distribution_plan_id']);
    }
    // ensure the json data is a string
    if (data['relevant_to'] && !(typeof data['relevant_to'] === 'string' || data['relevant_to'] instanceof String)) {
      throw new Error("Expected the field `relevant_to` to be a primitive type in the JSON string but got " + data['relevant_to']);
    }
    // ensure the json data is a string
    if (data['in_industry'] && !(typeof data['in_industry'] === 'string' || data['in_industry'] instanceof String)) {
      throw new Error("Expected the field `in_industry` to be a primitive type in the JSON string but got " + data['in_industry']);
    }
    // ensure the json data is a string
    if (data['in_category'] && !(typeof data['in_category'] === 'string' || data['in_category'] instanceof String)) {
      throw new Error("Expected the field `in_category` to be a primitive type in the JSON string but got " + data['in_category']);
    }
    // ensure the json data is a string
    if (data['in_customer_category'] && !(typeof data['in_customer_category'] === 'string' || data['in_customer_category'] instanceof String)) {
      throw new Error("Expected the field `in_customer_category` to be a primitive type in the JSON string but got " + data['in_customer_category']);
    }
    // ensure the json data is a string
    if (data['available_to_customer_id'] && !(typeof data['available_to_customer_id'] === 'string' || data['available_to_customer_id'] instanceof String)) {
      throw new Error("Expected the field `available_to_customer_id` to be a primitive type in the JSON string but got " + data['available_to_customer_id']);
    }
    // ensure the json data is a string
    if (data['search_term'] && !(typeof data['search_term'] === 'string' || data['search_term'] instanceof String)) {
      throw new Error("Expected the field `search_term` to be a primitive type in the JSON string but got " + data['search_term']);
    }
    // ensure the json data is a string
    if (data['source'] && !(typeof data['source'] === 'string' || data['source'] instanceof String)) {
      throw new Error("Expected the field `source` to be a primitive type in the JSON string but got " + data['source']);
    }
    // ensure the json data is a string
    if (data['q'] && !(typeof data['q'] === 'string' || data['q'] instanceof String)) {
      throw new Error("Expected the field `q` to be a primitive type in the JSON string but got " + data['q']);
    }
    return true;
  }
}

/**
 * @member {String} country_id
 */
MediaFilter.prototype['country_id'] = undefined;

/**
 * @member {String} id
 */
MediaFilter.prototype['id'] = undefined;

/**
 * @member {Array.<String>} elastic_ids
 */
MediaFilter.prototype['elastic_ids'] = undefined;

/**
 * @member {String} distribution_plan_id
 */
MediaFilter.prototype['distribution_plan_id'] = undefined;

/**
 * @member {module:model/DefinedMediaType} media_type
 */
MediaFilter.prototype['media_type'] = undefined;

/**
 * @member {String} relevant_to
 */
MediaFilter.prototype['relevant_to'] = undefined;

/**
 * @member {String} in_industry
 */
MediaFilter.prototype['in_industry'] = undefined;

/**
 * @member {String} in_category
 */
MediaFilter.prototype['in_category'] = undefined;

/**
 * @member {String} in_customer_category
 */
MediaFilter.prototype['in_customer_category'] = undefined;

/**
 * @member {String} available_to_customer_id
 */
MediaFilter.prototype['available_to_customer_id'] = undefined;

/**
 * @member {Boolean} is_released
 */
MediaFilter.prototype['is_released'] = undefined;

/**
 * @member {Boolean} is_not_released
 */
MediaFilter.prototype['is_not_released'] = undefined;

/**
 * @member {Boolean} use_elastic
 */
MediaFilter.prototype['use_elastic'] = undefined;

/**
 * @member {Number} owner_filter
 */
MediaFilter.prototype['owner_filter'] = undefined;

/**
 * @member {String} search_term
 */
MediaFilter.prototype['search_term'] = undefined;

/**
 * @member {String} source
 */
MediaFilter.prototype['source'] = undefined;

/**
 * @member {String} released_from
 */
MediaFilter.prototype['released_from'] = undefined;

/**
 * @member {String} released_to
 */
MediaFilter.prototype['released_to'] = undefined;

/**
 * @member {module:model/MediaReleaseStatus} release_status
 */
MediaFilter.prototype['release_status'] = undefined;

/**
 * @member {Boolean} visible_to_customer
 */
MediaFilter.prototype['visible_to_customer'] = undefined;

/**
 * @member {Boolean} unlocked_to_customer
 */
MediaFilter.prototype['unlocked_to_customer'] = undefined;

/**
 * @member {Boolean} is_study_head
 */
MediaFilter.prototype['is_study_head'] = undefined;

/**
 * @member {Boolean} reduce_to_latest_season
 */
MediaFilter.prototype['reduce_to_latest_season'] = undefined;

/**
 * @member {String} q
 */
MediaFilter.prototype['q'] = undefined;
var _default = exports.default = MediaFilter;