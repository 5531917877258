import { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getModalOpenState, modalActionCreators, getModalParameters } from '../state/modals';
import { getRatingGotIt } from '../components/modals';

export const useLocalModalState = initialOpen => {
	const [open, setOpen] = useState(initialOpen);
	const toggle = useCallback(() => setOpen(o => !o), [setOpen]);
	return [open, toggle, setOpen];
};

export const useStoreModalState = modalId => useSelector(state => getModalOpenState(state, modalId), shallowEqual);
export const useStoreModalParameters = modalId =>
	useSelector(state => getModalParameters(state, modalId), shallowEqual);
export const useStoreModalActions = (modalId, parameters) => {
	const dispatch = useDispatch();
	const { toggle, set, open, close, setParameters } = useMemo(
		() => modalActionCreators(modalId, parameters),
		[modalId, parameters]
	); // parameters object would change each render. We should shallow-compare it somehow
	const _toggle = useCallback(p => dispatch(toggle(p)), [dispatch, toggle]);
	const _set = useCallback((value, p) => dispatch(set(value, p)), [dispatch, set]);
	const _open = useCallback(p => dispatch(open(p)), [dispatch, open]);
	const _close = useCallback(() => dispatch(close()), [dispatch, close]);
	const _setParams = useCallback(p => dispatch(setParameters(p)), [dispatch, setParameters]);

	return {
		toggle: _toggle,
		set: _set,
		open: _open,
		close: _close,
		setParameters: _setParams,
		dispatch: dispatch
	};
};

export const useRatingModalActions = (modalId, parameters) => {
	var { open: isOpen } = useStoreModalState(modalId);
	var { open, toggle, set, ...rest } = useStoreModalActions(modalId);

	var finalOpen = useCallback(
		(...args) => {
			var gotIt = getRatingGotIt(modalId);
			if (!gotIt) open(...args);
		},
		[open, modalId]
	);

	var finalSet = useCallback(
		(value, ...args) => {
			if (!value) set(value, ...args);
			var gotIt = getRatingGotIt(modalId);
			if (!gotIt) set(value, ...args);
		},
		[set, modalId]
	);

	var finalToggle = useCallback(
		(...args) => {
			if (isOpen) toggle(...args);
			var gotIt = getRatingGotIt(modalId);
			if (!gotIt) toggle(...args);
		},
		[isOpen, toggle, modalId]
	);

	return {
		open: finalOpen,
		toggle: finalToggle,
		set: finalSet,
		...rest
	};
};
