import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useResize } from 'react-browser-hooks';
import { useNavigate } from 'react-router-dom';
import { screenfull } from '../utilities/screenfull';
import { getPrefetched, prefetchUrl } from '../utilities/url-prefetch';

export const useMinimumScreenWidth = width => {
	const { width: currentWidth } = useResize();
	return currentWidth < width;
};

export const useKeyNavigationHandler = ({ enable, isFullScreen, onEscape, onPrev, onNext, globalMode }) => {
	const handler = useMemo(() => {
		if (!enable || (!onPrev && !onNext)) {
			return null;
		}
		return e => {
			if (e.isComposing || e.keyCode === 229) return;
			let callback = null;
			switch (e.key) {
				case 'Escape':
					callback = onEscape;
					break;
				case 'ArrowDown':
					if (!isFullScreen) callback = onNext;
					break;
				case 'ArrowUp':
					if (!isFullScreen) callback = onPrev;
					break;
				case 'ArrowLeft':
					callback = onPrev;
					break;
				case 'ArrowRight':
					callback = onNext;
					break;
				default:
			}
			if (callback) {
				e.preventDefault();
				callback();
			}
		};
	}, [enable, onPrev, onNext, isFullScreen, onEscape]);

	useLayoutEffect(() => {
		if (!document || !globalMode) return;
		const h = handler;
		document.addEventListener('keydown', h);
		return () => document.removeEventListener('keydown', h);
	}, [handler, globalMode]);

	return globalMode === true ? null : handler;
};

export const useFocusElementWhen = (element, when) =>
	useEffect(() => {
		if (element && when) element.focus();
	}, [element, when]);

export const useDebouncedValue = (value, ms) => {
	const [val, setVal] = useState(value);
	useEffect(() => {
		const _val = value;
		const _tm = setTimeout(() => setVal(_val), ms);
		return () => clearTimeout(_tm);
	}, [ms, value]);
	return val;
};

export const useDebouncedCallback = (callback, ms) => {
	const tmout = useRef();

	const debounced = useMemo(() => {
		if (ms > 0) {
			return (...args) => {
				clearTimeout(tmout.current);
				tmout.current = setTimeout(() => callback(...args), ms);
			};
		} else {
			clearTimeout(tmout.current);
			return callback;
		}
	}, [callback, ms]);

	const immediate = useMemo(() => {
		if (ms > 0) {
			return (...args) => {
				clearTimeout(tmout.current);
				return callback(...args);
			};
		} else {
			clearTimeout(tmout.current);
			return callback;
		}
	}, [callback, ms]);

	return [debounced, immediate];
};

export const useGoBack = () => {
	const navigate = useNavigate();
	return useCallback((path, opts) => navigate(-1), [navigate]);
};

export const useNavigateReplace = () => {
	const navigate = useNavigate();
	return useCallback((path, opts) => navigate(path, { ...opts, replace: true }), [navigate]);
};

export const useNavigatePush = () => {
	const navigate = useNavigate();
	return useCallback((path, opts) => navigate(path, { ...opts, replace: false }), [navigate]);
};

const dummyPrefetch = () => {};
export const usePrefetchUrl = url => {
	const [finalUrl, setUrl] = useState(getPrefetched(url));
	const [pending, setPending] = useState(false);
	const idle = finalUrl === null && !pending;
	const prefetch = useCallback(async () => {
		setPending(true);
		try {
			var u = await prefetchUrl(url);
			if (u) setUrl(u);
		} finally {
			setPending(false);
		}
	}, [setPending, url]);

	return { url: finalUrl, pending, idle, prefetch: pending ? dummyPrefetch : prefetch };
};

export const useTriggerOnFirstTrue = (value, callback) => {
	const triggeredRef = useRef(false);
	useLayoutEffect(() => {
		if (!triggeredRef.current && !!value) {
			triggeredRef.current = true;
			callback();
		}
	}, [value, callback]);
};

export const useAutoPrefetchUrl = (url, triggerValue) => {
	const { url: finalUrl, pending, idle, prefetch } = usePrefetchUrl(url);
	useTriggerOnFirstTrue(triggerValue === undefined || !!triggerValue, prefetch);
	return { url: finalUrl, pending, idle, prefetch };
};

export const useMouseActivity = duration => {
	const timeout = useRef();
	const [hasActivity, setHasActivity] = useState(false);
	const detectedActivity = useCallback(() => {
		clearTimeout(timeout.current);
		setHasActivity(true);
		timeout.current = setTimeout(() => setHasActivity(false), duration);
	}, [setHasActivity, duration]);

	return { hasActivity, detectedActivity };
};

export const useScreenfull = options => {
	const [isFullscreen, setIsFullscreen] = useState(false);
	const { element, navigationUI, onEnter, onExit, onToggle, onError } = options ?? {};

	const fullscreenEnabled = !!screenfull.isEnabled;

	useEffect(() => {
		if (!screenfull.isEnabled) return;
		const callback = () => setIsFullscreen(!!screenfull.isFullscreen);
		callback();
		screenfull.on('change', callback);
		return () => {
			screenfull.off('change', callback);
		};
	}, []);

	useEffect(() => {
		if (!screenfull.isEnabled || !onError) return;
		const callback = onError;
		screenfull.on('error', callback);
		return () => {
			screenfull.off('error', callback);
		};
	}, [onError]);

	useEffect(() => {
		if (!screenfull.isEnabled || !onEnter) return;
		const callback = () => (screenfull.isFullscreen ? onEnter() : null);
		screenfull.on('change', callback);
		return () => {
			screenfull.off('change', callback);
		};
	}, [onEnter]);

	useEffect(() => {
		if (!screenfull.isEnabled || !onExit) return;
		const callback = () => (!screenfull.isFullscreen ? onExit() : null);
		screenfull.on('change', callback);
		return () => {
			screenfull.off('change', callback);
		};
	}, [onExit]);

	useEffect(() => {
		if (!screenfull.isEnabled || !onToggle) return;
		const callback = () => onToggle(!!screenfull.isFullscreen);
		screenfull.on('change', callback);
		return () => {
			screenfull.off('change', callback);
		};
	}, [onToggle]);

	const opts = useMemo(() => {
		if (navigationUI === undefined) return;
		return { navigationUI };
	}, [navigationUI]);

	const enterFullscreen = useCallback(() => {
		if (!screenfull.isEnabled) return Promise.reject(new Error('Fullscreen is not enabled'));
		return screenfull.request(element?.current ?? undefined, opts);
	}, [element, opts]);

	const exitFullscreen = useCallback(() => {
		if (!screenfull.isEnabled) return Promise.reject(new Error('Fullscreen is not enabled'));
		return screenfull.exit();
	}, []);

	const toggleFullscreen = useCallback(() => {
		if (!screenfull.isEnabled) return Promise.reject(new Error('Fullscreen is not enabled'));
		return screenfull.toggle(element?.current ?? undefined, opts);
	}, [element, opts]);

	return { fullscreenEnabled, isFullscreen, enterFullscreen, exitFullscreen, toggleFullscreen };
};
