import get from 'lodash/get';
export const getFieldValue = (record, field, defaultValue) => {
	if (record) {
		if (field) {
			if (typeof field === 'string') return get(record, field, defaultValue);

			return field(record) || defaultValue;
		}
	}
	return defaultValue || null;
};
