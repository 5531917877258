"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedCustomerType = _interopRequireDefault(require("./DefinedCustomerType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ViewClientUserInfo model module.
 * @module model/ViewClientUserInfo
 * @version 1.1.3-alpha.1
 */
class ViewClientUserInfo {
  /**
   * Constructs a new <code>ViewClientUserInfo</code>.
   * @alias module:model/ViewClientUserInfo
   * @param email {String} 
   * @param user_id {String} 
   */
  constructor(email, user_id) {
    ViewClientUserInfo.initialize(this, email, user_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, email, user_id) {
    obj['email'] = email;
    obj['user_id'] = user_id;
  }

  /**
   * Constructs a <code>ViewClientUserInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ViewClientUserInfo} obj Optional instance to populate.
   * @return {module:model/ViewClientUserInfo} The populated <code>ViewClientUserInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ViewClientUserInfo();
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('customer_last_name')) {
        obj['customer_last_name'] = _ApiClient.default.convertToType(data['customer_last_name'], 'String');
      }
      if (data.hasOwnProperty('customer_name')) {
        obj['customer_name'] = _ApiClient.default.convertToType(data['customer_name'], 'String');
      }
      if (data.hasOwnProperty('customer_type')) {
        obj['customer_type'] = _DefinedCustomerType.default.constructFromObject(data['customer_type']);
      }
      if (data.hasOwnProperty('contact_id')) {
        obj['contact_id'] = _ApiClient.default.convertToType(data['contact_id'], 'Number');
      }
      if (data.hasOwnProperty('contact_name')) {
        obj['contact_name'] = _ApiClient.default.convertToType(data['contact_name'], 'String');
      }
      if (data.hasOwnProperty('contact_last_name')) {
        obj['contact_last_name'] = _ApiClient.default.convertToType(data['contact_last_name'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ViewClientUserInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ViewClientUserInfo</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ViewClientUserInfo.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['customer_last_name'] && !(typeof data['customer_last_name'] === 'string' || data['customer_last_name'] instanceof String)) {
      throw new Error("Expected the field `customer_last_name` to be a primitive type in the JSON string but got " + data['customer_last_name']);
    }
    // ensure the json data is a string
    if (data['customer_name'] && !(typeof data['customer_name'] === 'string' || data['customer_name'] instanceof String)) {
      throw new Error("Expected the field `customer_name` to be a primitive type in the JSON string but got " + data['customer_name']);
    }
    // ensure the json data is a string
    if (data['contact_name'] && !(typeof data['contact_name'] === 'string' || data['contact_name'] instanceof String)) {
      throw new Error("Expected the field `contact_name` to be a primitive type in the JSON string but got " + data['contact_name']);
    }
    // ensure the json data is a string
    if (data['contact_last_name'] && !(typeof data['contact_last_name'] === 'string' || data['contact_last_name'] instanceof String)) {
      throw new Error("Expected the field `contact_last_name` to be a primitive type in the JSON string but got " + data['contact_last_name']);
    }
    return true;
  }
}
ViewClientUserInfo.RequiredProperties = ["email", "user_id"];

/**
 * @member {String} email
 */
ViewClientUserInfo.prototype['email'] = undefined;

/**
 * @member {String} customer_id
 */
ViewClientUserInfo.prototype['customer_id'] = undefined;

/**
 * @member {String} user_id
 */
ViewClientUserInfo.prototype['user_id'] = undefined;

/**
 * @member {String} customer_last_name
 */
ViewClientUserInfo.prototype['customer_last_name'] = undefined;

/**
 * @member {String} customer_name
 */
ViewClientUserInfo.prototype['customer_name'] = undefined;

/**
 * @member {module:model/DefinedCustomerType} customer_type
 */
ViewClientUserInfo.prototype['customer_type'] = undefined;

/**
 * @member {Number} contact_id
 */
ViewClientUserInfo.prototype['contact_id'] = undefined;

/**
 * @member {String} contact_name
 */
ViewClientUserInfo.prototype['contact_name'] = undefined;

/**
 * @member {String} contact_last_name
 */
ViewClientUserInfo.prototype['contact_last_name'] = undefined;
var _default = exports.default = ViewClientUserInfo;