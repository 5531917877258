"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schemaUsers = exports.schemaUserStatsList = exports.schemaUserStats = exports.schemaUser = exports.schemaTags = exports.schemaTag = exports.schemaSubscriptions = exports.schemaSubscriptionTypes = exports.schemaSubscriptionTypeMediaAvailability = exports.schemaSubscriptionTypeMediaAvailabilities = exports.schemaSubscriptionType = exports.schemaSubscriptionDistributionSettings = exports.schemaSubscriptionDistributionSettingList = exports.schemaSubscription = exports.schemaSlideComments = exports.schemaSlideComment = exports.schemaRoles = exports.schemaRole = exports.schemaReportStatsList = exports.schemaReportStats = exports.schemaPreferenceDefinitions = exports.schemaPreferenceDefinition = exports.schemaMediaTypes = exports.schemaMediaType = exports.schemaMediaSlides = exports.schemaMediaSlideFiles = exports.schemaMediaSlideFile = exports.schemaMediaSlide = exports.schemaMediaReadStatsList = exports.schemaMediaReadStats = exports.schemaMediaList = exports.schemaMediaFiles = exports.schemaMediaFile = exports.schemaMedia = exports.schemaLearnMoreList = exports.schemaLearnMore = exports.schemaKeyTakeAways = exports.schemaKeyTakeAway = exports.schemaIndustry = exports.schemaIndustries = exports.schemaFiles = exports.schemaFile = exports.schemaEntityAttachments = exports.schemaEntityAttachment = exports.schemaDistributionPlans = exports.schemaDistributionPlan = exports.schemaCustomers = exports.schemaCustomerCategory = exports.schemaCustomerCategories = exports.schemaCustomer = exports.schemaCountry = exports.schemaCountries = exports.schemaContacts = exports.schemaContact = exports.schemaCompany = exports.schemaCompanies = exports.schemaCategory = exports.schemaCategories = exports.schemaBrands = exports.schemaBrand = exports.schemaAddresses = exports.schemaAddress = exports.schemaAdScores = exports.schemaAdScoreSlides = exports.schemaAdScoreSlide = exports.schemaAdScoreAttributes = exports.schemaAdScoreAttribute = exports.schemaAdScore = void 0;
var _normalizr = require("normalizr");
var _resourceTypes = require("./resource-types");
// ENTITY DEFINITIONS ---------------------------------------------
const schemaEntityAttachment = new _normalizr.schema.Entity(_resourceTypes.ENTITY_ATTACHMENTS);
exports.schemaEntityAttachment = schemaEntityAttachment;
const schemaEntityAttachments = new _normalizr.schema.Array(schemaEntityAttachment);
exports.schemaEntityAttachments = schemaEntityAttachments;
const schemaPreferenceDefinition = new _normalizr.schema.Entity(_resourceTypes.PREFERENCE_DEFINITIONS);
exports.schemaPreferenceDefinition = schemaPreferenceDefinition;
const schemaPreferenceDefinitions = new _normalizr.schema.Array(schemaPreferenceDefinition);
exports.schemaPreferenceDefinitions = schemaPreferenceDefinitions;
const schemaAdScore = new _normalizr.schema.Entity(_resourceTypes.AD_SCORES);
exports.schemaAdScore = schemaAdScore;
const schemaAdScores = new _normalizr.schema.Array(schemaAdScore);
exports.schemaAdScores = schemaAdScores;
const schemaAdScoreSlide = new _normalizr.schema.Entity(_resourceTypes.AD_SCORE_SLIDES);
exports.schemaAdScoreSlide = schemaAdScoreSlide;
const schemaAdScoreSlides = new _normalizr.schema.Array(schemaAdScoreSlide);
exports.schemaAdScoreSlides = schemaAdScoreSlides;
const schemaAdScoreAttribute = new _normalizr.schema.Entity(_resourceTypes.AD_SCORE_ATTRIBUTES);
exports.schemaAdScoreAttribute = schemaAdScoreAttribute;
const schemaAdScoreAttributes = new _normalizr.schema.Array(schemaAdScoreAttribute);
exports.schemaAdScoreAttributes = schemaAdScoreAttributes;
const schemaAddress = new _normalizr.schema.Entity(_resourceTypes.ADDRESSES);
exports.schemaAddress = schemaAddress;
const schemaAddresses = new _normalizr.schema.Array(schemaAddress);
exports.schemaAddresses = schemaAddresses;
const schemaContact = new _normalizr.schema.Entity(_resourceTypes.CONTACTS);
exports.schemaContact = schemaContact;
const schemaContacts = new _normalizr.schema.Array(schemaContact);
exports.schemaContacts = schemaContacts;
const schemaCountry = new _normalizr.schema.Entity(_resourceTypes.COUNTRIES);
exports.schemaCountry = schemaCountry;
const schemaCountries = new _normalizr.schema.Array(schemaCountry);
exports.schemaCountries = schemaCountries;
const schemaCustomer = new _normalizr.schema.Entity(_resourceTypes.CUSTOMERS);
exports.schemaCustomer = schemaCustomer;
const schemaCustomers = new _normalizr.schema.Array(schemaCustomer);
exports.schemaCustomers = schemaCustomers;
const schemaFile = new _normalizr.schema.Entity(_resourceTypes.FILE);
exports.schemaFile = schemaFile;
const schemaFiles = new _normalizr.schema.Array(schemaFile);
exports.schemaFiles = schemaFiles;
const schemaMedia = new _normalizr.schema.Entity(_resourceTypes.MEDIA);
exports.schemaMedia = schemaMedia;
const schemaMediaList = new _normalizr.schema.Array(schemaMedia);
exports.schemaMediaList = schemaMediaList;
const schemaMediaReadStats = new _normalizr.schema.Entity(_resourceTypes.MEDIA_READ_STATS);
exports.schemaMediaReadStats = schemaMediaReadStats;
const schemaMediaReadStatsList = new _normalizr.schema.Array(schemaMediaReadStats);
exports.schemaMediaReadStatsList = schemaMediaReadStatsList;
const schemaMediaFile = new _normalizr.schema.Entity(_resourceTypes.MEDIA_FILES);
exports.schemaMediaFile = schemaMediaFile;
const schemaMediaFiles = new _normalizr.schema.Array(schemaMediaFile);
exports.schemaMediaFiles = schemaMediaFiles;
const schemaMediaSlide = new _normalizr.schema.Entity(_resourceTypes.MEDIA_SLIDES);
exports.schemaMediaSlide = schemaMediaSlide;
const schemaMediaSlides = new _normalizr.schema.Array(schemaMediaSlide);
exports.schemaMediaSlides = schemaMediaSlides;
const schemaMediaSlideFile = new _normalizr.schema.Entity(_resourceTypes.MEDIA_SLIDE_FILES);
exports.schemaMediaSlideFile = schemaMediaSlideFile;
const schemaMediaSlideFiles = new _normalizr.schema.Array(schemaMediaSlideFile);
exports.schemaMediaSlideFiles = schemaMediaSlideFiles;
const schemaSlideComment = new _normalizr.schema.Entity(_resourceTypes.SLIDE_COMMENTS);
exports.schemaSlideComment = schemaSlideComment;
const schemaSlideComments = new _normalizr.schema.Array(schemaSlideComment);
exports.schemaSlideComments = schemaSlideComments;
const schemaMediaType = new _normalizr.schema.Entity(_resourceTypes.MEDIA_TYPES);
exports.schemaMediaType = schemaMediaType;
const schemaMediaTypes = new _normalizr.schema.Array(schemaMediaType);
exports.schemaMediaTypes = schemaMediaTypes;
const schemaSubscriptionType = new _normalizr.schema.Entity(_resourceTypes.SUBSCRIPTION_TYPES);
exports.schemaSubscriptionType = schemaSubscriptionType;
const schemaSubscriptionTypes = new _normalizr.schema.Array(schemaSubscriptionType);
exports.schemaSubscriptionTypes = schemaSubscriptionTypes;
const schemaSubscription = new _normalizr.schema.Entity(_resourceTypes.SUBSCRIPTIONS);
exports.schemaSubscription = schemaSubscription;
const schemaSubscriptions = new _normalizr.schema.Array(schemaSubscription);
exports.schemaSubscriptions = schemaSubscriptions;
const schemaTag = new _normalizr.schema.Entity(_resourceTypes.TAGS);
exports.schemaTag = schemaTag;
const schemaTags = new _normalizr.schema.Array(schemaTag);
exports.schemaTags = schemaTags;
const schemaUser = new _normalizr.schema.Entity(_resourceTypes.USERS);
exports.schemaUser = schemaUser;
const schemaUsers = new _normalizr.schema.Array(schemaUser);
exports.schemaUsers = schemaUsers;
const schemaUserStats = new _normalizr.schema.Entity(_resourceTypes.USER_STATS);
exports.schemaUserStats = schemaUserStats;
const schemaUserStatsList = new _normalizr.schema.Array(schemaUserStats);
exports.schemaUserStatsList = schemaUserStatsList;
const schemaKeyTakeAway = new _normalizr.schema.Entity(_resourceTypes.KEY_TAKE_AWAYS);
exports.schemaKeyTakeAway = schemaKeyTakeAway;
const schemaKeyTakeAways = new _normalizr.schema.Array(schemaKeyTakeAway);
exports.schemaKeyTakeAways = schemaKeyTakeAways;
const schemaReportStats = new _normalizr.schema.Entity(_resourceTypes.REPORT_STATS);
exports.schemaReportStats = schemaReportStats;
const schemaReportStatsList = new _normalizr.schema.Array(schemaReportStats);
exports.schemaReportStatsList = schemaReportStatsList;
const schemaIndustry = new _normalizr.schema.Entity(_resourceTypes.INDUSTRIES);
exports.schemaIndustry = schemaIndustry;
const schemaIndustries = new _normalizr.schema.Array(schemaIndustry);
exports.schemaIndustries = schemaIndustries;
const schemaCustomerCategory = new _normalizr.schema.Entity(_resourceTypes.CUSTOMER_CATEGORIES);
exports.schemaCustomerCategory = schemaCustomerCategory;
const schemaCustomerCategories = new _normalizr.schema.Array(schemaCustomerCategory);
exports.schemaCustomerCategories = schemaCustomerCategories;
const schemaCategory = new _normalizr.schema.Entity(_resourceTypes.CATEGORIES);
exports.schemaCategory = schemaCategory;
const schemaCategories = new _normalizr.schema.Array(schemaCategory);
exports.schemaCategories = schemaCategories;
const schemaCompany = new _normalizr.schema.Entity(_resourceTypes.COMPANIES);
exports.schemaCompany = schemaCompany;
const schemaCompanies = new _normalizr.schema.Array(schemaCompany);
exports.schemaCompanies = schemaCompanies;
const schemaBrand = new _normalizr.schema.Entity(_resourceTypes.BRANDS);
exports.schemaBrand = schemaBrand;
const schemaBrands = new _normalizr.schema.Array(schemaBrand);
exports.schemaBrands = schemaBrands;
const schemaDistributionPlan = new _normalizr.schema.Entity(_resourceTypes.DISTRIBUTION_PLANS);
exports.schemaDistributionPlan = schemaDistributionPlan;
const schemaDistributionPlans = new _normalizr.schema.Array(schemaDistributionPlan);
exports.schemaDistributionPlans = schemaDistributionPlans;
const schemaSubscriptionDistributionSettings = new _normalizr.schema.Entity(_resourceTypes.SUBSCRIPTION_DISTRIBUTION_SETTINGS);
exports.schemaSubscriptionDistributionSettings = schemaSubscriptionDistributionSettings;
const schemaSubscriptionDistributionSettingList = new _normalizr.schema.Array(schemaSubscriptionDistributionSettings);
exports.schemaSubscriptionDistributionSettingList = schemaSubscriptionDistributionSettingList;
const schemaSubscriptionTypeMediaAvailability = new _normalizr.schema.Entity(_resourceTypes.SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY);
exports.schemaSubscriptionTypeMediaAvailability = schemaSubscriptionTypeMediaAvailability;
const schemaSubscriptionTypeMediaAvailabilities = new _normalizr.schema.Array(schemaSubscriptionTypeMediaAvailability);
exports.schemaSubscriptionTypeMediaAvailabilities = schemaSubscriptionTypeMediaAvailabilities;
const schemaLearnMore = new _normalizr.schema.Entity(_resourceTypes.LEARN_MORE);
exports.schemaLearnMore = schemaLearnMore;
const schemaLearnMoreList = new _normalizr.schema.Array(schemaLearnMore);
exports.schemaLearnMoreList = schemaLearnMoreList;
const schemaRole = new _normalizr.schema.Entity(_resourceTypes.ROLES);
exports.schemaRole = schemaRole;
const schemaRoles = new _normalizr.schema.Array(schemaRole);

// REFERENCE DEFINITIONS ------------------------------------------
exports.schemaRoles = schemaRoles;
schemaEntityAttachment.define({
  fileIndex: schemaFile
});
schemaAdScore.define({
  industry: schemaIndustry,
  country: schemaCountry,
  company: schemaCompany,
  brand: schemaBrand
});
schemaMediaSlide.define({
  fileIndex: schemaFile,
  adScore: schemaAdScore,
  tags: schemaTags
});
schemaAddress.define({
  customer: schemaCustomer
});
schemaBrand.define({
  company: schemaCompany
});
schemaContact.define({
  user: schemaUser,
  customer: schemaCustomer
});
schemaCustomer.define({
  user: schemaUser,
  logo: schemaFile,
  addresses: schemaAddresses,
  subscriptions: schemaSubscriptions,
  contacts: schemaContacts,
  ownedMedia: schemaMediaList,
  customerCategories: schemaCustomerCategories,
  tags: schemaTags
});
schemaFile.define({
  thumbnail_parent: schemaFile
});
schemaMedia.define({
  mediaFiles: schemaMediaFiles,
  ownerCustomer: schemaCustomer,
  distributionPlan: schemaDistributionPlan,
  mediaBackground: schemaFile,
  mediaBanner: schemaFile,
  mediaDownloadFile: schemaFile,
  mediaImage: schemaFile,
  mediaLogo: schemaFile,
  customerCategories: schemaCustomerCategories,
  tags: schemaTags,
  keyTakeAways: schemaKeyTakeAways
});
schemaKeyTakeAway.define({
  media: schemaMedia
});
schemaMediaFile.define({
  fileIndex: schemaFile,
  media: schemaMedia
});
schemaMediaSlide.define({
  slideImage: schemaFile,
  media: schemaMedia,
  mediaSlideFiles: schemaMediaSlideFiles,
  tags: schemaTags
});
schemaMediaSlideFile.define({
  fileIndex: schemaFile,
  mediaSlide: schemaMediaSlide
});
schemaSlideComment.define({
  parentComment: schemaSlideComment,
  subComments: schemaSlideComments,
  user: schemaUser,
  customer: schemaCustomer,
  contact: schemaContact
});
schemaSubscriptionType.define({
  tags: schemaTags
});
schemaSubscription.define({
  customer: schemaCustomer,
  subscriptionType: schemaSubscriptionType
});
schemaUser.define({
  role: schemaRole,
  tags: schemaTags
});
schemaIndustry.define({
  subIndustries: schemaIndustries,
  parentIndustry: schemaIndustry,
  tags: schemaTags
});
schemaCustomerCategory.define({
  customer: schemaCustomer,
  tags: schemaTags
});
schemaCategory.define({
  subCategories: schemaCategories,
  parentCategory: schemaCategory,
  tags: schemaTags
});
schemaDistributionPlan.define({
  media: schemaMediaList,
  availableMediaPerSubscriptionType: schemaSubscriptionTypeMediaAvailabilities,
  tags: schemaTags
});
schemaSubscriptionTypeMediaAvailability.define({
  distributionPlan: schemaDistributionPlan,
  subscriptionType: schemaSubscriptionType
});
schemaSubscriptionDistributionSettings.define({
  distributionPlan: schemaDistributionPlan,
  subscriptionType: schemaSubscriptionType
});
schemaLearnMore.define({
  tags: schemaTags
});