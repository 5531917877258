"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BaseResult = _interopRequireDefault(require("../model/BaseResult"));
var _CommandRequestUpgrade = _interopRequireDefault(require("../model/CommandRequestUpgrade"));
var _InviteColeaguesModel = _interopRequireDefault(require("../model/InviteColeaguesModel"));
var _RequestSectionUnlock = _interopRequireDefault(require("../model/RequestSectionUnlock"));
var _UpdateMyAccountModel = _interopRequireDefault(require("../model/UpdateMyAccountModel"));
var _UpdateMyUserPasswordCommand = _interopRequireDefault(require("../model/UpdateMyUserPasswordCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Account service.
* @module api/AccountApi
* @version 1.1.3-alpha.1
*/
class AccountApi {
  /**
  * Constructs a new AccountApi. 
  * @alias module:api/AccountApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Deletes account of the authorized user
   * Applies only for customers and customer contacts
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  deleteMeWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Deletes account of the authorized user
   * Applies only for customers and customer contacts
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  deleteMe() {
    return this.deleteMeWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Send invitation email to colleagues
   * @param {module:model/InviteColeaguesModel} InviteColeaguesModel The emails to accept invitation
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  inviteWithHttpInfo(InviteColeaguesModel) {
    let postBody = InviteColeaguesModel;
    // verify the required parameter 'InviteColeaguesModel' is set
    if (InviteColeaguesModel === undefined || InviteColeaguesModel === null) {
      throw new Error("Missing the required parameter 'InviteColeaguesModel' when calling invite");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account/invite', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Send invitation email to colleagues
   * @param {module:model/InviteColeaguesModel} InviteColeaguesModel The emails to accept invitation
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  invite(InviteColeaguesModel) {
    return this.inviteWithHttpInfo(InviteColeaguesModel).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Deletes an account
   * Applies only for admins
   * @param {String} id Id of the user account to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Deletes an account
   * Applies only for admins
   * @param {String} id Id of the user account to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Sends an unlock request
   * Sends an unlock request to the contact's administrator
   * @param {Object} opts Optional parameters
   * @param {module:model/RequestSectionUnlock} opts.RequestSectionUnlock 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  requestUnlockWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['RequestSectionUnlock'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account/requestUnlock', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Sends an unlock request
   * Sends an unlock request to the contact's administrator
   * @param {Object} opts Optional parameters
   * @param {module:model/RequestSectionUnlock} opts.RequestSectionUnlock 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  requestUnlock(opts) {
    return this.requestUnlockWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Sends an upgrade info request
   * Sends an upgrade information request to the site's admins
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandRequestUpgrade} opts.CommandRequestUpgrade 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  requestUpgradeInfoWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandRequestUpgrade'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account/requestUpgradeInfo', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Sends an upgrade info request
   * Sends an upgrade information request to the site's admins
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandRequestUpgrade} opts.CommandRequestUpgrade 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  requestUpgradeInfo(opts) {
    return this.requestUpgradeInfoWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Updates an account's data
   * Applies only for admins
   * @param {String} id Id of the user account to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMyAccountModel} opts.UpdateMyAccountModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateMyAccountModel'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account/{id}/update', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Updates an account's data
   * Applies only for admins
   * @param {String} id Id of the user account to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMyAccountModel} opts.UpdateMyAccountModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Updates the account data of the authorized user
   * Applies only for customers and customer contacts
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMyAccountModel} opts.UpdateMyAccountModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  updateMineWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['UpdateMyAccountModel'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account/update', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Updates the account data of the authorized user
   * Applies only for customers and customer contacts
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMyAccountModel} opts.UpdateMyAccountModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  updateMine(opts) {
    return this.updateMineWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Updates an account's password
   * Applies to any authenticated user
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMyUserPasswordCommand} opts.UpdateMyUserPasswordCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  updatePasswordWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['UpdateMyUserPasswordCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Account/update/password', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Updates an account's password
   * Applies to any authenticated user
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMyUserPasswordCommand} opts.UpdateMyUserPasswordCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  updatePassword(opts) {
    return this.updatePasswordWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AccountApi;