import { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router';
import { isDebug } from './is-debug';

export const GAInit = () => {
	const trackingId = window?.gaTrackingId || process.env.REACT_APP_GA_TRACKING_ID || '';
	// if (isDebug) console.log(`GA tracking id: ${trackingId}`);
	if (trackingId)
		ReactGA.initialize(trackingId, {
			debug: isDebug,
			titleCase: false,
			gaOptions: { siteSpeedSampleRate: 100 }
		});
};

export const useGAPageView = () => {
	const { pathname, search } = useLocation();
	useEffect(() => {
		ReactGA.pageview(`${pathname}${search}`);
	}, [pathname, search]);
};

export const useGAEvent = ({ category, action, label, value, nonInteraction }) =>
	useCallback(
		args => {
			const params = { category, action, label, value, nonInteraction, ...args };
			// if (isDebug) {
			// 	console.log('GA event');
			// 	console.log(params);
			// }
			ReactGA.event(params);
		},
		[category, action, label, value, nonInteraction]
	);
