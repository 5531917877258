import { createBrowserHistory } from 'history';
import { unstable_batchedUpdates } from 'react-dom';
import { createReduxHistoryContext } from 'redux-first-history';

export const nativeBrowserHistory = createBrowserHistory();

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
	history: nativeBrowserHistory,
	batch: unstable_batchedUpdates
});
