"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _SubscriptionDistributionPermissionQuery = _interopRequireDefault(require("../model/SubscriptionDistributionPermissionQuery"));
var _SubscriptionDistributionSetting = _interopRequireDefault(require("../model/SubscriptionDistributionSetting"));
var _SubscriptionDistributionSettingQueryResult = _interopRequireDefault(require("../model/SubscriptionDistributionSettingQueryResult"));
var _UpdateSubscriptionDistributionPermissionsCommand = _interopRequireDefault(require("../model/UpdateSubscriptionDistributionPermissionsCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* SubscriptionDistributionSettings service.
* @module api/SubscriptionDistributionSettingsApi
* @version 1.1.3-alpha.1
*/
class SubscriptionDistributionSettingsApi {
  /**
  * Constructs a new SubscriptionDistributionSettingsApi. 
  * @alias module:api/SubscriptionDistributionSettingsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Get User
   * Returns the requested subscription/distribution permission
   * @param {String} id Id of the subscription/distribution permission to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionDistributionSetting} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _SubscriptionDistributionSetting.default;
    return this.apiClient.callApi('/api/v1/SubscriptionDistributionSettings/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get User
   * Returns the requested subscription/distribution permission
   * @param {String} id Id of the subscription/distribution permission to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionDistributionSetting}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple subscription/distribution permissions
   * Returns an array of the requested subscription/distribution permissions
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the subscription/distribution permissions to get. Not specifiying ids will return all subscription/distribution permissions
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubscriptionDistributionSetting>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_SubscriptionDistributionSetting.default];
    return this.apiClient.callApi('/api/v1/SubscriptionDistributionSettings/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple subscription/distribution permissions
   * Returns an array of the requested subscription/distribution permissions
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the subscription/distribution permissions to get. Not specifiying ids will return all subscription/distribution permissions
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubscriptionDistributionSetting>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple subscription/distribution permissions referencing the requested id by a certain field
   * Returns a list of subscription/distribution permissions that are referencing the requested id by a certain field
   * @param {String} field The field of the subscription/distribution permission that is referencing the requested id
   * @param {String} id Id of the subscription/distribution permission that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubscriptionDistributionSetting>} and HTTP response
   */
  getReferencesWithHttpInfo(field, id) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error("Missing the required parameter 'field' when calling getReferences");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getReferences");
    }
    let pathParams = {
      'field': field,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_SubscriptionDistributionSetting.default];
    return this.apiClient.callApi('/api/v1/SubscriptionDistributionSettings/references/{field}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple subscription/distribution permissions referencing the requested id by a certain field
   * Returns a list of subscription/distribution permissions that are referencing the requested id by a certain field
   * @param {String} field The field of the subscription/distribution permission that is referencing the requested id
   * @param {String} id Id of the subscription/distribution permission that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubscriptionDistributionSetting>}
   */
  getReferences(field, id) {
    return this.getReferencesWithHttpInfo(field, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query subscription/distribution permission
   * Queries  subscription/distribution permissions and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionDistributionPermissionQuery} opts.SubscriptionDistributionPermissionQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionDistributionSettingQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['SubscriptionDistributionPermissionQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SubscriptionDistributionSettingQueryResult.default;
    return this.apiClient.callApi('/api/v1/SubscriptionDistributionSettings/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query subscription/distribution permission
   * Queries  subscription/distribution permissions and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionDistributionPermissionQuery} opts.SubscriptionDistributionPermissionQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionDistributionSettingQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update subscription/distribution permission
   * Updates a subscription/distribution permission and returns the updated object
   * @param {String} id Id of the subscription/distribution permission to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateSubscriptionDistributionPermissionsCommand} opts.UpdateSubscriptionDistributionPermissionsCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionDistributionSetting} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateSubscriptionDistributionPermissionsCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SubscriptionDistributionSetting.default;
    return this.apiClient.callApi('/api/v1/SubscriptionDistributionSettings/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update subscription/distribution permission
   * Updates a subscription/distribution permission and returns the updated object
   * @param {String} id Id of the subscription/distribution permission to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateSubscriptionDistributionPermissionsCommand} opts.UpdateSubscriptionDistributionPermissionsCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionDistributionSetting}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = SubscriptionDistributionSettingsApi;