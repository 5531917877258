"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ClientStatus = _interopRequireDefault(require("./ClientStatus"));
var _DefinedCustomerType = _interopRequireDefault(require("./DefinedCustomerType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CustomerFilter model module.
 * @module model/CustomerFilter
 * @version 1.1.3-alpha.1
 */
class CustomerFilter {
  /**
   * Constructs a new <code>CustomerFilter</code>.
   * @alias module:model/CustomerFilter
   */
  constructor() {
    CustomerFilter.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>CustomerFilter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CustomerFilter} obj Optional instance to populate.
   * @return {module:model/CustomerFilter} The populated <code>CustomerFilter</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerFilter();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('subscription_type_id')) {
        obj['subscription_type_id'] = _ApiClient.default.convertToType(data['subscription_type_id'], 'String');
      }
      if (data.hasOwnProperty('customer_type')) {
        obj['customer_type'] = _DefinedCustomerType.default.constructFromObject(data['customer_type']);
      }
      if (data.hasOwnProperty('available_to_media_id')) {
        obj['available_to_media_id'] = _ApiClient.default.convertToType(data['available_to_media_id'], 'String');
      }
      if (data.hasOwnProperty('withActiveSubscriptionInfo')) {
        obj['withActiveSubscriptionInfo'] = _ApiClient.default.convertToType(data['withActiveSubscriptionInfo'], 'Boolean');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ClientStatus.default.constructFromObject(data['status']);
      }
      if (data.hasOwnProperty('q')) {
        obj['q'] = _ApiClient.default.convertToType(data['q'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CustomerFilter</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CustomerFilter</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['subscription_type_id'] && !(typeof data['subscription_type_id'] === 'string' || data['subscription_type_id'] instanceof String)) {
      throw new Error("Expected the field `subscription_type_id` to be a primitive type in the JSON string but got " + data['subscription_type_id']);
    }
    // ensure the json data is a string
    if (data['available_to_media_id'] && !(typeof data['available_to_media_id'] === 'string' || data['available_to_media_id'] instanceof String)) {
      throw new Error("Expected the field `available_to_media_id` to be a primitive type in the JSON string but got " + data['available_to_media_id']);
    }
    // ensure the json data is a string
    if (data['q'] && !(typeof data['q'] === 'string' || data['q'] instanceof String)) {
      throw new Error("Expected the field `q` to be a primitive type in the JSON string but got " + data['q']);
    }
    return true;
  }
}

/**
 * @member {String} id
 */
CustomerFilter.prototype['id'] = undefined;

/**
 * @member {String} user_id
 */
CustomerFilter.prototype['user_id'] = undefined;

/**
 * @member {String} country_id
 */
CustomerFilter.prototype['country_id'] = undefined;

/**
 * @member {String} subscription_type_id
 */
CustomerFilter.prototype['subscription_type_id'] = undefined;

/**
 * @member {module:model/DefinedCustomerType} customer_type
 */
CustomerFilter.prototype['customer_type'] = undefined;

/**
 * @member {String} available_to_media_id
 */
CustomerFilter.prototype['available_to_media_id'] = undefined;

/**
 * @member {Boolean} withActiveSubscriptionInfo
 */
CustomerFilter.prototype['withActiveSubscriptionInfo'] = undefined;

/**
 * @member {module:model/ClientStatus} status
 */
CustomerFilter.prototype['status'] = undefined;

/**
 * @member {String} q
 */
CustomerFilter.prototype['q'] = undefined;
var _default = exports.default = CustomerFilter;