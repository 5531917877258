"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.YEARS = exports.USER_STATS = exports.USERS = exports.TAGS = exports.SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY = exports.SUBSCRIPTION_TYPES = exports.SUBSCRIPTION_DISTRIBUTION_SETTINGS = exports.SUBSCRIPTIONS = exports.SLIDE_COMMENTS = exports.ROLES = exports.REPORT_STATS = exports.PREFERENCE_DEFINITIONS = exports.MEDIA_TYPES = exports.MEDIA_STATS = exports.MEDIA_SLIDE_FILES = exports.MEDIA_SLIDES = exports.MEDIA_READ_STATS = exports.MEDIA_KEY_TAKE_AWAYS = exports.MEDIA_FILES = exports.MEDIA = exports.LOOKUP_VALUES = exports.LEARN_MORE = exports.KEY_TAKE_AWAYS = exports.INDUSTRIES = exports.FILE = exports.ENTITY_ATTACHMENTS = exports.DISTRIBUTION_PLANS = exports.CUSTOMER_MEDIA_STATS = exports.CUSTOMER_MEDIA_ACCESSIBILITIES = exports.CUSTOMER_CATEGORIES = exports.CUSTOMER_ADSCORE_ACCESSIBILITIES = exports.CUSTOMERS = exports.COUNTRIES = exports.CONTACTS = exports.COMPANIES = exports.CATEGORIES = exports.BRANDS = exports.ATTACHMENTS = exports.AD_SCORE_SLIDES = exports.AD_SCORE_MEASUREMENTS = exports.AD_SCORE_ATTRIBUTES = exports.AD_SCORES = exports.ADDRESSES = exports.ACCOUNT = void 0;
const AD_SCORES = 'adscores';
exports.AD_SCORES = AD_SCORES;
const AD_SCORE_SLIDES = 'adscoreslides';
exports.AD_SCORE_SLIDES = AD_SCORE_SLIDES;
const AD_SCORE_MEASUREMENTS = 'adscoremeasurements';
exports.AD_SCORE_MEASUREMENTS = AD_SCORE_MEASUREMENTS;
const AD_SCORE_ATTRIBUTES = 'adscoreattributes';
exports.AD_SCORE_ATTRIBUTES = AD_SCORE_ATTRIBUTES;
const ADDRESSES = 'addresses';
exports.ADDRESSES = ADDRESSES;
const CATEGORIES = 'categories';
exports.CATEGORIES = CATEGORIES;
const BRANDS = 'brands';
exports.BRANDS = BRANDS;
const COMPANIES = 'companies';
exports.COMPANIES = COMPANIES;
const COUNTRIES = 'countries';
exports.COUNTRIES = COUNTRIES;
const CUSTOMERS = 'customers';
exports.CUSTOMERS = CUSTOMERS;
const CONTACTS = 'contacts';
exports.CONTACTS = CONTACTS;
const DISTRIBUTION_PLANS = 'distributionplans';
exports.DISTRIBUTION_PLANS = DISTRIBUTION_PLANS;
const ENTITY_ATTACHMENTS = 'entityattachments';
exports.ENTITY_ATTACHMENTS = ENTITY_ATTACHMENTS;
const ATTACHMENTS = 'attachments';
exports.ATTACHMENTS = ATTACHMENTS;
const FILE = 'file';
exports.FILE = FILE;
const INDUSTRIES = 'industries';
exports.INDUSTRIES = INDUSTRIES;
const CUSTOMER_CATEGORIES = 'customercategories';
exports.CUSTOMER_CATEGORIES = CUSTOMER_CATEGORIES;
const LEARN_MORE = 'learnmore';
exports.LEARN_MORE = LEARN_MORE;
const ACCOUNT = 'account';
exports.ACCOUNT = ACCOUNT;
const MEDIA = 'media';
exports.MEDIA = MEDIA;
const MEDIA_READ_STATS = 'mediareadstats';
exports.MEDIA_READ_STATS = MEDIA_READ_STATS;
const MEDIA_FILES = 'mediafiles';
exports.MEDIA_FILES = MEDIA_FILES;
const MEDIA_SLIDES = 'mediaslides';
exports.MEDIA_SLIDES = MEDIA_SLIDES;
const MEDIA_SLIDE_FILES = 'mediaslidefiles';
exports.MEDIA_SLIDE_FILES = MEDIA_SLIDE_FILES;
const SLIDE_COMMENTS = 'slidecomments';
exports.SLIDE_COMMENTS = SLIDE_COMMENTS;
const MEDIA_TYPES = 'mediatypes';
exports.MEDIA_TYPES = MEDIA_TYPES;
const ROLES = 'roles';
exports.ROLES = ROLES;
const SUBSCRIPTIONS = 'subscriptions';
exports.SUBSCRIPTIONS = SUBSCRIPTIONS;
const SUBSCRIPTION_TYPES = 'subscriptiontypes';
exports.SUBSCRIPTION_TYPES = SUBSCRIPTION_TYPES;
const SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY = 'subscriptiontypemediaavailability';
exports.SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY = SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY;
const SUBSCRIPTION_DISTRIBUTION_SETTINGS = 'subscriptiondistributionsettings';
exports.SUBSCRIPTION_DISTRIBUTION_SETTINGS = SUBSCRIPTION_DISTRIBUTION_SETTINGS;
const TAGS = 'tags';
exports.TAGS = TAGS;
const USERS = 'users';
exports.USERS = USERS;
const USER_STATS = 'userstats';
exports.USER_STATS = USER_STATS;
const REPORT_STATS = 'reportstats';
exports.REPORT_STATS = REPORT_STATS;
const MEDIA_STATS = 'mediastats';
exports.MEDIA_STATS = MEDIA_STATS;
const PREFERENCE_DEFINITIONS = 'preferencedefinitions';
exports.PREFERENCE_DEFINITIONS = PREFERENCE_DEFINITIONS;
const CUSTOMER_MEDIA_ACCESSIBILITIES = 'customermediaaccessibilities';
exports.CUSTOMER_MEDIA_ACCESSIBILITIES = CUSTOMER_MEDIA_ACCESSIBILITIES;
const CUSTOMER_MEDIA_STATS = 'customermediastats';
exports.CUSTOMER_MEDIA_STATS = CUSTOMER_MEDIA_STATS;
const CUSTOMER_ADSCORE_ACCESSIBILITIES = 'customeradscoreaccessibilities';
exports.CUSTOMER_ADSCORE_ACCESSIBILITIES = CUSTOMER_ADSCORE_ACCESSIBILITIES;
const KEY_TAKE_AWAYS = 'keytakeaways';
exports.KEY_TAKE_AWAYS = KEY_TAKE_AWAYS;
const MEDIA_KEY_TAKE_AWAYS = 'mediakeytakeaways'; // this resource combines 5 key take aways and their title into one virtual resource. The id of the resource is the id of the media
exports.MEDIA_KEY_TAKE_AWAYS = MEDIA_KEY_TAKE_AWAYS;
const YEARS = 'years';
exports.YEARS = YEARS;
const LOOKUP_VALUES = 'lookupvalues';
exports.LOOKUP_VALUES = LOOKUP_VALUES;
var _default = {
  ACCOUNT,
  AD_SCORES,
  AD_SCORE_SLIDES,
  AD_SCORE_MEASUREMENTS,
  AD_SCORE_ATTRIBUTES,
  ADDRESSES,
  CATEGORIES,
  BRANDS,
  COMPANIES,
  COUNTRIES,
  CUSTOMERS,
  CONTACTS,
  DISTRIBUTION_PLANS,
  ENTITY_ATTACHMENTS,
  FILE,
  INDUSTRIES,
  CUSTOMER_CATEGORIES,
  LEARN_MORE,
  MEDIA,
  MEDIA_READ_STATS,
  MEDIA_FILES,
  MEDIA_SLIDES,
  MEDIA_SLIDE_FILES,
  SLIDE_COMMENTS,
  MEDIA_TYPES,
  ROLES,
  SUBSCRIPTIONS,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY,
  SUBSCRIPTION_DISTRIBUTION_SETTINGS,
  TAGS,
  USERS,
  USER_STATS,
  REPORT_STATS,
  MEDIA_STATS,
  PREFERENCE_DEFINITIONS,
  CUSTOMER_MEDIA_ACCESSIBILITIES,
  CUSTOMER_MEDIA_STATS,
  CUSTOMER_ADSCORE_ACCESSIBILITIES,
  KEY_TAKE_AWAYS,
  MEDIA_KEY_TAKE_AWAYS,
  YEARS,
  LOOKUP_VALUES,
  ATTACHMENTS
};
exports.default = _default;