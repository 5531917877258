"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CreateBrandCommand model module.
 * @module model/CreateBrandCommand
 * @version 1.1.3-alpha.1
 */
class CreateBrandCommand {
  /**
   * Constructs a new <code>CreateBrandCommand</code>.
   * @alias module:model/CreateBrandCommand
   * @param title {String} 
   * @param company_id {String} 
   */
  constructor(title, company_id) {
    CreateBrandCommand.initialize(this, title, company_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, title, company_id) {
    obj['title'] = title;
    obj['company_id'] = company_id;
  }

  /**
   * Constructs a <code>CreateBrandCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateBrandCommand} obj Optional instance to populate.
   * @return {module:model/CreateBrandCommand} The populated <code>CreateBrandCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateBrandCommand();
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('company_id')) {
        obj['company_id'] = _ApiClient.default.convertToType(data['company_id'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CreateBrandCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateBrandCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CreateBrandCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['company_id'] && !(typeof data['company_id'] === 'string' || data['company_id'] instanceof String)) {
      throw new Error("Expected the field `company_id` to be a primitive type in the JSON string but got " + data['company_id']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    return true;
  }
}
CreateBrandCommand.RequiredProperties = ["title", "company_id"];

/**
 * @member {String} title
 */
CreateBrandCommand.prototype['title'] = undefined;

/**
 * @member {String} company_id
 */
CreateBrandCommand.prototype['company_id'] = undefined;

/**
 * @member {String} description
 */
CreateBrandCommand.prototype['description'] = undefined;
var _default = exports.default = CreateBrandCommand;