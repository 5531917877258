"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ExpressionOfInterest model module.
 * @module model/ExpressionOfInterest
 * @version 1.1.3-alpha.1
 */
class ExpressionOfInterest {
  /**
   * Constructs a new <code>ExpressionOfInterest</code>.
   * @alias module:model/ExpressionOfInterest
   * @param email {String} 
   * @param is_ad_test_interest {Boolean} 
   * @param id {String} 
   * @param created_at {String} 
   * @param updated_at {String} 
   */
  constructor(email, is_ad_test_interest, id, created_at, updated_at) {
    ExpressionOfInterest.initialize(this, email, is_ad_test_interest, id, created_at, updated_at);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, email, is_ad_test_interest, id, created_at, updated_at) {
    obj['email'] = email;
    obj['is_ad_test_interest'] = is_ad_test_interest;
    obj['id'] = id;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
  }

  /**
   * Constructs a <code>ExpressionOfInterest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ExpressionOfInterest} obj Optional instance to populate.
   * @return {module:model/ExpressionOfInterest} The populated <code>ExpressionOfInterest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ExpressionOfInterest();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('company')) {
        obj['company'] = _ApiClient.default.convertToType(data['company'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = _ApiClient.default.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('is_ad_test_interest')) {
        obj['is_ad_test_interest'] = _ApiClient.default.convertToType(data['is_ad_test_interest'], 'Boolean');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = _ApiClient.default.convertToType(data['country'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
      if (data.hasOwnProperty('created_by')) {
        obj['created_by'] = _ApiClient.default.convertToType(data['created_by'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('time_created')) {
        obj['time_created'] = _ApiClient.default.convertToType(data['time_created'], 'String');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'String');
      }
      if (data.hasOwnProperty('updated_by')) {
        obj['updated_by'] = _ApiClient.default.convertToType(data['updated_by'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
      if (data.hasOwnProperty('time_updated')) {
        obj['time_updated'] = _ApiClient.default.convertToType(data['time_updated'], 'String');
      }
      if (data.hasOwnProperty('deleted_at')) {
        obj['deleted_at'] = _ApiClient.default.convertToType(data['deleted_at'], 'String');
      }
      if (data.hasOwnProperty('deleted_by')) {
        obj['deleted_by'] = _ApiClient.default.convertToType(data['deleted_by'], 'String');
      }
      if (data.hasOwnProperty('date_deleted')) {
        obj['date_deleted'] = _ApiClient.default.convertToType(data['date_deleted'], 'String');
      }
      if (data.hasOwnProperty('time_deleted')) {
        obj['time_deleted'] = _ApiClient.default.convertToType(data['time_deleted'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ExpressionOfInterest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExpressionOfInterest</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ExpressionOfInterest.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['company'] && !(typeof data['company'] === 'string' || data['company'] instanceof String)) {
      throw new Error("Expected the field `company` to be a primitive type in the JSON string but got " + data['company']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
      throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['country'] && !(typeof data['country'] === 'string' || data['country'] instanceof String)) {
      throw new Error("Expected the field `country` to be a primitive type in the JSON string but got " + data['country']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
      throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
    }
    // ensure the json data is a string
    if (data['time_created'] && !(typeof data['time_created'] === 'string' || data['time_created'] instanceof String)) {
      throw new Error("Expected the field `time_created` to be a primitive type in the JSON string but got " + data['time_created']);
    }
    // ensure the json data is a string
    if (data['updated_by'] && !(typeof data['updated_by'] === 'string' || data['updated_by'] instanceof String)) {
      throw new Error("Expected the field `updated_by` to be a primitive type in the JSON string but got " + data['updated_by']);
    }
    // ensure the json data is a string
    if (data['time_updated'] && !(typeof data['time_updated'] === 'string' || data['time_updated'] instanceof String)) {
      throw new Error("Expected the field `time_updated` to be a primitive type in the JSON string but got " + data['time_updated']);
    }
    // ensure the json data is a string
    if (data['deleted_by'] && !(typeof data['deleted_by'] === 'string' || data['deleted_by'] instanceof String)) {
      throw new Error("Expected the field `deleted_by` to be a primitive type in the JSON string but got " + data['deleted_by']);
    }
    // ensure the json data is a string
    if (data['time_deleted'] && !(typeof data['time_deleted'] === 'string' || data['time_deleted'] instanceof String)) {
      throw new Error("Expected the field `time_deleted` to be a primitive type in the JSON string but got " + data['time_deleted']);
    }
    return true;
  }
}
ExpressionOfInterest.RequiredProperties = ["email", "is_ad_test_interest", "id", "created_at", "updated_at"];

/**
 * @member {String} name
 */
ExpressionOfInterest.prototype['name'] = undefined;

/**
 * @member {String} company
 */
ExpressionOfInterest.prototype['company'] = undefined;

/**
 * @member {String} email
 */
ExpressionOfInterest.prototype['email'] = undefined;

/**
 * @member {String} message
 */
ExpressionOfInterest.prototype['message'] = undefined;

/**
 * @member {Boolean} is_ad_test_interest
 */
ExpressionOfInterest.prototype['is_ad_test_interest'] = undefined;

/**
 * @member {String} country_id
 */
ExpressionOfInterest.prototype['country_id'] = undefined;

/**
 * @member {String} country
 */
ExpressionOfInterest.prototype['country'] = undefined;

/**
 * @member {String} id
 */
ExpressionOfInterest.prototype['id'] = undefined;

/**
 * @member {String} created_at
 */
ExpressionOfInterest.prototype['created_at'] = undefined;

/**
 * @member {String} created_by
 */
ExpressionOfInterest.prototype['created_by'] = undefined;

/**
 * @member {String} date_created
 */
ExpressionOfInterest.prototype['date_created'] = undefined;

/**
 * @member {String} time_created
 */
ExpressionOfInterest.prototype['time_created'] = undefined;

/**
 * @member {String} updated_at
 */
ExpressionOfInterest.prototype['updated_at'] = undefined;

/**
 * @member {String} updated_by
 */
ExpressionOfInterest.prototype['updated_by'] = undefined;

/**
 * @member {String} date_updated
 */
ExpressionOfInterest.prototype['date_updated'] = undefined;

/**
 * @member {String} time_updated
 */
ExpressionOfInterest.prototype['time_updated'] = undefined;

/**
 * @member {String} deleted_at
 */
ExpressionOfInterest.prototype['deleted_at'] = undefined;

/**
 * @member {String} deleted_by
 */
ExpressionOfInterest.prototype['deleted_by'] = undefined;

/**
 * @member {String} date_deleted
 */
ExpressionOfInterest.prototype['date_deleted'] = undefined;

/**
 * @member {String} time_deleted
 */
ExpressionOfInterest.prototype['time_deleted'] = undefined;
var _default = exports.default = ExpressionOfInterest;