import { put } from 'redux-saga/effects';
import { createReducer } from './utils';

const SET_LOADING = 'SET_LOADING';

export const setLoading = () => ({ type: SET_LOADING, payload: 1 });
export const unsetLoading = () => ({ type: SET_LOADING, payload: -1 });

export const isLoadingReducer = createReducer(
	{
		[SET_LOADING]: (state, increment) => {
			let { count } = state;
			let newCount = count + increment;
			return {
				...state,
				count: newCount
			};
		}
	},
	{ state: { count: 0 } }
);

export const getGlobalIsLoading = state => state.loadingState.count > 0;

export const wrapInLoading = wrappedSaga =>
	function* (...args) {
		try {
			yield put(setLoading()); // dispatches set loading to the store
			yield wrappedSaga(...args); // calls and awaits the inner saga
			//yield call(wrappedSaga, ...args);
		} finally {
			yield put(unsetLoading()); // finally dispatches unset loading to the store
		}
	};
