"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The BrowserIdModel model module.
 * @module model/BrowserIdModel
 * @version 1.1.3-alpha.1
 */
class BrowserIdModel {
  /**
   * Constructs a new <code>BrowserIdModel</code>.
   * @alias module:model/BrowserIdModel
   * @param browser_id {String} Browser unique id. A session is opened for each id and must be activated with a 2-step authentication process (an e-mail with an activation id is sent to the user)
   */
  constructor(browser_id) {
    BrowserIdModel.initialize(this, browser_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, browser_id) {
    obj['browser_id'] = browser_id;
  }

  /**
   * Constructs a <code>BrowserIdModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BrowserIdModel} obj Optional instance to populate.
   * @return {module:model/BrowserIdModel} The populated <code>BrowserIdModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BrowserIdModel();
      if (data.hasOwnProperty('browser_id')) {
        obj['browser_id'] = _ApiClient.default.convertToType(data['browser_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>BrowserIdModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BrowserIdModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of BrowserIdModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['browser_id'] && !(typeof data['browser_id'] === 'string' || data['browser_id'] instanceof String)) {
      throw new Error("Expected the field `browser_id` to be a primitive type in the JSON string but got " + data['browser_id']);
    }
    return true;
  }
}
BrowserIdModel.RequiredProperties = ["browser_id"];

/**
 * Browser unique id. A session is opened for each id and must be activated with a 2-step authentication process (an e-mail with an activation id is sent to the user)
 * @member {String} browser_id
 */
BrowserIdModel.prototype['browser_id'] = undefined;
var _default = exports.default = BrowserIdModel;