"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CreateCustomerSubscriptionCommand = _interopRequireDefault(require("../model/CreateCustomerSubscriptionCommand"));
var _RequestSubscriptionCancellationModel = _interopRequireDefault(require("../model/RequestSubscriptionCancellationModel"));
var _Subscription = _interopRequireDefault(require("../model/Subscription"));
var _SubscriptionQuery = _interopRequireDefault(require("../model/SubscriptionQuery"));
var _SubscriptionQueryResult = _interopRequireDefault(require("../model/SubscriptionQueryResult"));
var _UpdateCustomerSubscriptionCommand = _interopRequireDefault(require("../model/UpdateCustomerSubscriptionCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Subscriptions service.
* @module api/SubscriptionsApi
* @version 1.1.3-alpha.1
*/
class SubscriptionsApi {
  /**
  * Constructs a new SubscriptionsApi. 
  * @alias module:api/SubscriptionsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Cancel subscription
   * Cancels a subscription
   * @param {Number} id Id of the subscription to cancel
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Subscription} and HTTP response
   */
  cancelWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling cancel");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Subscription.default;
    return this.apiClient.callApi('/api/v1/Subscriptions/{id}/cancel', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Cancel subscription
   * Cancels a subscription
   * @param {Number} id Id of the subscription to cancel
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Subscription}
   */
  cancel(id) {
    return this.cancelWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Request Cancellation
   * Requests cancellation (non-continuation) of a subscription based on cancellation token
   * @param {Object} opts Optional parameters
   * @param {module:model/RequestSubscriptionCancellationModel} opts.RequestSubscriptionCancellationModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
   */
  cancelRequestWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['RequestSubscriptionCancellationModel'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = 'Boolean';
    return this.apiClient.callApi('/api/v1/Subscriptions/cancelRequest', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Request Cancellation
   * Requests cancellation (non-continuation) of a subscription based on cancellation token
   * @param {Object} opts Optional parameters
   * @param {module:model/RequestSubscriptionCancellationModel} opts.RequestSubscriptionCancellationModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
   */
  cancelRequest(opts) {
    return this.cancelRequestWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create a new subscription
   * Creates a new subscription and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerSubscriptionCommand} opts.CreateCustomerSubscriptionCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Subscription} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateCustomerSubscriptionCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Subscription.default;
    return this.apiClient.callApi('/api/v1/Subscriptions', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new subscription
   * Creates a new subscription and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerSubscriptionCommand} opts.CreateCustomerSubscriptionCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Subscription}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get User
   * Returns the requested subscription
   * @param {Number} id Id of the subscription to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Subscription} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Subscription.default;
    return this.apiClient.callApi('/api/v1/Subscriptions/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get User
   * Returns the requested subscription
   * @param {Number} id Id of the subscription to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Subscription}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple subscriptions
   * Returns an array of the requested subscriptions
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the subscriptions to get. Not specifiying ids will return all subscriptions
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Subscription>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Subscription.default];
    return this.apiClient.callApi('/api/v1/Subscriptions/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple subscriptions
   * Returns an array of the requested subscriptions
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the subscriptions to get. Not specifiying ids will return all subscriptions
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Subscription>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query subscription
   * Queries users and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionQuery} opts.SubscriptionQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['SubscriptionQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SubscriptionQueryResult.default;
    return this.apiClient.callApi('/api/v1/Subscriptions/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query subscription
   * Queries users and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionQuery} opts.SubscriptionQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Revert subscription cancellation
   * Revert subscription cancellation
   * @param {Number} id Id of the subscription to revert cancel
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Subscription} and HTTP response
   */
  undoCancelWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling undoCancel");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Subscription.default;
    return this.apiClient.callApi('/api/v1/Subscriptions/{id}/cancel', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Revert subscription cancellation
   * Revert subscription cancellation
   * @param {Number} id Id of the subscription to revert cancel
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Subscription}
   */
  undoCancel(id) {
    return this.undoCancelWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update subscription
   * Updates a subscription and returns the updated object
   * @param {Number} id Id of the subscription to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerSubscriptionCommand} opts.UpdateCustomerSubscriptionCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Subscription} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateCustomerSubscriptionCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Subscription.default;
    return this.apiClient.callApi('/api/v1/Subscriptions/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update subscription
   * Updates a subscription and returns the updated object
   * @param {Number} id Id of the subscription to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerSubscriptionCommand} opts.UpdateCustomerSubscriptionCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Subscription}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = SubscriptionsApi;