export const MUTATION = {
	ADD_TO_LIST: 'ADD_TO_LIST',
	REMOVE_FROM_LIST: 'REMOVE_FROM_LIST',
	REQUEST_UNLOCK: 'REQUEST_UNLOCK',
	REQUEST_UPGRADE_INFO: 'REQUEST_UPGRADE_INFO',
	UNLOCK: 'UNLOCK',
	RESOURCE_CREATE: 'RESOURCE_CREATE',
	GIVE_FEEDBACK: 'GIVE_FEEDBACK',
	HAVE_QUESTION: 'HAVE_QUESTION',
	MY_MEDIA_CREATE: 'MY_MEDIA_CREATE',
	MY_MEDIA_UPDATE: 'MY_MEDIA_UPDATE'
};

export const CRUD_ACTION = {
	CREATE: 'create',
	READ: 'read',
	UPDATE: 'update',
	DELETE: 'delete'
};

export const REQUEST_TYPE = {
	GET_LIST: 'getList',
	GET_REFERENCE_MANY: 'refMany',
	QUERY: 'query'
};

export const RESOURCE_GET_QUERY = 'RESOURCE_GET_QUERY';
export const RESOURCE_GET = 'RESOURCE_GET';
export const RESOURCE_GET_CUSTOMER_META = 'RESOURCE_GET_CUSTOMER_META';
export const RESOURCE_GET_USER_META = 'RESOURCE_GET_USER_META';
export const RESOURCE_GET_LIST = 'RESOURCE_GET_LIST';
export const RESOURCE_GET_REF_MANY = 'RESOURCE_GET_REF_MANY';
export const RESOURCE_MUTATE = 'RESOURCE_MUTATE';
export const SLIDE_COMMENT_CREATION_COMPLETED = 'SLIDE_COMMENT_CREATION_COMPLETED';
export const FORM_SUBMISSION = '@FORM';
