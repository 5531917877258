import React, { memo } from 'react';
import smallLogo from '../../assets/imgs/w.png';
import bigImage from '../../assets/imgs/w.svg';
import { extractClasses } from '../../utilities/classname-utils';
import { Loading } from './loading-spinners';

export const TooSmallWidthLoader = memo(({ width }) => (
	<div className="loader width-too-small">
		<Loading spinner={false} image={smallLogo}>
			<p>{`The site is not viewable in resolutions less than ${width} pixels wide.`}</p>
		</Loading>
	</div>
));

export const PageLoader = memo(() => (
	<div className="loader page-loader">
		<Loading text={false} spinner="dots" image={bigImage} />
	</div>
));

export const ImageLoader = memo(props => (
	<div {...extractClasses(props, ['loader image-loader'])}>
		<Loading text={false} spinner="circle" />
	</div>
));
