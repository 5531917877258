"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Brand = _interopRequireDefault(require("./Brand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The BrandQueryResult model module.
 * @module model/BrandQueryResult
 * @version 1.1.3-alpha.1
 */
class BrandQueryResult {
  /**
   * Constructs a new <code>BrandQueryResult</code>.
   * @alias module:model/BrandQueryResult
   */
  constructor() {
    BrandQueryResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>BrandQueryResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BrandQueryResult} obj Optional instance to populate.
   * @return {module:model/BrandQueryResult} The populated <code>BrandQueryResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BrandQueryResult();
      if (data.hasOwnProperty('items')) {
        obj['items'] = _ApiClient.default.convertToType(data['items'], [_Brand.default]);
      }
      if (data.hasOwnProperty('total')) {
        obj['total'] = _ApiClient.default.convertToType(data['total'], 'Number');
      }
      if (data.hasOwnProperty('offset')) {
        obj['offset'] = _ApiClient.default.convertToType(data['offset'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>BrandQueryResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BrandQueryResult</code>.
   */
  static validateJSON(data) {
    if (data['items']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['items'])) {
        throw new Error("Expected the field `items` to be an array in the JSON data but got " + data['items']);
      }
      // validate the optional field `items` (array)
      for (const item of data['items']) {
        _Brand.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}

/**
 * @member {Array.<module:model/Brand>} items
 */
BrandQueryResult.prototype['items'] = undefined;

/**
 * @member {Number} total
 */
BrandQueryResult.prototype['total'] = undefined;

/**
 * @member {Number} offset
 */
BrandQueryResult.prototype['offset'] = undefined;
var _default = exports.default = BrandQueryResult;