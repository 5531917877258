"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CustomerGetAdScoreAccessibilityParams model module.
 * @module model/CustomerGetAdScoreAccessibilityParams
 * @version 1.1.3-alpha.1
 */
class CustomerGetAdScoreAccessibilityParams {
  /**
   * Constructs a new <code>CustomerGetAdScoreAccessibilityParams</code>.
   * @alias module:model/CustomerGetAdScoreAccessibilityParams
   */
  constructor() {
    CustomerGetAdScoreAccessibilityParams.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>CustomerGetAdScoreAccessibilityParams</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CustomerGetAdScoreAccessibilityParams} obj Optional instance to populate.
   * @return {module:model/CustomerGetAdScoreAccessibilityParams} The populated <code>CustomerGetAdScoreAccessibilityParams</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerGetAdScoreAccessibilityParams();
      if (data.hasOwnProperty('adscore_ids')) {
        obj['adscore_ids'] = _ApiClient.default.convertToType(data['adscore_ids'], ['String']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CustomerGetAdScoreAccessibilityParams</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CustomerGetAdScoreAccessibilityParams</code>.
   */
  static validateJSON(data) {
    // ensure the json data is an array
    if (!Array.isArray(data['adscore_ids'])) {
      throw new Error("Expected the field `adscore_ids` to be an array in the JSON data but got " + data['adscore_ids']);
    }
    return true;
  }
}

/**
 * @member {Array.<String>} adscore_ids
 */
CustomerGetAdScoreAccessibilityParams.prototype['adscore_ids'] = undefined;
var _default = exports.default = CustomerGetAdScoreAccessibilityParams;