"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The EntityAttachment model module.
 * @module model/EntityAttachment
 * @version 1.1.3-alpha.1
 */
class EntityAttachment {
  /**
   * Constructs a new <code>EntityAttachment</code>.
   * @alias module:model/EntityAttachment
   * @param id {String} 
   * @param entity_id {String} 
   * @param identifier {String} 
   * @param version {Number} 
   * @param date_created {String} 
   * @param date_updated {String} 
   */
  constructor(id, entity_id, identifier, version, date_created, date_updated) {
    EntityAttachment.initialize(this, id, entity_id, identifier, version, date_created, date_updated);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, entity_id, identifier, version, date_created, date_updated) {
    obj['id'] = id;
    obj['entity_id'] = entity_id;
    obj['identifier'] = identifier;
    obj['version'] = version;
    obj['date_created'] = date_created;
    obj['date_updated'] = date_updated;
  }

  /**
   * Constructs a <code>EntityAttachment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EntityAttachment} obj Optional instance to populate.
   * @return {module:model/EntityAttachment} The populated <code>EntityAttachment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EntityAttachment();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('entity_id')) {
        obj['entity_id'] = _ApiClient.default.convertToType(data['entity_id'], 'String');
      }
      if (data.hasOwnProperty('identifier')) {
        obj['identifier'] = _ApiClient.default.convertToType(data['identifier'], 'String');
      }
      if (data.hasOwnProperty('version')) {
        obj['version'] = _ApiClient.default.convertToType(data['version'], 'Number');
      }
      if (data.hasOwnProperty('metadata')) {
        obj['metadata'] = _ApiClient.default.convertToType(data['metadata'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>EntityAttachment</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>EntityAttachment</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of EntityAttachment.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['entity_id'] && !(typeof data['entity_id'] === 'string' || data['entity_id'] instanceof String)) {
      throw new Error("Expected the field `entity_id` to be a primitive type in the JSON string but got " + data['entity_id']);
    }
    // ensure the json data is a string
    if (data['identifier'] && !(typeof data['identifier'] === 'string' || data['identifier'] instanceof String)) {
      throw new Error("Expected the field `identifier` to be a primitive type in the JSON string but got " + data['identifier']);
    }
    // ensure the json data is a string
    if (data['metadata'] && !(typeof data['metadata'] === 'string' || data['metadata'] instanceof String)) {
      throw new Error("Expected the field `metadata` to be a primitive type in the JSON string but got " + data['metadata']);
    }
    return true;
  }
}
EntityAttachment.RequiredProperties = ["id", "entity_id", "identifier", "version", "date_created", "date_updated"];

/**
 * @member {String} id
 */
EntityAttachment.prototype['id'] = undefined;

/**
 * @member {String} entity_id
 */
EntityAttachment.prototype['entity_id'] = undefined;

/**
 * @member {String} identifier
 */
EntityAttachment.prototype['identifier'] = undefined;

/**
 * @member {Number} version
 */
EntityAttachment.prototype['version'] = undefined;

/**
 * @member {String} metadata
 */
EntityAttachment.prototype['metadata'] = undefined;

/**
 * @member {String} date_created
 */
EntityAttachment.prototype['date_created'] = undefined;

/**
 * @member {String} date_updated
 */
EntityAttachment.prototype['date_updated'] = undefined;
var _default = exports.default = EntityAttachment;