"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RECENTLY_ADDED = exports.READ_AGAIN = exports.MY_RECENTLY_ADDED = exports.MY_READ_AGAIN = exports.MY_MOST_POPULAR = exports.MY_CONTINUE_READING = exports.MOST_POPULAR = exports.CONTINUE_READING = exports.COMING_UP = exports.AFFILIATE = void 0;
const COMING_UP = 'comingUp';
exports.COMING_UP = COMING_UP;
const RECENTLY_ADDED = 'recentlyAdded';
exports.RECENTLY_ADDED = RECENTLY_ADDED;
const MOST_POPULAR = 'mostPopular';
exports.MOST_POPULAR = MOST_POPULAR;
const CONTINUE_READING = 'continue_reading';
exports.CONTINUE_READING = CONTINUE_READING;
const READ_AGAIN = 'read_again';
exports.READ_AGAIN = READ_AGAIN;
const MY_RECENTLY_ADDED = 'my_recentlyAdded';
exports.MY_RECENTLY_ADDED = MY_RECENTLY_ADDED;
const MY_MOST_POPULAR = 'my_mostPopular';
exports.MY_MOST_POPULAR = MY_MOST_POPULAR;
const MY_CONTINUE_READING = 'my_continue_reading';
exports.MY_CONTINUE_READING = MY_CONTINUE_READING;
const MY_READ_AGAIN = 'my_read_again';
exports.MY_READ_AGAIN = MY_READ_AGAIN;
const AFFILIATE = 'affiliate';
exports.AFFILIATE = AFFILIATE;
var _default = {
  COMING_UP,
  RECENTLY_ADDED,
  MOST_POPULAR,
  CONTINUE_READING,
  READ_AGAIN,
  AFFILIATE,
  MY_RECENTLY_ADDED,
  MY_MOST_POPULAR,
  MY_CONTINUE_READING,
  MY_READ_AGAIN
};
exports.default = _default;