"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CommandResetPassword model module.
 * @module model/CommandResetPassword
 * @version 1.1.3-alpha.1
 */
class CommandResetPassword {
  /**
   * Constructs a new <code>CommandResetPassword</code>.
   * @alias module:model/CommandResetPassword
   * @param passwordResetToken {String} 
   * @param newPassword {String} 
   */
  constructor(passwordResetToken, newPassword) {
    CommandResetPassword.initialize(this, passwordResetToken, newPassword);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, passwordResetToken, newPassword) {
    obj['passwordResetToken'] = passwordResetToken;
    obj['newPassword'] = newPassword;
  }

  /**
   * Constructs a <code>CommandResetPassword</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CommandResetPassword} obj Optional instance to populate.
   * @return {module:model/CommandResetPassword} The populated <code>CommandResetPassword</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CommandResetPassword();
      if (data.hasOwnProperty('passwordResetToken')) {
        obj['passwordResetToken'] = _ApiClient.default.convertToType(data['passwordResetToken'], 'String');
      }
      if (data.hasOwnProperty('newPassword')) {
        obj['newPassword'] = _ApiClient.default.convertToType(data['newPassword'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CommandResetPassword</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommandResetPassword</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CommandResetPassword.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['passwordResetToken'] && !(typeof data['passwordResetToken'] === 'string' || data['passwordResetToken'] instanceof String)) {
      throw new Error("Expected the field `passwordResetToken` to be a primitive type in the JSON string but got " + data['passwordResetToken']);
    }
    // ensure the json data is a string
    if (data['newPassword'] && !(typeof data['newPassword'] === 'string' || data['newPassword'] instanceof String)) {
      throw new Error("Expected the field `newPassword` to be a primitive type in the JSON string but got " + data['newPassword']);
    }
    return true;
  }
}
CommandResetPassword.RequiredProperties = ["passwordResetToken", "newPassword"];

/**
 * @member {String} passwordResetToken
 */
CommandResetPassword.prototype['passwordResetToken'] = undefined;

/**
 * @member {String} newPassword
 */
CommandResetPassword.prototype['newPassword'] = undefined;
var _default = exports.default = CommandResetPassword;