"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BaseResult = _interopRequireDefault(require("../model/BaseResult"));
var _CreateUserCommand = _interopRequireDefault(require("../model/CreateUserCommand"));
var _SetUserPreferencesModel = _interopRequireDefault(require("../model/SetUserPreferencesModel"));
var _User = _interopRequireDefault(require("../model/User"));
var _UserQuery = _interopRequireDefault(require("../model/UserQuery"));
var _UserQueryResult = _interopRequireDefault(require("../model/UserQueryResult"));
var _ViewUserPreference = _interopRequireDefault(require("../model/ViewUserPreference"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Users service.
* @module api/UsersApi
* @version 1.1.3-alpha.1
*/
class UsersApi {
  /**
  * Constructs a new UsersApi. 
  * @alias module:api/UsersApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new User
   * Creates a new User and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateUserCommand} opts.CreateUserCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateUserCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _User.default;
    return this.apiClient.callApi('/api/v1/Users', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new User
   * Creates a new User and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateUserCommand} opts.CreateUserCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get User
   * Returns the requested user
   * @param {String} id Id of the user to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _User.default;
    return this.apiClient.callApi('/api/v1/Users/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get User
   * Returns the requested user
   * @param {String} id Id of the user to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple Users
   * Returns an array of requested users
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the users to get. Not specifiying ids will return all Users
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_User.default];
    return this.apiClient.callApi('/api/v1/Users/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple Users
   * Returns an array of requested users
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the users to get. Not specifiying ids will return all Users
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Gets a user's preferences
   * Returns the requested user's preferences
   * @param {String} id Id of the user whose preferences to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ViewUserPreference>} and HTTP response
   */
  getPreferencesWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getPreferences");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_ViewUserPreference.default];
    return this.apiClient.callApi('/api/v1/Users/{id}/preferences', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Gets a user's preferences
   * Returns the requested user's preferences
   * @param {String} id Id of the user whose preferences to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ViewUserPreference>}
   */
  getPreferences(id) {
    return this.getPreferencesWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query Users
   * Queries users and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/UserQuery} opts.UserQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['UserQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _UserQueryResult.default;
    return this.apiClient.callApi('/api/v1/Users/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query Users
   * Queries users and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/UserQuery} opts.UserQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete user
   * Deletes a user and returns the deleted object
   * @param {String} id Id of the user to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _User.default;
    return this.apiClient.callApi('/api/v1/Users/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete user
   * Deletes a user and returns the deleted object
   * @param {String} id Id of the user to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple users
   * Deletes multiple users and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the users to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_User.default];
    return this.apiClient.callApi('/api/v1/Users/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple users
   * Deletes multiple users and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the users to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Resend activation email for non activated user
   * Resends an activation email for a user that is not yet activated
   * @param {String} id Id of the user to send email to
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  resendActivationEmailWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling resendActivationEmail");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Users/{id}/resendActivation', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Resend activation email for non activated user
   * Resends an activation email for a user that is not yet activated
   * @param {String} id Id of the user to send email to
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  resendActivationEmail(id) {
    return this.resendActivationEmailWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Gets a user's preferences
   * Returns the requested user's preferences
   * @param {String} id Id of the user whose preferences to get
   * @param {Object} opts Optional parameters
   * @param {module:model/SetUserPreferencesModel} opts.SetUserPreferencesModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ViewUserPreference>} and HTTP response
   */
  setPreferencesWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['SetUserPreferencesModel'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling setPreferences");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_ViewUserPreference.default];
    return this.apiClient.callApi('/api/v1/Users/{id}/preferences', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Gets a user's preferences
   * Returns the requested user's preferences
   * @param {String} id Id of the user whose preferences to get
   * @param {Object} opts Optional parameters
   * @param {module:model/SetUserPreferencesModel} opts.SetUserPreferencesModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ViewUserPreference>}
   */
  setPreferences(id, opts) {
    return this.setPreferencesWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = UsersApi;