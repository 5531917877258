"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _PaymentMode = _interopRequireDefault(require("./PaymentMode"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Subscription model module.
 * @module model/Subscription
 * @version 1.1.3-alpha.1
 */
class Subscription {
  /**
   * Constructs a new <code>Subscription</code>.
   * @alias module:model/Subscription
   * @param id {Number} 
   * @param customer_id {String} 
   * @param subscription_type_id {String} 
   * @param subscription_number {Number} 
   * @param payment_mode {module:model/PaymentMode} 
   * @param is_paid {Boolean} 
   * @param is_cancelled {Boolean} 
   * @param valid_from {String} 
   * @param valid_to {String} 
   * @param paid_amount {Number} 
   * @param notification_30_days_registered {Boolean} 
   * @param notification_60_days_registered {Boolean} 
   * @param cancellation_requested {Boolean} 
   */
  constructor(id, customer_id, subscription_type_id, subscription_number, payment_mode, is_paid, is_cancelled, valid_from, valid_to, paid_amount, notification_30_days_registered, notification_60_days_registered, cancellation_requested) {
    Subscription.initialize(this, id, customer_id, subscription_type_id, subscription_number, payment_mode, is_paid, is_cancelled, valid_from, valid_to, paid_amount, notification_30_days_registered, notification_60_days_registered, cancellation_requested);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, customer_id, subscription_type_id, subscription_number, payment_mode, is_paid, is_cancelled, valid_from, valid_to, paid_amount, notification_30_days_registered, notification_60_days_registered, cancellation_requested) {
    obj['id'] = id;
    obj['customer_id'] = customer_id;
    obj['subscription_type_id'] = subscription_type_id;
    obj['subscription_number'] = subscription_number;
    obj['payment_mode'] = payment_mode;
    obj['is_paid'] = is_paid;
    obj['is_cancelled'] = is_cancelled;
    obj['valid_from'] = valid_from;
    obj['valid_to'] = valid_to;
    obj['paid_amount'] = paid_amount;
    obj['notification_30_days_registered'] = notification_30_days_registered;
    obj['notification_60_days_registered'] = notification_60_days_registered;
    obj['cancellation_requested'] = cancellation_requested;
  }

  /**
   * Constructs a <code>Subscription</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Subscription} obj Optional instance to populate.
   * @return {module:model/Subscription} The populated <code>Subscription</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Subscription();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('subscription_type_id')) {
        obj['subscription_type_id'] = _ApiClient.default.convertToType(data['subscription_type_id'], 'String');
      }
      if (data.hasOwnProperty('subscription_number')) {
        obj['subscription_number'] = _ApiClient.default.convertToType(data['subscription_number'], 'Number');
      }
      if (data.hasOwnProperty('payment_mode')) {
        obj['payment_mode'] = _PaymentMode.default.constructFromObject(data['payment_mode']);
      }
      if (data.hasOwnProperty('is_paid')) {
        obj['is_paid'] = _ApiClient.default.convertToType(data['is_paid'], 'Boolean');
      }
      if (data.hasOwnProperty('is_cancelled')) {
        obj['is_cancelled'] = _ApiClient.default.convertToType(data['is_cancelled'], 'Boolean');
      }
      if (data.hasOwnProperty('is_active')) {
        obj['is_active'] = _ApiClient.default.convertToType(data['is_active'], 'Boolean');
      }
      if (data.hasOwnProperty('is_expired')) {
        obj['is_expired'] = _ApiClient.default.convertToType(data['is_expired'], 'Boolean');
      }
      if (data.hasOwnProperty('valid_from')) {
        obj['valid_from'] = _ApiClient.default.convertToType(data['valid_from'], 'String');
      }
      if (data.hasOwnProperty('valid_to')) {
        obj['valid_to'] = _ApiClient.default.convertToType(data['valid_to'], 'String');
      }
      if (data.hasOwnProperty('paid_amount')) {
        obj['paid_amount'] = _ApiClient.default.convertToType(data['paid_amount'], 'Number');
      }
      if (data.hasOwnProperty('purchased_date')) {
        obj['purchased_date'] = _ApiClient.default.convertToType(data['purchased_date'], 'String');
      }
      if (data.hasOwnProperty('cancelled_date')) {
        obj['cancelled_date'] = _ApiClient.default.convertToType(data['cancelled_date'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('notification_30_days_registered')) {
        obj['notification_30_days_registered'] = _ApiClient.default.convertToType(data['notification_30_days_registered'], 'Boolean');
      }
      if (data.hasOwnProperty('notification_60_days_registered')) {
        obj['notification_60_days_registered'] = _ApiClient.default.convertToType(data['notification_60_days_registered'], 'Boolean');
      }
      if (data.hasOwnProperty('cancellation_request_token')) {
        obj['cancellation_request_token'] = _ApiClient.default.convertToType(data['cancellation_request_token'], 'String');
      }
      if (data.hasOwnProperty('cancellation_requested')) {
        obj['cancellation_requested'] = _ApiClient.default.convertToType(data['cancellation_requested'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Subscription</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Subscription</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Subscription.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['subscription_type_id'] && !(typeof data['subscription_type_id'] === 'string' || data['subscription_type_id'] instanceof String)) {
      throw new Error("Expected the field `subscription_type_id` to be a primitive type in the JSON string but got " + data['subscription_type_id']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['cancellation_request_token'] && !(typeof data['cancellation_request_token'] === 'string' || data['cancellation_request_token'] instanceof String)) {
      throw new Error("Expected the field `cancellation_request_token` to be a primitive type in the JSON string but got " + data['cancellation_request_token']);
    }
    return true;
  }
}
Subscription.RequiredProperties = ["id", "customer_id", "subscription_type_id", "subscription_number", "payment_mode", "is_paid", "is_cancelled", "valid_from", "valid_to", "paid_amount", "notification_30_days_registered", "notification_60_days_registered", "cancellation_requested"];

/**
 * @member {Number} id
 */
Subscription.prototype['id'] = undefined;

/**
 * @member {String} customer_id
 */
Subscription.prototype['customer_id'] = undefined;

/**
 * @member {String} subscription_type_id
 */
Subscription.prototype['subscription_type_id'] = undefined;

/**
 * @member {Number} subscription_number
 */
Subscription.prototype['subscription_number'] = undefined;

/**
 * @member {module:model/PaymentMode} payment_mode
 */
Subscription.prototype['payment_mode'] = undefined;

/**
 * @member {Boolean} is_paid
 */
Subscription.prototype['is_paid'] = undefined;

/**
 * @member {Boolean} is_cancelled
 */
Subscription.prototype['is_cancelled'] = undefined;

/**
 * @member {Boolean} is_active
 */
Subscription.prototype['is_active'] = undefined;

/**
 * @member {Boolean} is_expired
 */
Subscription.prototype['is_expired'] = undefined;

/**
 * @member {String} valid_from
 */
Subscription.prototype['valid_from'] = undefined;

/**
 * @member {String} valid_to
 */
Subscription.prototype['valid_to'] = undefined;

/**
 * @member {Number} paid_amount
 */
Subscription.prototype['paid_amount'] = undefined;

/**
 * @member {String} purchased_date
 */
Subscription.prototype['purchased_date'] = undefined;

/**
 * @member {String} cancelled_date
 */
Subscription.prototype['cancelled_date'] = undefined;

/**
 * @member {String} description
 */
Subscription.prototype['description'] = undefined;

/**
 * @member {Boolean} notification_30_days_registered
 */
Subscription.prototype['notification_30_days_registered'] = undefined;

/**
 * @member {Boolean} notification_60_days_registered
 */
Subscription.prototype['notification_60_days_registered'] = undefined;

/**
 * @member {String} cancellation_request_token
 */
Subscription.prototype['cancellation_request_token'] = undefined;

/**
 * @member {Boolean} cancellation_requested
 */
Subscription.prototype['cancellation_requested'] = undefined;
var _default = exports.default = Subscription;