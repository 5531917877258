import { call, put } from 'redux-saga/effects';
import { actions as notificationActions } from '../components/notifications';
import isDebug from '../is-debug';

export const flowExecute = function* flowExecute(flow, ...args) {
	if (flow !== undefined && flow !== null) {
		if (typeof flow === 'function') {
			var result = yield call(flow, ...args);
			if (result && (typeof result.type === 'string' || Array.isArray(result))) {
				yield flowExecute(result);
			}
		} else if (Array.isArray(flow)) {
			// array of actions or flows
			for (var i = 0; i < flow.length; ++i) {
				yield flowExecute(flow[i], ...args);
			}
		} else if (typeof flow === 'object' && typeof flow.type === 'string') {
			// it is supposed to be action. Args are unimportant. The action itself contains the args
			yield put(flow);
		}
	}
};

export function* notifyError(e) {
	console.error(e);
	if (isDebug) yield put(notificationActions.jsError(e));
}
