"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AddExpressionOfInterestCommand = _interopRequireDefault(require("../model/AddExpressionOfInterestCommand"));
var _ExpressionOfInterest = _interopRequireDefault(require("../model/ExpressionOfInterest"));
var _ExpressionOfInterestQuery = _interopRequireDefault(require("../model/ExpressionOfInterestQuery"));
var _ExpressionOfInterestQueryResult = _interopRequireDefault(require("../model/ExpressionOfInterestQueryResult"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* LearnMore service.
* @module api/LearnMoreApi
* @version 1.1.3-alpha.1
*/
class LearnMoreApi {
  /**
  * Constructs a new LearnMoreApi. 
  * @alias module:api/LearnMoreApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new expression of interest
   * Creates a new expression of interest and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/AddExpressionOfInterestCommand} opts.AddExpressionOfInterestCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExpressionOfInterest} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AddExpressionOfInterestCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _ExpressionOfInterest.default;
    return this.apiClient.callApi('/api/v1/LearnMore', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new expression of interest
   * Creates a new expression of interest and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/AddExpressionOfInterestCommand} opts.AddExpressionOfInterestCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExpressionOfInterest}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Exports expressions of interest in excel format
   * Exports expressions of interest and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/ExpressionOfInterestQuery} opts.ExpressionOfInterestQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  exportFileWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['ExpressionOfInterestQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/LearnMore/export', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Exports expressions of interest in excel format
   * Exports expressions of interest and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/ExpressionOfInterestQuery} opts.ExpressionOfInterestQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  exportFile(opts) {
    return this.exportFileWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get expression of interest
   * Returns the requested expression of interest
   * @param {String} id Id of the expressions of interest to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExpressionOfInterest} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ExpressionOfInterest.default;
    return this.apiClient.callApi('/api/v1/LearnMore/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get expression of interest
   * Returns the requested expression of interest
   * @param {String} id Id of the expressions of interest to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExpressionOfInterest}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple expressions of interest
   * Returns an array of requested expressions of interest
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the expressions of interest to get. Not specifiying ids will return all expressions of interest
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ExpressionOfInterest>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_ExpressionOfInterest.default];
    return this.apiClient.callApi('/api/v1/LearnMore/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple expressions of interest
   * Returns an array of requested expressions of interest
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the expressions of interest to get. Not specifiying ids will return all expressions of interest
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ExpressionOfInterest>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query expressions of interest
   * Queries expressions of interest and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/ExpressionOfInterestQuery} opts.ExpressionOfInterestQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExpressionOfInterestQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['ExpressionOfInterestQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _ExpressionOfInterestQueryResult.default;
    return this.apiClient.callApi('/api/v1/LearnMore/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query expressions of interest
   * Queries expressions of interest and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/ExpressionOfInterestQuery} opts.ExpressionOfInterestQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExpressionOfInterestQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete expression of interest
   * Deletes an expression of interest and returns the deleted object
   * @param {String} id Id of the expression of interest to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExpressionOfInterest} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ExpressionOfInterest.default;
    return this.apiClient.callApi('/api/v1/LearnMore/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete expression of interest
   * Deletes an expression of interest and returns the deleted object
   * @param {String} id Id of the expression of interest to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExpressionOfInterest}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple expressions of interest
   * Deletes multiple expressions of interest and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the expressions of interest to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ExpressionOfInterest>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_ExpressionOfInterest.default];
    return this.apiClient.callApi('/api/v1/LearnMore/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple expressions of interest
   * Deletes multiple expressions of interest and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the expressions of interest to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ExpressionOfInterest>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = LearnMoreApi;