"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SessionLoginModel model module.
 * @module model/SessionLoginModel
 * @version 1.1.3-alpha.1
 */
class SessionLoginModel {
  /**
   * Constructs a new <code>SessionLoginModel</code>.
   * @alias module:model/SessionLoginModel
   * @param pin {String} The session pin sent to the user as a second step for authentication
   * @param browser_id {String} Browser unique id. A session is opened for each id and must be activated with a 2-step authentication process (an e-mail with an activation id is sent to the user)
   */
  constructor(pin, browser_id) {
    SessionLoginModel.initialize(this, pin, browser_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, pin, browser_id) {
    obj['pin'] = pin;
    obj['browser_id'] = browser_id;
  }

  /**
   * Constructs a <code>SessionLoginModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SessionLoginModel} obj Optional instance to populate.
   * @return {module:model/SessionLoginModel} The populated <code>SessionLoginModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SessionLoginModel();
      if (data.hasOwnProperty('pin')) {
        obj['pin'] = _ApiClient.default.convertToType(data['pin'], 'String');
      }
      if (data.hasOwnProperty('browser_id')) {
        obj['browser_id'] = _ApiClient.default.convertToType(data['browser_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SessionLoginModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SessionLoginModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of SessionLoginModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['pin'] && !(typeof data['pin'] === 'string' || data['pin'] instanceof String)) {
      throw new Error("Expected the field `pin` to be a primitive type in the JSON string but got " + data['pin']);
    }
    // ensure the json data is a string
    if (data['browser_id'] && !(typeof data['browser_id'] === 'string' || data['browser_id'] instanceof String)) {
      throw new Error("Expected the field `browser_id` to be a primitive type in the JSON string but got " + data['browser_id']);
    }
    return true;
  }
}
SessionLoginModel.RequiredProperties = ["pin", "browser_id"];

/**
 * The session pin sent to the user as a second step for authentication
 * @member {String} pin
 */
SessionLoginModel.prototype['pin'] = undefined;

/**
 * Browser unique id. A session is opened for each id and must be activated with a 2-step authentication process (an e-mail with an activation id is sent to the user)
 * @member {String} browser_id
 */
SessionLoginModel.prototype['browser_id'] = undefined;
var _default = exports.default = SessionLoginModel;