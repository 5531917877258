"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _EntityAttachment = _interopRequireDefault(require("../model/EntityAttachment"));
var _EntityOneToMenyReferences = _interopRequireDefault(require("../model/EntityOneToMenyReferences"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Entities service.
* @module api/EntitiesApi
* @version 1.1.3-alpha.1
*/
class EntitiesApi {
  /**
  * Constructs a new EntitiesApi. 
  * @alias module:api/EntitiesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Get entity's attachement
   * Returns the requested entity's attachement
   * @param {String} id Id of the entity whose attachment we request
   * @param {String} identifier Identifier of the attachment we request (it is unique within a entity's attachments)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EntityAttachment} and HTTP response
   */
  getAttachmentWithHttpInfo(id, identifier) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAttachment");
    }
    // verify the required parameter 'identifier' is set
    if (identifier === undefined || identifier === null) {
      throw new Error("Missing the required parameter 'identifier' when calling getAttachment");
    }
    let pathParams = {
      'id': id,
      'identifier': identifier
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _EntityAttachment.default;
    return this.apiClient.callApi('/api/v1/Entities/{id}/attachments/{identifier}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get entity's attachement
   * Returns the requested entity's attachement
   * @param {String} id Id of the entity whose attachment we request
   * @param {String} identifier Identifier of the attachment we request (it is unique within a entity's attachments)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EntityAttachment}
   */
  getAttachment(id, identifier) {
    return this.getAttachmentWithHttpInfo(id, identifier).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get entity's attachments
   * Returns an array of requested entity's attachments
   * @param {String} id Id of the entity whose attachments we request
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.identifiers Identifiers of the requested attachments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/EntityAttachment>} and HTTP response
   */
  getAttachmentsWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAttachments");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'identifiers': this.apiClient.buildCollectionParam(opts['identifiers'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_EntityAttachment.default];
    return this.apiClient.callApi('/api/v1/Entities/{id}/attachments', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get entity's attachments
   * Returns an array of requested entity's attachments
   * @param {String} id Id of the entity whose attachments we request
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.identifiers Identifiers of the requested attachments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/EntityAttachment>}
   */
  getAttachments(id, opts) {
    return this.getAttachmentsWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get refrenced entities (one-to-many) of the requested entity
   * Returns Get refrenced entities (one-to-many) of the requested entity
   * @param {String} id The id of the entity whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EntityOneToMenyReferences} and HTTP response
   */
  getOneToManyReferencesWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getOneToManyReferences");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _EntityOneToMenyReferences.default;
    return this.apiClient.callApi('/api/v1/Entities/{id}/onetomanyreferences', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get refrenced entities (one-to-many) of the requested entity
   * Returns Get refrenced entities (one-to-many) of the requested entity
   * @param {String} id The id of the entity whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EntityOneToMenyReferences}
   */
  getOneToManyReferences(id) {
    return this.getOneToManyReferencesWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = EntitiesApi;