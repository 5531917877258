import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons/faChevronCircleLeft';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faFilePowerpoint } from '@fortawesome/free-solid-svg-icons/faFilePowerpoint';
import { faFileImage } from '@fortawesome/free-solid-svg-icons/faFileImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import { extractClasses } from '../utilities/classname-utils';

export const FA = memo(props => <FontAwesomeIcon {...extractClasses(props, 'fa')} />);

export const FaSpinner = faSpinner;
export const IconSpinner = memo(({ spin, ...props }) => (
	<FA {...props} icon={faSpinner} fixedWidth spin={spin === false ? false : true} />
));
export const IconAngleRight = memo(props => <FA {...props} icon={faAngleRight} fixedWidth />);
export const IconChevronLeft = memo(props => <FA {...props} icon={faChevronCircleLeft} fixedWidth />);
export const IconChevronRight = memo(props => <FA {...props} icon={faChevronCircleRight} fixedWidth />);
export const IconFilePowerpoint = memo(props => <FA {...props} icon={faFilePowerpoint} fixedWidth />);
export const IconFileImage = memo(props => <FA {...props} icon={faFileImage} fixedWidth />);
