"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BaseResult = _interopRequireDefault(require("../model/BaseResult"));
var _BatchCreateMediaSlideCommand = _interopRequireDefault(require("../model/BatchCreateMediaSlideCommand"));
var _BatchUploadMediaSlideAttachmentsCommand = _interopRequireDefault(require("../model/BatchUploadMediaSlideAttachmentsCommand"));
var _CreateCustomerMediaCommand = _interopRequireDefault(require("../model/CreateCustomerMediaCommand"));
var _CreateMediaCommand = _interopRequireDefault(require("../model/CreateMediaCommand"));
var _Customer = _interopRequireDefault(require("../model/Customer"));
var _CustomerIdsParams = _interopRequireDefault(require("../model/CustomerIdsParams"));
var _CustomerQueryResult = _interopRequireDefault(require("../model/CustomerQueryResult"));
var _CustomersQuery = _interopRequireDefault(require("../model/CustomersQuery"));
var _EntityAttachment = _interopRequireDefault(require("../model/EntityAttachment"));
var _EntityCustomerMeta = _interopRequireDefault(require("../model/EntityCustomerMeta"));
var _ExcessiveDownloadResult = _interopRequireDefault(require("../model/ExcessiveDownloadResult"));
var _FeedbackParams = _interopRequireDefault(require("../model/FeedbackParams"));
var _FileIndex = _interopRequireDefault(require("../model/FileIndex"));
var _LastSeasonInfo = _interopRequireDefault(require("../model/LastSeasonInfo"));
var _Media = _interopRequireDefault(require("../model/Media"));
var _MediaOneToMenyReferences = _interopRequireDefault(require("../model/MediaOneToMenyReferences"));
var _MediaQuery = _interopRequireDefault(require("../model/MediaQuery"));
var _MediaQueryResult = _interopRequireDefault(require("../model/MediaQueryResult"));
var _MediaSlide = _interopRequireDefault(require("../model/MediaSlide"));
var _MediaSlideQuery = _interopRequireDefault(require("../model/MediaSlideQuery"));
var _MediaSlideQueryResult = _interopRequireDefault(require("../model/MediaSlideQueryResult"));
var _MetaValueParams = _interopRequireDefault(require("../model/MetaValueParams"));
var _Season = _interopRequireDefault(require("../model/Season"));
var _UpdateCustomerMediaCommand = _interopRequireDefault(require("../model/UpdateCustomerMediaCommand"));
var _UpdateMediaCommand = _interopRequireDefault(require("../model/UpdateMediaCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Media service.
* @module api/MediaApi
* @version 1.1.3-alpha.1
*/
class MediaApi {
  /**
  * Constructs a new MediaApi. 
  * @alias module:api/MediaApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Add multiple attachment files from an upload batch
   * Adds multiple attachment files from a previously uploaded batch
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/BatchUploadMediaSlideAttachmentsCommand} opts.BatchUploadMediaSlideAttachmentsCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediaSlide>} and HTTP response
   */
  addAttachmentsFromBatchWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['BatchUploadMediaSlideAttachmentsCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addAttachmentsFromBatch");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_MediaSlide.default];
    return this.apiClient.callApi('/api/v1/Media/{id}/slides/attachments', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Add multiple attachment files from an upload batch
   * Adds multiple attachment files from a previously uploaded batch
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/BatchUploadMediaSlideAttachmentsCommand} opts.BatchUploadMediaSlideAttachmentsCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediaSlide>}
   */
  addAttachmentsFromBatch(id, opts) {
    return this.addAttachmentsFromBatchWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Adds customers to media availability
   * Adds the specified customers to this media's availability
   * @param {String} id Id of the media to which to add the customers
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerIdsParams} opts.CustomerIdsParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Customer>} and HTTP response
   */
  addCustomersToAvailabilityWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CustomerIdsParams'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addCustomersToAvailability");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Customer.default];
    return this.apiClient.callApi('/api/v1/Media/{id}/customers', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Adds customers to media availability
   * Adds the specified customers to this media's availability
   * @param {String} id Id of the media to which to add the customers
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerIdsParams} opts.CustomerIdsParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Customer>}
   */
  addCustomersToAvailability(id, opts) {
    return this.addCustomersToAvailabilityWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Add multiple slides from an upload batch
   * Adds multiple slides from a previously uploaded batch
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/BatchCreateMediaSlideCommand} opts.BatchCreateMediaSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediaSlide>} and HTTP response
   */
  addSlidesFromBatchWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['BatchCreateMediaSlideCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addSlidesFromBatch");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_MediaSlide.default];
    return this.apiClient.callApi('/api/v1/Media/{id}/slides', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Add multiple slides from an upload batch
   * Adds multiple slides from a previously uploaded batch
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/BatchCreateMediaSlideCommand} opts.BatchCreateMediaSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediaSlide>}
   */
  addSlidesFromBatch(id, opts) {
    return this.addSlidesFromBatchWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Check unlock of full report
   * Checks if the user can unlock a media's full report
   * @param {String} id Id of the media whose full report we want to unlock
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  checkUnlockFullReportWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling checkUnlockFullReport");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/unlock/fullReport/check', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Check unlock of full report
   * Checks if the user can unlock a media's full report
   * @param {String} id Id of the media whose full report we want to unlock
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  checkUnlockFullReport(id) {
    return this.checkUnlockFullReportWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Check unlock of webinar
   * Checks if the user can unlock a media's webinar
   * @param {String} id Id of the media whose webinar we want to unlock
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  checkUnlockWebinarWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling checkUnlockWebinar");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/unlock/webinar/check', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Check unlock of webinar
   * Checks if the user can unlock a media's webinar
   * @param {String} id Id of the media whose webinar we want to unlock
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  checkUnlockWebinar(id) {
    return this.checkUnlockWebinarWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create a new media
   * Creates a new media and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateMediaCommand} opts.CreateMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Media} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateMediaCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Media.default;
    return this.apiClient.callApi('/api/v1/Media', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new media
   * Creates a new media and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateMediaCommand} opts.CreateMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Media}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create a new media for the logged in customer
   * Creates a new media for the logged in customer and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerMediaCommand} opts.CreateCustomerMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Media} and HTTP response
   */
  createMyMediaWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateCustomerMediaCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Media.default;
    return this.apiClient.callApi('/api/v1/Media/mymedia', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new media for the logged in customer
   * Creates a new media for the logged in customer and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerMediaCommand} opts.CreateCustomerMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Media}
   */
  createMyMedia(opts) {
    return this.createMyMediaWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete all slides of media
   * Delete all slides of media
   * @param {String} id Id of the media to delete all slides
   * @param {Boolean} topline Delete topline or not
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  deleteAllSlidesWithHttpInfo(id, topline) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling deleteAllSlides");
    }
    // verify the required parameter 'topline' is set
    if (topline === undefined || topline === null) {
      throw new Error("Missing the required parameter 'topline' when calling deleteAllSlides");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'topline': topline
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/Media/{id}/slides', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete all slides of media
   * Delete all slides of media
   * @param {String} id Id of the media to delete all slides
   * @param {Boolean} topline Delete topline or not
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  deleteAllSlides(id, topline) {
    return this.deleteAllSlidesWithHttpInfo(id, topline).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Exports media in excel format
   * Exports media and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaQuery} opts.MediaQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  exportFileWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['MediaQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/Media/export', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Exports media in excel format
   * Exports media and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaQuery} opts.MediaQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  exportFile(opts) {
    return this.exportFileWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media
   * Returns the requested media
   * @param {String} id Id of the media to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Media} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Media.default;
    return this.apiClient.callApi('/api/v1/Media/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media
   * Returns the requested media
   * @param {String} id Id of the media to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Media}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media's attachement
   * Returns the requested media's attachement
   * @param {String} id Id of the media whose attachment we request
   * @param {String} identifier Identifier of the attachment we request (it is unique within a media's attachments)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EntityAttachment} and HTTP response
   */
  getAttachmentWithHttpInfo(id, identifier) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAttachment");
    }
    // verify the required parameter 'identifier' is set
    if (identifier === undefined || identifier === null) {
      throw new Error("Missing the required parameter 'identifier' when calling getAttachment");
    }
    let pathParams = {
      'id': id,
      'identifier': identifier
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _EntityAttachment.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/attachments/{identifier}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media's attachement
   * Returns the requested media's attachement
   * @param {String} id Id of the media whose attachment we request
   * @param {String} identifier Identifier of the attachment we request (it is unique within a media's attachments)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EntityAttachment}
   */
  getAttachment(id, identifier) {
    return this.getAttachmentWithHttpInfo(id, identifier).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media's attachments
   * Returns an array of requested media's attachments
   * @param {String} id Id of the media whose attachments we request
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.identifiers Identifiers of the requested attachments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/EntityAttachment>} and HTTP response
   */
  getAttachmentsWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAttachments");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'identifiers': this.apiClient.buildCollectionParam(opts['identifiers'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_EntityAttachment.default];
    return this.apiClient.callApi('/api/v1/Media/{id}/attachments', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media's attachments
   * Returns an array of requested media's attachments
   * @param {String} id Id of the media whose attachments we request
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.identifiers Identifiers of the requested attachments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/EntityAttachment>}
   */
  getAttachments(id, opts) {
    return this.getAttachmentsWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested media. The action can be performed by the logged in customer or contact
   * @param {String} id Id of the media whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Object}>} and HTTP response
   */
  getCustomerMetaWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getCustomerMeta");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'meta': this.apiClient.buildCollectionParam(opts['meta'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': Object
    };
    return this.apiClient.callApi('/api/v1/Media/{id}/meta/customer', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested media. The action can be performed by the logged in customer or contact
   * @param {String} id Id of the media whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Object}>}
   */
  getCustomerMeta(id, opts) {
    return this.getCustomerMetaWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested media. The action can be performed by the logged in customer or contact
   * @param {Object} opts Optional parameters
   * @param {Object.<String, {String: [String]}>} opts.request_body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: {String: Object}}>} and HTTP response
   */
  getCustomerMetaMultipleWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = {
      'String': {
        'String': Object
      }
    };
    return this.apiClient.callApi('/api/v1/Media/meta/customer', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested media. The action can be performed by the logged in customer or contact
   * @param {Object} opts Optional parameters
   * @param {Object.<String, {String: [String]}>} opts.request_body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: {String: Object}}>}
   */
  getCustomerMetaMultiple(opts) {
    return this.getCustomerMetaMultipleWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media excessive slide download
   * Returns if the user has downloaded excessively in this media.
   * @param {String} id Id of the media whose excessive slide download we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExcessiveDownloadResult} and HTTP response
   */
  getExcessiveDownloadWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getExcessiveDownload");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ExcessiveDownloadResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/excessivedownload', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media excessive slide download
   * Returns if the user has downloaded excessively in this media.
   * @param {String} id Id of the media whose excessive slide download we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExcessiveDownloadResult}
   */
  getExcessiveDownload(id) {
    return this.getExcessiveDownloadWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get the last season of the study this media belongs to
   * Returns the last season of the study this media belongs to
   * @param {String} id The id of the media whose seasons to get the last
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LastSeasonInfo} and HTTP response
   */
  getLastSeasonWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getLastSeason");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _LastSeasonInfo.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/lastseason', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get the last season of the study this media belongs to
   * Returns the last season of the study this media belongs to
   * @param {String} id The id of the media whose seasons to get the last
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LastSeasonInfo}
   */
  getLastSeason(id) {
    return this.getLastSeasonWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a specific media list
   * Returns an array of the media included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Media>} and HTTP response
   */
  getListWithHttpInfo(listName) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling getList");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Media.default];
    return this.apiClient.callApi('/api/v1/Media/list/{listName}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a specific media list
   * Returns an array of the media included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Media>}
   */
  getList(listName) {
    return this.getListWithHttpInfo(listName).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media lists
   * Returns a dictionary of listnames to media lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: [Media]}>} and HTTP response
   */
  getListsWithHttpInfo(names) {
    let postBody = null;
    // verify the required parameter 'names' is set
    if (names === undefined || names === null) {
      throw new Error("Missing the required parameter 'names' when calling getLists");
    }
    let pathParams = {};
    let queryParams = {
      'names': this.apiClient.buildCollectionParam(names, 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': [_Media.default]
    };
    return this.apiClient.callApi('/api/v1/Media/lists', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media lists
   * Returns a dictionary of listnames to media lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: [Media]}>}
   */
  getLists(names) {
    return this.getListsWithHttpInfo(names).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media
   * Returns an array of requested media
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the media to get. Not specifiying ids will return all media
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Media>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Media.default];
    return this.apiClient.callApi('/api/v1/Media/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media
   * Returns an array of requested media
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the media to get. Not specifiying ids will return all media
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Media>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get refrenced entities (one-to-many) of the requested media
   * Returns Get refrenced entities (one-to-many) of the requested media
   * @param {String} id The id of the media whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaOneToMenyReferences} and HTTP response
   */
  getOneToManyReferencesWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getOneToManyReferences");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _MediaOneToMenyReferences.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/onetomanyreferences', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get refrenced entities (one-to-many) of the requested media
   * Returns Get refrenced entities (one-to-many) of the requested media
   * @param {String} id The id of the media whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaOneToMenyReferences}
   */
  getOneToManyReferences(id) {
    return this.getOneToManyReferencesWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media referencing the requested id by a certain field
   * Returns a list of media that are referencing the requested id by a certain field
   * @param {String} field The field of the media that is referencing the requested id
   * @param {String} id Id of the media that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Media>} and HTTP response
   */
  getReferencesWithHttpInfo(field, id) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error("Missing the required parameter 'field' when calling getReferences");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getReferences");
    }
    let pathParams = {
      'field': field,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Media.default];
    return this.apiClient.callApi('/api/v1/Media/references/{field}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media referencing the requested id by a certain field
   * Returns a list of media that are referencing the requested id by a certain field
   * @param {String} field The field of the media that is referencing the requested id
   * @param {String} id Id of the media that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Media>}
   */
  getReferences(field, id) {
    return this.getReferencesWithHttpInfo(field, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get the seasons of the given study
   * Returns a list of the seasons of the given study ordered by season number
   * @param {String} id The id of the media whose seasons to get the last
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Season>} and HTTP response
   */
  getSeasonsWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getSeasons");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Season.default];
    return this.apiClient.callApi('/api/v1/Media/{id}/seasons', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get the seasons of the given study
   * Returns a list of the seasons of the given study ordered by season number
   * @param {String} id The id of the media whose seasons to get the last
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Season>}
   */
  getSeasons(id) {
    return this.getSeasonsWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media's metadata (in user's scope)
   * Gets one, many or all user-scoped metadata for the requested media. The action can be performed by the logged in user
   * @param {String} id Id of the media whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Object}>} and HTTP response
   */
  getUserMetaWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getUserMeta");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'meta': this.apiClient.buildCollectionParam(opts['meta'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': Object
    };
    return this.apiClient.callApi('/api/v1/Media/{id}/meta/user', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media's metadata (in user's scope)
   * Gets one, many or all user-scoped metadata for the requested media. The action can be performed by the logged in user
   * @param {String} id Id of the media whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Object}>}
   */
  getUserMeta(id, opts) {
    return this.getUserMetaWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media's metadata (in user's scope)
   * Gets one, many or all user-scoped metadata for the requested media. The action can be performed by the logged in user or contact
   * @param {Object} opts Optional parameters
   * @param {Object.<String, {String: [String]}>} opts.request_body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: {String: Object}}>} and HTTP response
   */
  getUserMetaMultipleWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = {
      'String': {
        'String': Object
      }
    };
    return this.apiClient.callApi('/api/v1/Media/meta/user', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media's metadata (in user's scope)
   * Gets one, many or all user-scoped metadata for the requested media. The action can be performed by the logged in user or contact
   * @param {Object} opts Optional parameters
   * @param {Object.<String, {String: [String]}>} opts.request_body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: {String: Object}}>}
   */
  getUserMetaMultiple(opts) {
    return this.getUserMetaMultipleWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Give feedback about a media
   * Submits a feedback form with comments and ideas
   * @param {String} id Id of the media for which to give feedback
   * @param {Object} opts Optional parameters
   * @param {module:model/FeedbackParams} opts.FeedbackParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  giveFeedbackWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['FeedbackParams'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling giveFeedback");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/feedback', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Give feedback about a media
   * Submits a feedback form with comments and ideas
   * @param {String} id Id of the media for which to give feedback
   * @param {Object} opts Optional parameters
   * @param {module:model/FeedbackParams} opts.FeedbackParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  giveFeedback(id, opts) {
    return this.giveFeedbackWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query media
   * Queries media and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaQuery} opts.MediaQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['MediaQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _MediaQueryResult.default;
    return this.apiClient.callApi('/api/v1/Media/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query media
   * Queries media and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaQuery} opts.MediaQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query media's customers (the customers to whom this media is available)
   * Queries the specified media's customer and returns a filtered, sorted and paged result. This is equivalent to querying customers directly and adding an extra filter for available_to_media_id
   * @param {String} id Id of the media whose customers to query
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomersQuery} opts.CustomersQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerQueryResult} and HTTP response
   */
  queryCustomersWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CustomersQuery'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling queryCustomers");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _CustomerQueryResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/customers/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query media's customers (the customers to whom this media is available)
   * Queries the specified media's customer and returns a filtered, sorted and paged result. This is equivalent to querying customers directly and adding an extra filter for available_to_media_id
   * @param {String} id Id of the media whose customers to query
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomersQuery} opts.CustomersQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerQueryResult}
   */
  queryCustomers(id, opts) {
    return this.queryCustomersWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query media's slides
   * Queries the specified media's slide and returns a filtered, sorted and paged result. This is equivalent to querying slides directly and adding an extra filter for media id
   * @param {String} id Id of the media whose slides to query
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaSlideQuery} opts.MediaSlideQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaSlideQueryResult} and HTTP response
   */
  querySlidesWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['MediaSlideQuery'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling querySlides");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _MediaSlideQueryResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/slides/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query media's slides
   * Queries the specified media's slide and returns a filtered, sorted and paged result. This is equivalent to querying slides directly and adding an extra filter for media id
   * @param {String} id Id of the media whose slides to query
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaSlideQuery} opts.MediaSlideQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaSlideQueryResult}
   */
  querySlides(id, opts) {
    return this.querySlidesWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete media
   * Deletes a media and returns the deleted object
   * @param {String} id Id of the media to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Media} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Media.default;
    return this.apiClient.callApi('/api/v1/Media/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete media
   * Deletes a media and returns the deleted object
   * @param {String} id Id of the media to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Media}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Removes customers from media availability
   * Removes the specified customers from this media's availability
   * @param {String} id Id of the media from which to remove the customers
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerIdsParams} opts.CustomerIdsParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Customer>} and HTTP response
   */
  removeCustomersFromAvailabilityWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CustomerIdsParams'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling removeCustomersFromAvailability");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Customer.default];
    return this.apiClient.callApi('/api/v1/Media/{id}/customers', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Removes customers from media availability
   * Removes the specified customers from this media's availability
   * @param {String} id Id of the media from which to remove the customers
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerIdsParams} opts.CustomerIdsParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Customer>}
   */
  removeCustomersFromAvailability(id, opts) {
    return this.removeCustomersFromAvailabilityWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple media
   * Deletes multiple media and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the media to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Media>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Media.default];
    return this.apiClient.callApi('/api/v1/Media/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple media
   * Deletes multiple media and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the media to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Media>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Send a section unlock request to the contact's administrator
   * Sends a section unlock request to the contact's administrator for the media section requested
   * @param {String} id Id of the media for which we request a section unlock
   * @param {String} section The section of the media to request unlock ('fullReport' or 'webinar')
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  requestUnlockWithHttpInfo(id, section) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling requestUnlock");
    }
    // verify the required parameter 'section' is set
    if (section === undefined || section === null) {
      throw new Error("Missing the required parameter 'section' when calling requestUnlock");
    }
    let pathParams = {
      'id': id,
      'section': section
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/requestunlock/{section}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Send a section unlock request to the contact's administrator
   * Sends a section unlock request to the contact's administrator for the media section requested
   * @param {String} id Id of the media for which we request a section unlock
   * @param {String} section The section of the media to request unlock ('fullReport' or 'webinar')
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  requestUnlock(id, section) {
    return this.requestUnlockWithHttpInfo(id, section).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Set media's metadata (in customer's scope)
   * Sets a customer-scoped metadata for the requested media. The action can be performed only by the customer for whom the metadata are set
   * @param {String} id Id of the media whose metadata we set
   * @param {String} meta Metadata name to set
   * @param {Object} opts Optional parameters
   * @param {module:model/MetaValueParams} opts.MetaValueParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EntityCustomerMeta} and HTTP response
   */
  setCustomerMetaWithHttpInfo(id, meta, opts) {
    opts = opts || {};
    let postBody = opts['MetaValueParams'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling setCustomerMeta");
    }
    // verify the required parameter 'meta' is set
    if (meta === undefined || meta === null) {
      throw new Error("Missing the required parameter 'meta' when calling setCustomerMeta");
    }
    let pathParams = {
      'id': id,
      'meta': meta
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _EntityCustomerMeta.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/meta/customer/{meta}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Set media's metadata (in customer's scope)
   * Sets a customer-scoped metadata for the requested media. The action can be performed only by the customer for whom the metadata are set
   * @param {String} id Id of the media whose metadata we set
   * @param {String} meta Metadata name to set
   * @param {Object} opts Optional parameters
   * @param {module:model/MetaValueParams} opts.MetaValueParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EntityCustomerMeta}
   */
  setCustomerMeta(id, meta, opts) {
    return this.setCustomerMetaWithHttpInfo(id, meta, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Unlocks a media's full report
   * Unlocks a media's full report
   * @param {String} id Id of the media whose metadata we set
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  unlockFullReportWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling unlockFullReport");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/unlock/fullReport', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Unlocks a media's full report
   * Unlocks a media's full report
   * @param {String} id Id of the media whose metadata we set
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  unlockFullReport(id) {
    return this.unlockFullReportWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Unlocks a media's webinar
   * Unlocks a media's webinar
   * @param {String} id Id of the media whose metadata we set
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  unlockWebinarWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling unlockWebinar");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Media/{id}/unlock/webinar', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Unlocks a media's webinar
   * Unlocks a media's webinar
   * @param {String} id Id of the media whose metadata we set
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  unlockWebinar(id) {
    return this.unlockWebinarWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update media
   * Updates a media and returns the updated object
   * @param {String} id Id of the media to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMediaCommand} opts.UpdateMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Media} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateMediaCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Media.default;
    return this.apiClient.callApi('/api/v1/Media/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update media
   * Updates a media and returns the updated object
   * @param {String} id Id of the media to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMediaCommand} opts.UpdateMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Media}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Updates an existing media for the logged in customer
   * Updates an existing media for the logged in customer and returns the created object
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerMediaCommand} opts.UpdateCustomerMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Media} and HTTP response
   */
  updateMyMediaWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateCustomerMediaCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling updateMyMedia");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _Media.default;
    return this.apiClient.callApi('/api/v1/Media/mymedia/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Updates an existing media for the logged in customer
   * Updates an existing media for the logged in customer and returns the created object
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerMediaCommand} opts.UpdateCustomerMediaCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Media}
   */
  updateMyMedia(id, opts) {
    return this.updateMyMediaWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Upload media files
   * Uploads files to the specified media
   * @param {String} id Id of the media to upload files to
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FileIndex>} and HTTP response
   */
  uploadFilesWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling uploadFiles");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'files': this.apiClient.buildCollectionParam(opts['files'], 'passthrough')
    };
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['application/json'];
    let returnType = [_FileIndex.default];
    return this.apiClient.callApi('/api/v1/Media/{id}/files', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Upload media files
   * Uploads files to the specified media
   * @param {String} id Id of the media to upload files to
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FileIndex>}
   */
  uploadFiles(id, opts) {
    return this.uploadFilesWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = MediaApi;