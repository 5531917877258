"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActivationName = exports.getActivationKey = exports.default = exports.ActivationValues = exports.ActivationName = exports.ActivationKeyName = exports.ActivationItems = exports.ACTIVATION_VERY_LOW = exports.ACTIVATION_VERY_HIGH = exports.ACTIVATION_MEDIUM = exports.ACTIVATION_LOW = exports.ACTIVATION_HIGH = void 0;
const ACTIVATION_VERY_LOW = 0;
exports.ACTIVATION_VERY_LOW = ACTIVATION_VERY_LOW;
const ACTIVATION_LOW = 1;
exports.ACTIVATION_LOW = ACTIVATION_LOW;
const ACTIVATION_MEDIUM = 2;
exports.ACTIVATION_MEDIUM = ACTIVATION_MEDIUM;
const ACTIVATION_HIGH = 3;
exports.ACTIVATION_HIGH = ACTIVATION_HIGH;
const ACTIVATION_VERY_HIGH = 4;
exports.ACTIVATION_VERY_HIGH = ACTIVATION_VERY_HIGH;
const ActivationName = {
  [ACTIVATION_VERY_LOW]: 'Very Low',
  [ACTIVATION_LOW]: 'Low',
  [ACTIVATION_MEDIUM]: 'Medium',
  [ACTIVATION_HIGH]: 'High',
  [ACTIVATION_VERY_HIGH]: 'Very High'
};
exports.ActivationName = ActivationName;
const ActivationKeyName = {
  [ACTIVATION_VERY_LOW]: 'very_low',
  [ACTIVATION_LOW]: 'low',
  [ACTIVATION_MEDIUM]: 'medium',
  [ACTIVATION_HIGH]: 'high',
  [ACTIVATION_VERY_HIGH]: 'very_high'
};
exports.ActivationKeyName = ActivationKeyName;
const getActivationName = id => ActivationName[id] || '-';
exports.getActivationName = getActivationName;
const getActivationKey = id => ActivationKeyName[id] || '-';
exports.getActivationKey = getActivationKey;
const ActivationValues = [ACTIVATION_VERY_LOW, ACTIVATION_LOW, ACTIVATION_MEDIUM, ACTIVATION_HIGH, ACTIVATION_VERY_HIGH];
exports.ActivationValues = ActivationValues;
const ActivationItems = ActivationValues.map(id => ({
  id: id,
  name: ActivationName[id],
  keyText: ActivationKeyName[id]
}));
exports.ActivationItems = ActivationItems;
var _default = {
  VERY_LOW: ACTIVATION_VERY_LOW,
  LOW: ACTIVATION_LOW,
  MEDIUM: ACTIVATION_MEDIUM,
  HIGH: ACTIVATION_HIGH,
  VERY_HIGH: ACTIVATION_VERY_HIGH,
  Values: ActivationValues,
  Items: ActivationItems,
  Name: ActivationName,
  KeyName: ActivationKeyName,
  getName: getActivationName,
  getKey: getActivationKey
};
exports.default = _default;