import { reducer as notificationsReducer } from 'react-notification-system-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createReduxPromiseListener from 'redux-promise-listener';
import createSagaMiddleware from 'redux-saga';
import { initializeAuth } from '../flows/auth';
import { rootSaga } from '../flows/root-watcher';
import { routerMiddleware, routerReducer } from '../routing-setup';
import { authReducer } from './auth';
import { indexDataReducer } from './index-data';
import { learnMoreReducer } from './learn-more';
import { isLoadingReducer } from './loading';
import { modalsReducer } from './modals';
import { createResourceReducers } from './resources';
import { signInReducer } from './sign-in';
import { userSettingsReducer } from './user-settings';

const composeNotNull = (...args) => compose(...args.filter(a => a !== null && a !== undefined));
const reduxPromiseListener = createReduxPromiseListener();

export const getReduxPromiseListener = () => reduxPromiseListener;

export const createWisdropStore = ({ state, initialize, saga } = {}) => {
	var sagaMiddleware = createSagaMiddleware(saga);

	var store = createStore(
		combineReducers({
			router: routerReducer,
			learnMore: learnMoreReducer,
			userSettings: userSettingsReducer,
			signIn: signInReducer,
			auth: authReducer,
			loadingState: isLoadingReducer,
			modals: modalsReducer,
			indexes: indexDataReducer,
			notifications: notificationsReducer,
			resources: combineReducers(createResourceReducers())
		}),
		initialize ? initialize() : state,
		composeNotNull(
			applyMiddleware(routerMiddleware, reduxPromiseListener.middleware, sagaMiddleware),
			window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	);

	sagaMiddleware.run(rootSaga);
	sagaMiddleware.run(initializeAuth);
	return store;
};

export const getStateResources = state => state.resources;
export const getResourceSlice = (state, resourceType) => state.resources[resourceType];
