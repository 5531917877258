"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunEnvelopeInfo model module.
 * @module model/MailgunEnvelopeInfo
 * @version 1.1.3-alpha.1
 */
class MailgunEnvelopeInfo {
  /**
   * Constructs a new <code>MailgunEnvelopeInfo</code>.
   * @alias module:model/MailgunEnvelopeInfo
   */
  constructor() {
    MailgunEnvelopeInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunEnvelopeInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunEnvelopeInfo} obj Optional instance to populate.
   * @return {module:model/MailgunEnvelopeInfo} The populated <code>MailgunEnvelopeInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunEnvelopeInfo();
      if (data.hasOwnProperty('transport')) {
        obj['transport'] = _ApiClient.default.convertToType(data['transport'], 'String');
      }
      if (data.hasOwnProperty('sender')) {
        obj['sender'] = _ApiClient.default.convertToType(data['sender'], 'String');
      }
      if (data.hasOwnProperty('sending-ip')) {
        obj['sending-ip'] = _ApiClient.default.convertToType(data['sending-ip'], 'String');
      }
      if (data.hasOwnProperty('targets')) {
        obj['targets'] = _ApiClient.default.convertToType(data['targets'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunEnvelopeInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunEnvelopeInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['transport'] && !(typeof data['transport'] === 'string' || data['transport'] instanceof String)) {
      throw new Error("Expected the field `transport` to be a primitive type in the JSON string but got " + data['transport']);
    }
    // ensure the json data is a string
    if (data['sender'] && !(typeof data['sender'] === 'string' || data['sender'] instanceof String)) {
      throw new Error("Expected the field `sender` to be a primitive type in the JSON string but got " + data['sender']);
    }
    // ensure the json data is a string
    if (data['sending-ip'] && !(typeof data['sending-ip'] === 'string' || data['sending-ip'] instanceof String)) {
      throw new Error("Expected the field `sending-ip` to be a primitive type in the JSON string but got " + data['sending-ip']);
    }
    // ensure the json data is a string
    if (data['targets'] && !(typeof data['targets'] === 'string' || data['targets'] instanceof String)) {
      throw new Error("Expected the field `targets` to be a primitive type in the JSON string but got " + data['targets']);
    }
    return true;
  }
}

/**
 * @member {String} transport
 */
MailgunEnvelopeInfo.prototype['transport'] = undefined;

/**
 * @member {String} sender
 */
MailgunEnvelopeInfo.prototype['sender'] = undefined;

/**
 * @member {String} sending-ip
 */
MailgunEnvelopeInfo.prototype['sending-ip'] = undefined;

/**
 * @member {String} targets
 */
MailgunEnvelopeInfo.prototype['targets'] = undefined;
var _default = exports.default = MailgunEnvelopeInfo;