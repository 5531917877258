import ReactGA from 'react-ga';
import Session from '../api';
import { BufferedResourceReads } from '../flows/buffered-read-context';
import { createWisdropStore } from './store';

const { tokenPersistor, initialToken, api } = Session;
const readBuffer = new BufferedResourceReads();
const initialState = {
	auth: {
		apiKey: initialToken
	}
};

export const store = createWisdropStore({
	state: initialState,
	saga: {
		context: {
			api: api,
			tokenPersisor: tokenPersistor,
			readBuffer: readBuffer,
			ga: ReactGA
		}
	}
});
