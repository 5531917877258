import { createReducer } from './utils';

// action types
export const SIGN_IN_MODAL_TOGGLE_OPEN = 'SIGN_IN_MODAL_TOGGLE_OPEN';
export const SIGN_IN_MODAL_CLOSE = 'SIGN_IN_MODAL_CLOSE';
export const SIGN_IN_FORGOT_PASSWORD = 'SIGN_IN_FORGOT_PASSWORD';
export const SIGN_IN_SUBMIT = 'SIGN_IN_SUBMIT';
export const SIGN_IN_SUBMIT_RESOLVED = 'SIGN_IN_SUBMIT_RESOLVED';

export const SIGN_IN_PICK_USER_MODAL_TOGGLE_OPEN = 'SIGN_IN_PICK_USER_MODAL_TOGGLE_OPEN';
export const SIGN_IN_PICK_USER_MODAL_CLOSE = 'SIGN_IN_PICK_USER_MODAL_CLOSE';
export const SIGN_IN_PICK_USER_SUBMIT = 'SIGN_IN_PICK_USER_SUBMIT';
export const SIGN_IN_PICK_USER_SUBMIT_RESOLVED = 'SIGN_IN_PICK_USER_SUBMIT_RESOLVED';

export const RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD_SUBMIT';
export const RESET_PASSWORD_SUBMIT_RESOLVED = 'RESET_PASSWORD_SUBMIT_RESOLVED';

export const SIGN_IN_MODAL_STATE_FORM = 'FORM';
export const SIGN_IN_MODAL_STATE_SUCCESS = 'SUCCESS';
export const SIGN_IN_MODAL_STATE_FAILURE = 'FAILURE';

export const signInModalClose = () => ({ type: SIGN_IN_MODAL_CLOSE, payload: {} });
export const signInToggleOpen = ({ error, parameters } = {}) => ({
	type: SIGN_IN_MODAL_TOGGLE_OPEN,
	payload: { error, parameters }
});
export const signInSubmit = values => {
	console.log('Creating submit action', values);
	return { type: SIGN_IN_SUBMIT, payload: values };
};
export const signInSubmitResolved = error => {
	var action = { type: SIGN_IN_SUBMIT_RESOLVED };
	if (error !== undefined) action.payload = error;
	return action;
};

export const signInPickUserModalClose = () => ({ type: SIGN_IN_PICK_USER_MODAL_CLOSE, payload: {} });
export const signInPickUserToggleOpen = ({ error } = {}) => ({
	type: SIGN_IN_PICK_USER_MODAL_TOGGLE_OPEN,
	payload: { error }
});
export const signInPickUserSubmit = values => ({ type: SIGN_IN_PICK_USER_SUBMIT, payload: values });
export const signInPickUserSubmitResolved = error => {
	var action = { type: SIGN_IN_PICK_USER_SUBMIT_RESOLVED };
	if (error !== undefined) action.payload = error;
	return action;
};

export const signInForgotPasswordRequest = email => ({
	type: SIGN_IN_FORGOT_PASSWORD,
	payload: { email: email }
});

const initialState = {
	modalIsOpen: false,
	modalState: null,
	error: null,
	submitting: false,
	modalParameters: null,

	pickUserModalIsOpen: false,
	pickUserModalState: null,
	pickUserError: null,
	pickUserSubmitting: false
};

// selectors
export const getSignInModalIsOpen = state => !!state.signIn.modalIsOpen;
export const getSignInModalState = state => state.signIn.modalState;
export const getSignInModalParameters = state => state.signIn.modalParameters;
export const getSignInSubmitting = state => !!state.signIn.submitting;
export const getSignInSubmitResult = state => state.signIn.result;
export const getSignInSubmitError = state => state.signIn.error;

export const getSignInPickUserModalIsOpen = state => !!state.signIn?.pickUserModalIsOpen;
export const getSignInPickUserModalState = state => state.signIn.pickUserModalState;
export const getSignInPickUserSubmitting = state => !!state.signIn.pickUserSubmitting;
export const getSignInPickUserSubmitResult = state => state.signIn.pickUserResult;
export const getSignInPickUserSubmitError = state => state.signIn.pickUserError;

export const signInReducer = createReducer(
	{
		[SIGN_IN_MODAL_TOGGLE_OPEN]: (state, { error = null, parameters = null } = {}) => {
			let newIsOpen = !state.modalIsOpen;
			return {
				...state,
				error: error,
				modalIsOpen: !state.modalIsOpen,
				modalState: newIsOpen ? SIGN_IN_MODAL_STATE_FORM : state.modalState,
				modalParameters: parameters
			};
		},
		[SIGN_IN_MODAL_CLOSE]: state => ({
			...state,
			error: null,
			submitting: false,
			modalIsOpen: false,
			modalParameters: null
		}),
		[SIGN_IN_SUBMIT]: state => ({ ...state, error: null, submitting: true }),
		[SIGN_IN_SUBMIT_RESOLVED]: (state, error) => {
			return {
				...state,
				modalIsOpen: !!error,
				error: error,
				submitting: false,
				modalParameters: null,
				modalState: error ? SIGN_IN_MODAL_STATE_FAILURE : SIGN_IN_MODAL_STATE_SUCCESS
			};
		},

		[SIGN_IN_PICK_USER_MODAL_TOGGLE_OPEN]: (state, { error = null } = {}) => {
			let newIsOpen = !state.pickUserModalIsOpen;
			return {
				...state,
				pickUserError: error,
				pickUserModalIsOpen: !state.pickUserModalIsOpen,
				pickUserModalState: newIsOpen ? SIGN_IN_MODAL_STATE_FORM : state.pickUserModalState
			};
		},
		[SIGN_IN_PICK_USER_MODAL_CLOSE]: state => ({
			...state,
			pickUserError: null,
			pickUserSubmitting: false,
			pickUserModalIsOpen: false
		}),
		[SIGN_IN_PICK_USER_SUBMIT]: state => ({ ...state, pickUserError: null, pickUserSubmitting: true }),
		[SIGN_IN_PICK_USER_SUBMIT_RESOLVED]: (state, error) => {
			return {
				...state,
				pickUserModalIsOpen: !!error,
				pickUserError: error,
				pickUserSubmitting: false,
				pickUserModalState: error ? SIGN_IN_MODAL_STATE_FAILURE : SIGN_IN_MODAL_STATE_SUCCESS
			};
		}
	},
	{
		state: initialState
	}
);
