import { normalized } from '@extend/paywall-api/lib';
import { AD_SCORES, USER_STATS } from '@extend/paywall-api/lib/resource-types';
import createResourceActions from 'redux-resource-action-creators';
import { call, cancel, delay, fork, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { getIndexDataActionCreators, getIndexDataState } from '../state/index-data';
import { wrapInLoading } from '../state/loading';
import { CRUD_ACTION } from '../state/resource-constants';
import { normalizrToReduxResource } from '../utils';
import { notifyError } from './flow-utils';

export const INDEX_AD_SCORES = 'adscoreIndex';
export const INDEX_USER_STATS = 'userStatsIndex';
export const INDEX_REPORT_STATS = 'reportStatsIndex';

const indexResourceMap = {
	[INDEX_AD_SCORES]: AD_SCORES,
	[INDEX_USER_STATS]: USER_STATS
};

export const INDEX_DATA_FETCH_QUEUE = 'INDEX_DATA_FETCH_QUEUE';
export const queueIndexFetch = ({
	namespace,
	sorting = null,
	filter = null,
	offset = 0,
	take = null,
	delay = 0,
	replace = true
} = {}) => ({
	type: INDEX_DATA_FETCH_QUEUE,
	payload: { namespace, sorting, filter, offset, take, delay, replace }
});

var fetchesPending = {}; // holds pending (delayed) fetch actions per namespace
var fetchIds = {}; // holds an autoincrement number per fetch
const getFetchId = namespace => {
	var result = fetchIds[namespace];
	if (result == null) fetchIds[namespace] = result = 0;
	return result;
};

const getNextFetchId = namespace => {
	var result = fetchIds[namespace];
	if (result == null) result = 0;
	result = fetchIds[namespace] = result + 1;
	return result;
};

const indexDataFetchFinalWorker = wrapInLoading(function* indexDataFetchFinalWorker({
	namespace,
	sorting: requestedSorting,
	filter: requestedFilter,
	offset: skip,
	take,
	resource,
	replace
}) {
	var fetchId = getNextFetchId(namespace);
	var { fetchPending, fetchFailed, fetchSucceeded } = getIndexDataActionCreators(namespace);
	try {
		// get current state of index
		var sorting = requestedSorting;
		if (!sorting) sorting = yield select(state => getIndexDataState(state.indexes, namespace, 'sorting'));
		var filter = requestedFilter;
		if (!filter) filter = yield select(state => getIndexDataState(state.indexes, namespace, 'filter'));
		if (take === null) {
			var { pageSize } = yield select(state => getIndexDataState(state.indexes, namespace, 'paging'));
			take = pageSize;
		}

		var queryParam = {
			skip: skip,
			take: take,
			sorting: sorting,
			filter: filter
		};

		let api = yield getContext('api');
		yield put(fetchPending());

		var { items, total, offset } = yield call(api.resourceQuery, resource, queryParam);
		var currentFetchId = getFetchId(namespace);
		if (currentFetchId === fetchId) {
			var ids =
				resource === AD_SCORES
					? (items || []).map(i => {
							return {
								id: i.id,
								filtered_ranking: i.filtered_ranking
							};
					  })
					: (items || []).map(i => i.id);
			if (resource === AD_SCORES) {
				// remove filtered_ranking
				items = items.map(item => {
					var { filtered_ranking, ...rest } = item;
					return rest;
				});
			}
			// update resources in resource index
			let data = normalized(items, resource);
			var { succeeded: resourceSucceeded } = createResourceActions(CRUD_ACTION.READ, {
				resourceType: resource,
				resources: resource === AD_SCORES ? ids.map(i => i.id) : ids
			});
			yield put(resourceSucceeded(normalizrToReduxResource(resource, data)));
			// update data index
			yield put(
				fetchSucceeded({
					data: ids,
					offset: offset,
					pageSize: queryParam.take,
					total: total,
					sorting: queryParam.sorting,
					filter: queryParam.filter,
					replace
				})
			);
		}
	} catch (e) {
		yield notifyError(e);
		yield put(fetchFailed({ error: e }));
	}
});

function* indexDataBeginFetchDelayer({ namespace, delay: delayMs, ...rest }) {
	try {
		if (delayMs > 0) yield delay(delayMs);
		if (fetchesPending[namespace]) delete fetchesPending[namespace];
		yield fork(indexDataFetchFinalWorker, { namespace, ...rest });
	} catch (e) {
		yield notifyError(e);
	}
}

function* indexDataBeginFetchWorker({
	payload: { namespace, sorting = null, filter = null, offset = 0, take = null, delay = 0, replace = true } = {}
} = {}) {
	if (namespace) {
		var { fetchFailed } = getIndexDataActionCreators(namespace);
		var resource = indexResourceMap[namespace];
		if (resource) {
			try {
				var pendingFetch = fetchesPending[namespace];
				if (pendingFetch) {
					delete fetchesPending[namespace];
					yield cancel(pendingFetch);
				}
				fetchesPending[namespace] = yield fork(indexDataBeginFetchDelayer, {
					namespace,
					sorting,
					filter,
					offset,
					take,
					resource,
					delay,
					replace
				});
			} catch (e) {
				yield notifyError(e);
				yield put(fetchFailed({ error: e }));
			}
		}
	}
}

export function* indexDataFlowWatcher() {
	yield takeEvery(INDEX_DATA_FETCH_QUEUE, indexDataBeginFetchWorker);
}
