import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createReducer } from './utils';

// action types
export const USER_SETTING_AUTOADVANCE_TOGGLE = 'USER_SETTING_AUTOADVANCE_TOGGLE';

export const actionUserSettingAutoAdvanceToggle = () => ({ type: USER_SETTING_AUTOADVANCE_TOGGLE });

const initialState = {
	autoAdvance: true
};

// selectors
export const getAutoAdvance = state => !!state.userSettings.autoAdvance;

export const userSettingsReducer = createReducer(
	{
		[USER_SETTING_AUTOADVANCE_TOGGLE]: state => {
			return {
				...state,
				autoAdvance: !state.autoAdvance
			};
		}
	},
	{
		state: initialState
	}
);

export const useUserSetting = setting => useSelector(state => state.userSettings[setting]);
export const useUserSettingAutoAdvance = () => {
	const dispatch = useDispatch();
	const value = useSelector(getAutoAdvance);
	const toggleValue = useCallback(() => dispatch(actionUserSettingAutoAdvanceToggle()), [dispatch]);
	return [value, toggleValue];
};
