"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CreateKeyTakeAwayModel = _interopRequireDefault(require("../model/CreateKeyTakeAwayModel"));
var _KeyTakeAway = _interopRequireDefault(require("../model/KeyTakeAway"));
var _KeyTakeAwayQuery = _interopRequireDefault(require("../model/KeyTakeAwayQuery"));
var _KeyTakeAwayQueryResult = _interopRequireDefault(require("../model/KeyTakeAwayQueryResult"));
var _MediaKeyTakeAwaysModel = _interopRequireDefault(require("../model/MediaKeyTakeAwaysModel"));
var _UpdateKeyTakeAwayModel = _interopRequireDefault(require("../model/UpdateKeyTakeAwayModel"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* KeyTakeAways service.
* @module api/KeyTakeAwaysApi
* @version 1.1.3-alpha.1
*/
class KeyTakeAwaysApi {
  /**
  * Constructs a new KeyTakeAwaysApi. 
  * @alias module:api/KeyTakeAwaysApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new media key take away
   * Creates a new media key take away and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateKeyTakeAwayModel} opts.CreateKeyTakeAwayModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/KeyTakeAway} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateKeyTakeAwayModel'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _KeyTakeAway.default;
    return this.apiClient.callApi('/api/v1/KeyTakeAways', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new media key take away
   * Creates a new media key take away and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateKeyTakeAwayModel} opts.CreateKeyTakeAwayModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/KeyTakeAway}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media key take away
   * Returns the requested media key take away
   * @param {String} id Id of the media key take away to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/KeyTakeAway} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _KeyTakeAway.default;
    return this.apiClient.callApi('/api/v1/KeyTakeAways/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media key take away
   * Returns the requested media key take away
   * @param {String} id Id of the media key take away to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/KeyTakeAway}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media key take aways
   * Returns an array of requested media key take aways
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the media key take aways to get. Not specifiying ids will return all media key take aways
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/KeyTakeAway>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_KeyTakeAway.default];
    return this.apiClient.callApi('/api/v1/KeyTakeAways/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media key take aways
   * Returns an array of requested media key take aways
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the media key take aways to get. Not specifiying ids will return all media key take aways
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/KeyTakeAway>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Gets a media's key take aways info
   * Returns an object with all media's key take aways info
   * @param {String} media_id Id of the media for which to get key take aways
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaKeyTakeAwaysModel} and HTTP response
   */
  getMediaKeyTakeAwaysInfoWithHttpInfo(media_id) {
    let postBody = null;
    // verify the required parameter 'media_id' is set
    if (media_id === undefined || media_id === null) {
      throw new Error("Missing the required parameter 'media_id' when calling getMediaKeyTakeAwaysInfo");
    }
    let pathParams = {
      'media_id': media_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _MediaKeyTakeAwaysModel.default;
    return this.apiClient.callApi('/api/v1/KeyTakeAways/media/{media_id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Gets a media's key take aways info
   * Returns an object with all media's key take aways info
   * @param {String} media_id Id of the media for which to get key take aways
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaKeyTakeAwaysModel}
   */
  getMediaKeyTakeAwaysInfo(media_id) {
    return this.getMediaKeyTakeAwaysInfoWithHttpInfo(media_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple key take aways referencing the requested field by a certain id
   * Returns a list of media slides that are referencing the requested field by a certain id
   * @param {String} field The field of the key take away that is referencing the requested id
   * @param {String} id Id of the key take away that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/KeyTakeAway>} and HTTP response
   */
  getReferencesWithHttpInfo(field, id) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error("Missing the required parameter 'field' when calling getReferences");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getReferences");
    }
    let pathParams = {
      'field': field,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_KeyTakeAway.default];
    return this.apiClient.callApi('/api/v1/KeyTakeAways/references/{field}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple key take aways referencing the requested field by a certain id
   * Returns a list of media slides that are referencing the requested field by a certain id
   * @param {String} field The field of the key take away that is referencing the requested id
   * @param {String} id Id of the key take away that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/KeyTakeAway>}
   */
  getReferences(field, id) {
    return this.getReferencesWithHttpInfo(field, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query media key take aways
   * Queries media key take aways and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/KeyTakeAwayQuery} opts.KeyTakeAwayQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/KeyTakeAwayQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['KeyTakeAwayQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _KeyTakeAwayQueryResult.default;
    return this.apiClient.callApi('/api/v1/KeyTakeAways/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query media key take aways
   * Queries media key take aways and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/KeyTakeAwayQuery} opts.KeyTakeAwayQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/KeyTakeAwayQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete media key take away
   * Deletes a media key take away and returns the deleted object
   * @param {String} id Id of the media key take away to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/KeyTakeAway} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _KeyTakeAway.default;
    return this.apiClient.callApi('/api/v1/KeyTakeAways/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete media key take away
   * Deletes a media key take away and returns the deleted object
   * @param {String} id Id of the media key take away to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/KeyTakeAway}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple media key take aways
   * Deletes multiple media key take aways and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the key take aways to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/KeyTakeAway>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_KeyTakeAway.default];
    return this.apiClient.callApi('/api/v1/KeyTakeAways/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple media key take aways
   * Deletes multiple media key take aways and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the key take aways to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/KeyTakeAway>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Sets a media's key take aways info
   * Sets the key take away info for a media and returns an object with all media's key take aways info
   * @param {String} media_id Id of the media for which to get key take aways
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaKeyTakeAwaysModel} opts.MediaKeyTakeAwaysModel The key take away information of the media
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaKeyTakeAwaysModel} and HTTP response
   */
  setMediaKeyTakeAwaysInfoWithHttpInfo(media_id, opts) {
    opts = opts || {};
    let postBody = opts['MediaKeyTakeAwaysModel'];
    // verify the required parameter 'media_id' is set
    if (media_id === undefined || media_id === null) {
      throw new Error("Missing the required parameter 'media_id' when calling setMediaKeyTakeAwaysInfo");
    }
    let pathParams = {
      'media_id': media_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _MediaKeyTakeAwaysModel.default;
    return this.apiClient.callApi('/api/v1/KeyTakeAways/media/{media_id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Sets a media's key take aways info
   * Sets the key take away info for a media and returns an object with all media's key take aways info
   * @param {String} media_id Id of the media for which to get key take aways
   * @param {Object} opts Optional parameters
   * @param {module:model/MediaKeyTakeAwaysModel} opts.MediaKeyTakeAwaysModel The key take away information of the media
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaKeyTakeAwaysModel}
   */
  setMediaKeyTakeAwaysInfo(media_id, opts) {
    return this.setMediaKeyTakeAwaysInfoWithHttpInfo(media_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update media key take away
   * Updates a media key take away and returns the updated object
   * @param {String} id Id of the media key take away to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateKeyTakeAwayModel} opts.UpdateKeyTakeAwayModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/KeyTakeAway} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateKeyTakeAwayModel'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _KeyTakeAway.default;
    return this.apiClient.callApi('/api/v1/KeyTakeAways/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update media key take away
   * Updates a media key take away and returns the updated object
   * @param {String} id Id of the media key take away to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateKeyTakeAwayModel} opts.UpdateKeyTakeAwayModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/KeyTakeAway}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = KeyTakeAwaysApi;