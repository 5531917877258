"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ApiSession", {
  enumerable: true,
  get: function () {
    return _apiSession.ApiSession;
  }
});
exports.RESOURCES = exports.RESOURCE = void 0;
Object.defineProperty(exports, "RESOURCE_LIST", {
  enumerable: true,
  get: function () {
    return _index.default;
  }
});
exports.SCHEMA = void 0;
Object.defineProperty(exports, "TYPES", {
  enumerable: true,
  get: function () {
    return _index2.default;
  }
});
Object.defineProperty(exports, "apiDownloadUrlPattern", {
  enumerable: true,
  get: function () {
    return _apiSession.apiDownloadUrlPattern;
  }
});
Object.defineProperty(exports, "getSchema", {
  enumerable: true,
  get: function () {
    return _resourceMappings.getSchema;
  }
});
Object.defineProperty(exports, "normalize", {
  enumerable: true,
  get: function () {
    return _normalizr.normalize;
  }
});
exports.normalized = void 0;
var _ApiClient = _interopRequireDefault(require("@extend/paywall-api-javascript/dist/ApiClient"));
var _normalizr = require("normalizr");
var _apiSession = require("./api-session");
var _index = _interopRequireDefault(require("./lists/index"));
var _resourceMappings = require("./resource-mappings");
var _resourceTypes = require("./resource-types");
var _schema = require("./schema");
var _index2 = _interopRequireDefault(require("./types/index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_ApiClient.default.instance.defaultHeaders = {};
const normalized = (data, resource) => {
  if (data === null || data === undefined) return {
    entities: {},
    result: null
  };
  let schema = (0, _resourceMappings.getSchema)(resource, {
    isArray: Array.isArray(data)
  });
  if (!schema) return data;
  return (0, _normalizr.normalize)(data, schema);
};
exports.normalized = normalized;
const SCHEMA = {
  // one
  AD_SCORE: _schema.schemaAdScore,
  AD_SCORE_SLIDE: _schema.schemaAdScoreSlide,
  AD_SCORE_ATTRIBUTE: _schema.schemaAdScoreAttribute,
  ADDRESS: _schema.schemaAddress,
  CATEGORY: _schema.schemaCategory,
  BRAND: _schema.schemaBrand,
  COMPANY: _schema.schemaCompany,
  COUNTRY: _schema.schemaCountry,
  CUSTOMER: _schema.schemaCustomer,
  CONTACT: _schema.schemaContact,
  DISTRIBUTION_PLAN: _schema.schemaDistributionPlan,
  ENTITY_ATTACHMENT: _schema.schemaEntityAttachment,
  FILE: _schema.schemaFile,
  INDUSTRY: _schema.schemaIndustry,
  CUSTOMER_CATEGORY: _schema.schemaCustomerCategory,
  LEARN_MORE: _schema.schemaLearnMore,
  MEDIA: _schema.schemaMedia,
  MEDIA_READ_STATS: _schema.schemaMediaReadStats,
  MEDIA_FILE: _schema.schemaMediaFile,
  MEDIA_SLIDE: _schema.schemaMediaSlide,
  MEDIA_SLIDE_FILE: _schema.schemaMediaSlideFile,
  SLIDE_COMMENT: _schema.schemaSlideComment,
  MEDIA_TYPE: _schema.schemaMediaType,
  ROLE: _schema.schemaRole,
  SUBSCRIPTION: _schema.schemaSubscription,
  SUBSCRIPTION_TYPE: _schema.schemaSubscriptionType,
  SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY: _schema.schemaSubscriptionTypeMediaAvailability,
  SUBSCRIPTION_DISTRIBUTION_SETTINGS: _schema.schemaSubscriptionDistributionSettings,
  TAG: _schema.schemaTag,
  USER: _schema.schemaUser,
  USER_STATS: _schema.schemaUserStats,
  REPORT_STATS: _schema.schemaReportStats,
  PREFERENCE_DEFINITIONS: _schema.schemaPreferenceDefinition,
  // many (list)
  AD_SCORES: _schema.schemaAdScores,
  AD_SCORE_SLIDES: _schema.schemaAdScoreSlides,
  AD_SCORE_ATTRIBUTE_LIST: _schema.schemaAdScoreAttributes,
  ADDRESSES: _schema.schemaAddresses,
  CATEGORIES: _schema.schemaCategories,
  BRANDS: _schema.schemaBrands,
  COMPANIES: _schema.schemaCompanies,
  COUNTRIES: _schema.schemaCountries,
  CUSTOMERS: _schema.schemaCustomers,
  CONTACTS: _schema.schemaContacts,
  DISTRIBUTION_PLANS: _schema.schemaDistributionPlans,
  ENTITY_ATTACHMENTS: _schema.schemaEntityAttachments,
  FILES: _schema.schemaFiles,
  INDUSTRIES: _schema.schemaIndustries,
  CUSTOMER_CATEGORIES: _schema.schemaCustomerCategories,
  LEARN_MORE_LIST: _schema.schemaLearnMoreList,
  MEDIA_LIST: _schema.schemaMediaList,
  MEDIA_READ_STATS_LIST: _schema.schemaMediaReadStatsList,
  MEDIA_FILES: _schema.schemaMediaFiles,
  MEDIA_SLIDES: _schema.schemaMediaSlides,
  MEDIA_SLIDE_FILES: _schema.schemaMediaSlideFiles,
  SLIDE_COMMENTS: _schema.schemaSlideComments,
  MEDIA_TYPES: _schema.schemaMediaTypes,
  ROLES: _schema.schemaRoles,
  SUBSCRIPTIONS: _schema.schemaSubscriptions,
  SUBSCRIPTION_TYPES: _schema.schemaSubscriptionTypes,
  SUBSCRIPTION_TYPE_MEDIA_AVAILABILITIES: _schema.schemaSubscriptionTypeMediaAvailabilities,
  SUBSCRIPTION_DISTRIBUTION_SETTINGS_LIST: _schema.schemaSubscriptionDistributionSettingList,
  TAGS: _schema.schemaTags,
  USERS: _schema.schemaUsers,
  USER_STATS_LIST: _schema.schemaUserStatsList,
  REPORT_STATS_LIST: _schema.schemaReportStatsList,
  PREFERENCE_DEFINITION_LIST: _schema.schemaPreferenceDefinitions
};
exports.SCHEMA = SCHEMA;
const RESOURCE = {
  ACCOUNT: _resourceTypes.ACCOUNT,
  AD_SCORES: _resourceTypes.AD_SCORES,
  AD_SCORE_SLIDES: _resourceTypes.AD_SCORE_SLIDES,
  AD_SCORE_ATTRIBUTES: _resourceTypes.AD_SCORE_ATTRIBUTES,
  ADDRESSES: _resourceTypes.ADDRESSES,
  CATEGORIES: _resourceTypes.CATEGORIES,
  BRANDS: _resourceTypes.BRANDS,
  COMPANIES: _resourceTypes.COMPANIES,
  COUNTRIES: _resourceTypes.COUNTRIES,
  CUSTOMERS: _resourceTypes.CUSTOMERS,
  CONTACTS: _resourceTypes.CONTACTS,
  DISTRIBUTION_PLANS: _resourceTypes.DISTRIBUTION_PLANS,
  ENTITY_ATTACHMENTS: _resourceTypes.ENTITY_ATTACHMENTS,
  FILE: _resourceTypes.FILE,
  INDUSTRIES: _resourceTypes.INDUSTRIES,
  LEARN_MORE: _resourceTypes.LEARN_MORE,
  MEDIA: _resourceTypes.MEDIA,
  MEDIA_READ_STATS: _resourceTypes.MEDIA_READ_STATS,
  MEDIA_FILES: _resourceTypes.MEDIA_FILES,
  MEDIA_SLIDES: _resourceTypes.MEDIA_SLIDES,
  MEDIA_SLIDE_FILES: _resourceTypes.MEDIA_SLIDE_FILES,
  SLIDE_COMMENTS: _resourceTypes.SLIDE_COMMENTS,
  MEDIA_TYPES: _resourceTypes.MEDIA_TYPES,
  ROLES: _resourceTypes.ROLES,
  SUBSCRIPTIONS: _resourceTypes.SUBSCRIPTIONS,
  SUBSCRIPTION_TYPES: _resourceTypes.SUBSCRIPTION_TYPES,
  SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY: _resourceTypes.SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY,
  SUBSCRIPTION_DISTRIBUTION_SETTINGS: _resourceTypes.SUBSCRIPTION_DISTRIBUTION_SETTINGS,
  TAGS: _resourceTypes.TAGS,
  USERS: _resourceTypes.USERS,
  USER_STATS: _resourceTypes.USER_STATS,
  REPORT_STATS: _resourceTypes.REPORT_STATS,
  MEDIA_STATS: _resourceTypes.MEDIA_STATS,
  PREFERENCE_DEFINITIONS: _resourceTypes.PREFERENCE_DEFINITIONS,
  YEARS: _resourceTypes.YEARS
};
exports.RESOURCE = RESOURCE;
const RESOURCES = [_resourceTypes.ACCOUNT, _resourceTypes.AD_SCORES, _resourceTypes.AD_SCORE_SLIDES, _resourceTypes.AD_SCORE_ATTRIBUTES, _resourceTypes.AD_SCORE_MEASUREMENTS, _resourceTypes.ADDRESSES, _resourceTypes.CATEGORIES, _resourceTypes.BRANDS, _resourceTypes.COMPANIES, _resourceTypes.COUNTRIES, _resourceTypes.CUSTOMERS, _resourceTypes.CONTACTS, _resourceTypes.DISTRIBUTION_PLANS, _resourceTypes.ENTITY_ATTACHMENTS, _resourceTypes.FILE, _resourceTypes.INDUSTRIES, _resourceTypes.CUSTOMER_CATEGORIES, _resourceTypes.LEARN_MORE, _resourceTypes.MEDIA, _resourceTypes.MEDIA_READ_STATS, _resourceTypes.MEDIA_FILES, _resourceTypes.MEDIA_SLIDES, _resourceTypes.MEDIA_SLIDE_FILES, _resourceTypes.SLIDE_COMMENTS, _resourceTypes.MEDIA_TYPES, _resourceTypes.ROLES, _resourceTypes.SUBSCRIPTIONS, _resourceTypes.SUBSCRIPTION_TYPES, _resourceTypes.SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY, _resourceTypes.SUBSCRIPTION_DISTRIBUTION_SETTINGS, _resourceTypes.TAGS, _resourceTypes.USERS, _resourceTypes.USER_STATS, _resourceTypes.REPORT_STATS, _resourceTypes.MEDIA_STATS, _resourceTypes.PREFERENCE_DEFINITIONS, _resourceTypes.YEARS];
exports.RESOURCES = RESOURCES;