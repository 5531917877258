"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CommandRequestUpgrade model module.
 * @module model/CommandRequestUpgrade
 * @version 1.1.3-alpha.1
 */
class CommandRequestUpgrade {
  /**
   * Constructs a new <code>CommandRequestUpgrade</code>.
   * @alias module:model/CommandRequestUpgrade
   * @param media_id {String} 
   * @param triggerAction {String} 
   */
  constructor(media_id, triggerAction) {
    CommandRequestUpgrade.initialize(this, media_id, triggerAction);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, media_id, triggerAction) {
    obj['media_id'] = media_id;
    obj['triggerAction'] = triggerAction;
  }

  /**
   * Constructs a <code>CommandRequestUpgrade</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CommandRequestUpgrade} obj Optional instance to populate.
   * @return {module:model/CommandRequestUpgrade} The populated <code>CommandRequestUpgrade</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CommandRequestUpgrade();
      if (data.hasOwnProperty('media_id')) {
        obj['media_id'] = _ApiClient.default.convertToType(data['media_id'], 'String');
      }
      if (data.hasOwnProperty('triggerAction')) {
        obj['triggerAction'] = _ApiClient.default.convertToType(data['triggerAction'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CommandRequestUpgrade</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommandRequestUpgrade</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CommandRequestUpgrade.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['media_id'] && !(typeof data['media_id'] === 'string' || data['media_id'] instanceof String)) {
      throw new Error("Expected the field `media_id` to be a primitive type in the JSON string but got " + data['media_id']);
    }
    // ensure the json data is a string
    if (data['triggerAction'] && !(typeof data['triggerAction'] === 'string' || data['triggerAction'] instanceof String)) {
      throw new Error("Expected the field `triggerAction` to be a primitive type in the JSON string but got " + data['triggerAction']);
    }
    return true;
  }
}
CommandRequestUpgrade.RequiredProperties = ["media_id", "triggerAction"];

/**
 * @member {String} media_id
 */
CommandRequestUpgrade.prototype['media_id'] = undefined;

/**
 * @member {String} triggerAction
 */
CommandRequestUpgrade.prototype['triggerAction'] = undefined;
var _default = exports.default = CommandRequestUpgrade;