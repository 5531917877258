"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Attachment = _interopRequireDefault(require("../model/Attachment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Attachments service.
* @module api/AttachmentsApi
* @version 1.1.3-alpha.1
*/
class AttachmentsApi {
  /**
  * Constructs a new AttachmentsApi. 
  * @alias module:api/AttachmentsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Get attachment
   * Returns the requested attachment of specific identifier for the requested entity
   * @param {String} identifier 
   * @param {String} id Id of the entitity to get attachment for
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attachment} and HTTP response
   */
  getWithHttpInfo(identifier, id) {
    let postBody = null;
    // verify the required parameter 'identifier' is set
    if (identifier === undefined || identifier === null) {
      throw new Error("Missing the required parameter 'identifier' when calling get");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'identifier': identifier,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Attachment.default;
    return this.apiClient.callApi('/api/v1/Attachments/{identifier}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get attachment
   * Returns the requested attachment of specific identifier for the requested entity
   * @param {String} identifier 
   * @param {String} id Id of the entitity to get attachment for
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attachment}
   */
  get(identifier, id) {
    return this.getWithHttpInfo(identifier, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple attachments of specific identifier
   * Returns an array of requested attachments (one record per entity)
   * @param {String} identifier 
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the entities to get attachments for. Not specifiying ids will return attachments of specified identifier for all entities
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Attachment>} and HTTP response
   */
  getManyWithHttpInfo(identifier, opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    // verify the required parameter 'identifier' is set
    if (identifier === undefined || identifier === null) {
      throw new Error("Missing the required parameter 'identifier' when calling getMany");
    }
    let pathParams = {
      'identifier': identifier
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_Attachment.default];
    return this.apiClient.callApi('/api/v1/Attachments/{identifier}/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple attachments of specific identifier
   * Returns an array of requested attachments (one record per entity)
   * @param {String} identifier 
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the entities to get attachments for. Not specifiying ids will return attachments of specified identifier for all entities
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Attachment>}
   */
  getMany(identifier, opts) {
    return this.getManyWithHttpInfo(identifier, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AttachmentsApi;