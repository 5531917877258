import { createReducer } from './utils';

const MODAL_TOGGLE_OPEN = '@MODAL/TOGGLE_OPEN';
const MODAL_SET_OPEN = '@MODAL/SET_OPEN';
const MODAL_SET_PARAMETERS = '@MODAL/SET_PARAMETERS';

export const modalActionCreators = (modalId, parameters) => {
	return {
		toggle: p => ({
			type: MODAL_TOGGLE_OPEN,
			payload: { id: modalId, parameters: p === undefined ? parameters : p }
		}),
		set: (open, p) => ({
			type: MODAL_SET_OPEN,
			payload: { id: modalId, open: open, parameters: p === undefined ? parameters : p }
		}),
		open: p => ({
			type: MODAL_SET_OPEN,
			payload: { id: modalId, open: true, parameters: p === undefined ? parameters : p }
		}),
		close: () => ({ type: MODAL_SET_OPEN, payload: { id: modalId, open: false } }),
		setParameters: p => ({ type: MODAL_SET_PARAMETERS, payload: { id: modalId, parameters: p } })
	};
};

export const modalsReducer = createReducer(
	{
		[MODAL_TOGGLE_OPEN]: (state, { id, parameters }) => {
			var modalState = state[id] || { open: false };
			var prevState = modalState.open;
			var nextState = {
				open: !modalState.open
			};

			if (prevState === false && parameters !== undefined) nextState.parameters = parameters;

			return {
				...state,
				[id]: {
					...modalState,
					...nextState
				}
			};
		},
		[MODAL_SET_OPEN]: (state, { id, open, parameters }) => {
			var modalState = state[id] || { open: false };
			if (modalState.open === open) return state;
			var nextState = {
				open: !!open
			};

			if (modalState.open === false && open === true && parameters !== undefined) nextState.parameters = parameters;

			return {
				...state,
				[id]: {
					...modalState,
					...nextState
				}
			};
		},
		[MODAL_SET_PARAMETERS]: (state, { id, parameters }) => {
			var modalState = state[id] || {};

			return {
				...state,
				[id]: {
					...modalState,
					parameters: parameters
				}
			};
		}
	},
	{ state: {} }
);

// selectors
export const getModalOpenState = (state, id) => {
	var res = state.modals[id];
	return res || {};
};

export const getModalParameters = (state, id) => {
	var { parameters } = state.modals[id] || {};
	return parameters;
};
