"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunWebhookTestResult model module.
 * @module model/MailgunWebhookTestResult
 * @version 1.1.3-alpha.1
 */
class MailgunWebhookTestResult {
  /**
   * Constructs a new <code>MailgunWebhookTestResult</code>.
   * @alias module:model/MailgunWebhookTestResult
   */
  constructor() {
    MailgunWebhookTestResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunWebhookTestResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunWebhookTestResult} obj Optional instance to populate.
   * @return {module:model/MailgunWebhookTestResult} The populated <code>MailgunWebhookTestResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunWebhookTestResult();
      if (data.hasOwnProperty('message')) {
        obj['message'] = _ApiClient.default.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('statusText')) {
        obj['statusText'] = _ApiClient.default.convertToType(data['statusText'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunWebhookTestResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunWebhookTestResult</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
      throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
    }
    // ensure the json data is a string
    if (data['statusText'] && !(typeof data['statusText'] === 'string' || data['statusText'] instanceof String)) {
      throw new Error("Expected the field `statusText` to be a primitive type in the JSON string but got " + data['statusText']);
    }
    return true;
  }
}

/**
 * @member {String} message
 */
MailgunWebhookTestResult.prototype['message'] = undefined;

/**
 * @member {String} statusText
 */
MailgunWebhookTestResult.prototype['statusText'] = undefined;
var _default = exports.default = MailgunWebhookTestResult;