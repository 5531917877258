"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ListAddRemoveEntitiesParams = _interopRequireDefault(require("../model/ListAddRemoveEntitiesParams"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* MyLists service.
* @module api/MyListsApi
* @version 1.1.3-alpha.1
*/
class MyListsApi {
  /**
  * Constructs a new MyListsApi. 
  * @alias module:api/MyListsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Add an entity to a list of the logged in user
   * Adds the entity with the given entity id to the specified list of the currently logged in user
   * @param {String} listName The list name to add the entity into
   * @param {String} entityId The id of the entity to add
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  addWithHttpInfo(listName, entityId) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling add");
    }
    // verify the required parameter 'entityId' is set
    if (entityId === undefined || entityId === null) {
      throw new Error("Missing the required parameter 'entityId' when calling add");
    }
    let pathParams = {
      'listName': listName,
      'entityId': entityId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/MyLists/{listName}/entity/{entityId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Add an entity to a list of the logged in user
   * Adds the entity with the given entity id to the specified list of the currently logged in user
   * @param {String} listName The list name to add the entity into
   * @param {String} entityId The id of the entity to add
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  add(listName, entityId) {
    return this.addWithHttpInfo(listName, entityId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Add multiple entities to a list of the logged in user
   * Adds the entities with the given entity ids to the specified list of the currently logged in user
   * @param {String} listName The list name to add the entity into
   * @param {Object} opts Optional parameters
   * @param {module:model/ListAddRemoveEntitiesParams} opts.ListAddRemoveEntitiesParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  addManyWithHttpInfo(listName, opts) {
    opts = opts || {};
    let postBody = opts['ListAddRemoveEntitiesParams'];
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling addMany");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/MyLists/{listName}/entities', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Add multiple entities to a list of the logged in user
   * Adds the entities with the given entity ids to the specified list of the currently logged in user
   * @param {String} listName The list name to add the entity into
   * @param {Object} opts Optional parameters
   * @param {module:model/ListAddRemoveEntitiesParams} opts.ListAddRemoveEntitiesParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  addMany(listName, opts) {
    return this.addManyWithHttpInfo(listName, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Remove an entity from a list of the logged in user
   * Removes the entity with the given entity id from the specified list of the currently logged in user
   * @param {String} listName The list name to remove the entity from
   * @param {String} entityId The id of the entity to remove
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  removeWithHttpInfo(listName, entityId) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling remove");
    }
    // verify the required parameter 'entityId' is set
    if (entityId === undefined || entityId === null) {
      throw new Error("Missing the required parameter 'entityId' when calling remove");
    }
    let pathParams = {
      'listName': listName,
      'entityId': entityId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/MyLists/{listName}/entity/{entityId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Remove an entity from a list of the logged in user
   * Removes the entity with the given entity id from the specified list of the currently logged in user
   * @param {String} listName The list name to remove the entity from
   * @param {String} entityId The id of the entity to remove
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  remove(listName, entityId) {
    return this.removeWithHttpInfo(listName, entityId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Remove multiple entities from a list of the logged in user
   * Removes the entities with the given entity ids from the specified list of the currently logged in user
   * @param {String} listName The list name to remove the entity from
   * @param {Object} opts Optional parameters
   * @param {module:model/ListAddRemoveEntitiesParams} opts.ListAddRemoveEntitiesParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  removeManyWithHttpInfo(listName, opts) {
    opts = opts || {};
    let postBody = opts['ListAddRemoveEntitiesParams'];
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling removeMany");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/MyLists/{listName}/entities', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Remove multiple entities from a list of the logged in user
   * Removes the entities with the given entity ids from the specified list of the currently logged in user
   * @param {String} listName The list name to remove the entity from
   * @param {Object} opts Optional parameters
   * @param {module:model/ListAddRemoveEntitiesParams} opts.ListAddRemoveEntitiesParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  removeMany(listName, opts) {
    return this.removeManyWithHttpInfo(listName, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = MyListsApi;