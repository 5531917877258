"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UpdateCategoryCommand model module.
 * @module model/UpdateCategoryCommand
 * @version 1.1.3-alpha.1
 */
class UpdateCategoryCommand {
  /**
   * Constructs a new <code>UpdateCategoryCommand</code>.
   * @alias module:model/UpdateCategoryCommand
   * @param id {String} 
   * @param title {String} 
   */
  constructor(id, title) {
    UpdateCategoryCommand.initialize(this, id, title);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, title) {
    obj['id'] = id;
    obj['title'] = title;
  }

  /**
   * Constructs a <code>UpdateCategoryCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UpdateCategoryCommand} obj Optional instance to populate.
   * @return {module:model/UpdateCategoryCommand} The populated <code>UpdateCategoryCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UpdateCategoryCommand();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('parent_id')) {
        obj['parent_id'] = _ApiClient.default.convertToType(data['parent_id'], 'String');
      }
      if (data.hasOwnProperty('sorting_order')) {
        obj['sorting_order'] = _ApiClient.default.convertToType(data['sorting_order'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UpdateCategoryCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateCategoryCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of UpdateCategoryCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['parent_id'] && !(typeof data['parent_id'] === 'string' || data['parent_id'] instanceof String)) {
      throw new Error("Expected the field `parent_id` to be a primitive type in the JSON string but got " + data['parent_id']);
    }
    return true;
  }
}
UpdateCategoryCommand.RequiredProperties = ["id", "title"];

/**
 * @member {String} id
 */
UpdateCategoryCommand.prototype['id'] = undefined;

/**
 * @member {String} title
 */
UpdateCategoryCommand.prototype['title'] = undefined;

/**
 * @member {String} description
 */
UpdateCategoryCommand.prototype['description'] = undefined;

/**
 * @member {String} parent_id
 */
UpdateCategoryCommand.prototype['parent_id'] = undefined;

/**
 * @member {Number} sorting_order
 */
UpdateCategoryCommand.prototype['sorting_order'] = undefined;
var _default = exports.default = UpdateCategoryCommand;