"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CreateCategoryCommand model module.
 * @module model/CreateCategoryCommand
 * @version 1.1.3-alpha.1
 */
class CreateCategoryCommand {
  /**
   * Constructs a new <code>CreateCategoryCommand</code>.
   * @alias module:model/CreateCategoryCommand
   * @param title {String} 
   */
  constructor(title) {
    CreateCategoryCommand.initialize(this, title);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, title) {
    obj['title'] = title;
  }

  /**
   * Constructs a <code>CreateCategoryCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateCategoryCommand} obj Optional instance to populate.
   * @return {module:model/CreateCategoryCommand} The populated <code>CreateCategoryCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateCategoryCommand();
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('parent_id')) {
        obj['parent_id'] = _ApiClient.default.convertToType(data['parent_id'], 'String');
      }
      if (data.hasOwnProperty('sorting_order')) {
        obj['sorting_order'] = _ApiClient.default.convertToType(data['sorting_order'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CreateCategoryCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateCategoryCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CreateCategoryCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['parent_id'] && !(typeof data['parent_id'] === 'string' || data['parent_id'] instanceof String)) {
      throw new Error("Expected the field `parent_id` to be a primitive type in the JSON string but got " + data['parent_id']);
    }
    return true;
  }
}
CreateCategoryCommand.RequiredProperties = ["title"];

/**
 * @member {String} title
 */
CreateCategoryCommand.prototype['title'] = undefined;

/**
 * @member {String} description
 */
CreateCategoryCommand.prototype['description'] = undefined;

/**
 * @member {String} parent_id
 */
CreateCategoryCommand.prototype['parent_id'] = undefined;

/**
 * @member {Number} sorting_order
 */
CreateCategoryCommand.prototype['sorting_order'] = undefined;
var _default = exports.default = CreateCategoryCommand;