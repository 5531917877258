import { Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { memo } from 'react';

export const CloseFab = memo(({ className, fontSize, ...props }) => {
	return (
		<Fab variant="circular" size="medium" {...props} className={clsx('wd-close-button', className)}>
			<CloseIcon fontSize={fontSize} />
		</Fab>
	);
});
