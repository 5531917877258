"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AdScoreAttribute = _interopRequireDefault(require("../model/AdScoreAttribute"));
var _AdScoreAttributeQuery = _interopRequireDefault(require("../model/AdScoreAttributeQuery"));
var _AdScoreAttributeQueryResult = _interopRequireDefault(require("../model/AdScoreAttributeQueryResult"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* AdScoreAttributes service.
* @module api/AdScoreAttributesApi
* @version 1.1.3-alpha.1
*/
class AdScoreAttributesApi {
  /**
  * Constructs a new AdScoreAttributesApi. 
  * @alias module:api/AdScoreAttributesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Get ad score attribute
   * Returns the requested ad score attribute
   * @param {Number} id Id of the ad score attribute to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreAttribute} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _AdScoreAttribute.default;
    return this.apiClient.callApi('/api/v1/AdScoreAttributes/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get ad score attribute
   * Returns the requested ad score attribute
   * @param {Number} id Id of the ad score attribute to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreAttribute}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple ad score attributes
   * Returns an array of requested ad score attributes
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the ad score attributes to get. Not specifiying ids will return all ad score attributes
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScoreAttribute>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_AdScoreAttribute.default];
    return this.apiClient.callApi('/api/v1/AdScoreAttributes/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple ad score attributes
   * Returns an array of requested ad score attributes
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the ad score attributes to get. Not specifiying ids will return all ad score attributes
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScoreAttribute>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query ad score attributes
   * Queries ad score attributes and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreAttributeQuery} opts.AdScoreAttributeQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreAttributeQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AdScoreAttributeQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AdScoreAttributeQueryResult.default;
    return this.apiClient.callApi('/api/v1/AdScoreAttributes/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query ad score attributes
   * Queries ad score attributes and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreAttributeQuery} opts.AdScoreAttributeQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreAttributeQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AdScoreAttributesApi;