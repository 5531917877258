"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DecimalAnalyticsItem = _interopRequireDefault(require("./DecimalAnalyticsItem"));
var _Int32AnalyticsItem = _interopRequireDefault(require("./Int32AnalyticsItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AnalyticsKpisResult model module.
 * @module model/AnalyticsKpisResult
 * @version 1.1.3-alpha.1
 */
class AnalyticsKpisResult {
  /**
   * Constructs a new <code>AnalyticsKpisResult</code>.
   * @alias module:model/AnalyticsKpisResult
   */
  constructor() {
    AnalyticsKpisResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>AnalyticsKpisResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AnalyticsKpisResult} obj Optional instance to populate.
   * @return {module:model/AnalyticsKpisResult} The populated <code>AnalyticsKpisResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AnalyticsKpisResult();
      if (data.hasOwnProperty('paying_clients')) {
        obj['paying_clients'] = _Int32AnalyticsItem.default.constructFromObject(data['paying_clients']);
      }
      if (data.hasOwnProperty('arr')) {
        obj['arr'] = _DecimalAnalyticsItem.default.constructFromObject(data['arr']);
      }
      if (data.hasOwnProperty('users_per_paying_client')) {
        obj['users_per_paying_client'] = _DecimalAnalyticsItem.default.constructFromObject(data['users_per_paying_client']);
      }
      if (data.hasOwnProperty('logins_per_user')) {
        obj['logins_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['logins_per_user']);
      }
      if (data.hasOwnProperty('content_per_user')) {
        obj['content_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['content_per_user']);
      }
      if (data.hasOwnProperty('slides_per_paying_client')) {
        obj['slides_per_paying_client'] = _DecimalAnalyticsItem.default.constructFromObject(data['slides_per_paying_client']);
      }
      if (data.hasOwnProperty('reports_per_paying_client')) {
        obj['reports_per_paying_client'] = _DecimalAnalyticsItem.default.constructFromObject(data['reports_per_paying_client']);
      }
      if (data.hasOwnProperty('trial_clients')) {
        obj['trial_clients'] = _Int32AnalyticsItem.default.constructFromObject(data['trial_clients']);
      }
      if (data.hasOwnProperty('users_per_trial_client')) {
        obj['users_per_trial_client'] = _DecimalAnalyticsItem.default.constructFromObject(data['users_per_trial_client']);
      }
      if (data.hasOwnProperty('slides_per_trial_client')) {
        obj['slides_per_trial_client'] = _DecimalAnalyticsItem.default.constructFromObject(data['slides_per_trial_client']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AnalyticsKpisResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AnalyticsKpisResult</code>.
   */
  static validateJSON(data) {
    // validate the optional field `paying_clients`
    if (data['paying_clients']) {
      // data not null
      _Int32AnalyticsItem.default.validateJSON(data['paying_clients']);
    }
    // validate the optional field `arr`
    if (data['arr']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['arr']);
    }
    // validate the optional field `users_per_paying_client`
    if (data['users_per_paying_client']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['users_per_paying_client']);
    }
    // validate the optional field `logins_per_user`
    if (data['logins_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['logins_per_user']);
    }
    // validate the optional field `content_per_user`
    if (data['content_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['content_per_user']);
    }
    // validate the optional field `slides_per_paying_client`
    if (data['slides_per_paying_client']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['slides_per_paying_client']);
    }
    // validate the optional field `reports_per_paying_client`
    if (data['reports_per_paying_client']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['reports_per_paying_client']);
    }
    // validate the optional field `trial_clients`
    if (data['trial_clients']) {
      // data not null
      _Int32AnalyticsItem.default.validateJSON(data['trial_clients']);
    }
    // validate the optional field `users_per_trial_client`
    if (data['users_per_trial_client']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['users_per_trial_client']);
    }
    // validate the optional field `slides_per_trial_client`
    if (data['slides_per_trial_client']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['slides_per_trial_client']);
    }
    return true;
  }
}

/**
 * @member {module:model/Int32AnalyticsItem} paying_clients
 */
AnalyticsKpisResult.prototype['paying_clients'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} arr
 */
AnalyticsKpisResult.prototype['arr'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} users_per_paying_client
 */
AnalyticsKpisResult.prototype['users_per_paying_client'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} logins_per_user
 */
AnalyticsKpisResult.prototype['logins_per_user'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} content_per_user
 */
AnalyticsKpisResult.prototype['content_per_user'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} slides_per_paying_client
 */
AnalyticsKpisResult.prototype['slides_per_paying_client'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} reports_per_paying_client
 */
AnalyticsKpisResult.prototype['reports_per_paying_client'] = undefined;

/**
 * @member {module:model/Int32AnalyticsItem} trial_clients
 */
AnalyticsKpisResult.prototype['trial_clients'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} users_per_trial_client
 */
AnalyticsKpisResult.prototype['users_per_trial_client'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} slides_per_trial_client
 */
AnalyticsKpisResult.prototype['slides_per_trial_client'] = undefined;
var _default = exports.default = AnalyticsKpisResult;