"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CustomerAddressFilter model module.
 * @module model/CustomerAddressFilter
 * @version 1.1.3-alpha.1
 */
class CustomerAddressFilter {
  /**
   * Constructs a new <code>CustomerAddressFilter</code>.
   * @alias module:model/CustomerAddressFilter
   */
  constructor() {
    CustomerAddressFilter.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>CustomerAddressFilter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CustomerAddressFilter} obj Optional instance to populate.
   * @return {module:model/CustomerAddressFilter} The populated <code>CustomerAddressFilter</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerAddressFilter();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('is_billing')) {
        obj['is_billing'] = _ApiClient.default.convertToType(data['is_billing'], 'Boolean');
      }
      if (data.hasOwnProperty('is_default')) {
        obj['is_default'] = _ApiClient.default.convertToType(data['is_default'], 'Boolean');
      }
      if (data.hasOwnProperty('is_delivery')) {
        obj['is_delivery'] = _ApiClient.default.convertToType(data['is_delivery'], 'Boolean');
      }
      if (data.hasOwnProperty('q')) {
        obj['q'] = _ApiClient.default.convertToType(data['q'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CustomerAddressFilter</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CustomerAddressFilter</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['q'] && !(typeof data['q'] === 'string' || data['q'] instanceof String)) {
      throw new Error("Expected the field `q` to be a primitive type in the JSON string but got " + data['q']);
    }
    return true;
  }
}

/**
 * @member {Number} id
 */
CustomerAddressFilter.prototype['id'] = undefined;

/**
 * @member {String} customer_id
 */
CustomerAddressFilter.prototype['customer_id'] = undefined;

/**
 * @member {Boolean} is_billing
 */
CustomerAddressFilter.prototype['is_billing'] = undefined;

/**
 * @member {Boolean} is_default
 */
CustomerAddressFilter.prototype['is_default'] = undefined;

/**
 * @member {Boolean} is_delivery
 */
CustomerAddressFilter.prototype['is_delivery'] = undefined;

/**
 * @member {String} q
 */
CustomerAddressFilter.prototype['q'] = undefined;
var _default = exports.default = CustomerAddressFilter;