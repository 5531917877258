"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ContentProtectionToken = _interopRequireDefault(require("../model/ContentProtectionToken"));
var _DownloadFileInfo = _interopRequireDefault(require("../model/DownloadFileInfo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Download service.
* @module api/DownloadApi
* @version 1.1.3-alpha.1
*/
class DownloadApi {
  /**
  * Constructs a new DownloadApi. 
  * @alias module:api/DownloadApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Download a file
   * Downloads a file. If there is no authorization header in the request, an access token must be supplied in the 'token' query parameter
   * @param {String} file_id The file id for which we request the token
   * @param {Object} opts Optional parameters
   * @param {String} opts.displayName The display name of the file. Not required. Used mainly for correct download naming of the file
   * @param {String} opts.token The download token to use if not using JWT authentication
   * @param {String} opts.thumbnail The thumbnail kind in case of downloading thumbnail
   * @param {Number} opts.w The thumbnail width in case of downloading thumbnail
   * @param {Number} opts.h The thumbnail height kind in case of downloading thumbnail
   * @param {String} opts.action The kind of download action (none for normal download, 'track' for tracking download)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  downloadWithHttpInfo(file_id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'file_id' is set
    if (file_id === undefined || file_id === null) {
      throw new Error("Missing the required parameter 'file_id' when calling download");
    }
    let pathParams = {
      'file_id': file_id,
      'displayName': opts['displayName']
    };
    let queryParams = {
      'token': opts['token'],
      'thumbnail': opts['thumbnail'],
      'w': opts['w'],
      'h': opts['h'],
      'action': opts['action']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/Download/{file_id}/{displayName}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Download a file
   * Downloads a file. If there is no authorization header in the request, an access token must be supplied in the 'token' query parameter
   * @param {String} file_id The file id for which we request the token
   * @param {Object} opts Optional parameters
   * @param {String} opts.displayName The display name of the file. Not required. Used mainly for correct download naming of the file
   * @param {String} opts.token The download token to use if not using JWT authentication
   * @param {String} opts.thumbnail The thumbnail kind in case of downloading thumbnail
   * @param {Number} opts.w The thumbnail width in case of downloading thumbnail
   * @param {Number} opts.h The thumbnail height kind in case of downloading thumbnail
   * @param {String} opts.action The kind of download action (none for normal download, 'track' for tracking download)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  download(file_id, opts) {
    return this.downloadWithHttpInfo(file_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Request complete download info
   * Returns complete download info (Filename, mimeType and download token). Generates a download token to be used when creating clickable download links for browsers. The token is valid for a few minutes only. It must be provided as a query parameter to the url
   * @param {String} file_id The file id for which we request the token
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DownloadFileInfo} and HTTP response
   */
  requestDownloadInfoWithHttpInfo(file_id) {
    let postBody = null;
    // verify the required parameter 'file_id' is set
    if (file_id === undefined || file_id === null) {
      throw new Error("Missing the required parameter 'file_id' when calling requestDownloadInfo");
    }
    let pathParams = {
      'file_id': file_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = _DownloadFileInfo.default;
    return this.apiClient.callApi('/request/{file_id}/info', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Request complete download info
   * Returns complete download info (Filename, mimeType and download token). Generates a download token to be used when creating clickable download links for browsers. The token is valid for a few minutes only. It must be provided as a query parameter to the url
   * @param {String} file_id The file id for which we request the token
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DownloadFileInfo}
   */
  requestDownloadInfo(file_id) {
    return this.requestDownloadInfoWithHttpInfo(file_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Request download token
   * Generates a download token to be used when creating clickable download links for browsers. The token is valid for a few minutes only. It must be provided as a query parameter to the url
   * @param {String} file_id The file id for which we request the token
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ContentProtectionToken} and HTTP response
   */
  requestDownloadTokenWithHttpInfo(file_id) {
    let postBody = null;
    // verify the required parameter 'file_id' is set
    if (file_id === undefined || file_id === null) {
      throw new Error("Missing the required parameter 'file_id' when calling requestDownloadToken");
    }
    let pathParams = {
      'file_id': file_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = _ContentProtectionToken.default;
    return this.apiClient.callApi('/request/{file_id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Request download token
   * Generates a download token to be used when creating clickable download links for browsers. The token is valid for a few minutes only. It must be provided as a query parameter to the url
   * @param {String} file_id The file id for which we request the token
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ContentProtectionToken}
   */
  requestDownloadToken(file_id) {
    return this.requestDownloadTokenWithHttpInfo(file_id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = DownloadApi;