"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CreateCustomerContactCommand = _interopRequireDefault(require("../model/CreateCustomerContactCommand"));
var _CustomerContact = _interopRequireDefault(require("../model/CustomerContact"));
var _CustomerContactQuery = _interopRequireDefault(require("../model/CustomerContactQuery"));
var _CustomerContactQueryResult = _interopRequireDefault(require("../model/CustomerContactQueryResult"));
var _MediaPaywallErrorModel = _interopRequireDefault(require("../model/MediaPaywallErrorModel"));
var _UpdateCustomerContactCommand = _interopRequireDefault(require("../model/UpdateCustomerContactCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Contacts service.
* @module api/ContactsApi
* @version 1.1.3-alpha.1
*/
class ContactsApi {
  /**
  * Constructs a new ContactsApi. 
  * @alias module:api/ContactsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new customer contact
   * Creates a new customer contact and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerContactCommand} opts.CreateCustomerContactCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerContact} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateCustomerContactCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _CustomerContact.default;
    return this.apiClient.callApi('/api/v1/Contacts', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new customer contact
   * Creates a new customer contact and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateCustomerContactCommand} opts.CreateCustomerContactCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerContact}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Exports customer contacts in excel format
   * Exports customer contacts in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerContactQuery} opts.CustomerContactQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  exportFileWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CustomerContactQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/Contacts/export', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Exports customer contacts in excel format
   * Exports customer contacts in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerContactQuery} opts.CustomerContactQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  exportFile(opts) {
    return this.exportFileWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get customer contact
   * Returns the requested customer contact
   * @param {Number} id Id of the customer contact to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerContact} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _CustomerContact.default;
    return this.apiClient.callApi('/api/v1/Contacts/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get customer contact
   * Returns the requested customer contact
   * @param {Number} id Id of the customer contact to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerContact}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple customer contacts
   * Returns an array of requested customer contacts
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the customer contacts to get. Not specifiying ids will return all customer contacts
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CustomerContact>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_CustomerContact.default];
    return this.apiClient.callApi('/api/v1/Contacts/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple customer contacts
   * Returns an array of requested customer contacts
   * @param {Object} opts Optional parameters
   * @param {Array.<Number>} opts.request_body Ids of the customer contacts to get. Not specifiying ids will return all customer contacts
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CustomerContact>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query customer contacts
   * Queries customer contacts and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerContactQuery} opts.CustomerContactQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerContactQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CustomerContactQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _CustomerContactQueryResult.default;
    return this.apiClient.callApi('/api/v1/Contacts/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query customer contacts
   * Queries customer contacts and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/CustomerContactQuery} opts.CustomerContactQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerContactQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete contact
   * Deletes an contact and returns the deleted object
   * @param {Number} id Id of the contact to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerContact} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _CustomerContact.default;
    return this.apiClient.callApi('/api/v1/Contacts/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete contact
   * Deletes an contact and returns the deleted object
   * @param {Number} id Id of the contact to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerContact}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple contacts
   * Deletes multiple contacts and returns a list of the deleted records
   * @param {Array.<Number>} request_body Ids of the contacts to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CustomerContact>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_CustomerContact.default];
    return this.apiClient.callApi('/api/v1/Contacts/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple contacts
   * Deletes multiple contacts and returns a list of the deleted records
   * @param {Array.<Number>} request_body Ids of the contacts to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CustomerContact>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Suspend multiple contacts
   * Suspends multiple contacts and returns a list of the suspended contact ids
   * @param {Array.<Number>} request_body Ids of the contacts to suspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  suspendManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling suspendMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/Contacts/suspend', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Suspend multiple contacts
   * Suspends multiple contacts and returns a list of the suspended contact ids
   * @param {Array.<Number>} request_body Ids of the contacts to suspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  suspendMany(request_body) {
    return this.suspendManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Unsuspend multiple contacts
   * Unsuspends multiple contacts and returns a list of the unsuspended contact ids
   * @param {Array.<Number>} request_body Ids of the contacts to unsuspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  unsuspendManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling unsuspendMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/Contacts/unsuspend', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Unsuspend multiple contacts
   * Unsuspends multiple contacts and returns a list of the unsuspended contact ids
   * @param {Array.<Number>} request_body Ids of the contacts to unsuspend
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  unsuspendMany(request_body) {
    return this.unsuspendManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update customer contact
   * Updates a customer contact and returns the updated object
   * @param {Number} id Id of the customer contact to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerContactCommand} opts.UpdateCustomerContactCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerContact} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateCustomerContactCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _CustomerContact.default;
    return this.apiClient.callApi('/api/v1/Contacts/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update customer contact
   * Updates a customer contact and returns the updated object
   * @param {Number} id Id of the customer contact to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateCustomerContactCommand} opts.UpdateCustomerContactCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerContact}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = ContactsApi;