import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import App from './App';
import { GAInit } from './ga';
import * as serviceWorker from './serviceWorker';
import { browserHistory } from './state/history';
import { store } from './state/store-setup';
import './utilities/range-steps';
const container = document.getElementById('root');
const root = createRoot(container);

GAInit();

const queryClient = new QueryClient();

root.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<Router history={browserHistory}>
				<App fixedHeader />
			</Router>
		</Provider>
	</QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
