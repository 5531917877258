"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CreateSubscriptionTypeCommand = _interopRequireDefault(require("../model/CreateSubscriptionTypeCommand"));
var _SubscriptionType = _interopRequireDefault(require("../model/SubscriptionType"));
var _SubscriptionTypeQuery = _interopRequireDefault(require("../model/SubscriptionTypeQuery"));
var _SubscriptionTypeQueryResult = _interopRequireDefault(require("../model/SubscriptionTypeQueryResult"));
var _UpdateSubscriptionTypeCommand = _interopRequireDefault(require("../model/UpdateSubscriptionTypeCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* SubscriptionTypes service.
* @module api/SubscriptionTypesApi
* @version 1.1.3-alpha.1
*/
class SubscriptionTypesApi {
  /**
  * Constructs a new SubscriptionTypesApi. 
  * @alias module:api/SubscriptionTypesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new subscription type
   * Creates a new subscription type and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateSubscriptionTypeCommand} opts.CreateSubscriptionTypeCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionType} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateSubscriptionTypeCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SubscriptionType.default;
    return this.apiClient.callApi('/api/v1/SubscriptionTypes', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new subscription type
   * Creates a new subscription type and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateSubscriptionTypeCommand} opts.CreateSubscriptionTypeCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionType}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get User
   * Returns the requested subscription type
   * @param {String} id Id of the subscription type to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionType} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _SubscriptionType.default;
    return this.apiClient.callApi('/api/v1/SubscriptionTypes/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get User
   * Returns the requested subscription type
   * @param {String} id Id of the subscription type to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionType}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get assigned subscription types
   * Returns subscription types that are assigned to either at least one of the specified categories, or least one of the specified media
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.category_ids Ids of the categories to search for their subscription types
   * @param {Array.<String>} opts.media_ids Ids of the media to search for their subscription types
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubscriptionType>} and HTTP response
   */
  getAssignedToWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'category_ids': this.apiClient.buildCollectionParam(opts['category_ids'], 'multi'),
      'media_ids': this.apiClient.buildCollectionParam(opts['media_ids'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_SubscriptionType.default];
    return this.apiClient.callApi('/api/v1/SubscriptionTypes/assigned', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get assigned subscription types
   * Returns subscription types that are assigned to either at least one of the specified categories, or least one of the specified media
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.category_ids Ids of the categories to search for their subscription types
   * @param {Array.<String>} opts.media_ids Ids of the media to search for their subscription types
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubscriptionType>}
   */
  getAssignedTo(opts) {
    return this.getAssignedToWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple subscription types
   * Returns an array of the requested subscription types
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the subscription types to get. Not specifiying ids will return all subscription types
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubscriptionType>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_SubscriptionType.default];
    return this.apiClient.callApi('/api/v1/SubscriptionTypes/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple subscription types
   * Returns an array of the requested subscription types
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the subscription types to get. Not specifiying ids will return all subscription types
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubscriptionType>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query subscription type
   * Queries users and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionTypeQuery} opts.SubscriptionTypeQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionTypeQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['SubscriptionTypeQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SubscriptionTypeQueryResult.default;
    return this.apiClient.callApi('/api/v1/SubscriptionTypes/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query subscription type
   * Queries users and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SubscriptionTypeQuery} opts.SubscriptionTypeQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionTypeQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update subscription type
   * Updates a subscription type and returns the updated object
   * @param {String} id Id of the subscription type to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateSubscriptionTypeCommand} opts.UpdateSubscriptionTypeCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriptionType} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateSubscriptionTypeCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SubscriptionType.default;
    return this.apiClient.callApi('/api/v1/SubscriptionTypes/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update subscription type
   * Updates a subscription type and returns the updated object
   * @param {String} id Id of the subscription type to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateSubscriptionTypeCommand} opts.UpdateSubscriptionTypeCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriptionType}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = SubscriptionTypesApi;