import React, { memo, useCallback } from 'react';
import NotificationsComponent, {
	error,
	hide,
	info,
	removeAll,
	show,
	success,
	warning
} from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import isDebug from '../is-debug';

export const Notifications = memo(() => {
	const notifications = useSelector(state => state.notifications);
	return <NotificationsComponent notifications={notifications} />;
});

const defaultNotificationSettings = {
	position: 'br',
	autoDismiss: 8
};

export const actions = {
	show: (n, l) => show({ ...defaultNotificationSettings, ...n }, l),
	success: n => success({ ...defaultNotificationSettings, ...n }),
	error: n => error({ ...defaultNotificationSettings, ...n }),
	warning: n => warning({ ...defaultNotificationSettings, ...n }),
	info: n => info({ ...defaultNotificationSettings, ...n }),
	jsError: (e, n) => {
		if (e.status === 422)
			return error({
				...defaultNotificationSettings,
				title: `Error: ${e.errorCode}`,
				message: e.message,
				...n
			});
		return error({
			...defaultNotificationSettings,
			title: `Error`,
			message: e.message || 'Unspecified error',
			...n
		});
	},
	hide,
	removeAll
};

export const useNotifications = () => {
	const dispatch = useDispatch();

	const _show = useCallback((notification, level) => dispatch(actions.show(notification, level)), [dispatch]);
	const _success = useCallback(notification => dispatch(actions.success(notification)), [dispatch]);
	const _error = useCallback(
		notification => {
			console.error('Error', notification?.message);
			if (isDebug) dispatch(actions.error(notification));
		},
		[dispatch]
	);
	const _jsError = useCallback(
		(e, notification) => {
			console.error(e);
			if (isDebug) dispatch(actions.jsError(e, notification));
		},
		[dispatch]
	);
	const _warning = useCallback(notification => dispatch(actions.warning(notification)), [dispatch]);
	const _info = useCallback(notification => dispatch(actions.info(notification)), [dispatch]);
	const _hide = useCallback(uid => dispatch(actions.hide(uid)), [dispatch]);
	const _removeAll = useCallback(() => dispatch(actions.removeAll()), [dispatch]);

	return {
		show: _show,
		success: _success,
		error: _error,
		jsError: _jsError,
		warning: _warning,
		info: _info,
		hide: _hide,
		removeAll: _removeAll
	};
};
