"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _SlideCommentFilter = _interopRequireDefault(require("./SlideCommentFilter"));
var _SortDescriptor = _interopRequireDefault(require("./SortDescriptor"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SlideCommentQuery model module.
 * @module model/SlideCommentQuery
 * @version 1.1.3-alpha.1
 */
class SlideCommentQuery {
  /**
   * Constructs a new <code>SlideCommentQuery</code>.
   * @alias module:model/SlideCommentQuery
   */
  constructor() {
    SlideCommentQuery.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>SlideCommentQuery</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SlideCommentQuery} obj Optional instance to populate.
   * @return {module:model/SlideCommentQuery} The populated <code>SlideCommentQuery</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SlideCommentQuery();
      if (data.hasOwnProperty('skip')) {
        obj['skip'] = _ApiClient.default.convertToType(data['skip'], 'Number');
      }
      if (data.hasOwnProperty('take')) {
        obj['take'] = _ApiClient.default.convertToType(data['take'], 'Number');
      }
      if (data.hasOwnProperty('sorting')) {
        obj['sorting'] = _ApiClient.default.convertToType(data['sorting'], [_SortDescriptor.default]);
      }
      if (data.hasOwnProperty('filter')) {
        obj['filter'] = _SlideCommentFilter.default.constructFromObject(data['filter']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SlideCommentQuery</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SlideCommentQuery</code>.
   */
  static validateJSON(data) {
    if (data['sorting']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['sorting'])) {
        throw new Error("Expected the field `sorting` to be an array in the JSON data but got " + data['sorting']);
      }
      // validate the optional field `sorting` (array)
      for (const item of data['sorting']) {
        _SortDescriptor.default.validateJSON(item);
      }
      ;
    }
    // validate the optional field `filter`
    if (data['filter']) {
      // data not null
      _SlideCommentFilter.default.validateJSON(data['filter']);
    }
    return true;
  }
}

/**
 * @member {Number} skip
 */
SlideCommentQuery.prototype['skip'] = undefined;

/**
 * @member {Number} take
 */
SlideCommentQuery.prototype['take'] = undefined;

/**
 * @member {Array.<module:model/SortDescriptor>} sorting
 */
SlideCommentQuery.prototype['sorting'] = undefined;

/**
 * @member {module:model/SlideCommentFilter} filter
 */
SlideCommentQuery.prototype['filter'] = undefined;
var _default = exports.default = SlideCommentQuery;