import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { localStorage } from 'js-storage';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useStoreModalActions, useStoreModalParameters, useStoreModalState } from '../hooks/modal-state';
import '../styles/modals.scss';
import { extractClasses, setClasses } from '../utilities/classname-utils';
import { renderFuncElementComponent, renderFuncsOrElements } from '../utils';
import { CloseModalButton } from './close-modal-button';
import { FA } from './icons';
import { WisdropButton } from './wisdrop-buttons';

export const ModalImage = memo(({ image, upgrade, alt, ...props }) => (
	<div {...extractClasses(props, { 'modal-img': !upgrade, 'modal-img-upgrade': !!upgrade })}>
		<img loading="lazy" src={image} alt={alt || '...'} />
	</div>
));

export const withModalState = Component => props => {
	const { open } = useStoreModalState(props.id);
	const { toggle, close } = useStoreModalActions(props.id);
	return <Component open={open} toggle={toggle} close={close} {...props} />;
};

export const WisdropModalBase = memo(
	({
		id,
		open,
		toggle,
		close,
		image,
		noCloseOnClickOutside,
		imageComponent,
		header,
		headerComponent,
		headerProps,
		footer,
		footerComponent,
		footerProps,
		children,
		closeButton,
		closeButtonComponent,
		renderModalText,
		...props
	}) => {
		var closeProp = {};
		if (closeButton === false) closeProp.close = null;
		else if (closeButton || closeButtonComponent)
			closeProp.close = renderFuncElementComponent(closeButton, closeButtonComponent, {
				onClick: close
			});

		var renderedImage = null;
		if (image || imageComponent) renderedImage = renderFuncElementComponent(image, imageComponent);

		var renderedHeader = null;
		if (header || headerComponent) {
			if (typeof header === 'string') {
				renderedHeader = header;
			} else {
				renderedHeader = renderFuncElementComponent(header, headerComponent);
			}
		}

		var renderedFooter = null;
		if (footer || footerComponent)
			renderedFooter = (
				<ModalFooter {...footerProps}>{renderFuncElementComponent(footer, footerComponent)}</ModalFooter>
			);

		var renderedBody = null;
		if (children) {
			if (typeof children === 'function') renderedBody = children({ close, toggle, open });
			renderedBody = children;
		}

		if (renderModalText) {
			return (
				<Modal
					{...extractClasses(props)}
					centered
					id={id}
					isOpen={open}
					toggle={noCloseOnClickOutside ? null : toggle}>
					{renderedImage}
					<div className="modal-text">
						<ModalHeader {...extractClasses(headerProps || {})} toggle={toggle} {...closeProp}>
							{renderedHeader}
						</ModalHeader>
						<ModalBody>{renderedBody}</ModalBody>
						{renderedFooter}
					</div>
				</Modal>
			);
		}
		return (
			<Modal
				{...extractClasses(props)}
				centered
				id={id}
				isOpen={open}
				toggle={noCloseOnClickOutside ? null : toggle}>
				{renderedImage}
				<ModalHeader {...extractClasses(headerProps || {}, 'modal-title')} toggle={toggle} {...closeProp}>
					{renderedHeader}
				</ModalHeader>
				<ModalBody>{renderedBody}</ModalBody>
				{renderedFooter}
			</Modal>
		);
	}
);

export const WisdropModal = withModalState(WisdropModalBase);

WisdropModal.propTypes = {
	id: PropTypes.string.isRequired
};

WisdropModal.defaultProps = {
	closeButton: ({ onClick }) => <CloseModalButton onClick={onClick} />,
	headerProps: { className: 'helvetica-neue-bold' }
};

export const WisdropModalActions = memo(
	({ modalId, render, component, children, mapToggle, mapOpen, mapClose, mapSet }) => {
		const { toggle, close, open, set } = useStoreModalActions(modalId);

		var passedProps = {
			[mapToggle || 'toggle']: toggle,
			[mapClose || 'close']: close,
			[mapOpen || 'open']: open,
			[mapSet || 'set']: set
		};

		if (children) {
			if (Array.isArray(children)) return renderFuncsOrElements(children, passedProps);
			return renderFuncElementComponent(children, null, passedProps);
		}
		return renderFuncElementComponent(render, component, passedProps);
	}
);

WisdropModalActions.propTypes = {
	modalId: PropTypes.string.isRequired,
	mapToggle: PropTypes.string,
	mapOpen: PropTypes.string,
	mapClose: PropTypes.string,
	mapSet: PropTypes.string
};

const ratingGotIt = id => `${id}-got-it`;

export const getRatingGotIt = id => !!localStorage.get(ratingGotIt(id));

export const RatingModal = memo(({ id }) => (
	<WisdropModal id={id} classes={['rating-modal']} header="Thanks for rating">
		<RatingModalContent modalId={id} />
	</WisdropModal>
));

export const RatingModalContent = memo(({ modalId }) => {
	var { close } = useStoreModalActions(modalId);
	var saveGotIt = useCallback(
		(...args) => {
			localStorage.set(ratingGotIt(modalId), true);
			close(...args);
		},
		[close, modalId]
	);

	return (
		<>
			<p className="helvetica-neue intro">
				{'More ratings mean better suggestions for you and better studies for everyone.'}
			</p>
			<div className="rating modal-rating">
				<div className="fa-container">
					<FA icon={faThumbsUp} />
					<span>{'I like it a lot'}</span>
				</div>

				<div className="fa-container">
					<FA icon={faThumbsDown} />
					<span>{'This one’s not for me'}</span>
				</div>
			</div>
			<WisdropButton onClick={saveGotIt} classes={['w-100', 'submit']}>
				{'Got it!'}
			</WisdropButton>
		</>
	);
});

export const ActionResultModal = memo(() => {
	var params = useStoreModalParameters('actionResultModal');
	var { success = false, message = '', title } = params || {};
	return (
		<WisdropModal id="actionResultModal" header={title} size="lg">
			<p className={setClasses({ 'sign-in-invalid': !success })}>{message}</p>
		</WisdropModal>
	);
});

export const ExcessiveDownloadModal = memo(() => {
	const { close } = useStoreModalActions('excessive-download');
	return (
		<WisdropModal
			id="excessive-download"
			noCloseOnClickOutside
			className="excessive-download"
			closeButton={<span />}
			header={<span style={{ fontSize: '1.9vw' }}>It seems that you really liked this study</span>}>
			<p style={{ fontSize: '1.1vw' }}>That's great!</p>
			<p style={{ fontSize: '1.1vw' }}>
				Just keep in mind that you can share this content only within your organisation and use it as long as you
				have an active subscription.
				<br />
			</p>
			<p style={{ fontSize: '0.9vw' }}>
				Feel free to contact us at{' '}
				<a href="mailto:contact@wisdrop.com" className="helvetica stress">
					{'contact@wisdrop.com'}
				</a>
				<br />
			</p>
			<div className="d-flex justify-content-center">
				<WisdropButton className="close-button" onClick={close} style={{ width: '70%', fontSize: '1.2vw' }}>
					Got it
				</WisdropButton>
			</div>
		</WisdropModal>
	);
});

export const SessionExpiredModal = memo(() => {
	const { close } = useStoreModalActions('sessionExpiredModal');
	return (
		<WisdropModal id="sessionExpiredModal" className="session-expired-modal" header="Subscription expired">
			<p className="helvetica-neue intro">
				{'Your subscription has expired, but don’t worry. We can turn it back on if you want. Send us an email at '}
				<a href="mailto:contact@wisdrop.com" className="helvetica stress">
					{'contact@wisdrop.com'}
				</a>
				{'.'}
			</p>
			<div className="d-flex justify-content-center">
				<WisdropButton className="close-button" onClick={close}>
					Ok
				</WisdropButton>
			</div>
		</WisdropModal>
	);
});

const ConfirmModalFooter = memo(({ onConfirm, onCancel, cancelText, confirmText, hideCancel }) => {
	return (
		<>
			{!hideCancel ? (
				<WisdropButton className="mr-auto" filled={false} onClick={onCancel}>
					{' '}
					{cancelText || 'Cancel'}
				</WisdropButton>
			) : null}
			<WisdropButton className={setClasses('ml-auto', { 'mr-auto': hideCancel })} onClick={onConfirm}>
				{' '}
				{confirmText || 'OK'}
			</WisdropButton>
		</>
	);
});

export const useConfirmModal = ({
	title,
	message,
	cancelText,
	confirmText,
	onSuccess,
	successAction,
	onCancel,
	cancelAction,
	hideCancel,
	modalProps
}) => {
	const { open: handleOpen, ...restActions } = useStoreModalActions('confirmActionModal');

	const open = useCallback(
		() =>
			handleOpen({
				title,
				message,
				cancelText,
				confirmText,
				onSuccess,
				successAction,
				onCancel,
				cancelAction,
				hideCancel,
				modalProps
			}),
		[
			handleOpen,
			title,
			message,
			cancelText,
			confirmText,
			onSuccess,
			successAction,
			onCancel,
			cancelAction,
			hideCancel,
			modalProps
		]
	);

	return { open, ...restActions };
};

export const ConfirmActionModal = memo(() => {
	var dispatch = useDispatch();
	var params = useStoreModalParameters('confirmActionModal');
	const { close: closeModal } = useStoreModalActions('confirmActionModal');

	var {
		title = 'Confirm',
		message = 'Are you sure?',
		cancelText,
		confirmText,
		onSuccess,
		successAction,
		onCancel,
		cancelAction,
		hideCancel,
		modalProps
	} = params || {};

	const handleConfirm = useCallback(() => {
		closeModal();
		if (onSuccess) return onSuccess();
		if (successAction) return dispatch(successAction);
	}, [onSuccess, successAction, dispatch, closeModal]);

	const handleCancel = useCallback(() => {
		closeModal();
		if (onCancel) return onCancel();

		if (cancelAction) return dispatch(cancelAction);
	}, [onCancel, cancelAction, dispatch, closeModal]);

	const footer = (
		<ConfirmModalFooter
			onConfirm={handleConfirm}
			onCancel={handleCancel}
			cancelText={cancelText}
			confirmText={confirmText}
			hideCancel={hideCancel}
		/>
	);

	return (
		<WisdropModal
			id="confirmActionModal"
			size="lg"
			{...modalProps}
			header={title}
			footer={footer}
			footerProps={{ className: 'd-flex' }}>
			{message}
		</WisdropModal>
	);
});
