"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPositioningName = exports.getPositioningKey = exports.default = exports.PositioningValues = exports.PositioningName = exports.PositioningKeyName = exports.PositioningItems = exports.POSITIONING_SAFETY = exports.POSITIONING_PLEASURE = exports.POSITIONING_INDEPENDENCE = exports.POSITIONING_EXCITEMENT = exports.POSITIONING_CONTROL = exports.POSITIONING_ADVENTURE = void 0;
const POSITIONING_SAFETY = 0;
exports.POSITIONING_SAFETY = POSITIONING_SAFETY;
const POSITIONING_CONTROL = 1;
exports.POSITIONING_CONTROL = POSITIONING_CONTROL;
const POSITIONING_INDEPENDENCE = 2;
exports.POSITIONING_INDEPENDENCE = POSITIONING_INDEPENDENCE;
const POSITIONING_ADVENTURE = 3;
exports.POSITIONING_ADVENTURE = POSITIONING_ADVENTURE;
const POSITIONING_EXCITEMENT = 4;
exports.POSITIONING_EXCITEMENT = POSITIONING_EXCITEMENT;
const POSITIONING_PLEASURE = 5;
exports.POSITIONING_PLEASURE = POSITIONING_PLEASURE;
const PositioningName = {
  [POSITIONING_SAFETY]: 'Trust',
  [POSITIONING_CONTROL]: 'Logic',
  [POSITIONING_INDEPENDENCE]: 'Status',
  [POSITIONING_ADVENTURE]: 'Discovery',
  [POSITIONING_EXCITEMENT]: 'Fun',
  [POSITIONING_PLEASURE]: 'Pleasure'
};
exports.PositioningName = PositioningName;
const PositioningKeyName = {
  [POSITIONING_SAFETY]: 'safety',
  [POSITIONING_CONTROL]: 'control',
  [POSITIONING_INDEPENDENCE]: 'independence',
  [POSITIONING_ADVENTURE]: 'adventure',
  [POSITIONING_EXCITEMENT]: 'excitement',
  [POSITIONING_PLEASURE]: 'pleasure'
};
exports.PositioningKeyName = PositioningKeyName;
const getPositioningName = id => PositioningName[id] || '-';
exports.getPositioningName = getPositioningName;
const getPositioningKey = id => PositioningKeyName[id] || '-';
exports.getPositioningKey = getPositioningKey;
const PositioningValues = [POSITIONING_SAFETY, POSITIONING_CONTROL, POSITIONING_INDEPENDENCE, POSITIONING_ADVENTURE, POSITIONING_EXCITEMENT, POSITIONING_PLEASURE];
exports.PositioningValues = PositioningValues;
const PositioningItems = PositioningValues.map(id => ({
  id: id,
  name: PositioningName[id],
  keyText: PositioningKeyName[id]
}));
exports.PositioningItems = PositioningItems;
var _default = {
  SAFETY: POSITIONING_SAFETY,
  CONTROL: POSITIONING_CONTROL,
  INDEPENDENCE: POSITIONING_INDEPENDENCE,
  ADVENTURE: POSITIONING_ADVENTURE,
  EXCITEMENT: POSITIONING_EXCITEMENT,
  PLEASURE: POSITIONING_PLEASURE,
  Values: PositioningValues,
  Items: PositioningItems,
  Name: PositioningName,
  KeyName: PositioningKeyName,
  getName: getPositioningName,
  getKey: getPositioningKey
};
exports.default = _default;