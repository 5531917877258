"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CommandAddMediaSlideComment = _interopRequireDefault(require("../model/CommandAddMediaSlideComment"));
var _SlideComment = _interopRequireDefault(require("../model/SlideComment"));
var _SlideCommentQuery = _interopRequireDefault(require("../model/SlideCommentQuery"));
var _SlideCommentQueryResult = _interopRequireDefault(require("../model/SlideCommentQueryResult"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* SlideComments service.
* @module api/SlideCommentsApi
* @version 1.1.3-alpha.1
*/
class SlideCommentsApi {
  /**
  * Constructs a new SlideCommentsApi. 
  * @alias module:api/SlideCommentsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new slide
   * Creates a new slide and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandAddMediaSlideComment} opts.CommandAddMediaSlideComment 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SlideComment} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandAddMediaSlideComment'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SlideComment.default;
    return this.apiClient.callApi('/api/v1/SlideComments', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new slide
   * Creates a new slide and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandAddMediaSlideComment} opts.CommandAddMediaSlideComment 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SlideComment}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get slide comment
   * Returns the requested slide comment
   * @param {String} id Id of the slide comment to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SlideComment} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _SlideComment.default;
    return this.apiClient.callApi('/api/v1/SlideComments/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get slide comment
   * Returns the requested slide comment
   * @param {String} id Id of the slide comment to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SlideComment}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a specific slide comment list
   * Returns an array of the slide comment included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SlideComment>} and HTTP response
   */
  getListWithHttpInfo(listName) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling getList");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_SlideComment.default];
    return this.apiClient.callApi('/api/v1/SlideComments/list/{listName}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a specific slide comment list
   * Returns an array of the slide comment included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SlideComment>}
   */
  getList(listName) {
    return this.getListWithHttpInfo(listName).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple slide comment lists
   * Returns a dictionary of listnames to slide comment lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: [SlideComment]}>} and HTTP response
   */
  getListsWithHttpInfo(names) {
    let postBody = null;
    // verify the required parameter 'names' is set
    if (names === undefined || names === null) {
      throw new Error("Missing the required parameter 'names' when calling getLists");
    }
    let pathParams = {};
    let queryParams = {
      'names': this.apiClient.buildCollectionParam(names, 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': [_SlideComment.default]
    };
    return this.apiClient.callApi('/api/v1/SlideComments/lists', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple slide comment lists
   * Returns a dictionary of listnames to slide comment lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: [SlideComment]}>}
   */
  getLists(names) {
    return this.getListsWithHttpInfo(names).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple slide comments
   * Returns an array of requested slide comments
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the slide comments to get. Not specifiying ids will return all slide comments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SlideComment>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_SlideComment.default];
    return this.apiClient.callApi('/api/v1/SlideComments/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple slide comments
   * Returns an array of requested slide comments
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the slide comments to get. Not specifiying ids will return all slide comments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SlideComment>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple slide comments referencing the requested id by a certain field
   * Returns a list of slide comments that are referencing the requested id by a certain field
   * @param {String} field The field of the slide comment that is referencing the requested id
   * @param {String} id Id of the slide comment that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SlideComment>} and HTTP response
   */
  getReferencesWithHttpInfo(field, id) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error("Missing the required parameter 'field' when calling getReferences");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getReferences");
    }
    let pathParams = {
      'field': field,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_SlideComment.default];
    return this.apiClient.callApi('/api/v1/SlideComments/references/{field}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple slide comments referencing the requested id by a certain field
   * Returns a list of slide comments that are referencing the requested id by a certain field
   * @param {String} field The field of the slide comment that is referencing the requested id
   * @param {String} id Id of the slide comment that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SlideComment>}
   */
  getReferences(field, id) {
    return this.getReferencesWithHttpInfo(field, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query slide comments
   * Queries slide comments and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SlideCommentQuery} opts.SlideCommentQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SlideCommentQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['SlideCommentQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _SlideCommentQueryResult.default;
    return this.apiClient.callApi('/api/v1/SlideComments/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query slide comments
   * Queries slide comments and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SlideCommentQuery} opts.SlideCommentQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SlideCommentQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = SlideCommentsApi;