"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AdminLoginModel = _interopRequireDefault(require("../model/AdminLoginModel"));
var _BaseResult = _interopRequireDefault(require("../model/BaseResult"));
var _BrowserIdModel = _interopRequireDefault(require("../model/BrowserIdModel"));
var _CommandActivateAccount = _interopRequireDefault(require("../model/CommandActivateAccount"));
var _CommandRequestUserPasswordReset = _interopRequireDefault(require("../model/CommandRequestUserPasswordReset"));
var _CommandResetPassword = _interopRequireDefault(require("../model/CommandResetPassword"));
var _ErrorResponse = _interopRequireDefault(require("../model/ErrorResponse"));
var _FrontEndLoginModel = _interopRequireDefault(require("../model/FrontEndLoginModel"));
var _GetPermissionsRequest = _interopRequireDefault(require("../model/GetPermissionsRequest"));
var _LoginModel = _interopRequireDefault(require("../model/LoginModel"));
var _LoginResult = _interopRequireDefault(require("../model/LoginResult"));
var _PasswordActivateResult = _interopRequireDefault(require("../model/PasswordActivateResult"));
var _PasswordResetResult = _interopRequireDefault(require("../model/PasswordResetResult"));
var _PickLoginUserModel = _interopRequireDefault(require("../model/PickLoginUserModel"));
var _SessionLoginModel = _interopRequireDefault(require("../model/SessionLoginModel"));
var _SimpleErrorResult = _interopRequireDefault(require("../model/SimpleErrorResult"));
var _TwoStepRequiredResult = _interopRequireDefault(require("../model/TwoStepRequiredResult"));
var _ViewClientUserInfo = _interopRequireDefault(require("../model/ViewClientUserInfo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Auth service.
* @module api/AuthApi
* @version 1.1.3-alpha.1
*/
class AuthApi {
  /**
  * Constructs a new AuthApi. 
  * @alias module:api/AuthApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Activates a newly registered account
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandActivateAccount} opts.CommandActivateAccount 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PasswordActivateResult} and HTTP response
   */
  activateWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandActivateAccount'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _PasswordActivateResult.default;
    return this.apiClient.callApi('/api/v1/Auth/activate', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Activates a newly registered account
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandActivateAccount} opts.CommandActivateAccount 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PasswordActivateResult}
   */
  activate(opts) {
    return this.activateWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Performs login action for the admin dashboard
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine). When a login attempt happens the first time for a particular browser_id, a second step of authentication will be required
   * @param {module:model/AdminLoginModel} AdminLoginModel The login model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResult} and HTTP response
   */
  adminLoginWithHttpInfo(AdminLoginModel) {
    let postBody = AdminLoginModel;
    // verify the required parameter 'AdminLoginModel' is set
    if (AdminLoginModel === undefined || AdminLoginModel === null) {
      throw new Error("Missing the required parameter 'AdminLoginModel' when calling adminLogin");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _LoginResult.default;
    return this.apiClient.callApi('/api/v1/Auth/login/admin', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Performs login action for the admin dashboard
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine). When a login attempt happens the first time for a particular browser_id, a second step of authentication will be required
   * @param {module:model/AdminLoginModel} AdminLoginModel The login model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResult}
   */
  adminLogin(AdminLoginModel) {
    return this.adminLoginWithHttpInfo(AdminLoginModel).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Performs login action from the front end
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine). When a login attempt happens the first time for a particular browser_id, a second step of authentication will be required
   * @param {module:model/FrontEndLoginModel} FrontEndLoginModel The login model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResult} and HTTP response
   */
  frontEndLoginWithHttpInfo(FrontEndLoginModel) {
    let postBody = FrontEndLoginModel;
    // verify the required parameter 'FrontEndLoginModel' is set
    if (FrontEndLoginModel === undefined || FrontEndLoginModel === null) {
      throw new Error("Missing the required parameter 'FrontEndLoginModel' when calling frontEndLogin");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _LoginResult.default;
    return this.apiClient.callApi('/api/v1/Auth/login/front', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Performs login action from the front end
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine). When a login attempt happens the first time for a particular browser_id, a second step of authentication will be required
   * @param {module:model/FrontEndLoginModel} FrontEndLoginModel The login model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResult}
   */
  frontEndLogin(FrontEndLoginModel) {
    return this.frontEndLoginWithHttpInfo(FrontEndLoginModel).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Returns the logged in user
   * @param {Object} opts Optional parameters
   * @param {module:model/BrowserIdModel} opts.BrowserIdModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResult} and HTTP response
   */
  getLoggedInUserWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['BrowserIdModel'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _LoginResult.default;
    return this.apiClient.callApi('/api/v1/Auth/user', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Returns the logged in user
   * @param {Object} opts Optional parameters
   * @param {module:model/BrowserIdModel} opts.BrowserIdModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResult}
   */
  getLoggedInUser(opts) {
    return this.getLoggedInUserWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Returns the logged in user
   * @param {Object} opts Optional parameters
   * @param {module:model/GetPermissionsRequest} opts.GetPermissionsRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Boolean}>} and HTTP response
   */
  getPermissionsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['GetPermissionsRequest'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = {
      'String': 'Boolean'
    };
    return this.apiClient.callApi('/api/v1/Auth/permissions', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Returns the logged in user
   * @param {Object} opts Optional parameters
   * @param {module:model/GetPermissionsRequest} opts.GetPermissionsRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Boolean}>}
   */
  getPermissions(opts) {
    return this.getPermissionsWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Performs login action
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine). When a login attempt happens the first time for a particular browser_id, a second step of authentication will be required
   * @param {Object} opts Optional parameters
   * @param {module:model/LoginModel} opts.LoginModel The login model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResult} and HTTP response
   */
  loginWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['LoginModel'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _LoginResult.default;
    return this.apiClient.callApi('/api/v1/Auth/login', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Performs login action
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine). When a login attempt happens the first time for a particular browser_id, a second step of authentication will be required
   * @param {Object} opts Optional parameters
   * @param {module:model/LoginModel} opts.LoginModel The login model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResult}
   */
  login(opts) {
    return this.loginWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Fetches user information for the provided email. The results are used in the second step of the login process
   * @param {module:model/PickLoginUserModel} PickLoginUserModel The pick user model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ViewClientUserInfo>} and HTTP response
   */
  pickLoginUserWithHttpInfo(PickLoginUserModel) {
    let postBody = PickLoginUserModel;
    // verify the required parameter 'PickLoginUserModel' is set
    if (PickLoginUserModel === undefined || PickLoginUserModel === null) {
      throw new Error("Missing the required parameter 'PickLoginUserModel' when calling pickLoginUser");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_ViewClientUserInfo.default];
    return this.apiClient.callApi('/api/v1/Auth/login/users', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Fetches user information for the provided email. The results are used in the second step of the login process
   * @param {module:model/PickLoginUserModel} PickLoginUserModel The pick user model
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ViewClientUserInfo>}
   */
  pickLoginUser(PickLoginUserModel) {
    return this.pickLoginUserWithHttpInfo(PickLoginUserModel).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Request a change for a user's password
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandRequestUserPasswordReset} opts.CommandRequestUserPasswordReset 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  requestPasswordChangeWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandRequestUserPasswordReset'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Auth/password/request', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Request a change for a user's password
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandRequestUserPasswordReset} opts.CommandRequestUserPasswordReset 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  requestPasswordChange(opts) {
    return this.requestPasswordChangeWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Changes a user's password
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandResetPassword} opts.CommandResetPassword 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PasswordResetResult} and HTTP response
   */
  resetPasswordWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandResetPassword'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _PasswordResetResult.default;
    return this.apiClient.callApi('/api/v1/Auth/password/reset', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Changes a user's password
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandResetPassword} opts.CommandResetPassword 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PasswordResetResult}
   */
  resetPassword(opts) {
    return this.resetPasswordWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Performs the second step of the login flow (when requested)
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine), and the session_pin that is generated for this browser_id
   * @param {Object} opts Optional parameters
   * @param {module:model/SessionLoginModel} opts.SessionLoginModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResult} and HTTP response
   */
  sessionLoginWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['SessionLoginModel'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _LoginResult.default;
    return this.apiClient.callApi('/api/v1/Auth/sessionLogin', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Performs the second step of the login flow (when requested)
   * The caller must always provide the browser_id field, which must always be the same for a specific browser (or machine), and the session_pin that is generated for this browser_id
   * @param {Object} opts Optional parameters
   * @param {module:model/SessionLoginModel} opts.SessionLoginModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResult}
   */
  sessionLogin(opts) {
    return this.sessionLoginWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AuthApi;