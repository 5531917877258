"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WITH_MEDIA = void 0;
const WITH_MEDIA = 'with_media';
exports.WITH_MEDIA = WITH_MEDIA;
var _default = {
  WITH_MEDIA
};
exports.default = _default;