"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rethrowRejection = exports.rejection = exports.getError = exports.convertError = exports.apiDownloadUrlPattern = exports.ApiSession = void 0;
var _paywallApiJavascript = require("@extend/paywall-api-javascript");
var _get = _interopRequireDefault(require("lodash/get"));
var _urlPattern = _interopRequireDefault(require("url-pattern"));
var _browserId = require("./browser-id");
var _resourceMappings = require("./resource-mappings");
var _resourceTypes = require("./resource-types");
const _excluded = ["headers"],
  _excluded2 = ["path", "headers"],
  _excluded3 = ["Content-Type"],
  _excluded4 = ["path", "files", "fileProp", "headers", "method"],
  _excluded5 = ["path", "method"];
var _class;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const getApiDownloadUrlPattern = () => new _urlPattern.default(`/download/:id(/*)`);
const apiDownloadUrlPattern = getApiDownloadUrlPattern();
exports.apiDownloadUrlPattern = apiDownloadUrlPattern;
const getError = (status, message, errorCode, errors) => {
  var err = new Error(message);
  err.status = status;
  if (errorCode !== undefined) err.errorCode = errorCode;
  if (errors !== undefined) err.errors = errors;
  return err;
};
exports.getError = getError;
const rejection = (status, message) => Promise.reject(getError(status, message));
exports.rejection = rejection;
const rethrowRejection = error => Promise.reject(convertError(error));
exports.rethrowRejection = rethrowRejection;
const convertError = e => {
  if (e instanceof Error) {
    if (!e.status) e.status = 500;
    return e;
  }
  if (typeof e === 'string') return getError(500, e);
  if (e.status === 422 || e.body && e.body.errors) {
    var {
      errorCode,
      error,
      errors
    } = e.body;
    return getError(e.status, error, errorCode, errors);
  }
  return getError(e.status || 500, (0, _get.default)(e, 'error.response.body.message') || (0, _get.default)(e, 'error.response.message') || (0, _get.default)(e, 'error.message') || (0, _get.default)(e, 'errorMessage') || (0, _get.default)(e, 'errorText') || (0, _get.default)(e, 'message') || (0, _get.default)(e, 'error') || (0, _get.default)(e, 'responseText', 'Unspecified error'));
};
exports.convertError = convertError;
const _resolveApi = (resource, client) => (0, _resourceMappings.getResourceMapping)(resource).then(({
  apiType
}) => {
  if (!apiType) return Promise.reject(getError(500, `No api type defined for resource '${resource}'`));
  return new apiType(client);
});
class ApiSession {
  constructor(basePath, apiKey) {
    _defineProperty(this, "setAuthToken", value => {
      this.authToken = value;
      return value;
    });
    _defineProperty(this, "resolveApi", resource => _resolveApi(resource, this.client));
    _defineProperty(this, "fetchJson", props => this.fetch(props).then(r => r.json()).catch(rethrowRejection));
    _defineProperty(this, "fetchBlob", _ref => {
      let {
          headers = {}
        } = _ref,
        props = _objectWithoutProperties(_ref, _excluded);
      return this.fetch(_objectSpread({
        headers: _objectSpread(_objectSpread({}, headers), {}, {
          Accept: 'application/octet-stream'
        })
      }, props)).then(r => r.blob());
    });
    _defineProperty(this, "fetchAsDownload", props => this.fetch(props).then(async r => {
      return {
        contentType: r.headers.get('content-type'),
        filename: r.headers.get('x-download-name'),
        blob: await r.blob()
      };
    }));
    _defineProperty(this, "fetchText", props => this.fetch(props).then(r => r.text()));
    _defineProperty(this, "fetch", _ref2 => {
      let {
          path,
          headers = {}
        } = _ref2,
        options = _objectWithoutProperties(_ref2, _excluded2);
      if (this._authToken) headers['Authorization'] = `Bearer ${this._authToken}`;
      return fetch(`${this._basePath}${path}`, _objectSpread(_objectSpread({}, options), {}, {
        headers
      })).then(response => {
        if (response.ok) return response;
        let {
          status = 500,
          statusText: message = ''
        } = response;
        return response.text().then(responseText => rejection(status, `${message}: ${responseText}`)).catch(e => rejection(status, message));
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "uploadFiles", async _ref3 => {
      let {
          path,
          files,
          fileProp,
          headers: {
            ['Content-Type']: ct
          } = {},
          method = 'POST'
        } = _ref3,
        headersWithoutContentType = _objectWithoutProperties(_ref3.headers, _excluded3),
        options = _objectWithoutProperties(_ref3, _excluded4);
      if (!files) return rejection(500, 'No files provided');
      let formData = new FormData();
      if (Array.isArray(files)) {
        fileProp = fileProp || 'files';
        for (var i = 0; i < files.length; ++i) formData.append(fileProp, files[i], files[i].name);
      } else if (typeof files === 'object') {
        for (var fProp in files) {
          var file = files[fProp];
          formData.append(fProp, file, file.name);
        }
      } else {
        fileProp = fileProp || 'file';
        formData.append(fileProp, files, files.name);
      }
      var result = await this.fetchJson(_objectSpread(_objectSpread({
        path,
        method
      }, options), {}, {
        headers: headersWithoutContentType,
        body: formData
      }));
      return result;
    });
    _defineProperty(this, "downloadFiles", _ref4 => {
      let {
          path,
          method = 'GET'
        } = _ref4,
        options = _objectWithoutProperties(_ref4, _excluded5);
      return this.fetchBlob(_objectSpread({
        path,
        method
      }, options));
    });
    _defineProperty(this, "submitLearnMore", ({
      name,
      email,
      company,
      message
    } = {}) => {
      return this.resourceCreate(_resourceTypes.LEARN_MORE, {
        name,
        email,
        company,
        message
      });
    });
    _defineProperty(this, "submitAdTestRequest", ({
      email
    } = {}) => {
      return this.resourceCreate(_resourceTypes.LEARN_MORE, {
        email,
        is_ad_test_request: true
      });
    });
    _defineProperty(this, "submitFrontEndPickLoginUser", ({
      username
    } = {}) => {
      return new _paywallApiJavascript.AuthApi(this.client).pickLoginUser({
        email: username
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "submitFrontEndSignIn", ({
      userId,
      password
    } = {}) => {
      return new _paywallApiJavascript.AuthApi(this.client).frontEndLogin({
        user_id: userId,
        password: password,
        browser_id: (0, _browserId.getBrowserId)()
      }).then(loginResult => {
        this.authToken = loginResult.authToken;
        return loginResult;
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "submitSignIn", ({
      username,
      password
    } = {}) => {
      return new _paywallApiJavascript.AuthApi(this.client).login({
        LoginModel: {
          email: username,
          password: password,
          browser_id: (0, _browserId.getBrowserId)()
        }
      }).then(loginResult => {
        this.authToken = loginResult.authToken;
        return loginResult;
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "sessionLogin", pin => {
      return new _paywallApiJavascript.AuthApi(this.client).sessionLogin({
        SessionLoginModel: {
          pin: pin,
          browser_id: (0, _browserId.getBrowserId)()
        }
      }).then(loginResult => {
        this.authToken = loginResult.authToken;
        return loginResult;
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getCurrentAuth", () => {
      return new _paywallApiJavascript.AuthApi(this.client).getLoggedInUser({
        BrowserIdModel: {
          browser_id: (0, _browserId.getBrowserId)()
        }
      }).then(loginResult => {
        this.authToken = loginResult.authToken;
        return loginResult;
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getPermissions", permissions => {
      return new _paywallApiJavascript.AuthApi(this.client).getPermissions({
        GetPermissionsRequest: {
          permissions: permissions
        }
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "suspendCustomers", ids => new _paywallApiJavascript.CustomersApi(this.client).suspendMany(ids).catch(rethrowRejection));
    _defineProperty(this, "unsuspendCustomers", ids => new _paywallApiJavascript.CustomersApi(this.client).unsuspendMany(ids).catch(rethrowRejection));
    _defineProperty(this, "setCustomerMeta", (resource, id, meta, value) => {
      return _resolveApi(resource, this.client).then(api => {
        if (!api.setCustomerMeta) return rejection(500, `Api for resource '${resource}' does not have a setCustomerMeta method`);
        return api.setCustomerMeta(id, meta, {
          MetaValueParams: {
            value: value
          }
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getCustomerMeta", (resource, id, metas) => {
      return _resolveApi(resource, this.client).then(api => {
        if (!api.getCustomerMeta) return rejection(500, `Api for resource '${resource}' does not have a getCustomerMeta method`);
        return api.getCustomerMeta(id, {
          meta: [...(metas || [])]
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getCustomerMetaMultiple", (resource, requestData) => {
      return _resolveApi(resource, this.client).then(api => {
        if (!api.getCustomerMetaMultiple) return rejection(500, `Api for resource '${resource}' does not have a getCustomerMetaMultiple method`);
        return api.getCustomerMetaMultiple({
          request_body: requestData
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getUserMeta", (resource, id, metas) => {
      return _resolveApi(resource, this.client).then(api => {
        if (!api.getUserMeta) return rejection(500, `Api for resource '${resource}' does not have a getUserMeta method`);
        return api.getUserMeta(id, {
          meta: [...(metas || [])]
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getUserMetaMultiple", (resource, requestData) => {
      return _resolveApi(resource, this.client).then(api => {
        if (!api.getUserMetaMultiple) return rejection(500, `Api for resource '${resource}' does not have a getUserMetaMultiple method`);
        return api.getUserMetaMultiple({
          request_body: requestData
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getDownloadLink", (fileId, url) => {
      var api = new _paywallApiJavascript.DownloadApi(this.client);
      return api.requestDownloadToken(fileId).then(response => {
        var token = response.id;
        if (!token) return null;
        return `${this._basePath}${url}?token=${token}`;
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getDownloadInfo", (fileId, url) => {
      var api = new _paywallApiJavascript.DownloadApi(this.client);
      return api.requestDownloadInfo(fileId).then(response => {
        var {
          fileName,
          token,
          mimeType
        } = response;
        return {
          url: token && token.id ? `${this._basePath}${url}?token=${token.id}&action=track` : null,
          fileName: fileName,
          mimeType: mimeType
        };
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceCreate", (resource, payload) => {
      return (0, _resourceMappings.getResourceMapping)(resource).then(({
        apiType,
        createParam
      }) => {
        if (!apiType) return rejection(500, `No api type defined for resource '${resource}'`);
        if (!createParam) return rejection(500, `No create parameter name defined for resource '${resource}'`);
        var api = new apiType(this.client);
        return api.create({
          [createParam]: payload
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceUpdate", (resource, id, payload) => {
      return (0, _resourceMappings.getResourceMapping)(resource).then(({
        apiType,
        updateParam
      }) => {
        if (!apiType) return rejection(500, `No api type defined for resource '${resource}'`);
        if (!updateParam) return rejection(500, `No update parameter name defined for resource '${resource}'`);
        var api = new apiType(this.client);
        return api.update(id, {
          [updateParam]: payload
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceQuery", (resource, query) => {
      return (0, _resourceMappings.getResourceMapping)(resource).then(({
        apiType,
        queryParam
      }) => {
        if (!apiType) return rejection(500, `No api type defined for resource '${resource}'`);
        if (!queryParam) return rejection(500, `No query parameter name defined for resource '${resource}'`);
        var api = new apiType(this.client);
        return api.query({
          [queryParam]: query
        });
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceGetOne", (resource, id) => {
      return _resolveApi(resource, this.client).then(api => api.get ? api.get(id) : null).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceGetMany", (resource, ids) => {
      return _resolveApi(resource, this.client).then(api => api.getMany ? api.getMany({
        request_body: ids
      }) : []).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceGetList", (resource, list) => {
      return _resolveApi(resource, this.client).then(api => api.getList ? api.getList(list) : []).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceGetLists", (resource, names) => {
      return _resolveApi(resource, this.client).then(api => {
        if (!api.getLists) {
          console.error(`Resource ${resource} does not have a getLists api`);
          return {};
        }
        return api.getLists(names);
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getReferences", (resource, field, id) => {
      return _resolveApi(resource, this.client).then(api => api.getReferences ? api.getReferences(field, id) : null).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceDeleteOne", (resource, id) => {
      return _resolveApi(resource, this.client).then(api => api.remove ? api.remove(id) : null).catch(rethrowRejection);
    });
    _defineProperty(this, "resourceDeleteMany", (resource, ids) => {
      return _resolveApi(resource, this.client).then(api => api.removeMany ? api.removeMany(ids) : []).catch(rethrowRejection);
    });
    _defineProperty(this, "addToList", (ids, listName) => {
      var api = new _paywallApiJavascript.MyListsApi(this.client);
      if (Array.isArray(ids)) return api.addMany(listName, {
        ListAddRemoveEntitiesParams: {
          ids: ids
        }
      }).catch(rethrowRejection);
      return api.add(listName, ids).catch(rethrowRejection);
    });
    _defineProperty(this, "removeFromList", (ids, listName) => {
      var api = new _paywallApiJavascript.MyListsApi(this.client);
      if (Array.isArray(ids)) return api.removeMany(listName, {
        ListAddRemoveEntitiesParams: {
          ids: ids
        }
      }).catch(rethrowRejection);
      return api.remove(listName, ids).catch(rethrowRejection);
    });
    _defineProperty(this, "requestUpgradeInfo", (researchId, triggerAction) => {
      if (researchId) {
        var api = new _paywallApiJavascript.AccountApi(this.client);
        return api.requestUpgradeInfo({
          CommandRequestUpgrade: {
            media_id: researchId,
            triggerAction: triggerAction
          }
        }).then(r => !!r.success).catch(rethrowRejection);
      }
      return Promise.reject('No researchId');
    });
    _defineProperty(this, "requestUnlock", (entityId, section) => {
      if (entityId) {
        var api = new _paywallApiJavascript.AccountApi(this.client);
        return api.requestUnlock({
          RequestSectionUnlock: {
            entity_id: entityId,
            section: section
          }
        }).then(r => !!r.success).catch(rethrowRejection);
      }
      return Promise.reject('No entityId');
    });
    _defineProperty(this, "giveFeedback", (mediaId, comments, ideas) => {
      if (!mediaId) return rejection(403, 'mediaId was not provided');
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.giveFeedback(mediaId, {
        FeedbackParams: {
          comments: comments,
          ideas: ideas
        }
      }).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "getExcessiveDownload", mediaId => {
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.getExcessiveDownload(mediaId).then(({
        isExcessive
      }) => isExcessive).catch(rethrowRejection);
    });
    _defineProperty(this, "updatePassword", ({
      current_password,
      new_password,
      confirm_password
    }) => {
      var api = new _paywallApiJavascript.AccountApi(this.client);
      return api.updatePassword({
        UpdateMyUserPasswordCommand: {
          current_password,
          new_password,
          confirm_password
        }
      }).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "requestResetPassword", email => {
      if (!email) return rejection(403, 'email was not provided');
      var api = new _paywallApiJavascript.AuthApi(this.client);
      return api.requestPasswordChange({
        CommandRequestUserPasswordReset: {
          email: email
        }
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "resetPassword", (token, password) => {
      if (!token) return rejection(403, 'token was not provided');
      if (!password) return rejection(403, 'password was not provided');
      var api = new _paywallApiJavascript.AuthApi(this.client);
      return api.resetPassword({
        CommandResetPassword: {
          passwordResetToken: token,
          newPassword: password
        }
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "checkUnlockFullReport", media_id => {
      if (!media_id) return rejection(403, 'media_id was not provided');
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.checkUnlockFullReport(media_id).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "checkUnlockWebinar", media_id => {
      if (!media_id) return rejection(403, 'media_id was not provided');
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.checkUnlockWebinar(media_id).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "unlockFullReport", media_id => {
      if (!media_id) return rejection(403, 'media_id was not provided');
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.unlockFullReport(media_id).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "unlockWebinar", media_id => {
      if (!media_id) return rejection(403, 'media_id was not provided');
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.unlockWebinar(media_id).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "requestSubscriptionCancellation", token => {
      if (!token) return rejection(403, 'token was not provided');
      var api = new _paywallApiJavascript.SubscriptionsApi(this.client);
      return api.cancelRequest({
        RequestSubscriptionCancellationModel: {
          token
        }
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "activateAccount", (token, password, password_confirm) => {
      if (!token) return rejection(403, 'token was not provided');
      if (!password) return rejection(403, 'password was not provided');
      if (!password_confirm) return rejection(403, 'password_confirm was not provided');
      if (password_confirm !== password) return rejection(403, 'confirmation does not match password');
      var api = new _paywallApiJavascript.AuthApi(this.client);
      return api.activate({
        CommandActivateAccount: {
          token,
          password,
          password_confirm,
          browser_id: (0, _browserId.getBrowserId)()
        }
      }).then(result => {
        if (result.loginResult && result.loginResult.authToken) this.authToken = result.loginResult.authToken;
        return result;
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "logSignIn", () => {
      var api = new _paywallApiJavascript.UserActionsApi(this.client);
      return api.logSignIn().catch(rethrowRejection);
    });
    _defineProperty(this, "statsLogEntityViewed", (entity_id, section) => {
      var api = new _paywallApiJavascript.StatsApi(this.client);
      return api.logEntityViewed(entity_id, section).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "statsLogIAmOnline", () => {
      var api = new _paywallApiJavascript.StatsApi(this.client);
      return api.logIAmOnline().then(s => !!s.success).catch(e => {
        console.error(e);
        return Promise.resolve(false);
      });
    });
    /** Catches error */
    _defineProperty(this, "statsLogAction", (action, data) => {
      var api = new _paywallApiJavascript.StatsApi(this.client);
      return api.logUserAction({
        LogUserActionModel: _objectSpread(_objectSpread({}, data), {}, {
          action: action
        })
      }).then(s => !!s.success).catch(e => {
        console.error(e);
        return Promise.resolve(false);
      });
    });
    /** Does not catch error */
    _defineProperty(this, "statsLogUserAction", (action, data) => {
      var api = new _paywallApiJavascript.StatsApi(this.client);
      return api.logUserAction({
        LogUserActionModel: _objectSpread(_objectSpread({}, data), {}, {
          action: action
        })
      }).then(s => !!s.success);
    });
    _defineProperty(this, "statsGetReport", ({
      customer_id,
      force_customer,
      subscription_type_id,
      report_filter
    }) => {
      var api = new _paywallApiJavascript.StatsApi(this.client);
      return api.getReport({
        ReportStatisticsQuery: {
          customer_id,
          force_customer,
          subscription_type_id,
          report_filter
        }
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "statsGetFullReport", ({
      filter: {
        customer_id,
        subscription_type_id,
        category_id,
        industry_id,
        media_ids
      } = {},
      sorting
    }) => {
      var api = new _paywallApiJavascript.StatsApi(this.client);
      return api.getFullReport({
        FullReportStatsQuery: {
          filter: {
            customer_id,
            subscription_type_id,
            category_id,
            industry_id,
            media_ids
          },
          sorting: sorting
        }
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "statsDownloadFullReport", ({
      filter: {
        customer_id,
        subscription_type_id,
        category_id,
        industry_id
      } = {},
      sorting
    }) => {
      return this.fetchAsDownload({
        path: '/api/v1/stats/fullreport/download',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: {
            customer_id,
            subscription_type_id,
            category_id,
            industry_id
          },
          sorting
        })
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "statsDownloadReportFeedback", ({
      report_id,
      filter: {
        customer_id,
        subscription_type_id,
        category_id,
        industry_id
      } = {},
      sorting
    }) => {
      return this.fetchAsDownload({
        path: `/api/v1/stats/feedback/${report_id}/download`,
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: {
            customer_id,
            subscription_type_id,
            category_id,
            industry_id
          },
          sorting
        })
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "userStatsDownloadReport", ({
      filter: {
        customer_id,
        subscription_type_id,
        date_from,
        date_to
      } = {},
      sorting
    }) => {
      return this.fetchAsDownload({
        path: '/api/v1/userstats/query/download',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: {
            customer_id,
            subscription_type_id,
            date_from,
            date_to
          },
          sorting
        })
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "statsManageUser", (id, opts) => {
      var api = new _paywallApiJavascript.UserStatsApi(this.client);
      return api.manage(id, {
        ManageUserContactCommand: _objectSpread(_objectSpread({}, opts), {}, {
          user_id: id
        })
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "resendActivationEmail", id => {
      var api = new _paywallApiJavascript.UsersApi(this.client);
      return api.resendActivationEmail(id).then(r => !!r.success).catch(rethrowRejection);
    });
    _defineProperty(this, "getUserPreferences", id => {
      var api = new _paywallApiJavascript.UsersApi(this.client);
      return api.getPreferences(id).catch(rethrowRejection);
    });
    _defineProperty(this, "setUserPreferences", (id, prefs = {}) => {
      var api = new _paywallApiJavascript.UsersApi(this.client);
      return api.setPreferences(id, {
        SetUserPreferencesModel: {
          preferences: Object.keys(prefs).map(k => ({
            id: k,
            value: prefs[k]
          }))
        }
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "createMyMedia", values => {
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.createMyMedia({
        CreateCustomerMediaCommand: values
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "updateMyMedia", (id, values) => {
      var api = new _paywallApiJavascript.MediaApi(this.client);
      return api.updateMyMedia(id, {
        UpdateCustomerMediaCommand: _objectSpread(_objectSpread({}, values), {}, {
          id
        })
      }).catch(rethrowRejection);
    });
    _defineProperty(this, "getAdScoreYearsWithData", () => new _paywallApiJavascript.AdScoresApi(this.client).getYearsWithData().catch(rethrowRejection));
    _defineProperty(this, "askSlideQuestion", (id, question) => new _paywallApiJavascript.MediaSlidesApi(this.client).askQuestion(id, {
      HaveQuestionParams: {
        question: question
      }
    }).catch(rethrowRejection));
    _defineProperty(this, "getKeyTakeAways", mediaId => new _paywallApiJavascript.KeyTakeAwaysApi(this.client).getMediaKeyTakeAwaysInfo(mediaId).catch(rethrowRejection));
    _defineProperty(this, "getSeasons", mediaId => new _paywallApiJavascript.MediaApi(this.client).getSeasons(mediaId).catch(rethrowRejection));
    _defineProperty(this, "getCountries", async () => {
      try {
        var api = new _paywallApiJavascript.CountriesApi(this.client);
        var {
          items
        } = await api.query({
          CountryQuery: {
            skip: 0,
            take: 0,
            sorting: [{
              field: 'name'
            }]
          }
        });
        return items;
      } catch (e) {
        return await rethrowRejection(e);
      }
    });
    this._client = new _paywallApiJavascript.ApiClient();
    this._client.defaultHeaders = {};
    this.basePath = basePath || '';
    this.authToken = apiKey;
  }
  get basePath() {
    return this._basePath;
  }
  set basePath(value) {
    this._basePath = value;
    this._client.basePath = value;
  }
  get authToken() {
    return this._authToken;
  }
  set authToken(token) {
    this._authToken = token ? token : null;
    let bearer = this._client.authentications['Bearer'];
    bearer.apiKey = token ? token : null;
    bearer.apiKeyPrefix = token ? 'Bearer' : null;
  }
  get client() {
    return this._client;
  }
}
exports.ApiSession = ApiSession;
_class = ApiSession;
_defineProperty(ApiSession, "create", (basePath, apiKey) => {
  return new _class(basePath, apiKey);
});