"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MediaPaywallErrorModel model module.
 * @module model/MediaPaywallErrorModel
 * @version 1.1.3-alpha.1
 */
class MediaPaywallErrorModel {
  /**
   * Constructs a new <code>MediaPaywallErrorModel</code>.
   * @alias module:model/MediaPaywallErrorModel
   */
  constructor() {
    MediaPaywallErrorModel.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MediaPaywallErrorModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MediaPaywallErrorModel} obj Optional instance to populate.
   * @return {module:model/MediaPaywallErrorModel} The populated <code>MediaPaywallErrorModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MediaPaywallErrorModel();
      if (data.hasOwnProperty('errorCode')) {
        obj['errorCode'] = _ApiClient.default.convertToType(data['errorCode'], 'Number');
      }
      if (data.hasOwnProperty('error')) {
        obj['error'] = _ApiClient.default.convertToType(data['error'], 'String');
      }
      if (data.hasOwnProperty('errors')) {
        obj['errors'] = _ApiClient.default.convertToType(data['errors'], {
          'String': ['String']
        });
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MediaPaywallErrorModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MediaPaywallErrorModel</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['error'] && !(typeof data['error'] === 'string' || data['error'] instanceof String)) {
      throw new Error("Expected the field `error` to be a primitive type in the JSON string but got " + data['error']);
    }
    return true;
  }
}

/**
 * @member {Number} errorCode
 */
MediaPaywallErrorModel.prototype['errorCode'] = undefined;

/**
 * @member {String} error
 */
MediaPaywallErrorModel.prototype['error'] = undefined;

/**
 * @member {Object.<String, Array.<String>>} errors
 */
MediaPaywallErrorModel.prototype['errors'] = undefined;
var _default = exports.default = MediaPaywallErrorModel;