"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DistributionPlan model module.
 * @module model/DistributionPlan
 * @version 1.1.3-alpha.1
 */
class DistributionPlan {
  /**
   * Constructs a new <code>DistributionPlan</code>.
   * @alias module:model/DistributionPlan
   * @param name {String} 
   * @param is_active {Boolean} 
   * @param id {String} 
   * @param created_at {String} 
   * @param updated_at {String} 
   */
  constructor(name, is_active, id, created_at, updated_at) {
    DistributionPlan.initialize(this, name, is_active, id, created_at, updated_at);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, name, is_active, id, created_at, updated_at) {
    obj['name'] = name;
    obj['is_active'] = is_active;
    obj['id'] = id;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
  }

  /**
   * Constructs a <code>DistributionPlan</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DistributionPlan} obj Optional instance to populate.
   * @return {module:model/DistributionPlan} The populated <code>DistributionPlan</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DistributionPlan();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('is_active')) {
        obj['is_active'] = _ApiClient.default.convertToType(data['is_active'], 'Boolean');
      }
      if (data.hasOwnProperty('media_list')) {
        obj['media_list'] = _ApiClient.default.convertToType(data['media_list'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
      if (data.hasOwnProperty('created_by')) {
        obj['created_by'] = _ApiClient.default.convertToType(data['created_by'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('time_created')) {
        obj['time_created'] = _ApiClient.default.convertToType(data['time_created'], 'String');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'String');
      }
      if (data.hasOwnProperty('updated_by')) {
        obj['updated_by'] = _ApiClient.default.convertToType(data['updated_by'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
      if (data.hasOwnProperty('time_updated')) {
        obj['time_updated'] = _ApiClient.default.convertToType(data['time_updated'], 'String');
      }
      if (data.hasOwnProperty('deleted_at')) {
        obj['deleted_at'] = _ApiClient.default.convertToType(data['deleted_at'], 'String');
      }
      if (data.hasOwnProperty('deleted_by')) {
        obj['deleted_by'] = _ApiClient.default.convertToType(data['deleted_by'], 'String');
      }
      if (data.hasOwnProperty('date_deleted')) {
        obj['date_deleted'] = _ApiClient.default.convertToType(data['date_deleted'], 'String');
      }
      if (data.hasOwnProperty('time_deleted')) {
        obj['time_deleted'] = _ApiClient.default.convertToType(data['time_deleted'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DistributionPlan</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DistributionPlan</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of DistributionPlan.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['media_list'] && !(typeof data['media_list'] === 'string' || data['media_list'] instanceof String)) {
      throw new Error("Expected the field `media_list` to be a primitive type in the JSON string but got " + data['media_list']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
      throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
    }
    // ensure the json data is a string
    if (data['time_created'] && !(typeof data['time_created'] === 'string' || data['time_created'] instanceof String)) {
      throw new Error("Expected the field `time_created` to be a primitive type in the JSON string but got " + data['time_created']);
    }
    // ensure the json data is a string
    if (data['updated_by'] && !(typeof data['updated_by'] === 'string' || data['updated_by'] instanceof String)) {
      throw new Error("Expected the field `updated_by` to be a primitive type in the JSON string but got " + data['updated_by']);
    }
    // ensure the json data is a string
    if (data['time_updated'] && !(typeof data['time_updated'] === 'string' || data['time_updated'] instanceof String)) {
      throw new Error("Expected the field `time_updated` to be a primitive type in the JSON string but got " + data['time_updated']);
    }
    // ensure the json data is a string
    if (data['deleted_by'] && !(typeof data['deleted_by'] === 'string' || data['deleted_by'] instanceof String)) {
      throw new Error("Expected the field `deleted_by` to be a primitive type in the JSON string but got " + data['deleted_by']);
    }
    // ensure the json data is a string
    if (data['time_deleted'] && !(typeof data['time_deleted'] === 'string' || data['time_deleted'] instanceof String)) {
      throw new Error("Expected the field `time_deleted` to be a primitive type in the JSON string but got " + data['time_deleted']);
    }
    return true;
  }
}
DistributionPlan.RequiredProperties = ["name", "is_active", "id", "created_at", "updated_at"];

/**
 * @member {String} name
 */
DistributionPlan.prototype['name'] = undefined;

/**
 * @member {Boolean} is_active
 */
DistributionPlan.prototype['is_active'] = undefined;

/**
 * @member {String} media_list
 */
DistributionPlan.prototype['media_list'] = undefined;

/**
 * @member {String} description
 */
DistributionPlan.prototype['description'] = undefined;

/**
 * @member {String} id
 */
DistributionPlan.prototype['id'] = undefined;

/**
 * @member {String} created_at
 */
DistributionPlan.prototype['created_at'] = undefined;

/**
 * @member {String} created_by
 */
DistributionPlan.prototype['created_by'] = undefined;

/**
 * @member {String} date_created
 */
DistributionPlan.prototype['date_created'] = undefined;

/**
 * @member {String} time_created
 */
DistributionPlan.prototype['time_created'] = undefined;

/**
 * @member {String} updated_at
 */
DistributionPlan.prototype['updated_at'] = undefined;

/**
 * @member {String} updated_by
 */
DistributionPlan.prototype['updated_by'] = undefined;

/**
 * @member {String} date_updated
 */
DistributionPlan.prototype['date_updated'] = undefined;

/**
 * @member {String} time_updated
 */
DistributionPlan.prototype['time_updated'] = undefined;

/**
 * @member {String} deleted_at
 */
DistributionPlan.prototype['deleted_at'] = undefined;

/**
 * @member {String} deleted_by
 */
DistributionPlan.prototype['deleted_by'] = undefined;

/**
 * @member {String} date_deleted
 */
DistributionPlan.prototype['date_deleted'] = undefined;

/**
 * @member {String} time_deleted
 */
DistributionPlan.prototype['time_deleted'] = undefined;
var _default = exports.default = DistributionPlan;