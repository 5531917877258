"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resourceMappings = exports.getSchema = exports.getResourceMapping = void 0;
var _paywallApiJavascript = require("@extend/paywall-api-javascript");
var _apiSession = require("./api-session");
var _resourceTypes = require("./resource-types");
var _schema = require("./schema");
const resourceMappings = {
  [_resourceTypes.ACCOUNT]: {
    apiType: _paywallApiJavascript.AccountApi
  },
  [_resourceTypes.AD_SCORES]: {
    apiType: _paywallApiJavascript.AdScoresApi,
    queryParam: 'AdScoreQuery',
    createParam: 'CommandCreateAdScore',
    updateParam: 'CommandUpdateAdScore',
    schema: _schema.schemaAdScore,
    arraySchema: _schema.schemaAdScores
  },
  [_resourceTypes.KEY_TAKE_AWAYS]: {
    apiType: _paywallApiJavascript.KeyTakeAwaysApi,
    queryParam: 'KeyTakeAwayQuery',
    createParam: 'CreateKeyTakeAwayModel',
    updateParam: 'UpdateKeyTakeAwayModel',
    schema: _schema.schemaKeyTakeAway,
    arraySchema: _schema.schemaKeyTakeAways
  },
  [_resourceTypes.AD_SCORE_SLIDES]: {
    apiType: _paywallApiJavascript.AdScoreSlidesApi,
    queryParam: 'AdScoreSlideQuery',
    createParam: 'CommandAddAdScoreSlide',
    updateParam: 'CommandUpdateAdScoreSlide',
    schema: _schema.schemaAdScoreSlide,
    arraySchema: _schema.schemaAdScoreSlides
  },
  [_resourceTypes.AD_SCORE_ATTRIBUTES]: {
    apiType: _paywallApiJavascript.AdScoreAttributesApi,
    queryParam: 'AdScoreAttributeQuery',
    schema: _schema.schemaAdScoreAttribute,
    arraySchema: _schema.schemaAdScoreAttributes
  },
  [_resourceTypes.ADDRESSES]: {
    apiType: _paywallApiJavascript.AddressesApi,
    queryParam: 'CustomerAddressQuery',
    createParam: 'CreateCustomerAddressCommand',
    updateParam: 'UpdateCustomerAddressCommand',
    schema: _schema.schemaAddress,
    arraySchema: _schema.schemaAddresses
  },
  [_resourceTypes.CATEGORIES]: {
    apiType: _paywallApiJavascript.CategoriesApi,
    queryParam: 'CategoryQuery',
    createParam: 'CreateCategoryCommand',
    updateParam: 'CreateCategoryCommand',
    schema: _schema.schemaCategory,
    arraySchema: _schema.schemaCategories
  },
  [_resourceTypes.BRANDS]: {
    apiType: _paywallApiJavascript.BrandsApi,
    queryParam: 'BrandQuery',
    createParam: 'CreateBrandCommand',
    updateParam: 'UpdateBrandCommand',
    schema: _schema.schemaBrand,
    arraySchema: _schema.schemaBrands
  },
  [_resourceTypes.COMPANIES]: {
    apiType: _paywallApiJavascript.CompaniesApi,
    queryParam: 'CompanyQuery',
    createParam: 'CreateCompanyCommand',
    updateParam: 'UpdateCompanyCommand',
    schema: _schema.schemaCompany,
    arraySchema: _schema.schemaCompanies
  },
  [_resourceTypes.CONTACTS]: {
    apiType: _paywallApiJavascript.ContactsApi,
    queryParam: 'CustomerContactQuery',
    createParam: 'CreateCustomerContactCommand',
    updateParam: 'UpdateCustomerContactCommand',
    schema: _schema.schemaContact,
    arraySchema: _schema.schemaContacts
  },
  [_resourceTypes.COUNTRIES]: {
    apiType: _paywallApiJavascript.CountriesApi,
    queryParam: 'CountryQuery',
    schema: _schema.schemaCountry,
    arraySchema: _schema.schemaCountries
  },
  [_resourceTypes.CUSTOMERS]: {
    apiType: _paywallApiJavascript.CustomersApi,
    queryParam: 'CustomersQuery',
    createParam: 'CreateCustomerCommand',
    updateParam: 'UpdateCustomerCommand',
    schema: _schema.schemaCustomer,
    arraySchema: _schema.schemaCustomers
  },
  [_resourceTypes.DISTRIBUTION_PLANS]: {
    apiType: _paywallApiJavascript.DistributionPlansApi,
    queryParam: 'DistributionPlanQuery',
    createParam: 'CommandAddDistributionPlan',
    updateParam: 'CommandEditDistributionPlan',
    schema: _schema.schemaDistributionPlan,
    arraySchema: _schema.schemaDistributionPlans
  },
  [_resourceTypes.FILE]: {
    apiType: _paywallApiJavascript.FileApi,
    schema: _schema.schemaFile,
    arraySchema: _schema.schemaFiles
  },
  [_resourceTypes.INDUSTRIES]: {
    apiType: _paywallApiJavascript.IndustriesApi,
    queryParam: 'IndustryQuery',
    createParam: 'CreateIndustryCommand',
    updateParam: 'CreateIndustryCommand',
    schema: _schema.schemaIndustry,
    arraySchema: _schema.schemaIndustries
  },
  [_resourceTypes.CUSTOMER_CATEGORIES]: {
    apiType: _paywallApiJavascript.CustomerCategoriesApi,
    queryParam: 'CustomerCategoryQuery',
    createParam: 'CreateCustomerCategoryCommand',
    updateParam: 'UpdateCustomerCategoryCommand',
    schema: _schema.schemaCustomerCategory,
    arraySchema: _schema.schemaCustomerCategories
  },
  [_resourceTypes.LEARN_MORE]: {
    apiType: _paywallApiJavascript.LearnMoreApi,
    queryParam: 'ExpressionOfInterestQuery',
    createParam: 'AddExpressionOfInterestCommand',
    schema: _schema.schemaLearnMore,
    arraySchema: _schema.schemaLearnMoreList
  },
  [_resourceTypes.MEDIA]: {
    apiType: _paywallApiJavascript.MediaApi,
    queryParam: 'MediaQuery',
    createParam: 'CreateMediaCommand',
    updateParam: 'UpdateMediaCommand',
    schema: _schema.schemaMedia,
    arraySchema: _schema.schemaMediaList
  },
  [_resourceTypes.MEDIA_READ_STATS]: {
    apiType: _paywallApiJavascript.MediaReadStatsApi,
    schema: _schema.schemaMedia,
    arraySchema: _schema.schemaMediaList
  },
  [_resourceTypes.MEDIA_FILES]: {
    apiType: _paywallApiJavascript.MediaFilesApi,
    queryParam: 'MediaFileQuery',
    schema: _schema.schemaMediaFile,
    arraySchema: _schema.schemaMediaFiles
  },
  [_resourceTypes.MEDIA_SLIDES]: {
    apiType: _paywallApiJavascript.MediaSlidesApi,
    queryParam: 'MediaSlideQuery',
    createParam: 'CreateMediaSlideCommand',
    updateParam: 'UpdateMediaSlideCommand',
    schema: _schema.schemaMediaSlide,
    arraySchema: _schema.schemaMediaSlides
  },
  [_resourceTypes.MEDIA_SLIDE_FILES]: {
    schema: _schema.schemaMediaSlideFile,
    arraySchema: _schema.schemaMediaSlideFiles
  },
  [_resourceTypes.SLIDE_COMMENTS]: {
    apiType: _paywallApiJavascript.SlideCommentsApi,
    queryParam: 'SlideCommentQuery',
    createParam: 'CommandAddMediaSlideComment',
    //updateParam: 'UpdateMediaSlideCommand',
    schema: _schema.schemaSlideComment,
    arraySchema: _schema.schemaSlideComments
  },
  [_resourceTypes.MEDIA_TYPES]: {
    apiType: _paywallApiJavascript.MediaTypesApi,
    queryParam: 'MediaTypeQuery',
    schema: _schema.schemaMediaType,
    arraySchema: _schema.schemaMediaTypes
  },
  [_resourceTypes.ROLES]: {
    apiType: _paywallApiJavascript.RolesApi,
    queryParam: 'RoleQuery',
    schema: _schema.schemaRole,
    arraySchema: _schema.schemaRoles
  },
  [_resourceTypes.SUBSCRIPTIONS]: {
    apiType: _paywallApiJavascript.SubscriptionsApi,
    queryParam: 'SubscriptionQuery',
    createParam: 'CreateCustomerSubscriptionCommand',
    updateParam: 'UpdateCustomerSubscriptionCommand',
    schema: _schema.schemaSubscription,
    arraySchema: _schema.schemaSubscriptions
  },
  [_resourceTypes.SUBSCRIPTION_TYPES]: {
    apiType: _paywallApiJavascript.SubscriptionTypesApi,
    queryParam: 'SubscriptionTypeQuery',
    createParam: 'CreateSubscriptionTypeCommand',
    updateParam: 'UpdateSubscriptionTypeCommand',
    schema: _schema.schemaSubscriptionType,
    arraySchema: _schema.schemaSubscriptionTypes
  },
  [_resourceTypes.SUBSCRIPTION_TYPE_MEDIA_AVAILABILITY]: {
    schema: _schema.schemaMediaSlideFile,
    arraySchema: _schema.schemaMediaSlideFiles
  },
  [_resourceTypes.SUBSCRIPTION_DISTRIBUTION_SETTINGS]: {
    apiType: _paywallApiJavascript.SubscriptionDistributionSettingsApi,
    queryParam: 'SubscriptionDistributionPermissionQuery',
    updateParam: 'UpdateSubscriptionDistributionPermissionsCommand',
    schema: _schema.schemaSubscriptionDistributionSettings,
    arraySchema: _schema.schemaSubscriptionDistributionSettingList
  },
  [_resourceTypes.TAGS]: {
    apiType: _paywallApiJavascript.TagsApi,
    queryParam: 'TagsQuery',
    createParam: 'CommandAddTag',
    updateParam: 'CommandRenameTag',
    schema: _schema.schemaTag,
    arraySchema: _schema.schemaTags
  },
  [_resourceTypes.USERS]: {
    apiType: _paywallApiJavascript.UsersApi,
    queryParam: 'UserQuery',
    createParam: 'CreateUserCommand',
    updateParam: 'CommandRenameTag',
    schema: _schema.schemaUser,
    arraySchema: _schema.schemaUsers
  },
  [_resourceTypes.USER_STATS]: {
    apiType: _paywallApiJavascript.UserStatsApi,
    queryParam: 'UserStatsQuery',
    schema: _schema.schemaUserStats,
    arraySchema: _schema.schemaUserStatsList
  },
  [_resourceTypes.REPORT_STATS]: {
    apiType: _paywallApiJavascript.ReportStatsApi,
    queryParam: 'ReportStatsQuery',
    schema: _schema.schemaReportStats,
    arraySchema: _schema.schemaReportStatsList
  },
  [_resourceTypes.PREFERENCE_DEFINITIONS]: {
    apiType: _paywallApiJavascript.PreferenceDefinitionsApi,
    schema: _schema.schemaPreferenceDefinition,
    arraySchema: _schema.schemaPreferenceDefinitions
  },
  [_resourceTypes.ATTACHMENTS]: {
    apiType: _paywallApiJavascript.AttachmentsApi
  }
};
exports.resourceMappings = resourceMappings;
const getSchema = (resource, {
  isArray = false
} = {}) => {
  var {
    schema = null,
    arraySchema = null
  } = resourceMappings[resource] || {};
  return isArray ? arraySchema : schema;
};
exports.getSchema = getSchema;
const getResourceMapping = resource => {
  var mapping = resourceMappings[resource];
  if (mapping) return Promise.resolve(mapping);
  return Promise.reject((0, _apiSession.getError)(500, `No mappings defined for resource '${resource}'`));
};
exports.getResourceMapping = getResourceMapping;