import { RESOURCE } from '@extend/paywall-api/lib';
import { ROLE_ADMIN, ROLE_CONTACT, ROLE_CUSTOMER, ROLE_SUPERADMIN } from '@extend/paywall-api/lib/types/user-roles';
import get from 'lodash/get';
import { getReadStatus, getResourceMeta, getResourceRecord } from './resources';
import { createReducer } from './utils';

// action types
export const AUTH_LOAD_USER = 'AUTH_LOAD_USER';
export const AUTH_LOAD_USER_FAILED = 'AUTH_LOAD_USER_FAILED';
export const AUTH_LOGGED_IN = 'AUTH_LOGGED_IN';
export const AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PERMISSIONS_LOADED = 'AUTH_PERMISSIONS_LOADED';
export const AUTH_LOAD_PERMISSIONS = 'AUTH_LOAD_PERMISSIONS';
export const AUTH_LOAD_PREFERENCES = 'AUTH_LOAD_PREFERENCES';
export const AUTH_PREFERENCES_LOADED = 'AUTH_PREFERENCES_LOADED';
export const AUTH_SAVE_PREFERENCES = 'AUTH_SAVE_PREFERENCES';

export const authLoadUser = () => ({ type: AUTH_LOAD_USER });
export const authLoadUserFailed = error => ({ type: AUTH_LOAD_USER_FAILED, payload: error });
export const authLoggedIn = (payload, redirect) => ({
	type: AUTH_LOGGED_IN,
	payload: payload,
	redirect: redirect
});

export const authLogOut = () => ({ type: AUTH_LOGOUT });
export const authSessionExpired = () => ({ type: AUTH_SESSION_EXPIRED });
export const authPermissionsLoaded = ({ permissions = {}, merge = true } = {}) => ({
	type: AUTH_PERMISSIONS_LOADED,
	payload: { permissions, merge }
});
export const authLoadPermissions = ({ permissions = {}, merge = true } = {}) => ({
	type: AUTH_LOAD_PERMISSIONS,
	payload: { permissions, merge }
});
export const authLoadPreferences = () => ({ type: AUTH_LOAD_PREFERENCES, payload: {} });
export const authSavePreferences = prefs => ({ type: AUTH_SAVE_PREFERENCES, payload: prefs });
export const authPreferencesLoaded = prefs => ({ type: AUTH_PREFERENCES_LOADED, payload: prefs });

export const getPrefLoadRequestKey = prefId => `getPref/${prefId}`;
export const getPrefSaveRequestKey = prefId => `setPref/${prefId}`;

const initialState = {
	apiKey: null,
	loading: false,
	loadingPermissions: false,
	loadingError: null,
	expired: true,
	username: null,
	user_id: null,
	role_id: null,
	customer_id: null,
	contact_id: null,
	active_subscription_id: null,
	subscription_type_id: null,
	permissions: {},
	preferences: {},
	countries: []
};

export const getRoleStatus = role => ({
	superadmin: role === ROLE_SUPERADMIN,
	admin: role === ROLE_ADMIN,
	customer: role === ROLE_CUSTOMER,
	contact: role === ROLE_CONTACT
});

// selectors
export const getAuthIsAuthenticated = state => !!get(state, 'auth.user_id');
export const getAuthPending = state => {
	var loading = get(state, 'auth.loading');
	if (loading) return true;

	if (get(state, 'auth.apiKey')) {
		var user_id = get(state, 'auth.user_id');
		if (!user_id) return true;

		var userMeta = getResourceMeta(state, RESOURCE.USERS, user_id);
		var readStatus = getReadStatus(userMeta);
		if (!readStatus.succeeded) return true;

		var user = getResourceRecord(state, RESOURCE.USERS, user_id);
		if (!user) return true;
	}
	return false;
};
export const getUserId = state => state.auth.user_id;
export const getUserRole = state => get(state, 'auth.role_id', null);
export const getUserRoleStatus = state => getRoleStatus(getUserRole(state));
export const getAuthError = state => state.auth.loadingErrorg;
export const getAuthExpired = state => state.auth.expired;
export const getAuthDisplayName = state => {
	let contactId = get(state, 'auth.contact_id', null);
	if (contactId) {
		var contact = getResourceRecord(state, RESOURCE.CONTACTS, contactId);
		if (!contact) return null;
		return `${contact.last_name} ${contact.name}`;
	}

	let customerId = get(state, 'auth.customer_id', null);
	if (customerId) {
		var customer = getResourceRecord(state, RESOURCE.CUSTOMERS, customerId);
		if (!customer) return null;
		if (get(customer, 'customer_type', 'Retail') === 'Retail') return `${customer.last_name} ${customer.name}`;
		return get(customer, 'name', null);
	}

	let userId = get(state, 'auth.user_id');
	if (userId) {
		var user = getResourceRecord(state, RESOURCE.USERS, userId);
		if (!user) return null;
		return user.email || null;
	}

	return null;
};

export const getAuthState = state => get(state, 'auth', {});
export const getUserCustomerId = state => get(state, 'auth.customer_id', null);
export const getUserContactId = state => get(state, 'auth.contact_id', null);
export const getPermissionsLoading = state => get(state, 'auth.loadingPermissions', false);
export const getPermission = (state, permission) => get(state, `auth.permissions.${permission}`, false);
export const getSubscriptionType = state => get(state, 'auth.subscription_type_id', null);
export const getAuthAvailableCountries = state => state.auth?.countries ?? [];

export const authReducer = createReducer(
	{
		[AUTH_LOAD_USER]: state => {
			return {
				...state,
				loading: true,
				loadingPermissions: true,
				loadingError: null,
				username: null,
				user_id: null,
				role_id: null,
				customer_id: null,
				contact_id: null,
				active_subscription_id: null,
				expired: false,
				permissions: {},
				preferences: {},
				countries: []
			};
		},
		[AUTH_LOAD_USER_FAILED]: (state, error) => {
			return {
				...state,
				loading: false,
				loadingPermissions: false,
				loadingError: error,
				username: null,
				user_id: null,
				role_id: null,
				customer_id: null,
				contact_id: null,
				active_subscription_id: null,
				subscription_type_id: null,
				expired: false,
				permissions: {},
				preferences: {}
			};
		},
		[AUTH_LOGGED_IN]: (
			state,
			{
				apiKey,
				user,
				permissions,
				user_id,
				role_id,
				customer_id,
				contact_id,
				active_subscription_id,
				subscription_type_id,
				countries
			}
		) => {
			return {
				...state,
				apiKey: apiKey,
				loading: false,
				loadingPermissions: false,
				loadingError: null,
				expired: false,
				username: user.email,
				user_id,
				role_id,
				customer_id,
				contact_id,
				active_subscription_id,
				subscription_type_id,
				permissions: permissions || {},
				preferences: {},
				countries: countries || []
			};
		},
		[AUTH_LOAD_PERMISSIONS]: state => {
			return {
				...state,
				loadingPermissions: true
			};
		},
		[AUTH_PERMISSIONS_LOADED]: (state, { permissions = {}, merge = true } = {}) => {
			return {
				...state,
				loadingPermissions: false,
				permissions: merge ? { ...state.permissions, ...permissions } : { ...permissions }
			};
		},
		[AUTH_PREFERENCES_LOADED]: (state, prefs = []) => {
			var newPrefs = {};
			prefs.forEach(pref => {
				var { id, value_bool, value_number, value_string } = pref;
				newPrefs[id] = { value_bool, value_number, value_string };
			});
			return {
				...state,
				loadingPermissions: false,
				preferences: { ...state.preferences, ...newPrefs }
			};
		},
		[AUTH_SESSION_EXPIRED]: state => {
			return {
				...state,
				apiKey: null,
				loading: false,
				loadingPermissions: false,
				loadingError: null,
				expired: true,
				username: null,
				user_id: null,
				role_id: null,
				customer_id: null,
				contact_id: null,
				active_subscription_id: null,
				subscription_type_id: null,
				permissions: {},
				preferences: {},
				countries: []
			};
		},
		[AUTH_LOGOUT]: state => {
			return {
				...state,
				apiKey: null,
				loading: false,
				loadingPermissions: false,
				loadingError: null,
				expired: false,
				username: null,
				user_id: null,
				role_id: null,
				customer_id: null,
				contact_id: null,
				active_subscription_id: null,
				subscription_type_id: null,
				permissions: {},
				preferences: {},
				countries: []
			};
		}
	},
	{
		state: initialState
	}
);
