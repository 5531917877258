"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TYPE_ACTIVATION", {
  enumerable: true,
  get: function () {
    return _activation.default;
  }
});
Object.defineProperty(exports, "TYPE_BRAND_SYNC", {
  enumerable: true,
  get: function () {
    return _brandSync.default;
  }
});
Object.defineProperty(exports, "TYPE_POSITIONING", {
  enumerable: true,
  get: function () {
    return _positioning.default;
  }
});
Object.defineProperty(exports, "TYPE_ROLE", {
  enumerable: true,
  get: function () {
    return _userRoles.default;
  }
});
exports.default = void 0;
var _activation = _interopRequireDefault(require("./activation"));
var _brandSync = _interopRequireDefault(require("./brand-sync"));
var _positioning = _interopRequireDefault(require("./positioning"));
var _userRoles = _interopRequireDefault(require("./user-roles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  ACTIVATION: _activation.default,
  POSITIONING: _positioning.default,
  BRAND_SYNC: _brandSync.default,
  ROLE: _userRoles.default
};
exports.default = _default;