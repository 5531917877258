"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RequestSectionUnlock model module.
 * @module model/RequestSectionUnlock
 * @version 1.1.3-alpha.1
 */
class RequestSectionUnlock {
  /**
   * Constructs a new <code>RequestSectionUnlock</code>.
   * @alias module:model/RequestSectionUnlock
   * @param entity_id {String} 
   */
  constructor(entity_id) {
    RequestSectionUnlock.initialize(this, entity_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, entity_id) {
    obj['entity_id'] = entity_id;
  }

  /**
   * Constructs a <code>RequestSectionUnlock</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RequestSectionUnlock} obj Optional instance to populate.
   * @return {module:model/RequestSectionUnlock} The populated <code>RequestSectionUnlock</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RequestSectionUnlock();
      if (data.hasOwnProperty('entity_id')) {
        obj['entity_id'] = _ApiClient.default.convertToType(data['entity_id'], 'String');
      }
      if (data.hasOwnProperty('section')) {
        obj['section'] = _ApiClient.default.convertToType(data['section'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RequestSectionUnlock</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RequestSectionUnlock</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of RequestSectionUnlock.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['entity_id'] && !(typeof data['entity_id'] === 'string' || data['entity_id'] instanceof String)) {
      throw new Error("Expected the field `entity_id` to be a primitive type in the JSON string but got " + data['entity_id']);
    }
    // ensure the json data is a string
    if (data['section'] && !(typeof data['section'] === 'string' || data['section'] instanceof String)) {
      throw new Error("Expected the field `section` to be a primitive type in the JSON string but got " + data['section']);
    }
    return true;
  }
}
RequestSectionUnlock.RequiredProperties = ["entity_id"];

/**
 * @member {String} entity_id
 */
RequestSectionUnlock.prototype['entity_id'] = undefined;

/**
 * @member {String} section
 */
RequestSectionUnlock.prototype['section'] = undefined;
var _default = exports.default = RequestSectionUnlock;