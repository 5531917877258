"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BaseResult = _interopRequireDefault(require("../model/BaseResult"));
var _CreateMediaSlideCommand = _interopRequireDefault(require("../model/CreateMediaSlideCommand"));
var _FileIndex = _interopRequireDefault(require("../model/FileIndex"));
var _HaveQuestionParams = _interopRequireDefault(require("../model/HaveQuestionParams"));
var _MediaSlide = _interopRequireDefault(require("../model/MediaSlide"));
var _MediaSlideQueryResult = _interopRequireDefault(require("../model/MediaSlideQueryResult"));
var _SlideQuery = _interopRequireDefault(require("../model/SlideQuery"));
var _UpdateMediaSlideCommand = _interopRequireDefault(require("../model/UpdateMediaSlideCommand"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* MediaSlides service.
* @module api/MediaSlidesApi
* @version 1.1.3-alpha.1
*/
class MediaSlidesApi {
  /**
  * Constructs a new MediaSlidesApi. 
  * @alias module:api/MediaSlidesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Ask question about a media slide
   * Submits a form with a question
   * @param {String} id Id of the media slide for which to ask question
   * @param {Object} opts Optional parameters
   * @param {module:model/HaveQuestionParams} opts.HaveQuestionParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  askQuestionWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['HaveQuestionParams'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling askQuestion");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/MediaSlides/{id}/question', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Ask question about a media slide
   * Submits a form with a question
   * @param {String} id Id of the media slide for which to ask question
   * @param {Object} opts Optional parameters
   * @param {module:model/HaveQuestionParams} opts.HaveQuestionParams 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  askQuestion(id, opts) {
    return this.askQuestionWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create a new slide
   * Creates a new slide and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateMediaSlideCommand} opts.CreateMediaSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaSlide} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CreateMediaSlideCommand'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _MediaSlide.default;
    return this.apiClient.callApi('/api/v1/MediaSlides', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new slide
   * Creates a new slide and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CreateMediaSlideCommand} opts.CreateMediaSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaSlide}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get media slide
   * Returns the requested media slide
   * @param {String} id Id of the media slide to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaSlide} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _MediaSlide.default;
    return this.apiClient.callApi('/api/v1/MediaSlides/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get media slide
   * Returns the requested media slide
   * @param {String} id Id of the media slide to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaSlide}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a specific media slide list
   * Returns an array of the media slide included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediaSlide>} and HTTP response
   */
  getListWithHttpInfo(listName) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling getList");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_MediaSlide.default];
    return this.apiClient.callApi('/api/v1/MediaSlides/list/{listName}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a specific media slide list
   * Returns an array of the media slide included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediaSlide>}
   */
  getList(listName) {
    return this.getListWithHttpInfo(listName).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media slide lists
   * Returns a dictionary of listnames to media slide lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: [MediaSlide]}>} and HTTP response
   */
  getListsWithHttpInfo(names) {
    let postBody = null;
    // verify the required parameter 'names' is set
    if (names === undefined || names === null) {
      throw new Error("Missing the required parameter 'names' when calling getLists");
    }
    let pathParams = {};
    let queryParams = {
      'names': this.apiClient.buildCollectionParam(names, 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': [_MediaSlide.default]
    };
    return this.apiClient.callApi('/api/v1/MediaSlides/lists', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media slide lists
   * Returns a dictionary of listnames to media slide lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: [MediaSlide]}>}
   */
  getLists(names) {
    return this.getListsWithHttpInfo(names).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media slides
   * Returns an array of requested media slides
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the media slides to get. Not specifiying ids will return all media slides
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediaSlide>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_MediaSlide.default];
    return this.apiClient.callApi('/api/v1/MediaSlides/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media slides
   * Returns an array of requested media slides
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the media slides to get. Not specifiying ids will return all media slides
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediaSlide>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple media slides referencing the requested id by a certain field
   * Returns a list of media slides that are referencing the requested id by a certain field
   * @param {String} field The field of the media slide that is referencing the requested id
   * @param {String} id Id of the media slide that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediaSlide>} and HTTP response
   */
  getReferencesWithHttpInfo(field, id) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error("Missing the required parameter 'field' when calling getReferences");
    }
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getReferences");
    }
    let pathParams = {
      'field': field,
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_MediaSlide.default];
    return this.apiClient.callApi('/api/v1/MediaSlides/references/{field}/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple media slides referencing the requested id by a certain field
   * Returns a list of media slides that are referencing the requested id by a certain field
   * @param {String} field The field of the media slide that is referencing the requested id
   * @param {String} id Id of the media slide that is referenced
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediaSlide>}
   */
  getReferences(field, id) {
    return this.getReferencesWithHttpInfo(field, id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query media slides
   * Queries media slides and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SlideQuery} opts.SlideQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaSlideQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['SlideQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _MediaSlideQueryResult.default;
    return this.apiClient.callApi('/api/v1/MediaSlides/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query media slides
   * Queries media slides and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/SlideQuery} opts.SlideQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaSlideQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete media slide
   * Deletes a media slide and returns the deleted object
   * @param {String} id Id of the media slide to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaSlide} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _MediaSlide.default;
    return this.apiClient.callApi('/api/v1/MediaSlides/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete media slide
   * Deletes a media slide and returns the deleted object
   * @param {String} id Id of the media slide to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaSlide}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple media slides
   * Deletes multiple media slides and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the media slides to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediaSlide>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_MediaSlide.default];
    return this.apiClient.callApi('/api/v1/MediaSlides/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple media slides
   * Deletes multiple media slides and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the media slides to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediaSlide>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update media slides
   * Updates a media slide and returns the updated object
   * @param {String} id Id of the media slide to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMediaSlideCommand} opts.UpdateMediaSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediaSlide} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['UpdateMediaSlideCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _MediaSlide.default;
    return this.apiClient.callApi('/api/v1/MediaSlides/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update media slides
   * Updates a media slide and returns the updated object
   * @param {String} id Id of the media slide to update
   * @param {Object} opts Optional parameters
   * @param {module:model/UpdateMediaSlideCommand} opts.UpdateMediaSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediaSlide}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Upload slide files
   * Uploads files to the specified slide
   * @param {String} id Id of the media slide to upload files to
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FileIndex>} and HTTP response
   */
  uploadFilesWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling uploadFiles");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'files': this.apiClient.buildCollectionParam(opts['files'], 'passthrough')
    };
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['application/json'];
    let returnType = [_FileIndex.default];
    return this.apiClient.callApi('/api/v1/MediaSlides/{id}/files', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Upload slide files
   * Uploads files to the specified slide
   * @param {String} id Id of the media slide to upload files to
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FileIndex>}
   */
  uploadFiles(id, opts) {
    return this.uploadFilesWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = MediaSlidesApi;