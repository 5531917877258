export const isDebug = (function () {
	try {
		const params = new URLSearchParams(window?.location?.search);
		return params.get('debug') === 'true' || process.env.NODE_ENV !== 'production';
	} catch (e) {
		console.error('Cannot get is debug. Returning true', e);
		return true;
	}
})();
export default isDebug;
