import { all } from 'redux-saga/effects';
import { loadAuthWatcher, loadPermissionsWatcher, loggedInWatcher, logoutWatcher } from './auth';
import { indexDataFlowWatcher } from './index-data';
import { learnMoreSubmitWatcher } from './learn-more';
import {
	resourceGetCustomerMetaWatcher,
	resourceGetListWatcher,
	resourceGetRefManyWatcher,
	resourceGetUserMetaWatcher,
	resourceGetWatcher,
	resourceMutationWatcher,
	resourceQueryGetWatcher
} from './resources';
import {
	requestPinSubmitted,
	resetPasswordWatcher,
	signForgotPasswordWatcher,
	signInPickUserSubmitWatcher,
	signInSubmitWatcher
} from './sign-in';

export const rootSaga = function* rootSaga() {
	yield all([
		indexDataFlowWatcher(),
		resourceGetWatcher(),
		resourceQueryGetWatcher(),
		resourceGetRefManyWatcher(),
		resourceGetCustomerMetaWatcher(),
		resourceGetUserMetaWatcher(),
		resourceGetListWatcher(),
		resourceMutationWatcher(),
		learnMoreSubmitWatcher(),
		signInSubmitWatcher(),
		signInPickUserSubmitWatcher(),
		signForgotPasswordWatcher(),
		resetPasswordWatcher(),
		requestPinSubmitted(),
		loadPermissionsWatcher(),
		logoutWatcher(),
		loggedInWatcher(),
		loadAuthWatcher()
	]);
};
