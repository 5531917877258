import { createReducer } from './utils';

// action types
export const LEARN_MORE_MODAL_TOGGLE_OPEN = 'LEARN_MORE_MODAL_TOGGLE_OPEN'; // toggles the open/closed state of the learn more modal
export const LEARN_MORE_SUBMIT = 'LEARN_MORE_SUBMIT'; // sets the submitting state of the learn more form
export const LEARN_MORE_SUBMIT_SUCCESS = 'LEARN_MORE_SUBMIT_SUCCESS'; // sets the submitting state of the learn more form to succesfull
export const LEARN_MORE_SUBMIT_FAILURE = 'LEARN_MORE_SUBMIT_FAILURE'; // sets the submitting state of the learn more form to failed

export const LEARN_MORE_MODAL_STATE_FORM = 'FORM';
export const LEARN_MORE_MODAL_STATE_SUCCESS = 'SUCCESS';
export const LEARN_MORE_MODAL_STATE_FAILURE = 'FAILURE';

// action creators (returns the description of each action). They must return an object in the form redux expects it (with a type property defining which action is this)
// Any possible data that must be dispatched along with the action name are put in the 'payload' property of the action object.

export const learnMoreToggleOpen = () => ({ type: LEARN_MORE_MODAL_TOGGLE_OPEN }); // creates the action object for toggling
export const learnMoreSubmit = values => ({ type: LEARN_MORE_SUBMIT, payload: values }); // creates the action object for submitting the form. The payload property contains the data to be submitted.
export const learnMoreSubmitSuccess = values => ({
	type: LEARN_MORE_SUBMIT_SUCCESS,
	payload: values
}); // creates the action object for submit success. The payload property contains the data returned by the server.
export const learnMoreSubmitFailure = error => ({
	type: LEARN_MORE_SUBMIT_FAILURE,
	payload: error
}); // creates the action object for submit failure. The payload property contains the error that was occured

// reducers (or action handlers). They are the pure methods that execute an action on the present state and provide the next state.
export const learnMoreReducer = createReducer(
	{
		// toggle open reducer. The next state has all the same properties with the previous one, except 'modalIsOpen' which is inverted
		// and 'modalState' which is set to 'FORM' when the modal is just opened
		[LEARN_MORE_MODAL_TOGGLE_OPEN]: state => {
			let newIsOpen = !state.modalIsOpen;
			return {
				...state,
				modalIsOpen: !state.modalIsOpen,
				modalState: newIsOpen ? LEARN_MORE_MODAL_STATE_FORM : state.modalState
			};
		},
		[LEARN_MORE_SUBMIT]: state => ({ ...state, result: null, error: null, submitting: true }), // submit reducer. The next state has submitting set to true and error and result set to null (they are reset when a new submission occurs).
		[LEARN_MORE_SUBMIT_SUCCESS]: (state, values) => ({
			...state,
			result: { ...values },
			error: null,
			submitting: false,
			modalState: LEARN_MORE_MODAL_STATE_SUCCESS
		}),
		[LEARN_MORE_SUBMIT_FAILURE]: (state, error) => ({
			...state,
			error: error,
			result: null,
			submitting: false,
			modalState: LEARN_MORE_MODAL_STATE_FAILURE
		})
	},
	{
		state: {
			modalIsOpen: false,
			modalState: null,
			result: null,
			error: null,
			submitting: false
		}
	}
);

// selectors
export const getLearnMoreModalIsOpen = state => !!state.learnMore.modalIsOpen;
export const getLearnMoreModalState = state => state.learnMore.modalState;
export const getLearnMoreSubmitting = state => !!state.learnMore.submitting;
export const getLearnMoreSubmitResult = state => state.learnMore.result;
export const getLearnMoreSubmitError = state => state.learnMore.error;
