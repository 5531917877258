"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _PaymentMode = _interopRequireDefault(require("./PaymentMode"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CreateCustomerSubscriptionCommand model module.
 * @module model/CreateCustomerSubscriptionCommand
 * @version 1.1.3-alpha.1
 */
class CreateCustomerSubscriptionCommand {
  /**
   * Constructs a new <code>CreateCustomerSubscriptionCommand</code>.
   * @alias module:model/CreateCustomerSubscriptionCommand
   * @param customer_id {String} 
   * @param subscription_type_id {String} 
   * @param valid_from {String} 
   * @param payment_mode {module:model/PaymentMode} 
   */
  constructor(customer_id, subscription_type_id, valid_from, payment_mode) {
    CreateCustomerSubscriptionCommand.initialize(this, customer_id, subscription_type_id, valid_from, payment_mode);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, customer_id, subscription_type_id, valid_from, payment_mode) {
    obj['customer_id'] = customer_id;
    obj['subscription_type_id'] = subscription_type_id;
    obj['valid_from'] = valid_from;
    obj['payment_mode'] = payment_mode;
  }

  /**
   * Constructs a <code>CreateCustomerSubscriptionCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateCustomerSubscriptionCommand} obj Optional instance to populate.
   * @return {module:model/CreateCustomerSubscriptionCommand} The populated <code>CreateCustomerSubscriptionCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateCustomerSubscriptionCommand();
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('subscription_type_id')) {
        obj['subscription_type_id'] = _ApiClient.default.convertToType(data['subscription_type_id'], 'String');
      }
      if (data.hasOwnProperty('valid_from')) {
        obj['valid_from'] = _ApiClient.default.convertToType(data['valid_from'], 'String');
      }
      if (data.hasOwnProperty('valid_to')) {
        obj['valid_to'] = _ApiClient.default.convertToType(data['valid_to'], 'String');
      }
      if (data.hasOwnProperty('purchased_date')) {
        obj['purchased_date'] = _ApiClient.default.convertToType(data['purchased_date'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('paid_amount')) {
        obj['paid_amount'] = _ApiClient.default.convertToType(data['paid_amount'], 'Number');
      }
      if (data.hasOwnProperty('is_paid')) {
        obj['is_paid'] = _ApiClient.default.convertToType(data['is_paid'], 'Boolean');
      }
      if (data.hasOwnProperty('payment_mode')) {
        obj['payment_mode'] = _PaymentMode.default.constructFromObject(data['payment_mode']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CreateCustomerSubscriptionCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateCustomerSubscriptionCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CreateCustomerSubscriptionCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['subscription_type_id'] && !(typeof data['subscription_type_id'] === 'string' || data['subscription_type_id'] instanceof String)) {
      throw new Error("Expected the field `subscription_type_id` to be a primitive type in the JSON string but got " + data['subscription_type_id']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    return true;
  }
}
CreateCustomerSubscriptionCommand.RequiredProperties = ["customer_id", "subscription_type_id", "valid_from", "payment_mode"];

/**
 * @member {String} customer_id
 */
CreateCustomerSubscriptionCommand.prototype['customer_id'] = undefined;

/**
 * @member {String} subscription_type_id
 */
CreateCustomerSubscriptionCommand.prototype['subscription_type_id'] = undefined;

/**
 * @member {String} valid_from
 */
CreateCustomerSubscriptionCommand.prototype['valid_from'] = undefined;

/**
 * @member {String} valid_to
 */
CreateCustomerSubscriptionCommand.prototype['valid_to'] = undefined;

/**
 * @member {String} purchased_date
 */
CreateCustomerSubscriptionCommand.prototype['purchased_date'] = undefined;

/**
 * @member {String} description
 */
CreateCustomerSubscriptionCommand.prototype['description'] = undefined;

/**
 * @member {Number} paid_amount
 */
CreateCustomerSubscriptionCommand.prototype['paid_amount'] = undefined;

/**
 * @member {Boolean} is_paid
 */
CreateCustomerSubscriptionCommand.prototype['is_paid'] = undefined;

/**
 * @member {module:model/PaymentMode} payment_mode
 */
CreateCustomerSubscriptionCommand.prototype['payment_mode'] = undefined;
var _default = exports.default = CreateCustomerSubscriptionCommand;