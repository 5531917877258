import { RESOURCES } from '@extend/paywall-api/lib';
import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-resource-plugins';
import {
	authLoadPermissions,
	authLogOut,
	authPreferencesLoaded,
	getAuthAvailableCountries,
	getAuthDisplayName,
	getAuthError,
	getAuthExpired,
	getAuthIsAuthenticated,
	getAuthPending,
	getAuthState,
	getPermission,
	getPermissionsLoading,
	getSubscriptionType,
	getUserContactId,
	getUserCustomerId,
	getUserId,
	getUserRole,
	getUserRoleStatus
} from '../state/auth';
import { usePaywallApi } from './paywall-api';

export const useAuthentication = () => {
	const dispatch = useDispatch();

	const { success, name, userId, customerId, contactId, subscriptionTypeId, role, pending, expired } = useSelector(
		state => ({
			success: getAuthIsAuthenticated(state),
			name: getAuthDisplayName(state),
			userId: getUserId(state),
			customerId: getUserCustomerId(state),
			contactId: getUserContactId(state),
			subscriptionTypeId: getSubscriptionType(state),
			role: getUserRole(state),
			pending: getAuthPending(state),
			expired: getAuthExpired(state)
		}),
		shallowEqual
	);

	const error = useSelector(getAuthError, shallowEqual);
	const state = useSelector(getAuthState, shallowEqual);

	const logOut = useCallback(() => {
		dispatch(authLogOut());
		RESOURCES.forEach(resource => dispatch(reset.resetResource(resource)));
	}, [dispatch]);

	return {
		success,
		name,
		userId,
		role,
		customerId,
		contactId,
		subscriptionTypeId,
		pending,
		expired,
		error,
		state,
		logOut
	};
};

export const useLoggedInUserId = () => useSelector(getUserId);
export const useLoggedInUserCustomerId = () => useSelector(getUserCustomerId);
export const useLoggedInUserContactID = () => useSelector(getUserContactId);
export const useLoggedInUserRole = () => useSelector(getUserRole);
export const useLoggedInUserRoleStatus = () => useSelector(getUserRoleStatus, shallowEqual);
export const useSubscriptionTypeID = () => useSelector(getSubscriptionType);

export const useAllPermissions = () => useSelector(state => get(state, 'auth.permissions', {}), shallowEqual);
export const usePermission = perm => useSelector(state => getPermission(state, perm));
export const usePermissions = perms =>
	useSelector(state => {
		var result = {};
		var _perms = get(state, `auth.permissions`, {});
		perms.forEach(perm => (result[perm] = _perms[perm] || false));
		return result;
	}, shallowEqual);
export const usePermissionLoading = () => useSelector(getPermissionsLoading);
export const useAvailableCountries = () => useSelector(getAuthAvailableCountries);
const createSetInclusionMap = (array, fnKey) => {
	const result = {};
	if (!array || array.length === 0) return result;
	for (var i = 0; i < array.length; ++i) {
		const key = fnKey ? fnKey(array[i]) : array[i];
		result[key] = true;
	}
};

export const useSetMemo = (array, fnKey) => {
	const arrayRef = useRef();
	const setRef = useRef({});
	const result = useMemo(() => {
		var current = arrayRef.current;
		var currentMap = setRef.current;

		if ((array && !current) || (current && !array)) {
			arrayRef.current = array;
			setRef.current = createSetInclusionMap(array, fnKey);
		} else if (array && current) {
			var nextMap = createSetInclusionMap(array, fnKey);
			var currentKeys = Object.keys(currentMap);
			var nextKeys = Object.keys(nextMap);
			if (nextKeys.find(key => !currentMap[key]) || currentKeys.find(key => !nextMap[key])) {
				arrayRef.current = array;
				setRef.current = nextMap;
			}
		}
		return arrayRef.current;
	}, [array, fnKey]);
	return result;
};

export const useObjectMemo = (obj, fnEquality) => {
	const objRef = useRef();
	return useMemo(() => {
		var current = objRef.current;
		var equals = fnEquality ? fnEquality(obj, current) : obj === current;
		if (!equals) objRef.current = obj;
		return objRef.current;
	}, [obj, fnEquality]);
};

export const useArrayMemo = (array, fnEquality) => {
	const arrayRef = useRef();
	return useMemo(() => {
		var current = arrayRef.current;
		if ((array && !current) || (current && !array)) arrayRef.current = array;
		else if (array && current) {
			if (array.length !== current.length) arrayRef.current = array;
			else {
				for (var i = 0; i < array.length; ++i) {
					var equals = fnEquality ? fnEquality(array[i], current[i]) : array[i] === current[i];
					if (!equals) {
						arrayRef.current = array;
						break;
					}
				}
			}
		}
		return arrayRef.current;
	}, [array, fnEquality]);
};

export const usePermissionLoader = permissions => {
	const dispatch = useDispatch();
	const actualPermissions = useArrayMemo(permissions);
	const loader = useCallback(
		({ merge = true } = {}) => dispatch(authLoadPermissions({ permissions: actualPermissions, merge })),
		[actualPermissions, dispatch]
	);
	return loader;
};

export const useAutoLoadPermissions = ({ permissions, merge = true } = {}) => {
	const loader = usePermissionLoader(permissions);
	var perms = usePermissions(permissions || []);
	var loading = usePermissionLoading();
	useEffect(() => {
		loader(merge);
	}, [merge, loader]);

	return { permissions: perms, loading: loading };
};

export const usePreferenceApi = () => {
	const api = usePaywallApi();
	const dispatch = useDispatch();

	const user_id = useSelector(state => get(state, ['auth', 'user_id'], null));

	const fetch = useCallback(async () => {
		if (user_id) {
			var prefs = await api.getUserPreferences(user_id);
			dispatch(authPreferencesLoaded(prefs));
		}
	}, [api, dispatch, user_id]);

	const updateMany = useCallback(
		async prefs => {
			if (user_id) {
				var newPrefs = await api.setUserPreferences(user_id, prefs);
				dispatch(authPreferencesLoaded(newPrefs));
			}
		},
		[api, dispatch, user_id]
	);

	const update = useCallback(
		async (id, value) => {
			if (user_id) {
				var newPrefs = await api.setUserPreferences(user_id, { [id]: value });
				dispatch(authPreferencesLoaded(newPrefs));
			}
		},
		[api, dispatch, user_id]
	);

	return { fetch, updateMany, update };
};

export const usePreference = id => useSelector(state => get(state, ['auth', 'preferences', id], {}), shallowEqual);
