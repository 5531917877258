import { ApiSession } from '@extend/paywall-api/lib';
import { localStorage } from 'js-storage';

export const apiBasePath = (window && window.apiBasePath) || process.env.REACT_APP_API_URL;
export const getApiPath = path => `${apiBasePath}${path}`;

export const createSession = sessionKey => {
	var persistedTokenKey = sessionKey ? `${sessionKey}--authToken` : 'authToken';
	const saveToken = token =>
		token ? localStorage.set(persistedTokenKey, token) : localStorage.remove(persistedTokenKey);
	const loadToken = () => localStorage.get(persistedTokenKey);
	var tokenPersistor = {
		get save() {
			return saveToken;
		},
		get load() {
			return loadToken;
		}
	};
	var token = loadToken();
	var api = ApiSession.create(apiBasePath, token);
	return {
		get tokenPersistor() {
			return tokenPersistor;
		},
		get initialToken() {
			return token;
		},
		get api() {
			return api;
		}
	};
};

const defaultSession = createSession();

export default defaultSession;
