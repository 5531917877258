"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedCustomerType = _interopRequireDefault(require("./DefinedCustomerType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Customer model module.
 * @module model/Customer
 * @version 1.1.3-alpha.1
 */
class Customer {
  /**
   * Constructs a new <code>Customer</code>.
   * @alias module:model/Customer
   * @param afm {String} 
   * @param doy {String} 
   * @param job_description {String} 
   * @param name {String} 
   * @param last_name {String} 
   * @param customer_type {module:model/DefinedCustomerType} 
   * @param user_id {String} 
   * @param is_suspended {Boolean} 
   * @param auto_renewal {Boolean} 
   * @param report_pdf_download {Boolean} 
   * @param id {String} 
   * @param created_at {String} 
   * @param updated_at {String} 
   */
  constructor(afm, doy, job_description, name, last_name, customer_type, user_id, is_suspended, auto_renewal, report_pdf_download, id, created_at, updated_at) {
    Customer.initialize(this, afm, doy, job_description, name, last_name, customer_type, user_id, is_suspended, auto_renewal, report_pdf_download, id, created_at, updated_at);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, afm, doy, job_description, name, last_name, customer_type, user_id, is_suspended, auto_renewal, report_pdf_download, id, created_at, updated_at) {
    obj['afm'] = afm;
    obj['doy'] = doy;
    obj['job_description'] = job_description;
    obj['name'] = name;
    obj['last_name'] = last_name;
    obj['customer_type'] = customer_type;
    obj['user_id'] = user_id;
    obj['is_suspended'] = is_suspended;
    obj['auto_renewal'] = auto_renewal;
    obj['report_pdf_download'] = report_pdf_download;
    obj['id'] = id;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
  }

  /**
   * Constructs a <code>Customer</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Customer} obj Optional instance to populate.
   * @return {module:model/Customer} The populated <code>Customer</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Customer();
      if (data.hasOwnProperty('afm')) {
        obj['afm'] = _ApiClient.default.convertToType(data['afm'], 'String');
      }
      if (data.hasOwnProperty('doy')) {
        obj['doy'] = _ApiClient.default.convertToType(data['doy'], 'String');
      }
      if (data.hasOwnProperty('job_description')) {
        obj['job_description'] = _ApiClient.default.convertToType(data['job_description'], 'String');
      }
      if (data.hasOwnProperty('email_domains')) {
        obj['email_domains'] = _ApiClient.default.convertToType(data['email_domains'], 'String');
      }
      if (data.hasOwnProperty('email_whitelist')) {
        obj['email_whitelist'] = _ApiClient.default.convertToType(data['email_whitelist'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('is_counted')) {
        obj['is_counted'] = _ApiClient.default.convertToType(data['is_counted'], 'Boolean');
      }
      if (data.hasOwnProperty('active_subscription_id')) {
        obj['active_subscription_id'] = _ApiClient.default.convertToType(data['active_subscription_id'], 'Number');
      }
      if (data.hasOwnProperty('active_subscription_type_id')) {
        obj['active_subscription_type_id'] = _ApiClient.default.convertToType(data['active_subscription_type_id'], 'String');
      }
      if (data.hasOwnProperty('user_count')) {
        obj['user_count'] = _ApiClient.default.convertToType(data['user_count'], 'Number');
      }
      if (data.hasOwnProperty('user_activated_count')) {
        obj['user_activated_count'] = _ApiClient.default.convertToType(data['user_activated_count'], 'Number');
      }
      if (data.hasOwnProperty('user_suspended_count')) {
        obj['user_suspended_count'] = _ApiClient.default.convertToType(data['user_suspended_count'], 'Number');
      }
      if (data.hasOwnProperty('uq_user_logins_p6m')) {
        obj['uq_user_logins_p6m'] = _ApiClient.default.convertToType(data['uq_user_logins_p6m'], 'Number');
      }
      if (data.hasOwnProperty('uq_user_logins_p12m')) {
        obj['uq_user_logins_p12m'] = _ApiClient.default.convertToType(data['uq_user_logins_p12m'], 'Number');
      }
      if (data.hasOwnProperty('logins_per_user_p6m')) {
        obj['logins_per_user_p6m'] = _ApiClient.default.convertToType(data['logins_per_user_p6m'], 'Number');
      }
      if (data.hasOwnProperty('logins_per_user_p12m')) {
        obj['logins_per_user_p12m'] = _ApiClient.default.convertToType(data['logins_per_user_p12m'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = _ApiClient.default.convertToType(data['last_name'], 'String');
      }
      if (data.hasOwnProperty('customer_type')) {
        obj['customer_type'] = _DefinedCustomerType.default.constructFromObject(data['customer_type']);
      }
      if (data.hasOwnProperty('mobile_phone')) {
        obj['mobile_phone'] = _ApiClient.default.convertToType(data['mobile_phone'], 'String');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('logo_id')) {
        obj['logo_id'] = _ApiClient.default.convertToType(data['logo_id'], 'String');
      }
      if (data.hasOwnProperty('is_suspended')) {
        obj['is_suspended'] = _ApiClient.default.convertToType(data['is_suspended'], 'Boolean');
      }
      if (data.hasOwnProperty('auto_renewal')) {
        obj['auto_renewal'] = _ApiClient.default.convertToType(data['auto_renewal'], 'Boolean');
      }
      if (data.hasOwnProperty('report_pdf_download')) {
        obj['report_pdf_download'] = _ApiClient.default.convertToType(data['report_pdf_download'], 'Boolean');
      }
      if (data.hasOwnProperty('industry_id')) {
        obj['industry_id'] = _ApiClient.default.convertToType(data['industry_id'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
      if (data.hasOwnProperty('created_by')) {
        obj['created_by'] = _ApiClient.default.convertToType(data['created_by'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('time_created')) {
        obj['time_created'] = _ApiClient.default.convertToType(data['time_created'], 'String');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'String');
      }
      if (data.hasOwnProperty('updated_by')) {
        obj['updated_by'] = _ApiClient.default.convertToType(data['updated_by'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
      if (data.hasOwnProperty('time_updated')) {
        obj['time_updated'] = _ApiClient.default.convertToType(data['time_updated'], 'String');
      }
      if (data.hasOwnProperty('deleted_at')) {
        obj['deleted_at'] = _ApiClient.default.convertToType(data['deleted_at'], 'String');
      }
      if (data.hasOwnProperty('deleted_by')) {
        obj['deleted_by'] = _ApiClient.default.convertToType(data['deleted_by'], 'String');
      }
      if (data.hasOwnProperty('date_deleted')) {
        obj['date_deleted'] = _ApiClient.default.convertToType(data['date_deleted'], 'String');
      }
      if (data.hasOwnProperty('time_deleted')) {
        obj['time_deleted'] = _ApiClient.default.convertToType(data['time_deleted'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Customer</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Customer</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Customer.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['afm'] && !(typeof data['afm'] === 'string' || data['afm'] instanceof String)) {
      throw new Error("Expected the field `afm` to be a primitive type in the JSON string but got " + data['afm']);
    }
    // ensure the json data is a string
    if (data['doy'] && !(typeof data['doy'] === 'string' || data['doy'] instanceof String)) {
      throw new Error("Expected the field `doy` to be a primitive type in the JSON string but got " + data['doy']);
    }
    // ensure the json data is a string
    if (data['job_description'] && !(typeof data['job_description'] === 'string' || data['job_description'] instanceof String)) {
      throw new Error("Expected the field `job_description` to be a primitive type in the JSON string but got " + data['job_description']);
    }
    // ensure the json data is a string
    if (data['email_domains'] && !(typeof data['email_domains'] === 'string' || data['email_domains'] instanceof String)) {
      throw new Error("Expected the field `email_domains` to be a primitive type in the JSON string but got " + data['email_domains']);
    }
    // ensure the json data is a string
    if (data['email_whitelist'] && !(typeof data['email_whitelist'] === 'string' || data['email_whitelist'] instanceof String)) {
      throw new Error("Expected the field `email_whitelist` to be a primitive type in the JSON string but got " + data['email_whitelist']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['active_subscription_type_id'] && !(typeof data['active_subscription_type_id'] === 'string' || data['active_subscription_type_id'] instanceof String)) {
      throw new Error("Expected the field `active_subscription_type_id` to be a primitive type in the JSON string but got " + data['active_subscription_type_id']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
      throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
    }
    // ensure the json data is a string
    if (data['mobile_phone'] && !(typeof data['mobile_phone'] === 'string' || data['mobile_phone'] instanceof String)) {
      throw new Error("Expected the field `mobile_phone` to be a primitive type in the JSON string but got " + data['mobile_phone']);
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['logo_id'] && !(typeof data['logo_id'] === 'string' || data['logo_id'] instanceof String)) {
      throw new Error("Expected the field `logo_id` to be a primitive type in the JSON string but got " + data['logo_id']);
    }
    // ensure the json data is a string
    if (data['industry_id'] && !(typeof data['industry_id'] === 'string' || data['industry_id'] instanceof String)) {
      throw new Error("Expected the field `industry_id` to be a primitive type in the JSON string but got " + data['industry_id']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
      throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
    }
    // ensure the json data is a string
    if (data['time_created'] && !(typeof data['time_created'] === 'string' || data['time_created'] instanceof String)) {
      throw new Error("Expected the field `time_created` to be a primitive type in the JSON string but got " + data['time_created']);
    }
    // ensure the json data is a string
    if (data['updated_by'] && !(typeof data['updated_by'] === 'string' || data['updated_by'] instanceof String)) {
      throw new Error("Expected the field `updated_by` to be a primitive type in the JSON string but got " + data['updated_by']);
    }
    // ensure the json data is a string
    if (data['time_updated'] && !(typeof data['time_updated'] === 'string' || data['time_updated'] instanceof String)) {
      throw new Error("Expected the field `time_updated` to be a primitive type in the JSON string but got " + data['time_updated']);
    }
    // ensure the json data is a string
    if (data['deleted_by'] && !(typeof data['deleted_by'] === 'string' || data['deleted_by'] instanceof String)) {
      throw new Error("Expected the field `deleted_by` to be a primitive type in the JSON string but got " + data['deleted_by']);
    }
    // ensure the json data is a string
    if (data['time_deleted'] && !(typeof data['time_deleted'] === 'string' || data['time_deleted'] instanceof String)) {
      throw new Error("Expected the field `time_deleted` to be a primitive type in the JSON string but got " + data['time_deleted']);
    }
    return true;
  }
}
Customer.RequiredProperties = ["afm", "doy", "job_description", "name", "last_name", "customer_type", "user_id", "is_suspended", "auto_renewal", "report_pdf_download", "id", "created_at", "updated_at"];

/**
 * @member {String} afm
 */
Customer.prototype['afm'] = undefined;

/**
 * @member {String} doy
 */
Customer.prototype['doy'] = undefined;

/**
 * @member {String} job_description
 */
Customer.prototype['job_description'] = undefined;

/**
 * @member {String} email_domains
 */
Customer.prototype['email_domains'] = undefined;

/**
 * @member {String} email_whitelist
 */
Customer.prototype['email_whitelist'] = undefined;

/**
 * @member {String} email
 */
Customer.prototype['email'] = undefined;

/**
 * @member {Boolean} is_counted
 */
Customer.prototype['is_counted'] = undefined;

/**
 * @member {Number} active_subscription_id
 */
Customer.prototype['active_subscription_id'] = undefined;

/**
 * @member {String} active_subscription_type_id
 */
Customer.prototype['active_subscription_type_id'] = undefined;

/**
 * @member {Number} user_count
 */
Customer.prototype['user_count'] = undefined;

/**
 * @member {Number} user_activated_count
 */
Customer.prototype['user_activated_count'] = undefined;

/**
 * @member {Number} user_suspended_count
 */
Customer.prototype['user_suspended_count'] = undefined;

/**
 * @member {Number} uq_user_logins_p6m
 */
Customer.prototype['uq_user_logins_p6m'] = undefined;

/**
 * @member {Number} uq_user_logins_p12m
 */
Customer.prototype['uq_user_logins_p12m'] = undefined;

/**
 * @member {Number} logins_per_user_p6m
 */
Customer.prototype['logins_per_user_p6m'] = undefined;

/**
 * @member {Number} logins_per_user_p12m
 */
Customer.prototype['logins_per_user_p12m'] = undefined;

/**
 * @member {String} name
 */
Customer.prototype['name'] = undefined;

/**
 * @member {String} last_name
 */
Customer.prototype['last_name'] = undefined;

/**
 * @member {module:model/DefinedCustomerType} customer_type
 */
Customer.prototype['customer_type'] = undefined;

/**
 * @member {String} mobile_phone
 */
Customer.prototype['mobile_phone'] = undefined;

/**
 * @member {String} user_id
 */
Customer.prototype['user_id'] = undefined;

/**
 * @member {String} logo_id
 */
Customer.prototype['logo_id'] = undefined;

/**
 * @member {Boolean} is_suspended
 */
Customer.prototype['is_suspended'] = undefined;

/**
 * @member {Boolean} auto_renewal
 */
Customer.prototype['auto_renewal'] = undefined;

/**
 * @member {Boolean} report_pdf_download
 */
Customer.prototype['report_pdf_download'] = undefined;

/**
 * @member {String} industry_id
 */
Customer.prototype['industry_id'] = undefined;

/**
 * @member {String} id
 */
Customer.prototype['id'] = undefined;

/**
 * @member {String} created_at
 */
Customer.prototype['created_at'] = undefined;

/**
 * @member {String} created_by
 */
Customer.prototype['created_by'] = undefined;

/**
 * @member {String} date_created
 */
Customer.prototype['date_created'] = undefined;

/**
 * @member {String} time_created
 */
Customer.prototype['time_created'] = undefined;

/**
 * @member {String} updated_at
 */
Customer.prototype['updated_at'] = undefined;

/**
 * @member {String} updated_by
 */
Customer.prototype['updated_by'] = undefined;

/**
 * @member {String} date_updated
 */
Customer.prototype['date_updated'] = undefined;

/**
 * @member {String} time_updated
 */
Customer.prototype['time_updated'] = undefined;

/**
 * @member {String} deleted_at
 */
Customer.prototype['deleted_at'] = undefined;

/**
 * @member {String} deleted_by
 */
Customer.prototype['deleted_by'] = undefined;

/**
 * @member {String} date_deleted
 */
Customer.prototype['date_deleted'] = undefined;

/**
 * @member {String} time_deleted
 */
Customer.prototype['time_deleted'] = undefined;
var _default = exports.default = Customer;