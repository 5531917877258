const methodMap = [
	[
		'requestFullscreen',
		'exitFullscreen',
		'fullscreenElement',
		'fullscreenEnabled',
		'fullscreenchange',
		'fullscreenerror'
	],
	// New WebKit
	[
		'webkitRequestFullscreen',
		'webkitExitFullscreen',
		'webkitFullscreenElement',
		'webkitFullscreenEnabled',
		'webkitfullscreenchange',
		'webkitfullscreenerror'
	],
	// Old WebKit
	[
		'webkitRequestFullScreen',
		'webkitCancelFullScreen',
		'webkitCurrentFullScreenElement',
		'webkitCancelFullScreen',
		'webkitfullscreenchange',
		'webkitfullscreenerror'
	],
	[
		'mozRequestFullScreen',
		'mozCancelFullScreen',
		'mozFullScreenElement',
		'mozFullScreenEnabled',
		'mozfullscreenchange',
		'mozfullscreenerror'
	],
	[
		'msRequestFullscreen',
		'msExitFullscreen',
		'msFullscreenElement',
		'msFullscreenEnabled',
		'MSFullscreenChange',
		'MSFullscreenError'
	]
];

const nativeAPI = (() => {
	const unprefixedMethods = methodMap[0];
	const returnValue = {};

	for (const methodList of methodMap) {
		const exitFullscreenMethod = methodList?.[1];
		if (exitFullscreenMethod in document) {
			for (const [index, method] of methodList.entries()) {
				returnValue[unprefixedMethods[index]] = method;
			}

			return returnValue;
		}
	}

	return false;
})();

const eventNameMap = {
	change: nativeAPI.fullscreenchange,
	error: nativeAPI.fullscreenerror
};

// eslint-disable-next-line import/no-mutable-exports
let _screenfull = {
	// eslint-disable-next-line default-param-last
	request(element, options) {
		try {
			if (!element) element = document.documentElement;
			const returnPromise = element[nativeAPI.requestFullscreen](options);
			if (returnPromise instanceof Promise) return returnPromise;
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	exit() {
		try {
			const returnPromise = document[nativeAPI.exitFullscreen]();
			if (returnPromise instanceof Promise) return returnPromise;
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	toggle(element, options) {
		return _screenfull.isFullscreen ? _screenfull.exit() : _screenfull.request(element, options);
	},
	onchange(callback) {
		_screenfull.on('change', callback);
	},
	onerror(callback) {
		_screenfull.on('error', callback);
	},
	on(event, callback) {
		const eventName = eventNameMap[event];
		if (eventName) {
			document.addEventListener(eventName, callback, false);
		}
	},
	off(event, callback) {
		const eventName = eventNameMap[event];
		if (eventName) {
			document.removeEventListener(eventName, callback, false);
		}
	},
	raw: nativeAPI
};

Object.defineProperties(_screenfull, {
	isFullscreen: {
		get: () => Boolean(document[nativeAPI.fullscreenElement])
	},
	element: {
		enumerable: true,
		get: () => document[nativeAPI.fullscreenElement] ?? undefined
	},
	isEnabled: {
		enumerable: true,
		// Coerce to boolean in case of old WebKit.
		get: () => Boolean(document[nativeAPI.fullscreenEnabled])
	}
});

if (!nativeAPI) {
	_screenfull = { isEnabled: false };
}

export const screenfull = _screenfull;
