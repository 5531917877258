import React, { cloneElement, isValidElement } from 'react';
import { getFieldValue } from './components/fields/utils';

export const identityId = id => id;
export const fieldId = record => getFieldValue(record, 'id');

export const foreachKey = (object, callback) => {
	var _obj = object || {};
	Object.keys(_obj).forEach(key => {
		if (callback) callback(key, _obj[key]);
	});
};

export const propertyValuesOf = function* propertyValuesOf(object) {
	var _obj = object || {};
	var keys = Object.keys(_obj);
	for (var i = 0; i < keys.length; ++i) yield [keys[i], _obj[keys[i]]];
};

export const valuesOfArray = function* valuesOfArray(array) {
	if (Array.isArray(array)) {
		for (var i = 0; i < array.length; ++i) yield array[i];
	}
};

export const mapPropValues = (initialValues, userMapping, defaultMapping) => {
	var finalPropValues = {};
	foreachKey({ ...defaultMapping, ...(userMapping || {}) }, (key, targetProp) => {
		if (targetProp) finalPropValues[targetProp] = initialValues[key];
	});
	return finalPropValues;
};

export const renderFuncElementComponent = (render, Comp = null, props = {}, key) => {
	if (render == null) {
		if (Comp) return <Comp {...props} />;
		console.error('You should provide a render prop or component');
		return null;
	}

	const keyProps = typeof key !== 'undefined' ? { key: key } : {};

	if (isValidElement(render)) {
		return cloneElement(render, { ...props, ...keyProps, ...render.props });
	}
	return render({ ...props, ...keyProps });
};

export const renderFuncsOrElements = (array, props = {}) => {
	if (Array.isArray(array))
		return array.map((element, index) => renderFuncElementComponent(element, null, props, index));
	else return renderFuncElementComponent(array, null, props);
};

export const arrayFromCount = (count, itemCreator) => {
	let result = [];
	let total = count || 0;
	for (var i = 0; i < total; ++i) result.push(itemCreator(i));
	return result;
};

const getCancelledError = message => {
	var result = new Error(message);
	result.cancelled = true;
	return result;
};

export const cancelablePromise = p => {
	let cancelled = false;
	const wrappedPromise = new Promise((resolve, reject) =>
		p.then(val => (cancelled ? reject(getCancelledError('cancelled normally')) : resolve(val)))
	);
	return {
		promise: wrappedPromise,
		cancel: () => (cancelled = true)
	};
};

export const normalizrToReduxResource = (resource, { result, entities }, fromGetList) => {
	let { [resource]: returnedResources, ...restEntities } = entities || {};

	let resources = [];
	if (Array.isArray(result)) resources = result.map(key => returnedResources[key]);
	else if (result) resources = [returnedResources[result]];

	return {
		resources: resources,
		includedResources: restEntities
	};
};

export const promiseDelay = ms => new Promise(r => setTimeout(r, ms));
