"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AdScore = _interopRequireDefault(require("../model/AdScore"));
var _AdScoreQuery = _interopRequireDefault(require("../model/AdScoreQuery"));
var _AdScoreQueryResult = _interopRequireDefault(require("../model/AdScoreQueryResult"));
var _AdScoreSlide = _interopRequireDefault(require("../model/AdScoreSlide"));
var _BatchCreateAdScoreSlideCommand = _interopRequireDefault(require("../model/BatchCreateAdScoreSlideCommand"));
var _CommandCreateAdScore = _interopRequireDefault(require("../model/CommandCreateAdScore"));
var _CommandUpdateAdScore = _interopRequireDefault(require("../model/CommandUpdateAdScore"));
var _EntityAttachment = _interopRequireDefault(require("../model/EntityAttachment"));
var _EntityOneToMenyReferences = _interopRequireDefault(require("../model/EntityOneToMenyReferences"));
var _ViewAdScoreMeasurementsTable = _interopRequireDefault(require("../model/ViewAdScoreMeasurementsTable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* AdScores service.
* @module api/AdScoresApi
* @version 1.1.3-alpha.1
*/
class AdScoresApi {
  /**
  * Constructs a new AdScoresApi. 
  * @alias module:api/AdScoresApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Add multiple slides from an upload batch
   * Adds multiple slides from a previously uploaded batch
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/BatchCreateAdScoreSlideCommand} opts.BatchCreateAdScoreSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScoreSlide>} and HTTP response
   */
  addSlidesFromBatchWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['BatchCreateAdScoreSlideCommand'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addSlidesFromBatch");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_AdScoreSlide.default];
    return this.apiClient.callApi('/api/v1/AdScores/{id}/slides', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Add multiple slides from an upload batch
   * Adds multiple slides from a previously uploaded batch
   * @param {String} id 
   * @param {Object} opts Optional parameters
   * @param {module:model/BatchCreateAdScoreSlideCommand} opts.BatchCreateAdScoreSlideCommand 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScoreSlide>}
   */
  addSlidesFromBatch(id, opts) {
    return this.addSlidesFromBatchWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create a new adScore
   * Creates a new adScore and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandCreateAdScore} opts.CommandCreateAdScore 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScore} and HTTP response
   */
  createWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['CommandCreateAdScore'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AdScore.default;
    return this.apiClient.callApi('/api/v1/AdScores', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new adScore
   * Creates a new adScore and returns the created object
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandCreateAdScore} opts.CommandCreateAdScore 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScore}
   */
  create(opts) {
    return this.createWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete all slides of adscore
   * Delete all slides of adscore
   * @param {String} id Id of the adscore to delete all slides
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  deleteAllSlidesWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling deleteAllSlides");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['String'];
    return this.apiClient.callApi('/api/v1/AdScores/{id}/slides', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete all slides of adscore
   * Delete all slides of adscore
   * @param {String} id Id of the adscore to delete all slides
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  deleteAllSlides(id) {
    return this.deleteAllSlidesWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Exports adscores in excel format
   * Exports adscores and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreQuery} opts.AdScoreQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  exportFileWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AdScoreQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/AdScores/export', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Exports adscores in excel format
   * Exports adscores and returns a filtered and sorted excel file
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreQuery} opts.AdScoreQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  exportFile(opts) {
    return this.exportFileWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get adScore
   * Returns the requested adScore
   * @param {String} id Id of the adScores to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScore} and HTTP response
   */
  getWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling get");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _AdScore.default;
    return this.apiClient.callApi('/api/v1/AdScores/{id}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get adScore
   * Returns the requested adScore
   * @param {String} id Id of the adScores to get
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScore}
   */
  get(id) {
    return this.getWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get adScore's attachement
   * Returns the requested adScore's attachement
   * @param {String} id Id of the adScore whose attachment we request
   * @param {String} identifier Identifier of the attachment we request (it is unique within a adScore's attachments)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EntityAttachment} and HTTP response
   */
  getAttachmentWithHttpInfo(id, identifier) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAttachment");
    }
    // verify the required parameter 'identifier' is set
    if (identifier === undefined || identifier === null) {
      throw new Error("Missing the required parameter 'identifier' when calling getAttachment");
    }
    let pathParams = {
      'id': id,
      'identifier': identifier
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _EntityAttachment.default;
    return this.apiClient.callApi('/api/v1/AdScores/{id}/attachments/{identifier}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get adScore's attachement
   * Returns the requested adScore's attachement
   * @param {String} id Id of the adScore whose attachment we request
   * @param {String} identifier Identifier of the attachment we request (it is unique within a adScore's attachments)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EntityAttachment}
   */
  getAttachment(id, identifier) {
    return this.getAttachmentWithHttpInfo(id, identifier).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get adScore's attachments
   * Returns an array of requested adScore's attachments
   * @param {String} id Id of the adScore whose attachments we request
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.identifiers Identifiers of the requested attachments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/EntityAttachment>} and HTTP response
   */
  getAttachmentsWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getAttachments");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'identifiers': this.apiClient.buildCollectionParam(opts['identifiers'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_EntityAttachment.default];
    return this.apiClient.callApi('/api/v1/AdScores/{id}/attachments', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get adScore's attachments
   * Returns an array of requested adScore's attachments
   * @param {String} id Id of the adScore whose attachments we request
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.identifiers Identifiers of the requested attachments
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/EntityAttachment>}
   */
  getAttachments(id, opts) {
    return this.getAttachmentsWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get adScore's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested adScore. The action can be performed by the logged in customer or contact
   * @param {String} id Id of the adScore whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Object}>} and HTTP response
   */
  getCustomerMetaWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getCustomerMeta");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'meta': this.apiClient.buildCollectionParam(opts['meta'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': Object
    };
    return this.apiClient.callApi('/api/v1/AdScores/{id}/meta/customer', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get adScore's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested adScore. The action can be performed by the logged in customer or contact
   * @param {String} id Id of the adScore whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Object}>}
   */
  getCustomerMeta(id, opts) {
    return this.getCustomerMetaWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get adScore's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested adScore. The action can be performed by the logged in customer or contact
   * @param {Object} opts Optional parameters
   * @param {Object.<String, {String: [String]}>} opts.request_body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: {String: Object}}>} and HTTP response
   */
  getCustomerMetaMultipleWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = {
      'String': {
        'String': Object
      }
    };
    return this.apiClient.callApi('/api/v1/AdScores/meta/customer', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get adScore's metadata (in customer's scope)
   * Gets one, many or all customer-scoped metadata for the requested adScore. The action can be performed by the logged in customer or contact
   * @param {Object} opts Optional parameters
   * @param {Object.<String, {String: [String]}>} opts.request_body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: {String: Object}}>}
   */
  getCustomerMetaMultiple(opts) {
    return this.getCustomerMetaMultipleWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a specific adScore list
   * Returns an array of the adScores included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScore>} and HTTP response
   */
  getListWithHttpInfo(listName) {
    let postBody = null;
    // verify the required parameter 'listName' is set
    if (listName === undefined || listName === null) {
      throw new Error("Missing the required parameter 'listName' when calling getList");
    }
    let pathParams = {
      'listName': listName
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_AdScore.default];
    return this.apiClient.callApi('/api/v1/AdScores/list/{listName}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a specific adScore list
   * Returns an array of the adScores included in the list
   * @param {String} listName The list name to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScore>}
   */
  getList(listName) {
    return this.getListWithHttpInfo(listName).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple adScore lists
   * Returns a dictionary of listnames to adscore lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: [AdScore]}>} and HTTP response
   */
  getListsWithHttpInfo(names) {
    let postBody = null;
    // verify the required parameter 'names' is set
    if (names === undefined || names === null) {
      throw new Error("Missing the required parameter 'names' when calling getLists");
    }
    let pathParams = {};
    let queryParams = {
      'names': this.apiClient.buildCollectionParam(names, 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': [_AdScore.default]
    };
    return this.apiClient.callApi('/api/v1/AdScores/lists', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple adScore lists
   * Returns a dictionary of listnames to adscore lists
   * @param {Array.<String>} names The list names to retrieve
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: [AdScore]}>}
   */
  getLists(names) {
    return this.getListsWithHttpInfo(names).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get multiple adScores
   * Returns an array of requested adScores
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the adScores to get. Not specifiying ids will return all adScores
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScore>} and HTTP response
   */
  getManyWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['request_body'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_AdScore.default];
    return this.apiClient.callApi('/api/v1/AdScores/getMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get multiple adScores
   * Returns an array of requested adScores
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.request_body Ids of the adScores to get. Not specifiying ids will return all adScores
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScore>}
   */
  getMany(opts) {
    return this.getManyWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get measurements for a certain ad score
   * Returns the measurements table for a certain adscore
   * @param {String} id Id of the adScore of which we request the measurements
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ViewAdScoreMeasurementsTable>} and HTTP response
   */
  getMeasurementsWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getMeasurements");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_ViewAdScoreMeasurementsTable.default];
    return this.apiClient.callApi('/api/v1/AdScores/{id}/measurements', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get measurements for a certain ad score
   * Returns the measurements table for a certain adscore
   * @param {String} id Id of the adScore of which we request the measurements
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ViewAdScoreMeasurementsTable>}
   */
  getMeasurements(id) {
    return this.getMeasurementsWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get refrenced entities (one-to-many) of the requested adScore
   * Returns Get refrenced entities (one-to-many) of the requested adScore
   * @param {String} id The id of the adScore whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EntityOneToMenyReferences} and HTTP response
   */
  getOneToManyReferencesWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getOneToManyReferences");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _EntityOneToMenyReferences.default;
    return this.apiClient.callApi('/api/v1/AdScores/{id}/onetomanyreferences', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get refrenced entities (one-to-many) of the requested adScore
   * Returns Get refrenced entities (one-to-many) of the requested adScore
   * @param {String} id The id of the adScore whose references we request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EntityOneToMenyReferences}
   */
  getOneToManyReferences(id) {
    return this.getOneToManyReferencesWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get adScore's metadata (in user's scope)
   * Gets one, many or all user-scoped metadata for the requested adScore. The action can be performed by the logged in user
   * @param {String} id Id of the adScore whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Object}>} and HTTP response
   */
  getUserMetaWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getUserMeta");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'meta': this.apiClient.buildCollectionParam(opts['meta'], 'multi')
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = {
      'String': Object
    };
    return this.apiClient.callApi('/api/v1/AdScores/{id}/meta/user', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get adScore's metadata (in user's scope)
   * Gets one, many or all user-scoped metadata for the requested adScore. The action can be performed by the logged in user
   * @param {String} id Id of the adScore whose metadata we get
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.meta List of metadata we request. Empty to get all metadata
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Object}>}
   */
  getUserMeta(id, opts) {
    return this.getUserMetaWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Years with ad scores
   * Returns a list with the years that have ad scores going on air
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<Number>} and HTTP response
   */
  getYearsWithDataWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['Number'];
    return this.apiClient.callApi('/api/v1/AdScores/yearsWithData', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Years with ad scores
   * Returns a list with the years that have ad scores going on air
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<Number>}
   */
  getYearsWithData() {
    return this.getYearsWithDataWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Query adScores
   * Queries adScores and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreQuery} opts.AdScoreQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScoreQueryResult} and HTTP response
   */
  queryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AdScoreQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AdScoreQueryResult.default;
    return this.apiClient.callApi('/api/v1/AdScores/query', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Query adScores
   * Queries adScores and returns a filtered, sorted and paged result
   * @param {Object} opts Optional parameters
   * @param {module:model/AdScoreQuery} opts.AdScoreQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScoreQueryResult}
   */
  query(opts) {
    return this.queryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete adScore
   * Deletes a adScore and returns the deleted object
   * @param {String} id Id of the adScore to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScore} and HTTP response
   */
  removeWithHttpInfo(id) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling remove");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _AdScore.default;
    return this.apiClient.callApi('/api/v1/AdScores/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete adScore
   * Deletes a adScore and returns the deleted object
   * @param {String} id Id of the adScore to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScore}
   */
  remove(id) {
    return this.removeWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete multiple adScores
   * Deletes multiple adScores and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the adScores to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdScore>} and HTTP response
   */
  removeManyWithHttpInfo(request_body) {
    let postBody = request_body;
    // verify the required parameter 'request_body' is set
    if (request_body === undefined || request_body === null) {
      throw new Error("Missing the required parameter 'request_body' when calling removeMany");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_AdScore.default];
    return this.apiClient.callApi('/api/v1/AdScores/removeMany', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete multiple adScores
   * Deletes multiple adScores and returns a list of the deleted records
   * @param {Array.<String>} request_body Ids of the adScores to delete
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdScore>}
   */
  removeMany(request_body) {
    return this.removeManyWithHttpInfo(request_body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update adScore
   * Updates a adScore and returns the updated object
   * @param {String} id Id of the adScores to update
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandUpdateAdScore} opts.CommandUpdateAdScore 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AdScore} and HTTP response
   */
  updateWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = opts['CommandUpdateAdScore'];
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling update");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AdScore.default;
    return this.apiClient.callApi('/api/v1/AdScores/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update adScore
   * Updates a adScore and returns the updated object
   * @param {String} id Id of the adScores to update
   * @param {Object} opts Optional parameters
   * @param {module:model/CommandUpdateAdScore} opts.CommandUpdateAdScore 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AdScore}
   */
  update(id, opts) {
    return this.updateWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Upload measurements
   * Uploads statistic measurements for a given ad score in the form of an excel file
   * @param {String} id Id of the adScore for wich we upload the measurements
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ViewAdScoreMeasurementsTable>} and HTTP response
   */
  uploadMeasurementsWithHttpInfo(id, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling uploadMeasurements");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'files': this.apiClient.buildCollectionParam(opts['files'], 'passthrough')
    };
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['application/json'];
    let returnType = [_ViewAdScoreMeasurementsTable.default];
    return this.apiClient.callApi('/api/v1/AdScores/{id}/measurements', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Upload measurements
   * Uploads statistic measurements for a given ad score in the form of an excel file
   * @param {String} id Id of the adScore for wich we upload the measurements
   * @param {Object} opts Optional parameters
   * @param {Array.<File>} opts.files 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ViewAdScoreMeasurementsTable>}
   */
  uploadMeasurements(id, opts) {
    return this.uploadMeasurementsWithHttpInfo(id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AdScoresApi;