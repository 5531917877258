"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBrandSyncName = exports.getBrandSyncKey = exports.default = exports.BrandSyncValues = exports.BrandSyncName = exports.BrandSyncKeyName = exports.BrandSyncItems = exports.BRAND_SYNC_WEAK = exports.BRAND_SYNC_VERY_WEAK = exports.BRAND_SYNC_VERY_STRONG = exports.BRAND_SYNC_STRONG = exports.BRAND_SYNC_MODERATE = void 0;
const BRAND_SYNC_VERY_WEAK = 0;
exports.BRAND_SYNC_VERY_WEAK = BRAND_SYNC_VERY_WEAK;
const BRAND_SYNC_WEAK = 1;
exports.BRAND_SYNC_WEAK = BRAND_SYNC_WEAK;
const BRAND_SYNC_MODERATE = 2;
exports.BRAND_SYNC_MODERATE = BRAND_SYNC_MODERATE;
const BRAND_SYNC_STRONG = 3;
exports.BRAND_SYNC_STRONG = BRAND_SYNC_STRONG;
const BRAND_SYNC_VERY_STRONG = 4;
exports.BRAND_SYNC_VERY_STRONG = BRAND_SYNC_VERY_STRONG;
const BrandSyncName = {
  [BRAND_SYNC_VERY_WEAK]: 'Very Weak',
  [BRAND_SYNC_WEAK]: 'Weak',
  [BRAND_SYNC_MODERATE]: 'Moderate',
  [BRAND_SYNC_STRONG]: 'Strong',
  [BRAND_SYNC_VERY_STRONG]: 'Very Strong'
};
exports.BrandSyncName = BrandSyncName;
const BrandSyncKeyName = {
  [BRAND_SYNC_VERY_WEAK]: 'very_weak',
  [BRAND_SYNC_WEAK]: 'weak',
  [BRAND_SYNC_MODERATE]: 'moderate',
  [BRAND_SYNC_STRONG]: 'strong',
  [BRAND_SYNC_VERY_STRONG]: 'very_strong'
};
exports.BrandSyncKeyName = BrandSyncKeyName;
const getBrandSyncName = id => BrandSyncName[id] || '-';
exports.getBrandSyncName = getBrandSyncName;
const getBrandSyncKey = id => BrandSyncKeyName[id] || '-';
exports.getBrandSyncKey = getBrandSyncKey;
const BrandSyncValues = [BRAND_SYNC_VERY_WEAK, BRAND_SYNC_WEAK, BRAND_SYNC_MODERATE, BRAND_SYNC_STRONG, BRAND_SYNC_VERY_STRONG];
exports.BrandSyncValues = BrandSyncValues;
const BrandSyncItems = BrandSyncValues.map(id => ({
  id: id,
  name: BrandSyncName[id],
  keyText: BrandSyncKeyName[id]
}));
exports.BrandSyncItems = BrandSyncItems;
var _default = {
  VERY_WEAK: BRAND_SYNC_VERY_WEAK,
  WEAK: BRAND_SYNC_WEAK,
  MODERATE: BRAND_SYNC_MODERATE,
  STRONG: BRAND_SYNC_STRONG,
  VERY_STRONG: BRAND_SYNC_VERY_STRONG,
  Values: BrandSyncValues,
  Items: BrandSyncItems,
  Name: BrandSyncName,
  KeyName: BrandSyncKeyName,
  getName: getBrandSyncName,
  getKey: getBrandSyncKey
};
exports.default = _default;