"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UserAction model module.
 * @module model/UserAction
 * @version 1.1.3-alpha.1
 */
class UserAction {
  /**
   * Constructs a new <code>UserAction</code>.
   * @alias module:model/UserAction
   */
  constructor() {
    UserAction.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UserAction</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserAction} obj Optional instance to populate.
   * @return {module:model/UserAction} The populated <code>UserAction</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserAction();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('action_datetime')) {
        obj['action_datetime'] = _ApiClient.default.convertToType(data['action_datetime'], 'String');
      }
      if (data.hasOwnProperty('action_date')) {
        obj['action_date'] = _ApiClient.default.convertToType(data['action_date'], 'String');
      }
      if (data.hasOwnProperty('action_time')) {
        obj['action_time'] = _ApiClient.default.convertToType(data['action_time'], 'String');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('contact_id')) {
        obj['contact_id'] = _ApiClient.default.convertToType(data['contact_id'], 'Number');
      }
      if (data.hasOwnProperty('action')) {
        obj['action'] = _ApiClient.default.convertToType(data['action'], 'String');
      }
      if (data.hasOwnProperty('master_entity_id')) {
        obj['master_entity_id'] = _ApiClient.default.convertToType(data['master_entity_id'], 'String');
      }
      if (data.hasOwnProperty('child_entity_1_id')) {
        obj['child_entity_1_id'] = _ApiClient.default.convertToType(data['child_entity_1_id'], 'String');
      }
      if (data.hasOwnProperty('child_entity_2_id')) {
        obj['child_entity_2_id'] = _ApiClient.default.convertToType(data['child_entity_2_id'], 'String');
      }
      if (data.hasOwnProperty('text_data')) {
        obj['text_data'] = _ApiClient.default.convertToType(data['text_data'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('first_name')) {
        obj['first_name'] = _ApiClient.default.convertToType(data['first_name'], 'String');
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = _ApiClient.default.convertToType(data['last_name'], 'String');
      }
      if (data.hasOwnProperty('client')) {
        obj['client'] = _ApiClient.default.convertToType(data['client'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UserAction</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserAction</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['action_time'] && !(typeof data['action_time'] === 'string' || data['action_time'] instanceof String)) {
      throw new Error("Expected the field `action_time` to be a primitive type in the JSON string but got " + data['action_time']);
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['action'] && !(typeof data['action'] === 'string' || data['action'] instanceof String)) {
      throw new Error("Expected the field `action` to be a primitive type in the JSON string but got " + data['action']);
    }
    // ensure the json data is a string
    if (data['master_entity_id'] && !(typeof data['master_entity_id'] === 'string' || data['master_entity_id'] instanceof String)) {
      throw new Error("Expected the field `master_entity_id` to be a primitive type in the JSON string but got " + data['master_entity_id']);
    }
    // ensure the json data is a string
    if (data['child_entity_1_id'] && !(typeof data['child_entity_1_id'] === 'string' || data['child_entity_1_id'] instanceof String)) {
      throw new Error("Expected the field `child_entity_1_id` to be a primitive type in the JSON string but got " + data['child_entity_1_id']);
    }
    // ensure the json data is a string
    if (data['child_entity_2_id'] && !(typeof data['child_entity_2_id'] === 'string' || data['child_entity_2_id'] instanceof String)) {
      throw new Error("Expected the field `child_entity_2_id` to be a primitive type in the JSON string but got " + data['child_entity_2_id']);
    }
    // ensure the json data is a string
    if (data['text_data'] && !(typeof data['text_data'] === 'string' || data['text_data'] instanceof String)) {
      throw new Error("Expected the field `text_data` to be a primitive type in the JSON string but got " + data['text_data']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['first_name'] && !(typeof data['first_name'] === 'string' || data['first_name'] instanceof String)) {
      throw new Error("Expected the field `first_name` to be a primitive type in the JSON string but got " + data['first_name']);
    }
    // ensure the json data is a string
    if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
      throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
    }
    // ensure the json data is a string
    if (data['client'] && !(typeof data['client'] === 'string' || data['client'] instanceof String)) {
      throw new Error("Expected the field `client` to be a primitive type in the JSON string but got " + data['client']);
    }
    return true;
  }
}

/**
 * @member {Number} id
 */
UserAction.prototype['id'] = undefined;

/**
 * @member {String} action_datetime
 */
UserAction.prototype['action_datetime'] = undefined;

/**
 * @member {String} action_date
 */
UserAction.prototype['action_date'] = undefined;

/**
 * @member {String} action_time
 */
UserAction.prototype['action_time'] = undefined;

/**
 * @member {String} user_id
 */
UserAction.prototype['user_id'] = undefined;

/**
 * @member {String} customer_id
 */
UserAction.prototype['customer_id'] = undefined;

/**
 * @member {Number} contact_id
 */
UserAction.prototype['contact_id'] = undefined;

/**
 * @member {String} action
 */
UserAction.prototype['action'] = undefined;

/**
 * @member {String} master_entity_id
 */
UserAction.prototype['master_entity_id'] = undefined;

/**
 * @member {String} child_entity_1_id
 */
UserAction.prototype['child_entity_1_id'] = undefined;

/**
 * @member {String} child_entity_2_id
 */
UserAction.prototype['child_entity_2_id'] = undefined;

/**
 * @member {String} text_data
 */
UserAction.prototype['text_data'] = undefined;

/**
 * @member {String} email
 */
UserAction.prototype['email'] = undefined;

/**
 * @member {String} first_name
 */
UserAction.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
UserAction.prototype['last_name'] = undefined;

/**
 * @member {String} client
 */
UserAction.prototype['client'] = undefined;
var _default = exports.default = UserAction;