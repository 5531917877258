"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SubscriptionDistributionSetting model module.
 * @module model/SubscriptionDistributionSetting
 * @version 1.1.3-alpha.1
 */
class SubscriptionDistributionSetting {
  /**
   * Constructs a new <code>SubscriptionDistributionSetting</code>.
   * @alias module:model/SubscriptionDistributionSetting
   * @param id {String} 
   * @param subscription_type_id {String} 
   * @param distribution_plan_id {String} 
   * @param slide_notes_available {Boolean} 
   * @param slide_comments_available {Boolean} 
   * @param full_report_available {Boolean} 
   * @param full_report_unlocked {Boolean} 
   * @param full_report_unlock_limit {Number} 
   * @param webinar_available {Boolean} 
   * @param webinar_unlocked {Boolean} 
   * @param webinar_unlock_limit {Number} 
   */
  constructor(id, subscription_type_id, distribution_plan_id, slide_notes_available, slide_comments_available, full_report_available, full_report_unlocked, full_report_unlock_limit, webinar_available, webinar_unlocked, webinar_unlock_limit) {
    SubscriptionDistributionSetting.initialize(this, id, subscription_type_id, distribution_plan_id, slide_notes_available, slide_comments_available, full_report_available, full_report_unlocked, full_report_unlock_limit, webinar_available, webinar_unlocked, webinar_unlock_limit);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, subscription_type_id, distribution_plan_id, slide_notes_available, slide_comments_available, full_report_available, full_report_unlocked, full_report_unlock_limit, webinar_available, webinar_unlocked, webinar_unlock_limit) {
    obj['id'] = id;
    obj['subscription_type_id'] = subscription_type_id;
    obj['distribution_plan_id'] = distribution_plan_id;
    obj['slide_notes_available'] = slide_notes_available;
    obj['slide_comments_available'] = slide_comments_available;
    obj['full_report_available'] = full_report_available;
    obj['full_report_unlocked'] = full_report_unlocked;
    obj['full_report_unlock_limit'] = full_report_unlock_limit;
    obj['webinar_available'] = webinar_available;
    obj['webinar_unlocked'] = webinar_unlocked;
    obj['webinar_unlock_limit'] = webinar_unlock_limit;
  }

  /**
   * Constructs a <code>SubscriptionDistributionSetting</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubscriptionDistributionSetting} obj Optional instance to populate.
   * @return {module:model/SubscriptionDistributionSetting} The populated <code>SubscriptionDistributionSetting</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubscriptionDistributionSetting();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('subscription_type_id')) {
        obj['subscription_type_id'] = _ApiClient.default.convertToType(data['subscription_type_id'], 'String');
      }
      if (data.hasOwnProperty('distribution_plan_id')) {
        obj['distribution_plan_id'] = _ApiClient.default.convertToType(data['distribution_plan_id'], 'String');
      }
      if (data.hasOwnProperty('slide_notes_available')) {
        obj['slide_notes_available'] = _ApiClient.default.convertToType(data['slide_notes_available'], 'Boolean');
      }
      if (data.hasOwnProperty('slide_comments_available')) {
        obj['slide_comments_available'] = _ApiClient.default.convertToType(data['slide_comments_available'], 'Boolean');
      }
      if (data.hasOwnProperty('full_report_available')) {
        obj['full_report_available'] = _ApiClient.default.convertToType(data['full_report_available'], 'Boolean');
      }
      if (data.hasOwnProperty('full_report_unlocked')) {
        obj['full_report_unlocked'] = _ApiClient.default.convertToType(data['full_report_unlocked'], 'Boolean');
      }
      if (data.hasOwnProperty('full_report_unlocked_period')) {
        obj['full_report_unlocked_period'] = _ApiClient.default.convertToType(data['full_report_unlocked_period'], 'Number');
      }
      if (data.hasOwnProperty('full_report_unlock_limit')) {
        obj['full_report_unlock_limit'] = _ApiClient.default.convertToType(data['full_report_unlock_limit'], 'Number');
      }
      if (data.hasOwnProperty('webinar_available')) {
        obj['webinar_available'] = _ApiClient.default.convertToType(data['webinar_available'], 'Boolean');
      }
      if (data.hasOwnProperty('webinar_unlocked')) {
        obj['webinar_unlocked'] = _ApiClient.default.convertToType(data['webinar_unlocked'], 'Boolean');
      }
      if (data.hasOwnProperty('webinar_unlocked_period')) {
        obj['webinar_unlocked_period'] = _ApiClient.default.convertToType(data['webinar_unlocked_period'], 'Number');
      }
      if (data.hasOwnProperty('webinar_unlock_limit')) {
        obj['webinar_unlock_limit'] = _ApiClient.default.convertToType(data['webinar_unlock_limit'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SubscriptionDistributionSetting</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SubscriptionDistributionSetting</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of SubscriptionDistributionSetting.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['subscription_type_id'] && !(typeof data['subscription_type_id'] === 'string' || data['subscription_type_id'] instanceof String)) {
      throw new Error("Expected the field `subscription_type_id` to be a primitive type in the JSON string but got " + data['subscription_type_id']);
    }
    // ensure the json data is a string
    if (data['distribution_plan_id'] && !(typeof data['distribution_plan_id'] === 'string' || data['distribution_plan_id'] instanceof String)) {
      throw new Error("Expected the field `distribution_plan_id` to be a primitive type in the JSON string but got " + data['distribution_plan_id']);
    }
    return true;
  }
}
SubscriptionDistributionSetting.RequiredProperties = ["id", "subscription_type_id", "distribution_plan_id", "slide_notes_available", "slide_comments_available", "full_report_available", "full_report_unlocked", "full_report_unlock_limit", "webinar_available", "webinar_unlocked", "webinar_unlock_limit"];

/**
 * @member {String} id
 */
SubscriptionDistributionSetting.prototype['id'] = undefined;

/**
 * @member {String} subscription_type_id
 */
SubscriptionDistributionSetting.prototype['subscription_type_id'] = undefined;

/**
 * @member {String} distribution_plan_id
 */
SubscriptionDistributionSetting.prototype['distribution_plan_id'] = undefined;

/**
 * @member {Boolean} slide_notes_available
 */
SubscriptionDistributionSetting.prototype['slide_notes_available'] = undefined;

/**
 * @member {Boolean} slide_comments_available
 */
SubscriptionDistributionSetting.prototype['slide_comments_available'] = undefined;

/**
 * @member {Boolean} full_report_available
 */
SubscriptionDistributionSetting.prototype['full_report_available'] = undefined;

/**
 * @member {Boolean} full_report_unlocked
 */
SubscriptionDistributionSetting.prototype['full_report_unlocked'] = undefined;

/**
 * @member {Number} full_report_unlocked_period
 */
SubscriptionDistributionSetting.prototype['full_report_unlocked_period'] = undefined;

/**
 * @member {Number} full_report_unlock_limit
 */
SubscriptionDistributionSetting.prototype['full_report_unlock_limit'] = undefined;

/**
 * @member {Boolean} webinar_available
 */
SubscriptionDistributionSetting.prototype['webinar_available'] = undefined;

/**
 * @member {Boolean} webinar_unlocked
 */
SubscriptionDistributionSetting.prototype['webinar_unlocked'] = undefined;

/**
 * @member {Number} webinar_unlocked_period
 */
SubscriptionDistributionSetting.prototype['webinar_unlocked_period'] = undefined;

/**
 * @member {Number} webinar_unlock_limit
 */
SubscriptionDistributionSetting.prototype['webinar_unlock_limit'] = undefined;
var _default = exports.default = SubscriptionDistributionSetting;