import { Grid, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { cloneElement, useMemo } from 'react';
import { Link, useMatch } from 'react-router-dom';
import logoImage from '../assets/imgs/wisdrop_logo_HRZ-04.png';
import { useAutoLoadPermissions } from '../hooks/auth';
import { useMinimumScreenWidth } from '../hooks/browser';
import { setClasses } from '../utilities/classname-utils';
import { InviteColleagueButton } from './InviteColleagueButton';
import { Tab, WisdropTabs } from './WisdropTabs';
import { AccountDropDown } from './auth';
import { LogoImage } from './logo-image';

export const BetaIndicator = ({ classes, className, ...props }) => (
	<span className={setClasses('beta-label helvetica-bold', className, classes)} {...props}>
		{'BETA'}
	</span>
);

const tabs = [
	{
		content: 'Home',
		path: '/wisdrop'
	},
	{
		content: 'Ad Scores',
		path: '/adscores'
	},
	{
		content: 'My Reports',
		path: '/myreports'
	},
	{
		content: 'My List',
		path: '/mylist'
	} //,
	// {
	// 	content: 'Admin',
	// 	path: '/admin'
	// },
	// {
	// 	element: <FA icon={faSearch} />,
	// 	path: '/search',
	// 	className: 'search d-flex align-items-center justify-content-center'
	// }
];

const NavTab = ({
	content,
	element,
	classes,
	className,
	linkProps: { classes: linkClasses, className: linkClass, ...restLinkProps } = {},
	path,
	disabled,
	...props
}) => {
	let match = !!useMatch({ path: path, end: false });
	var actualContent = useMemo(() => {
		if (element) return cloneElement(element, { ...element.props, content });
		if (content) return content;
		return null;
	}, [content, element]);

	return (
		<Tab classes={setClasses(classes, className)} active={match} {...props}>
			{disabled ? (
				<button className={setClasses(linkClasses, linkClass)} onClick={null}>
					{actualContent}
				</button>
			) : (
				<Link className={setClasses(linkClasses, linkClass)} to={path} {...restLinkProps}>
					{actualContent}
				</Link>
			)}
		</Tab>
	);
};

const NavTabs = ({ tabs, classes, className, itemProps = {}, ...props }) => (
	<WisdropTabs classes={setClasses(classes, className, 'd-flex align-items-center')} {...props}>
		{tabs.map((tab, i) => (
			<NavTab key={i} {...tab} {...itemProps} />
		))}
	</WisdropTabs>
);

export const TopMenu = props => {
	const minWidth = 1024;
	const widthTooSmall = useMinimumScreenWidth(minWidth);
	var adscoresMatch = useMatch({ path: '/adscores', end: false });
	var searchMatch = useMatch({ path: '/search', end: false });
	var adminMatch = useMatch({ path: '/admin', end: false });

	const {
		logoSection,
		accountSection,
		search: searchClass,
		root: rootClass,
		invite: inviteClass
	} = useTopMenuStyles({ lightBg: adscoresMatch || searchMatch });

	const {
		permissions: { InviteColleague = true }
	} = useAutoLoadPermissions({ permissions: ['InviteColleague'] });

	return (
		<Grid container className={rootClass} justifyContent="center" alignItems="center" wrap="nowrap">
			<Grid item className={logoSection}>
				<Link to="/wisdrop">
					<LogoImage src={logoImage} hideShadow={!!adscoresMatch || !!searchMatch || !!adminMatch} />
				</Link>
			</Grid>
			<Grid item>{!widthTooSmall ? <NavTabs tabs={tabs} /> : null}</Grid>
			<Grid item className={clsx(accountSection, 'account-col')}>
				<Grid container justifyContent="flex-end" spacing={2} alignItems="center" wrap="nowrap">
					<Grid item>
						<Link to="/search" className={searchClass}>
							<SearchIcon fontSize="inherit" />
						</Link>
					</Grid>
					{!InviteColleague ? null : (
						<Grid item>
							<span className={inviteClass}>
								<InviteColleagueButton fontSize="inherit" />
							</span>
						</Grid>
					)}
					<Grid item>
						<AccountDropDown />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const useTopMenuStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		fontSize: '2.6vw',
		[theme.breakpoints.up('lg')]: { fontSize: '2vw' }
	},
	logoSection: {
		position: 'absolute',
		left: 0
	},
	accountSection: {
		position: 'absolute',
		right: 0
	},
	search: {
		'transition': 'color 0.3s',
		'color': ({ lightBg }) => (lightBg ? '#555' : 'white'),
		'&:hover': {
			color: ({ lightBg }) => (lightBg ? '#555' : 'white')
		}
	},
	invite: {
		'transition': 'color 0.3s',
		'color': ({ lightBg }) => (lightBg ? '#555' : 'white'),
		'cursor': 'pointer',
		'&:hover': {
			color: ({ lightBg }) => (lightBg ? '#555' : 'white')
		}
	}
}));
