"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CommandActivateAccount model module.
 * @module model/CommandActivateAccount
 * @version 1.1.3-alpha.1
 */
class CommandActivateAccount {
  /**
   * Constructs a new <code>CommandActivateAccount</code>.
   * @alias module:model/CommandActivateAccount
   * @param token {String} 
   * @param password {String} 
   * @param password_confirm {String} 
   * @param browser_id {String} 
   */
  constructor(token, password, password_confirm, browser_id) {
    CommandActivateAccount.initialize(this, token, password, password_confirm, browser_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, token, password, password_confirm, browser_id) {
    obj['token'] = token;
    obj['password'] = password;
    obj['password_confirm'] = password_confirm;
    obj['browser_id'] = browser_id;
  }

  /**
   * Constructs a <code>CommandActivateAccount</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CommandActivateAccount} obj Optional instance to populate.
   * @return {module:model/CommandActivateAccount} The populated <code>CommandActivateAccount</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CommandActivateAccount();
      if (data.hasOwnProperty('token')) {
        obj['token'] = _ApiClient.default.convertToType(data['token'], 'String');
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = _ApiClient.default.convertToType(data['password'], 'String');
      }
      if (data.hasOwnProperty('password_confirm')) {
        obj['password_confirm'] = _ApiClient.default.convertToType(data['password_confirm'], 'String');
      }
      if (data.hasOwnProperty('browser_id')) {
        obj['browser_id'] = _ApiClient.default.convertToType(data['browser_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CommandActivateAccount</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommandActivateAccount</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CommandActivateAccount.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['token'] && !(typeof data['token'] === 'string' || data['token'] instanceof String)) {
      throw new Error("Expected the field `token` to be a primitive type in the JSON string but got " + data['token']);
    }
    // ensure the json data is a string
    if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
      throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
    }
    // ensure the json data is a string
    if (data['password_confirm'] && !(typeof data['password_confirm'] === 'string' || data['password_confirm'] instanceof String)) {
      throw new Error("Expected the field `password_confirm` to be a primitive type in the JSON string but got " + data['password_confirm']);
    }
    // ensure the json data is a string
    if (data['browser_id'] && !(typeof data['browser_id'] === 'string' || data['browser_id'] instanceof String)) {
      throw new Error("Expected the field `browser_id` to be a primitive type in the JSON string but got " + data['browser_id']);
    }
    return true;
  }
}
CommandActivateAccount.RequiredProperties = ["token", "password", "password_confirm", "browser_id"];

/**
 * @member {String} token
 */
CommandActivateAccount.prototype['token'] = undefined;

/**
 * @member {String} password
 */
CommandActivateAccount.prototype['password'] = undefined;

/**
 * @member {String} password_confirm
 */
CommandActivateAccount.prototype['password_confirm'] = undefined;

/**
 * @member {String} browser_id
 */
CommandActivateAccount.prototype['browser_id'] = undefined;
var _default = exports.default = CommandActivateAccount;